import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { Calculation } from '../../../shared/models/calculation';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class SupplierCalculationsService {
  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierlevel/';
  }

  private supplierId: number;

  setId(id: number) {
    this.supplierId = id;
  }

  getCalculations(auctionClusterId: number, langCode: string): Observable<Array<Calculation>> {
    return this.webApiService.getList(this.apiPath + this.supplierId + `/ac/${auctionClusterId}/calculation?langCode=${langCode}`);
  }
}