export class Difference {
  childPropertyName: string;
  object1: object;
  Object2: object;
  parentObject1: object;
  parentObject2: object;
  object1TypeName: string;
  object2TypeName: string;
  object1Value: string;
  object2Value: string;
  messagePrefix: string;
  parentPropertyName: string;
  actualName: string;
  expectedName: string;
}
