import { Component, Injector, ViewChild, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClrTabs } from '@clr/angular';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { SaveConfirmationComponent } from '../../shared/components/save-confirmation/save-confirmation.component';

// models
import { Language } from '../../shared/models/language';
import { SupplierRole } from '../../shared/models/supplier-role';
import { UserSupplierRole } from '../../shared/models/user';

import { Supplier } from '../shared/models/supplier';
import { SupplierUser } from '../shared/models/supplier-user';

// services
import { LanguageService } from '../../shared/services/language.service';

import { SupplierUserService } from '../shared/services/supplier-user.service';


class UserSupplierItem extends UserSupplierRole {
  filteredSupplierRoles: Array<SupplierRole> = [];
  auctionFilterData = '-';
  catalogFilterData = '-';
  clockFilterData = '-';
}

const ESC_KEYCODE = 27;

@Component({
  selector: 'supplier-user-component',
  templateUrl: 'supplier-user.component.html',
  styleUrls: ['./supplier-user.component.scss']
})
export class SupplierUserComponent extends ItemDetailsComponent<SupplierUser> implements OnInit, OnDestroy {

  // tabs checkers
  isSupplierUser: true;
  languages: Array<Language> = [];

  suppliers: Array<Supplier>;
  supplierRoles: Array<SupplierRole> = [];

  emailVerified: boolean;
  initialEmail: string;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('saveConfirmation') saveConfirmation: SaveConfirmationComponent;
  formDirty: boolean = false;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: SupplierUserService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new SupplierUser();
    this.clean();
    this.setTranslations('USER');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open (users: Array<SupplierUser>, userId: number, languages: Array<Language>,
    suppliers: Array<Supplier>, supplierRoles: Array<SupplierRole>) {

    this.allItems = users;
    this.languages = languages;

    this.suppliers = suppliers;
    this.supplierRoles = supplierRoles;
    this.formDirty = false;

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;
    this.clean();

    if (userId != null) {
      this.isEditMode = true;
      this.emailVerified = true;
      this.spinner.show();
      this.dataService.getUser(+this.route.snapshot.params['id'], userId)
        .subscribe((res: SupplierUser) => {
          this.model = res;
          this.initialEmail = this.model.email;
          this.parseChildObjects();
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    } else {
      this.model = new SupplierUser();
      this.model.isActive = true;
      this.isEditMode = false;
      this.isOpened = true;
    }
  }


  shouldShowSupplierApproval() {
    return this.model && this.model.existingSupplierVat !== '' && this.model.isPending;
  }

  addToSupplierAccount(supplier: Supplier) {
    const alreadyAdded = this.model.supplierRoles.find(supplierRole => supplierRole.supplierId === supplier.supplierId);
    if (alreadyAdded) {
      return;
    }

    this.isSupplierUser = true;
    const userSupplier = new UserSupplierItem();
    userSupplier.supplierId = supplier.supplierId;
    this.filterSupplierItem(userSupplier);
    userSupplier.supplierRoleId = userSupplier.filteredSupplierRoles.find(_ => true).supplierRoleId;
    this.model.supplierRoles.push(userSupplier);
  }

  private clean() {
    this.emailVerified = false;
    this.initialEmail = null;
    this.isSupplierUser = true;
  }

  // // Parse 'SupplierRoles'
  private parseChildObjects() {

    if (!this.model.supplierRoles) {
      this.model.supplierRoles = [];
    } else {
      this.model.supplierRoles = this.model.supplierRoles.map(source => {
        const item = new UserSupplierItem();
        item.supplierId = source.supplierId;
        item.supplierRoleId = source.supplierRoleId;
        this.filterSupplierItem(item);
        return item;
      });
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
  if (!this.isSupplierUser) {
    this.model.supplierRoles = [];
  }

  if (!this.check()) {
    return;
  }

  if (this.model.supplierRoles.length === 0) { // tslint:disable-line:max-line-length
    this.errorMessage = this.errorService.translations.AT_LEAST_ONE_LEVEL_NEEDS_TO_BE_SELECTED;
    return;
  }

  if (this.isEditMode) {
      this.spinner.show();
    this.dataService.edit(+this.route.snapshot.params['id'], this.model)
      .subscribe((res: any) => {
        this.model = new SupplierUser();
        // this.detailsForm.reset();
        this.close(true);
        this.errorMessage = null;
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
    } else {
      // this.spinner.show();
      // this.dataService.save(+this.route.snapshot.params['id'], this.model)
      //   .subscribe((res: any) => {
      //     this.model = new AuctionClusterUser();
      //     this.detailsForm.reset();
      //     this.close(true);
      //     this.errorMessage = null;
      //     this.spinner.hide();
      //   },
      //   error => {
      //     this.errorMessage = this.errorService.toString(error);
      //     this.spinner.hide();
      //   });
    }
  }

  addSupplier() {
    this.model.supplierRoles.push(new UserSupplierItem());
  }

  filterSupplierItem(item: UserSupplierItem) {
    item.filteredSupplierRoles = this.supplierRoles.filter(f => f.supplierId === item.supplierId);
  }

  deleteSupplier(index: number) {
    this.model.supplierRoles.splice(index, 1);
  }

  private check(): boolean {

    // create user list without current user
    const userList = this.allItems.filter((u) => {
      return u.userId !== this.model.userId;
    });

    const userNameNotUniqueList = userList.filter((u) => {
      return u.email === this.model.email;
    });

    if (userNameNotUniqueList.length > 0) {
      this.errorService.show(this.errorService.translations.EMAIL_NOT_UNIQUE);
      return false;
    }

    if (this.isSupplierUser && this.model.supplierRoles.length === 0) {
      this.errorService.show(this.errorService.translations.NO_ASSIGNED_SUPPLIER_ROLES);
      return false;
    }

    if (!this.checkSuppliers(this.model.supplierRoles)) {
      return false;
    }

    return true;
  }

  checkSuppliers(supplierRoles: Array<UserSupplierRole>): boolean {

    let hasDuplicate = false;
    for (let i = 0; i < supplierRoles.length - 1; i++) {
      if (supplierRoles[i].supplierId === supplierRoles[i + 1].supplierId) {
        this.errorService.show(this.errorService.translations.SUPPLIER_HAS_ONE_ROLE);
        hasDuplicate = true;
      }
    }
    return !hasDuplicate;
  }

  verifyEmail(userEmail: string) {
    this.dataService.verifyEmail(+this.route.snapshot.params['id'], userEmail).subscribe((user: SupplierUser) => {

      if (this.isEditMode) {
        if (user && user.userId !== this.model.userId) {
          // not allowed
          this.errorService.show(this.errorService.translations.EMAIL_EXISTS);
          this.model.email = this.initialEmail; // roll back stored email address
        }
      } else {
        if (user) {
          // edit another user
          this.isEditMode = true;
          this.modalTitle = this.translations.EDIT;
          this.model = user;
          this.parseChildObjects();
        }
      }

      this.emailVerified = true;
    });
  }

  @HostListener('window:keydown', ['$event'])
  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE) {
      event.target.blur();
      if (this.formDirty) {
        this.saveConfirmation.opened = true;
      } else {
        this.onCancel();
      }
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnSupplierUserSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnSupplierUserSubmit')[0].removeAttribute('disabled');
      }

      if (!this.formDirty) this.formDirty = true;
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnSupplierUserSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnSupplierUserSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
    
  }

  translateSelectBoxes = (item) => {
    if (item) {
      const label = this.getTranslation(item.name);
      return label;
    }
  }

  onEditorPreparing = (e: any) => {

    if (
      e.dataField === 'supplierRoleId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          return new Promise((resolve, reject) => {
            e.component
              .getDataSource()
              .store()
              .load()
              .done(gridData => {
                resolve(this.supplierRoles.filter(f => f.supplierId === e.row.data.supplierId));
              });
          });
        }
      };
    }
  }
}
