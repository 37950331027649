import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'translateJson'
})
export class TranslateJsonPipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  transform(value?: string): string {
    if (this.isJson(value)) {
      return this.languageService.getTranslatableText(value);
    } else {
      return value;
    }
  }

  isJson(value: any) {
    value = typeof value !== 'string' ? JSON.stringify(value) : value;

    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }
    if (typeof value === 'object' && value !== null) {
      return true;
    }
    return false;
  }
}
