import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: Array<any>, term: string) {

    const result: Array<any> = [];
    // iterates over all fields of every list object
    for (const i in value) { // tslint:disable-line:no-for-in-array
      if (i) {
        for (const j in value[i]) { // tslint:disable-line:no-for-in-array
          if (value[i].hasOwnProperty(j) && value[i][j] != null) {
            // check if the field contains key value && if list object not already in array
            if (value[i][j].toString().toLowerCase().indexOf(term.toLowerCase()) !== -1 && result.indexOf(value[i]) === -1) {
              result.push(value[i]);
            }
          }
        }
      }
    }
    return result;
  }
}
