import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';

const SPINNER_DURATION = 250; // in miliseconds

@Injectable()
export class SpinnerService {

  isShowing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private visible: boolean;
  private requestNo: number;

  constructor() {
    this.requestNo = 0;
  }

  public show() {
    //console.log(new Error().stack);
    this.visible = true;
    this.requestNo++;

    timer(SPINNER_DURATION).subscribe(
      () => {
        if (this.visible) {
          this.isShowing.next(true);
        }
      });
  }

  public hide() {
    if (this.requestNo > 0) {
      this.requestNo--;
    }

    if (this.requestNo === 0) {
      this.visible = false;
      this.isShowing.next(false);
    }
  }
}
