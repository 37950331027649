import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { MasterDataListComponent } from './master-data-list.component';

// models
import { MasterData } from '../../shared/models/master-data';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';

import { MasterDataService } from '../shared/services/master-data.service';

@Component({
  selector: 'master-data-lists-component',
  templateUrl: 'master-data-lists.component.html',
  styleUrls: ['./master-data-lists.component.scss']
})
export class MasterDataListsComponent extends FullListComponent<MasterData, MasterDataListComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: MasterDataListComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: MasterDataService,
    private translateService: TranslateService,
  ) {
    super(injector, MasterData);
    this.title.set('MASTER_DATA.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('MASTER_DATA');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    this.dataService.getMasterDataLists(this.id, this.translateService.currentLang).subscribe(result => {
      this.items = result;
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null);
  }

  edit = (e: any) => {
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, e.row.data.masterDataListId);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.masterDataListId;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete, this.id)
      .subscribe(() => {
        this.getData();
        this.spinner.hide();
      },
        () => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  openMasterDataListEditor = (e: any) => {
    this.router.navigate([`/auction/masterdata/${this.id}/masterdatalist/${e.row.data.masterDataListId}`]);
  }

  openMasterDataListEditorClick = (e: any) => {
    this.router.navigate([`/auction/masterdata/${this.id}/masterdatalist/${e.data.masterDataListId}`]);
  }
}
