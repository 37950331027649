import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'collapsable-section',
    templateUrl: 'collapsable-section.component.html',
    styleUrls: ['collapsable-section.component.scss']
})
export class CollapsableSectionComponent {

    @Input() caption: string;
    @Input() sectionId: string;
    @Output() clicked = new EventEmitter<string>();

    @ViewChild('sectionClicked') section: any;

    isChecked: boolean;

    constructor(
        private cookies: CookieService
    ) {}

    // on click show the section menu bar and save the state in cookie
    onChange() {
        this.isChecked = !(this.cookies.get(this.sectionId) == 'true'); // tslint:disable-line:triple-equals
        this.cookies.set(this.sectionId, String(this.isChecked));
        this.clicked.emit(this.sectionId);
    }

    // hide section menu bar and save the state in cookie
    collapse() {
        this.isChecked = true;
        this.cookies.set(this.sectionId, String(true));
    }

    // get state of section from cookie
    getState() {
      const checked = this.cookies.get(this.sectionId) == 'true' || this.cookies.get(this.sectionId) == null; // tslint:disable-line:triple-equals
      this.section.nativeElement.checked = checked;
    }
}
