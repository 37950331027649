import { Component, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

// models
import { TermsAndConditions } from '../shared/models/terms-and-conditions';

// services
import { ErrorService } from '../../shared/services/error.service';
import { LanguageService } from '../../shared/services/language.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { TermsAndConditionsService } from '../../shared/services/terms-and-conditions.service';


@Component({
  selector: 'terms-and-conditions-add-component',
  templateUrl: './terms-and-conditions-add.component.html',
  styleUrls: ['./terms-and-conditions-add.component.scss']
})
export class TermsAndConditionsAddComponent implements OnDestroy {
  auctionClusterId: number;
  model: TermsAndConditions = new TermsAndConditions();
  form: FormGroup;
  isOpened = false;
  errorMessage = '';

  @ViewChild('fileInput') fileInput: ElementRef;

  @Output() onClosed = new EventEmitter<any>();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    private router: Router,
    private dataService: TermsAndConditionsService,
    protected route: ActivatedRoute,
    private spinner: SpinnerService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private languageService: LanguageService
  ) { 
    this.createForm();
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  open(auctionClusterId: number) {
    this.auctionClusterId = auctionClusterId;
    this.model = new TermsAndConditions();
    this.isOpened = true;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private prepareSave(): any {
    const input = new FormData();
    input.append('uploadedfile', this.form.get('uploadedfile').value);
    return input;
  }

  onSubmit() {
    this.errorMessage = '';
    const formModel = this.prepareSave();
    this.spinner.show();
    this.dataService.save(this.auctionClusterId, this.model.version, formModel).subscribe((res) => {
      this.spinner.hide();
      this.onClosed.emit(true);
      this.errorMessage = '';
      this.isOpened = false;
      this.clearFile();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  onCancel() {
    this.isOpened = false;
    this.errorMessage = '';
    this.clearFile();
    this.onClosed.emit(false);
  }

  clearFile() {
    this.form.get('uploadedfile').setValue(null);
    this.fileInput.nativeElement.value = '';
  }

  createForm() {
    this.form = this.fb.group({
      version: [null, Validators.required],
      uploadedfile: [null, Validators.required]
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('uploadedfile').setValue(file);
    }
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnTermsSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnTermsSubmit')[0].removeAttribute('disabled');
      }
    } else {
        document.getElementsByName('btnUserSubmit')[0].removeAttribute('disabled');
    }
  }
}
