import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

// components
import { DxFormComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// models
import { Catalog } from '../../shared/models/catalog';
import { Language } from '../../shared/models/language';
import { MasterData, MasterDataListField } from '../../shared/models/master-data';
import { ProductProperty, Product } from '../../shared/models/product';

import { Mask, MaskTypes } from '../shared/models/mask';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';

import { CatalogService } from '../shared/services/catalog.service';
import { MasterDataService } from '../shared/services/master-data.service';
import { MaskService } from '../shared/services/mask.service';
import { ProductService } from '../shared/services/product.service';


@Component({
  selector: 'mask-component',
  templateUrl: 'mask.component.html',
  styleUrls: ['./mask.component.scss']
})
export class MaskComponent extends ItemDetailsComponent<Mask> implements OnInit, OnDestroy {

  @ViewChild('maskName') maskName: TranslatableFieldComponent;

  auctionClusterId: number;
  languages: Array<Language> = [];
  maskTypes: any = MaskTypes;
  productProperties: Array<ProductProperty> = [];
  masterDataListFields: Array<MasterDataListField> = [];
  masterDataLists: Array<MasterData> = [];
  catalogs: Array<Catalog> = [];
  filteredCatalogs: Array<Catalog> = [];
  products: Array<Product> = [];
  translationSelectBoxes: any;
  validFields: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('editMasksForm', { read: DxFormComponent }) editMasksForm: DxFormComponent;
  @ViewChild('sourceCatalog') selectbox: DxSelectBoxComponent;

  constructor(
    protected injector: Injector,
    private dataService: MaskService,
    private languageService: LanguageService,
    private productService: ProductService,
    private masterDataService: MasterDataService,
    private catalogService: CatalogService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.maskTypes = Object.keys(this.maskTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.maskTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new Mask();
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.setTranslations('MASK');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(masks: Array<Mask>, maskId: number) {
    this.modalTitle = maskId ? this.translations.EDIT : this.translations.ADD_NEW;
    this.allItems = masks;

    forkJoin(
      this.languageService.getAuctionClusterLanguages(this.auctionClusterId),
      this.masterDataService.getMasterDataLists(this.auctionClusterId, this.translateService.currentLang),
      this.catalogService.getAllSupplyCatalogs(this.auctionClusterId),
      this.productService.getProducts(this.auctionClusterId, this.translateService.currentLang)
    ).subscribe(result => {
      this.languages = result[0];
      this.masterDataLists = result[1];
      this.catalogs = result[2];
      this.products = result[3];

      // EDIT
      if (maskId != null) {
        this.isEditMode = true;
        this.spinner.show();
        this.dataService.getMask(this.auctionClusterId, maskId)
          .subscribe((res: Mask) => {
            this.model = res;
            this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);

            this.onProductChanged(new Event('click'));
            this.isOpened = true;
            this.spinner.hide();
          },
            error => {
              this.errorService.show(error);
              this.spinner.hide();
            });
      // NEW
      } else {
        this.model = new Mask();
        this.model.name = this.model.name = this.languageService.updateStringToAllLanguages(this.languages, "{}");
        this.isEditMode = false;
        this.isOpened = true;
      }
    });
  }

  onCatalogChanged = (e: any) => {
    const catalog = this.catalogs.find(c => {
      return c.catalogId === this.model.sourceCatalogId;
    });
  }

  onProductChanged = (e: any) => {
    this.filteredCatalogs = this.catalogs.filter(c => {
      return c.productId === this.model.productId;
    });

    if (this.model.productId) {
      this.productService.getProduct(this.auctionClusterId, this.model.productId)
        .subscribe((pp: Product) => {
          this.productProperties = pp.productProperties;
        });
    }

  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
    this.model.name = this.maskName.data;

    if (this.model.sourceType === MaskTypes.SUPPLY_MASK_TYPE || this.model.sourceType === MaskTypes.SUPPLY_FORECAST_MASK_TYPE) {
      this.model.maskMasterDataListFields = [];
      this.model.sourceMasterDataListId = null;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          // this.model = res;
          this.model = new Mask();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;

      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new Mask();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
    if (this.errorMessage != null) {
      this.errorService.show(this.errorMessage);
    }
  }

  saveWithoutClose() {
    this.model.name = this.maskName.data;

    if (this.model.sourceType === MaskTypes.SUPPLY_MASK_TYPE || this.model.sourceType === MaskTypes.SUPPLY_FORECAST_MASK_TYPE) {
      this.model.maskMasterDataListFields = [];
      this.model.sourceMasterDataListId = null;
    }

    if (this.model.maskId) {
      this.isEditMode = true;
    } else {
      return;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;

      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  onFieldDataChanged = (e: any) => {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnMasksSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnMasksSubmit')[0].setAttribute('disabled', 'disabled');
      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnMasksSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnMasksSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }

    if (e.dataField === 'sourceCatalogId') {
      const catalogInterval = setInterval(() => {
        if (this.catalogs.length > 0) {
          clearInterval(catalogInterval);
          this.filteredCatalogs = this.catalogs.filter(c => {
            return c.productId === this.model.productId;
          });
        }
      }, 50);
    }
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.maskName.data);
      this.editMasksForm.instance.updateData(fieldName, this.maskName.data);
    }
  }

  translateSelectBoxes = (item: any) => {
    if (item) {
      return this.translations[item.name];
    }
  }
}