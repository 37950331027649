<div class="item-list-component auction-transaction-detail-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup width="98%"
            [showTitle]="true"
            title="{{ 'SHARED.TRANSACTIONS' | translate }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <!-- TransactionLot datagrid -->
        <dx-data-grid #transactionLotGrid [rtlEnabled]="rtlEnabled" [showBorders]="true"
                      (onInitialized)="initLotColumn($event)"
                      [hoverStateEnabled]="true"
                      [columnAutoWidth]="true"
                      [rowAlternationEnabled]="true"
                      title="{{ 'CATALOGS.LOTS' | translate }}">
          <div *dxTemplate="let cell of 'cellTemplate'">
            <span [innerHtml]="cell.text | format | safeHtml"></span>
          </div>
          <div *dxTemplate="let cell of 'textCellTemplate'">
            <span [innerHtml]="cell.text | safeHtml"></span>
          </div>
          <dxi-column [visible]="false"></dxi-column>
        </dx-data-grid>

        <dx-form #editForm [(formData)]="formdata" class="transactionForm">
          <dxi-item dataField="transactionPrice" editorType="dxNumberBox">
            <dxo-label text="{{ 'TRANSACTION_MONITOR.PRICE' | translate }}"></dxo-label>
            <dxi-validation-rule type="range" message="{{ ('VALIDATION.GREATER_THAN' | translate) + '0' }}" min="0"></dxi-validation-rule>
          </dxi-item>
        </dx-form>

        <button type="submit" class="btn btn-primary" (click)="onSubmit();">{{ 'SHARED.SAVE' | translate }}</button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
      </div>
      </dx-scroll-view>
  </dx-popup>
</div>
