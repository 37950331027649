import { Component, OnInit, OnDestroy, Injector, ViewChild} from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { LocationComponent } from './location.component';

// models
import { Location } from '../../shared/models/location';

// services
import { LocationService } from '../shared/services/location.service';


@Component({
  selector: 'locations-component',
  templateUrl: 'locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent extends FullListComponent<Location, LocationComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: LocationComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: LocationService,
  ) {
    super(injector, Location);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('LOCATIONS.TITLE');
    this.setTranslations('LOCATIONS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.dataService.getLocations(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  edit = (e: any) => {
    const locationId = e.row !== undefined ? e.row.data.locationId : e.data.locationId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, locationId, this.id);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.id);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.locationId;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe(_ => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }
}
