import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { Auction } from '../../../shared/models/auction';
import { ApplicationSettings } from '../../../shared/models/application-settings';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class AuctionService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auction';
  }

  getAuctions(): Observable<Array<Auction>> {
    return this.webApiService.getList(this.apiPath);
  }

  getAuction(auctionId): Observable<Auction> {
    return this.webApiService.get(this.apiPath, auctionId);
  }

  save(auction: Auction): Observable<any> {
    return this.webApiService.save<Auction>(this.apiPath, auction);
  }

  edit(auction: Auction): Observable<any> {
    return this.webApiService.edit<Auction>(this.apiPath, auction.auctionId, auction);
  }

  delete(auctionId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, auctionId);
  }
}
