import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';

const ALERT_DURATION = 5000; // in miliseconds

@Injectable()
export class AlertService {

  message: BehaviorSubject<string> = new BehaviorSubject('');
  isVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public alert(message: string) {
    this.message.next(message);
    this.isVisible.next(true);

    timer(ALERT_DURATION).subscribe(
      () => this.close()
    );
  }

  public close() {
    this.message.next('');
    this.isVisible.next(false);
  }
}
