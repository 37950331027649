import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReportsComponent } from './reports/reports.component';
import { ReportEditorComponent } from './reports/report-editor.component';
import { ReportExportComponent } from './reports/report-export.component';
import { SupplierUsersComponent } from './users/supplier-users.component';

import { SupplierContentComponent } from './content/supplier-content.component';
import { SupplierHomeComponent } from './home/supplier-home.component';
import { PublicSupplyForecastURKComponent } from '../shared/components/public-supply-forecast-urk/public-supply-forecast-urk.component';

import { SupplierClocksComponent } from './clocks/supplier-clocks.component';

import { AuthGuard } from '../shared/services/auth-guard.service';
import { BrowserGuard } from '../shared/services/browser-guard.service';
import { AdditionalMenuItemsContainerComponent } from '../shared/components/additional-menu-items-container/additional-menu-items-container.component';
import { SupplierForecastCatalogsComponent } from './catalogs/supplier-forecast-catalogs.component';
import { SupplyLotsComponent } from '../shared/components/catalogs/supply-lots.component';
import { SupplyLotsTouchComponent } from '../shared/components/catalogs/supply-lots-touch.component';

const appRoutes: Routes = [
  {
    path: 'supplier',
    component: SupplierContentComponent,
    canActivateChild: [AuthGuard, BrowserGuard],
    data: { level: 'supplier' },
    children: [
      {
        path: 'clocks/:id', component: SupplierClocksComponent, data: { permission: 'SupplierPermissions.4' }
      },
      {
        path: 'home', component: SupplierHomeComponent
      },
      {
        path: 'users/:id', component: SupplierUsersComponent, data: { permission: 'SupplierPermissions.3' }
      },
      {
        path: 'supplyforecast/:id', component: PublicSupplyForecastURKComponent
      },
      {
        path: ':id/menu-item/:menuId', component: AdditionalMenuItemsContainerComponent
      },
      {
        path: 'reports/:id',
        children: [
          {
            path: '',
            component: ReportsComponent,
            data: { permission: 'SupplierPermissions.2' }
          },
          {
            path: 'ac/:auctionClusterId/report/:reportId',
            component: ReportEditorComponent,
            data: { permission: 'SupplierPermissions.2' }
          },
          {
            path: 'ac/:auctionClusterId/report/:reportId/export',
            component: ReportExportComponent,
            data: { permission: 'SupplierPermissions.2' }
          }
        ],
        data: { permission: 'SupplierPermissions.2' }
      },
      { path: 'forecastcatalogs/:id/ac/:auctionClusterId', component: SupplierForecastCatalogsComponent, data: { permission: ['SupplierPermissions.5'] } },
      {
        path: 'catalogs/:id',
        data: { permission: 'SupplierPermissions.5' },
        children: [
          { path: 'ac/:auctionClusterId/supplyforecastlots/:catalogId', component: SupplyLotsComponent, data: { permission: 'SupplierPermissions.5', isForecast: true } },
          { path: 'ac/:auctionClusterId/supplyforecastlots/:catalogId/editor', component:  SupplyLotsTouchComponent, data: { permission: 'SupplierPermissions.5', isForecast: true } },
        ]
      },
    ]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(appRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class SupplierRoutingModule { }
