import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

// components
import { DxDateBoxComponent } from 'devextreme-angular';

// services
import { TranslateService } from '@ngx-translate/core';

export const LONG_DATE_FORMAT = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11ylabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

const NOON = 12;

@Component({
  selector: 'long-date-component',
  templateUrl: './long-date.component.html',
  providers: [{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: LONG_DATE_FORMAT }]
})
export class LongDateComponent {
  @ViewChild('dateInput') dateInput: DxDateBoxComponent;

  time: any;
  nameString: string;
  tabIndexValue: string;
  requiredValue: boolean;
  disabledValue: boolean;
  minValue: Date;

  constructor(private translateService: TranslateService, private dateTimeAdapter: DateTimeAdapter<any>) {
    dateTimeAdapter.setLocale(translateService.currentLang);
  }

  get instance() {
    return this.dateInput.instance;
  }

  @Input('data')
  set times(value: Date) {
    if (value) {
      this.time = value;
    }
  }

  @Input('name')
  set nameStringSetter(value: string) {
    if (value) {
      this.nameString = value;
    }
  }

  @Input('tabIndex')
  set tabIndexSetter(value: string) {
    this.tabIndexValue = value;
  }

  @Input('required')
  set requiredSetter(value: boolean) {
    this.requiredValue = value;
  }

  @Input('disabled')
  set disabledSetter(value: boolean) {
    this.disabledValue = value;
  }

  @Input('min')
  set minSetter(value: Date) {
    if (value) {
      this.minValue = value;
    }
  }

  @Output() modelChanged = new EventEmitter<Date>();
  @Output() onblur = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter<any>();
  @Output() onInitialized = new EventEmitter<any>();

  changeModelState() {
    this.time.hours(NOON);
    this.modelChanged.emit(this.time);
  }

  onFocusIn() {
    this.onFocus.emit();
  }

  onblurEvent() {
    this.modelChanged.emit(this.time);
    this.onblur.emit();
  }
}
