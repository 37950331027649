import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../models/application-settings';
import { Buyer, BuyerSimple } from '../models/buyer';
import { BuyerLevel } from '../models/buyer-level';

// services
import { WebApiService } from './web-api.service';

@Injectable()
export class BuyerService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyer';
  }

  getBuyers(): Observable<Array<Buyer>> {
    return this.webApiService.getList(this.apiPath);
  }

  getBuyersSimple(): Observable<Array<BuyerSimple>> {
    return this.webApiService.getList(this.apiPath + '/simple');
  }


  getBuyer(buyerId: number): Observable<Buyer> {
    return this.webApiService.get(this.apiPath, buyerId);
  }

  // Get available buyers for current user
  getUserLevelBuyers(): Observable<Array<BuyerLevel>> {
    return this.webApiService.getList(this.apiPath + 'level/buyer');
  }

  save(buyer: Buyer): Observable<any> {
    return this.webApiService.save<Buyer>(this.apiPath, buyer);
  }

  saveWithAuctionClusterId(buyer: Buyer, auctionclusterId: number): Observable<any> {
    return this.webApiService.save<Buyer>(this.appSettings.adminApi + 'core/buyer/auctionclusterid/' + auctionclusterId, buyer);
  }

  edit(buyer: Buyer): Observable<any> {
    return this.webApiService.edit<Buyer>(this.apiPath, buyer.buyerId, buyer);
  }

  incrementLicenseCount(buyer: Buyer, auctionClusterId: number): Observable<any> {
    return this.webApiService.save<Buyer>(`${this.apiPath}/incrementLicenseCount/${auctionClusterId}/${buyer.buyerId}`, buyer);
  }

  delete(buyerId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, buyerId);
  }

  enableDisable(buyer: Buyer): Observable<Buyer> {
    if (buyer.isActive) {
      return this.webApiService.enableDisable(this.apiPath + '/disable', buyer.buyerId);
    } else {
      return this.webApiService.enableDisable(this.apiPath + '/enable', buyer.buyerId);
    }
  }
}
