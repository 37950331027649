import { Component, Injector, ViewChild, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClrTabs } from '@clr/angular';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { SaveConfirmationComponent } from '../../shared/components/save-confirmation/save-confirmation.component';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { BuyerRole } from '../../shared/models/buyer-role';
import { Language } from '../../shared/models/language';
import { UserBuyerRole } from '../../shared/models/user';

import { Buyer } from '../shared/models/buyer-buyer';
import { BuyerUser } from '../shared/models/buyer-user';

// services
import { ServiceFactory } from '../../shared/factory/service-factory';
import { LanguageService } from '../../shared/services/language.service';
import { WebApiService } from '../../shared/services/web-api.service';

import { UserService } from '../shared/services/buyer-user.service';


class UserBuyerItem extends UserBuyerRole {
  filteredBuyerRoles: Array<BuyerRole> = [];
  auctionFilterData = '-';
  catalogFilterData = '-';
  clockFilterData = '-';
}

const ESC_KEYCODE = 27;

@Component({
  selector: 'buyer-user-component',
  templateUrl: 'buyer-user.component.html',
  styleUrls: ['./buyer-user.component.scss']
})
export class BuyerUserComponent extends ItemDetailsComponent<BuyerUser> implements OnInit, OnDestroy {

  userService: UserService;

  // tabs checkers
  isBuyerUser: true;
  languages: Array<Language> = [];

  buyers: Array<Buyer>;
  buyerRoles: Array<BuyerRole> = [];

  emailVerified: boolean;
  initialEmail: string;

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('saveConfirmation') saveConfirmation: SaveConfirmationComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  formDirty: boolean = false;

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private languageService: LanguageService
  ) {
    super(injector);
    this.getServices(route, appSettings, webApiService);

    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new BuyerUser();
    this.clean();
    this.setTranslations('USER');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.userService = ServiceFactory.getUserService(route, appSettings, webApiService) as UserService;
  }

  open(users: Array<BuyerUser>, userId: number, languages: Array<Language>, buyers: Array<Buyer>, buyerRoles: Array<BuyerRole>) {

    this.allItems = users;
    this.languages = languages;

    this.buyers = buyers;
    this.buyerRoles = buyerRoles;
    this.formDirty = false;

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;
    this.clean();

    if (userId != null) {
      this.isEditMode = true;
      this.emailVerified = true;
      this.spinner.show();
      this.userService.getUser(userId)
        .subscribe((res: BuyerUser) => {
          this.model = res;
          this.initialEmail = this.model.email;
          this.parseChildObjects();
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new BuyerUser();
      this.model.isActive = true;
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  shouldShowBuyerApproval() {
    return this.model && this.model.existingBuyerVat !== '' && this.model.isPending;
  }

  addToBuyerAccount(buyer: Buyer) {
    const alreadyAdded = this.model.buyerRoles.find(buyerRole => buyerRole.buyerId === buyer.buyerId);
    if (alreadyAdded) {
      return;
    }

    this.isBuyerUser = true;
    const userBuyer = new UserBuyerItem();
    userBuyer.buyerId = buyer.buyerId;
    this.filterBuyerItem(userBuyer);
    userBuyer.buyerRoleId = userBuyer.filteredBuyerRoles.find(_ => true).buyerRoleId;
    this.model.buyerRoles.push(userBuyer);
  }

  private clean() {
    this.emailVerified = false;
    this.initialEmail = null;
    this.isBuyerUser = true;
  }

  // // Parse 'buyerRoles'
  private parseChildObjects() {

    if (!this.model.buyerRoles) {
      this.model.buyerRoles = [];
    } else {
      this.model.buyerRoles = this.model.buyerRoles.map(source => {
        const item = new UserBuyerItem();
        item.buyerId = source.buyerId;
        item.buyerRoleId = source.buyerRoleId;
        this.filterBuyerItem(item);
        return item;
      });
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
    if (!this.isBuyerUser) {
      this.model.buyerRoles = [];
    }

    if (!this.check()) {
      return;
    }

    if (this.model.buyerRoles.length === 0) { // tslint:disable-line:max-line-length
      this.errorService.show(this.errorService.translations.AT_LEAST_ONE_LEVEL_NEEDS_TO_BE_SELECTED);
      return;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.userService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new BuyerUser();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      // this.spinner.show();
      // this.dataService.save(+this.route.snapshot.params['id'], this.model)
      //   .subscribe((res: any) => {
      //     this.model = new AuctionClusterUser();
      //     this.detailsForm.reset();
      //     this.close(true);
      //     this.errorMessage = null;
      //     this.spinner.hide();
      //   },
      //   error => {
      //     this.errorMessage = this.errorService.toString(error);
      //     this.spinner.hide();
      //   });
    }
  }

  addBuyer() {
    this.model.buyerRoles.push(new UserBuyerItem());
  }

  filterBuyerItem(item: UserBuyerItem) {
    item.filteredBuyerRoles = this.buyerRoles.filter(f => f.buyerId === item.buyerId);
  }

  deleteBuyer(index: number) {
    this.model.buyerRoles.splice(index, 1);
  }

  private check(): boolean {

    // create user list without current user
    const userList = this.allItems.filter((u) => {
      return u.userId !== this.model.userId;
    });

    const userNameNotUniqueList = userList.filter((u) => {
      return u.email === this.model.email;
    });

    if (userNameNotUniqueList.length > 0) {
      this.errorService.show(this.errorService.translations.EMAIL_NOT_UNIQUE);
      return false;
    }

    if (this.isBuyerUser && this.model.buyerRoles.length === 0) {
      this.errorService.show(this.errorService.translations.NO_ASSIGNED_BUYER_ROLES);
      return false;
    }

    if (!this.checkBuyers(this.model.buyerRoles)) {
      return false;
    }

    return true;
  }

  checkBuyers(buyerRoles: Array<UserBuyerRole>): boolean {

    let hasDuplicate = false;
    for (let i = 0; i < buyerRoles.length - 1; i++) {
      if (buyerRoles[i].buyerId === buyerRoles[i + 1].buyerId) {
        this.errorService.show(this.errorService.translations.BUYER_HAS_ONE_ROLE);
        hasDuplicate = true;
      }
    }
    return !hasDuplicate;
  }

  @HostListener('window:keydown', ['$event'])
  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE) {
      event.target.blur();
      if (this.formDirty) {
        this.saveConfirmation.opened = true;
      } else {
        this.onCancel();
      }
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnBuyerUserSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnBuyerUserSubmit')[0].removeAttribute('disabled');
      }
      if (!this.formDirty) this.formDirty = true;
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnBuyerUserSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnBuyerUserSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  translateSelectBoxes = (item) => {
    if (item) {
      const label = this.getTranslation(item.name);
      return label;
    }
  }

  onEditorPreparing = (e: any) => {

    if (
      e.dataField === 'buyerRoleId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          return new Promise((resolve, reject) => {
            e.component
              .getDataSource()
              .store()
              .load()
              .done(gridData => {
                resolve(this.buyerRoles.filter(f => f.buyerId === e.row.data.buyerId));
              });
          });
        }
      };
    }
  }
}
