import { Language } from './language';

export class ApplicationSettings {
  baseURLs: Array<BaseURL>;
  applicationCode: string;

  languages: Array<Language>;
  baseURL: string;
  adminApi: string;
  clockURL: string;
  notificationURL: string;
  reportingApiURL: string;
  privacyPolicyUrl: string;
  validateEmail: boolean;
  expirationTimeMinutes: number;
  expirationTimeDays: number;
  checkLogoutIntervalMinutes: number;
  expirationBufferTimeMinutes: number;

  authZeroSettings: AuthZeroSettings;
}

export class BaseURL {
  weburl: string;
  apiurl: string;
  notificationUrl: string;
  clockUrl: string;
  reportingApiUrl: string;
  privacyPolicyUrl: string;
  validateEmail: boolean;
  expirationTimeMinutes: number;
  expirationTimeDays: number;
  checkLogoutIntervalMinutes: number;
  expirationBufferTimeMinutes: number;

  authZeroSettings: AuthZeroSettings;
}

export class AuthZeroSettings {
  useAuthZero: boolean;
  clientID: string;
  domain: string;
  responseType: string;
  redirectUri: string;
}
