import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

// components
import { AuctionClusterUserComponent } from './auction-cluster-user.component';
import { AuthenticationComponent } from '../../shared/components/authentication/authentication.component';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';

// models
import { Auction } from '../../shared/models/auction';
import { AuctionCluster } from '../../shared/models/auction-cluster';
import { AuctionClusterRole } from '../../shared/models/auction-cluster-role';
import { AuctionClusterReportingRole } from '../../shared/models/auction-cluster-reporting-role';
import { Buyer } from '../../shared/models/buyer';
import { BuyerRole } from '../../shared/models/buyer-role';
import { Language } from '../../shared/models/language';
import { Supplier } from '../../shared/models/supplier';
import { SupplierRole } from '../../shared/models/supplier-role';
import { User } from '../../shared/models/user';

import { AuctionClusterUser } from '../shared/models/auction-cluster-user';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';
import { UserService } from '../../shared/services/user.service';

import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';
import { AuctionClusterLevelRoleService } from '../shared/services/auction-cluster-level-role.service';
import { AuctionClusterReportingRoleService } from '../shared/services/auction-cluster-reporting-role.service';
import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { AuctionClusterSupplierService } from '../shared/services/auction-cluster.supplier.service';
import { AuctionClusterUserService } from '../shared/services/auction-cluster-user.service';


class UserFilter {
  showAuction = false;
  showBuyer = false;
  showSupplier = false;
  showActive = true;
  showInactive = true;
  showBlocked = true;
  showPending = true;
  auctionId: number;
  buyerId: number;
  supplierId: number;
}


@Component({
  selector: 'auction-cluster-users-component',
  templateUrl: 'auction-cluster-users.component.html',
  styleUrls: ['./auction-cluster-users.component.scss']
})
export class AuctionClusterUsersComponent extends FullListComponent<AuctionClusterUser, AuctionClusterUserComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: AuctionClusterUserComponent;
  @ViewChild(AuthenticationComponent) authComponent: AuthenticationComponent;
  @ViewChild('confirmationAuctionClusterLicenses') confirmationAuctionClusterLicenses: ConfirmationComponent;
  @ViewChild('disable') disable: ConfirmationComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  auctions: Array<Auction> = [];
  auctionCluster: AuctionCluster;
  auctionClusterRoles: Array<AuctionClusterRole> = [];
  auctionClusterReportingRoles: Array<AuctionClusterReportingRole> = [];
  languages: Array<Language> = [];
  userToEnableDisable: AuctionClusterUser = new AuctionClusterUser();
  userToBlockUnblock: AuctionClusterUser = new AuctionClusterUser();
  user: User = new User();
  initialized = false;
  usersPaginated: any;

  buyers: Array<Buyer> = [];
  buyerRoles: Array<BuyerRole> = [];

  suppliers: Array<Supplier> = [];
  supplierRoles: Array<SupplierRole> = [];

  userFilter: UserFilter;

  buyersPaginated: any = {};
  suppliersPaginated: any = {};

  usersStore = new CustomStore({
    key: 'userId',
    load: async (loadOptions) => {
      var result = await this.dataService.getUsersPaged(this.id, this.userFilter, loadOptions).toPromise();
      this.translateUserRoles(result);
      return result;
    }
  });

  constructor(
    protected injector: Injector,
    private auctionService: AuctionClusterAuctionService,
    private dataService: AuctionClusterUserService,
    private languageService: LanguageService,
    private auctionClusterRoleService: AuctionClusterLevelRoleService,
    private auctionClusterReportingRoleService: AuctionClusterReportingRoleService,
    private buyerService: AuctionClusterBuyerService,
    private supplierService: AuctionClusterSupplierService,
    private auctionClusterService: AuctionClusterService,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    super(injector, AuctionClusterUser);
    this.title.set('SHARED.USERS');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.userFilter = new UserFilter();

    this.route.queryParams.subscribe(params => {
      if (params['auctionCluster']) {
        this.userFilter.showAuction = true;
        this.userFilter.showBuyer = false;
        this.userFilter.showSupplier = false;
        this.userFilter.auctionId = +params['auction'];
      } else if (params['buyers']) {
        this.userFilter.showAuction = false;
        this.userFilter.showSupplier = false;
        this.userFilter.showBuyer = true;
        this.userFilter.buyerId = +params['buyers'];
      } else if (params['suppliers']) {
        this.userFilter.showSupplier = true;
        this.userFilter.showAuction = false;
        this.userFilter.showBuyer = false;
        this.userFilter.supplierId = +params['suppliers'];
      } else {
        this.userFilter.showActive = true;
        this.userFilter.showAuction = true;
        this.userFilter.showBlocked = true;
        this.userFilter.showBuyer = true;
        this.userFilter.showInactive = true;
        this.userFilter.showPending = true;
        this.userFilter.showSupplier = true;
      }
      this.initialized = true;

      this.getData();

      this.setTranslations('USERS');

    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  // tslint:disable:no-magic-numbers
  getData(filter?: boolean) {
    if(!this.initialized) return;

    if (this.userFilter) {
      this.spinner.show();
    }

    if (this.usersPaginated != null) {
      this.usersPaginated.reload();
      this.spinner.hide();
      return;
    }

    forkJoin(
      this.auctionClusterRoleService.getAuctionClusterRoles(this.id, this.translateService.currentLang),
      this.auctionService.getAuctions(this.id),
      this.languageService.getAuctionClusterLanguages(this.id),
      this.buyerService.getBuyers(this.id),
      this.supplierService.getSuppliersSimple(this.id),
      this.auctionClusterService.getAuctionCluster(this.id),
      this.auctionClusterReportingRoleService.getAuctionClusterReportingRoles(this.id, this.translateService.currentLang),
      this.userService.getCurrentUser()
    ).subscribe((result: Array<any>) => {
      this.auctionClusterRoles = result[0];
      this.auctions = result[1];
      this.languages = result[2];
      this.buyers = result[3];
      this.suppliers = result[4];
      this.auctionCluster = result[5];
      this.auctionClusterReportingRoles = result[6];
      this.user = result[7];

      this.buyersPaginated = {
        paginate: true,
        pageSize: 20,
        store: this.buyers,
        sort: 'name'
      };

      this.suppliersPaginated = {
        paginate: true,
        pageSize: 20,
        store: this.suppliers,
        sort: 'name'
      };

      this.usersPaginated = new DataSource({
        paginate: true,
        pageSize: 20,
        store: this.usersStore,
        sort: [{ selector: 'isPending', desc: true }, { selector: 'isBlocked', desc: true }]
      });

      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        this.errorService.show(error);
      });
  }
  // tslint:disable:no-magic-numbers

  edit = (e: any) => {    
    const userId = e.row !== undefined ? e.row.data.userId : e.data.userId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.usersPaginated.items(), userId, this.auctionClusterRoles, this.auctions,
      this.languages, this.buyers, this.suppliers, this.auctionCluster, this.auctionClusterReportingRoles, this.user.isSystemUser);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.usersPaginated.items(), null, this.auctionClusterRoles, this.auctions,
      this.languages, this.buyers, this.suppliers, this.auctionCluster, this.auctionClusterReportingRoles, this.user.isSystemUser);
  }

  enableDisableUser = (e: any) => {
    const user = e.row.data;
    this.userToEnableDisable = user;
    this.dataService.enableDisable(this.id, this.userToEnableDisable, false).subscribe(() => {
      this.getData();
    },
      error => {
        if (error.error && error.error.length > 0 && error.error[0].ErrorMessage === 'ALL_AUCTIONCLUSTER_LICENCES_USED') {
          this.confirmationAuctionClusterLicenses.opened = true;
        }
        else {
          this.errorService.show(error);
        }
      }
    );
  }

  blockUnblockUser = (e: any) => {
    const user: AuctionClusterUser = e.row.data;
    this.userToBlockUnblock = user;
    this.dataService.blockUnblock(this.id, this.userToBlockUnblock).subscribe(() => {
      this.getData();
    },
      error => {
        this.errorService.show(error);
      }
    );
  }

  sendUserLicenseExceededMail() {
    this.dataService.enableDisable(this.id, this.userToEnableDisable, true).subscribe(() => {
      this.getData();
    },
      error => {
        this.errorService.show(error);
      }
    );
  }

  translateUserRoles(users) {
    users.data.forEach((user) => {
      let items: string[] = [];
      user.roles.forEach((role) => {
        var translated = this.languageService.getTranslatableText(role);
        items.push(translated);
      });
      let unique_values = items.filter((v, i, a) => a.indexOf(v) == i);
      unique_values.forEach((role) => {
        if (user.rolesString) {
          user.rolesString += ', ' + role;
        } else {
          user.rolesString = role;
        }
      });
    });
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe((users: Array<AuctionClusterUser>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
        this.spinner.hide();
      });
  }

  changeUserPassword = (e: any) => {
    this.authComponent.open(e.row.data.userId, this.id, false, false);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.userId;
    this.confirmation.opened = true;
  }

  calculateDisabledDisplayValue = (e: any) => {
    if (e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
  }

  calculateEnabledDisplayValue = (e: any) => {
    if (!e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
   }

   calculateBlockedDisplayValue = (e: any) => {
    if (e.row.data.isBlocked) {
      return false;
    } else {
      return true;
    }
  }

  calculateUnblockedDisplayValue = (e: any) => {
    if (!e.row.data.isBlocked) {
      return false;
    } else {
      return true;
    }
   }
}
