import { Component, Output, OnInit, Injector, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { Language } from '../../shared/models/language';
import { MasterDataListField } from '../../shared/models/master-data';
import { ProductProperty } from '../../shared/models/product';

import { MaskProductProperty, MaskMasterDataListField } from '../shared/models/mask';

// services
import { LanguageService } from '../../shared/services/language.service';

@Component({
  selector: 'mask-field-component',
  templateUrl: 'mask-field.component.html',
  styleUrls: ['./mask-field.component.scss']
})
export class MaskFieldComponent extends ItemDetailsComponent<MaskProductProperty | MaskMasterDataListField> implements OnInit, OnDestroy {

  languages: Array<Language> = [];
  productProperties: Array<ProductProperty> = [];
  masterDataListFields: Array<MasterDataListField> = [];
  maskType: number;
  isEditingDefault: boolean;
  isEditingUnsaved: boolean;
  allMaskMasterDataFields: Array<MaskMasterDataListField> = [];
  allMaskProductPropertyFields: Array<MaskProductProperty> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<MaskProductProperty | MaskMasterDataListField>();
  @Output() onUpdated = new EventEmitter<MaskProductProperty | MaskMasterDataListField>();
  // tslint:enable:no-output-on-prefix

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new MaskProductProperty();
    this.setTranslations('MASK.FIELD');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(maskFieldId: number, maskType: number, maskMasterDataFileds: Array<MaskMasterDataListField>,
    productPropertyFields: Array<MaskProductProperty>, maskField: MaskProductProperty | MaskMasterDataListField,
    languages: Array<Language>, productProperties: Array<ProductProperty>, masterDataListFields: Array<MasterDataListField>) {

    this.allMaskMasterDataFields = maskMasterDataFileds;
    this.allMaskProductPropertyFields = productPropertyFields;
    this.languages = languages;
    this.productProperties = productProperties;
    this.masterDataListFields = masterDataListFields;
    this.maskType = maskType;

    this.model = maskField;

    this.isOpened = true;
    if (maskFieldId) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
    if (this.errorMessage) {
      this.errorService.show(this.errorMessage);
      return;
    }

    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new MaskProductProperty();
    // this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnMaskFieldSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnMaskFieldSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnMaskFieldSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnMaskFieldSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  translateFields = (item: any) => {
    if (item) {
      const label = this.getTranslation(item.name);
      return label;
    }
  }
}
