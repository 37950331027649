import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { MaskFieldComponent } from './mask-field.component';

// models
import { ProductProperty} from '../../shared/models/product';
import { Language } from '../../shared/models/language';
import { MasterDataListField } from '../../shared/models/master-data';

import { MaskMasterDataListField, MaskProductProperty, MaskTypes } from '../shared/models/mask';

// services
import { LanguageService } from '../../shared/services/language.service';

@Component({
  selector: 'mask-fields-component',
  templateUrl: 'mask-fields.component.html',
  styleUrls: ['./mask-fields.component.scss']
})
export class MaskFieldsComponent extends SimpleListComponent implements OnInit, OnDestroy {

  masterDataFieldsItems: Array<MaskMasterDataListField> = [];
  productPropertyItems: Array<MaskProductProperty> = [];
  languages: Array<Language> = [];
  productProperties: Array<ProductProperty> = [];
  masterDataListFields: Array<MasterDataListField> = [];
  maskType: number;
  editingIndex: number;
  pageSize = 50;
  dataGridInstance: any;
  gridData: any;
  maskId: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @Input('masterDataFieldsItems')
  set dataMasterDataFields(value: Array<MaskMasterDataListField>) {
    this.masterDataFieldsItems = value;
  }
  get dataMasterDataFields(): Array<MaskMasterDataListField> {
    return this.masterDataFieldsItems;
  }

  @Input('productPropertyItems')
  set dataProductProperties(value: Array<MaskProductProperty>) {
    this.productPropertyItems = value;
    this.initGrid();
  }
  get dataProductProperties(): Array<MaskProductProperty> {
    return this.productPropertyItems;
  }

  get items(): Array<any> {
    if (this.maskType === MaskTypes.SUPPLY_MASK_TYPE || this.maskType === MaskTypes.SUPPLY_FORECAST_MASK_TYPE) {
      return this.productPropertyItems;
    }

    return [];
  }

  @Input('maskId')
  set maskIdSetter(value: number) {
    this.maskId = value;
  }

  @Input('languages')
  set language(value: Array<Language>) {
    this.languages = value;
  }


  @Input('productProperties')
  set productPropertiesSetter(value: Array<ProductProperty>) {
    this.productProperties = value;
  }

  @Input('masterDataFields')
  set masterDataFieldsSetter(value: Array<MasterDataListField>) {
    this.masterDataListFields = value;
  }

  @Input('maskType')
  set maskTypeSetter(value: number) {
    this.maskType = value;
  }

  @Output() dataChanged = new EventEmitter<boolean>();

  @ViewChild(MaskFieldComponent) detailsComponent: MaskFieldComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    //
    this.setTranslations('MASK');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  initGrid() {
    const gridInterval = setInterval(() => {
      if (this.maskType !== undefined || this.maskId == null) {
        clearInterval(gridInterval);
        this.gridData = this.items;
        
        if (this.dataGridInstance) {
            this.dataGridInstance.option('dataSource', this.gridData);
        }
      }
    }, 50);
  }

  edit(maskField: MaskMasterDataListField | MaskProductProperty, event: Event, index?: number) {
    this.editingIndex = this.items.indexOf(maskField);
    event.stopPropagation();

    this.detailsComponent.modalTitle = this.translations.FIELD.EDIT;
    const clone = { ...maskField };
    const maskFieldId = (<any>maskField).maskProductPropertyId;
    this.detailsComponent.open(maskFieldId, this.maskType, this.masterDataFieldsItems, this.productPropertyItems, clone,
      this.languages, this.productProperties, this.masterDataListFields);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.FIELD.ADD_NEW;
    const newObject = new MaskProductProperty();
    this.detailsComponent.open(null, this.maskType, this.masterDataFieldsItems, this.productPropertyItems, newObject, this.languages,
      this.productProperties, this.masterDataListFields);
  }

  onMaskFieldAdded(maskField: any) {
     
    const pp = new MaskProductProperty();
    pp.productPropertyId = maskField.productPropertyId;
    pp.isResult = maskField.isResult;
    this.productPropertyItems.push(pp);

    this.refreshGrid();
    
    this.dataChanged.emit();
  }

  onMaskFieldEdited(maskField: any) {
    
    const pp = new MaskProductProperty();
    pp.productPropertyId = maskField.productPropertyId;
    pp.isResult = maskField.isResult;
    this.productPropertyItems[this.editingIndex] = pp;
    
    this.dataChanged.emit();
  }

  delete(maskField: MaskMasterDataListField | MaskProductProperty) {
    
    this.productPropertyItems.splice(this.productPropertyItems.indexOf(<any>maskField), 1);
    
    this.dataChanged.emit();
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  getMaskFieldName = (e: any) => {
    const item = e.data;
    const field = this.productProperties.find(p => {
      return p.productPropertyId === item.productPropertyId;
    });

    if (field) {
      return this.getTranslation(field.name);
    }

    return '-';
  }

  deleteItem = (e: any) => {
    this.delete(e.row.data);
  }

  editItem = (e: any) => {
    this.edit(e.row.data, new Event('click'));
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  openEditClick = (e: any) => {
    this.edit(e.data, new Event('click'));
  }

  refreshGrid() {
    this.gridData = this.items;
        
    if (this.dataGridInstance) {
      this.dataGridInstance.option('dataSource', this.gridData);
    }
  }
}

