import { UserBuyerRole, UserSupplierRole, UserAuctionClusterRole } from '../../../shared/models/user';

export class SupplierUser {
  userId: number;
  email: string;
  password: string;
  confirmPassword: string;
  firstname: string;
  lastname: string;
  isActive: boolean;
  languageId: number;
  usedByOtherObjects: boolean;
  auctionClusterRoles: Array<UserAuctionClusterRole> = [];
  buyerRoles: Array<UserBuyerRole> = [];
  supplierRoles: Array<UserSupplierRole> = [];
  existingSupplierVat: string;
  isPending: boolean;
  existingBuyerVat: string;
  sendAccountInfo: boolean;
  sendSmsTransactionInfo: boolean;
  sendSmsTransactionSummary: boolean;
  telNumber: string;

  // displayable fields:
  buyerRolesText: string;
}
