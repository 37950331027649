import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { BrowserGuard } from '../shared/services/browser-guard.service';
import { AuctionsClusterComponent } from './auction-cluster/auction-clusters.component';
import { BuyersComponent } from './buyers/buyers.component';
import { PlatformContentComponent } from './content/platform-content.component';
import { AdditionalMenuItemsComponent } from './additional-menu-items/additional-menu-items.component';
import { ReportEditorComponent } from './reports/report-editor.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportExportComponent } from './reports/report-export.component';
import { RolesComponent } from './roles/roles.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { UsersComponent } from './users/users.component';
import { ImportAuctionClusterComponent } from './import-auctioncluster/import-auctioncluster.component';
import { ExternalPlatformsComponent } from './external-platform/external-platforms.component';
import { ProductPropertyStylingsComponent } from './product-property-styling/product-property-stylings.component';
import { PublicSupplyForecastURKComponent } from '../shared/components/public-supply-forecast-urk/public-supply-forecast-urk.component';
import { AdditionalMenuItemsContainerComponent } from '../shared/components/additional-menu-items-container/additional-menu-items-container.component';
import { ConfigurationParameterComponent } from './configuration-parameter/configuration-parameter.component';



const appRoutes: Routes = [
  {
    path: 'platform',
    component: PlatformContentComponent,
    canActivateChild: [AuthGuard, BrowserGuard],
    data: { level: 'platform' },
    children: [
      //{ path: 'home', component: PlatformHomeComponent },
      { path: 'users', component: UsersComponent, data: { permission: 'PlatformPermissions.1' } },
      { path: 'roles', component: RolesComponent, data: { permission: 'PlatformPermissions.8' } },
      { path: 'buyers', component: BuyersComponent, data: { permission: 'PlatformPermissions.4' } },
      { path: 'suppliers', component: SuppliersComponent, data: { permission: 'PlatformPermissions.5' } },
      { path: 'clusters', component: AuctionsClusterComponent, data: { permission: 'PlatformPermissions.3' } },
      { path: 'additional-menu-items', component: AdditionalMenuItemsComponent, data: { permission: 'PlatformPermissions.7' } },
      { path: 'menu-item/:menuId', component: AdditionalMenuItemsContainerComponent },
      {
        path: 'reports',
        children: [
          { path: '', component: ReportsComponent, data: { permission: 'PlatformPermissions.6' } },
          { path: 'reports/report/:reportId', component: ReportEditorComponent, data: { permission: 'PlatformPermissions.6' } },
          { path: 'reports/report/:reportId/export', component: ReportExportComponent, data: { permission: 'PlatformPermissions.6' } },
          { path: 'reports/ac/:auctionClusterId/report/:reportId', component: ReportEditorComponent, data: { permission: 'PlatformPermissions.6' } },
          { path: 'reports/ac/:auctionClusterId/report/:reportId/export', component: ReportExportComponent, data: { permission: 'PlatformPermissions.6' } },
        ],
        data: { permission: 'PlatformPermissions.6' }
      },
      { path: 'configurationparameter', component: ConfigurationParameterComponent, data: { permission: 'PlatformPermissions.9' } },
      { path: 'importauctioncluster', component: ImportAuctionClusterComponent, data: { permission: 'PlatformPermissions.9' } },
      { path: 'externalplatforms', component: ExternalPlatformsComponent, data: { permission: 'PlatformPermissions.9' } },
      { path: 'supplyforecast', component: PublicSupplyForecastURKComponent },
      { path: 'productpropertystylings', component: ProductPropertyStylingsComponent, data: { permission: 'PlatformPermissions.9' } }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PlatformRoutingModule { }
