import { Component, OnInit } from '@angular/core';

// services
import { TitleService } from '../../shared/services/title.service';

@Component({
  selector: 'supplier-home.component',
  templateUrl: 'supplier-home.component.html',
  styleUrls: ['./supplier-home.component.scss']
})
export class SupplierHomeComponent implements OnInit {

  constructor(private titleService: TitleService) { }

  ngOnInit() {
    this.titleService.set('SUPPLIER.TITLE');
  }
}
