import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';

// services
import { WebApiService } from '../../shared/services/web-api.service';

@Injectable()
export class MailService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  sendUserLicenseExceededMail(auctionclusterId: number): Observable<any> {
    var url = this.apiPath + '/' + auctionclusterId + '/userlicenseexceeded';
    return this.webApiService.getSingle(url);
  }
}
