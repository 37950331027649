<div class="mask-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }"
  dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #editMask [width]="700" height="500px" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened" *ngIf="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #editMasksForm id="editMasksForm" [showValidationSummary]="false"
          (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">
          <dxi-item itemType="tabbed">
            <dxi-tab title="{{ 'SHARED.GENERAL' | translate }}">
              <dxi-item dataField="name" [template]="'masksNameField'">
                <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required"
                  message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item #product name="product" dataField="productId" editorType="dxSelectBox"
                [editorOptions]="{ items: products, valueExpr:'productId', displayExpr: 'name', onValueChanged: onProductChanged, disabled: isEditMode }"
                [rtlEnabled]="rtlEnabled">
                <dxo-label text="{{ 'SHARED.PRODUCT' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item #sourceCatalog name="sourceCatalog" dataField="sourceCatalogId" editorType="dxSelectBox"
                [editorOptions]="{ items: filteredCatalogs, valueExpr:'catalogId', displayExpr: 'name', onValueChanged : onCatalogChanged }"
                [rtlEnabled]="rtlEnabled">
                <dxo-label text="{{ 'MASK.SOURCE_CATALOG' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item #sourceType name="sourceType" dataField="sourceType" editorType="dxSelectBox"
                [editorOptions]="{ items: maskTypes, valueExpr:'value', displayExpr: translateSelectBoxes }"
                [rtlEnabled]="rtlEnabled">
                <dxo-label text="{{ 'MASK.SOURCE_TYPE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="publishedOnly" editorType="dxCheckBox">
                <dxo-label text="{{ 'MASK.PUBLISHED_ONLY' | translate }}" location="right"></dxo-label>
              </dxi-item>
            </dxi-tab>
            <dxi-tab title="{{ 'SHARED.FIELDS' | translate }}">
              <mask-fields-component [maskType]="model.sourceType"
                [(masterDataFieldsItems)]="model.maskMasterDataListFields"
                [(productPropertyItems)]="model.maskProductProperties" [languages]="languages"
                [productProperties]="productProperties" [masterDataListFields]="masterDataListFields"
                [maskId]="model.maskId" (dataChanged)="saveWithoutClose()">
              </mask-fields-component>
            </dxi-tab>
          </dxi-item>
          <div *dxTemplate="let data of 'masksNameField'" class="translatable-template">
            <translatable-field (change)="onChangeTranslationField($event, 'name')" [isDevExpress]="true"
              [isLabelHidden]="true" [caption]="'SHARED.NAME'" [clusterLanguages]="languages"
              [(data)]="data.component.option('formData')[data.dataField]" [name]="'name'"
              #maskName></translatable-field>
            <br />
          </div>
        </dx-form>
        <br />
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnMasksSubmit" disabled="disabled">{{
          'SHARED.SAVE' | translate }}</button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
      </div>
    </dx-scroll-view>
  </dx-popup>
</div>