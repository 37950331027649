export class LookupTable {
  lookupTableId: number;
  auctionClusterId: number;
  name: string;
  resultPropertyTypeId: number;
  resultMasterDataListId: number;
  hasPeriodFilter: boolean;
  lookupTableType: number;
  rowCount: number;
  resultPropertyFormatId: number;

  lookupTableFields: Array<LookupTableField> = [];

  lookupTableRows: Array<LookupTableRow> = [];

  // displayableFields
  lookupTableText: string;
  enableCreate: boolean;
  enableUpdate: boolean;
  enableDelete: boolean;
}

export class LookupTableField {
  lookupTableFieldId: number;
  masterDataListId: number;
  isResult: boolean;
  sysName: string;

  // calculated field not in DB
  propertyTypeId: number;
  propertyFormatId: number;
}

export class LookupTableRow {
  lookupTableRowId: number;
  lookupTableId: number;
  periodFrom: Date;
  periodTill: Date;
  isDefault: boolean;


  resultIntValue: number;
  resultDecimalValue: number;
  resultStringValue: string;
  resultDateTimeValue: Date;
  resultBooleanValue = false;
  resultMasterDataListRowId: number;
  resultMasterDataListId: number;
  resultUrlValue: string;

  lookupTableValues: Array<LookupTableValue> = [];

  // Parsed lookup table values for display purpose
  lookupTableValuesParsed: Array<any> = [];
  displayUrlImageValue: string;
  displayUrlTextValue: string;
}

export class LookupTableValue {
  lookupTableFilterValueId: number;
  lookupTableFieldId: number;
  masterDataListRowId: number;
  masterDataListRow: MasterDataListRow;
  masterDataListId: number;
}

export class MasterDataListRow {
  masterDataListRowId: number;
  masterDataListId: number;
}
