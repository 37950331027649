import { Injectable } from '@angular/core';

@Injectable()
export class VatService {
  // Source: https://github.com/ddeboer/vatin/blob/master/src/Validator.php
  // Reference: http://ec.europa.eu/taxation_customs/vies/faq.html?locale=en#item_11
  private rules = {
    AT: { pattern: /^U[A-Z\d]{8}$/, example: 'UKA8DUU50' },
    BE: { pattern: /^[0|1]{1}\d{9}$/, example: '1797798413' },
    BG: { pattern: /^\d{9,10}$/, example: '1911201813' },
    CY: { pattern: /^\d{8}[A-Z]$/, example: '78430657H' },
    CZ: { pattern: /^\d{8,10}$/, example: '026096643' },
    DE: { pattern: /^\d{9}$/, example: '337377184' },
    DK: { pattern: /^(\d{2} ?){3}\d{2}$/, example: '53 0432 77' },
    EE: { pattern: /^\d{9}$/, example: '517529506' },
    EL: { pattern: /^\d{9}$/, example: '517529506' },
    ES: { pattern: /^[A-Z]\d{7}[A-Z]|\d{8}[A-Z]|[A-Z]\d{8}$/, example: '13456647I' },
    FI: { pattern: /^\d{8}$/, example: '61653248' },
    FR: { pattern: /^([A-Z0-9]{2})\d{9}$/, example: 'OZ300003477' },
    GB: { pattern: /^\d{9}|\d{12}|(GD|HA)\d{3}$/, example: 'HA439 / 935014501255' },
    HR: { pattern: /^\d{11}$/, example: '94980603197' },
    HU: { pattern: /^\d{8}$/, example: '29909423' },
    IE: { pattern: /^[A-Z\d]{8}|[A-Z\d]{9}$/, example: 'KGMKA3QK3' },
    IT: { pattern: /^\d{11}$/, example: '20573346307' },
    LT: { pattern: /^(\d{9}|\d{12})$/, example: '274807567' },
    LU: { pattern: /^\d{8}$/, example: '95139339' },
    LV: { pattern: /^\d{11}$/, example: '14826504984' },
    MT: { pattern: /^\d{8}$/, example: '81184326' },
    NL: { pattern: /^\d{9}B\d{2}$/, example: '467483723B10' },
    NO: { pattern: /^\d{9}$/, example: '123383537' },
    PL: { pattern: /^\d{10}$/, example: '5793877995' },
    PT: { pattern: /^\d{9}$/, example: '972447290' },
    RO: { pattern: /^\d{2,10}$/, example: '1262322438' },
    SE: { pattern: /^\d{12}$/, example: '265062760649' },
    SI: { pattern: /^\d{8}$/, example: '31331453' },
    SK: { pattern: /^\d{10}$/, example: '061003876200' },
  };

  getCountryCodes(): Array<string> {
    return Object.keys(this.rules);
  }

  getExample(countryCode: string): string {
    if (!(countryCode in this.rules)) {
      return '';
    }

    return this.rules[countryCode].example;
  }

  validate(countryCode: string, vat: string) {
    if (!(countryCode in this.rules)) {
      return false;
    }

    const pattern: RegExp = this.rules[countryCode].pattern;

    return pattern.test(vat);
  }
}
