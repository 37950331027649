export class PrebidOnProduct {
  catalogId: number;
  auctionClusterId: number;
  productId: number;
  name: string;
  description: string;

  prebidNameText: string;
  prebidDescriptionText: string;
}
