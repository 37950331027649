import { AuctionCluster } from "./auction-cluster";

export class User {
  userId: number;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
  isActive: boolean;
  isDeleted: boolean;
  isPending: boolean;
  isBlocked: boolean;
  platformRoleId: number;
  rolesString: string;
  existingBuyerVat: string;
  existingSupplierVat: string;
  existingAuctionClusterId: number;
  languageId: number;
  shoppingListSettings: string;
  auctionClusterRoles: Array<UserAuctionClusterRole> = [];
  userAuctionClusterReportingRoles: Array<UserAuctionClusterReportingRole> = [];
  buyerRoles: Array<UserBuyerRole> = [];
  supplierRoles: Array<UserSupplierRole> = [];
  supplyMail: boolean;
  supplyForecastMail: boolean;
  telNumber: string;
  sendMailTransactionSummary: boolean;
  sendSmsTransactionInfo: boolean;
  sendSmsTransactionSummary: boolean;
  platformRole: any;
  isSystemUser: boolean;
  mfaEnabled: boolean;
  sendAccountInfo: boolean;
  domain: string;
}

export class UserAuctionClusterRole {
  auctionClusterId: number;
  auctionCluster: AuctionCluster
  auctionId: number;
  auctionClusterRoleId: number;

  auctionFilterData = '';
  clockFilterData = '';
  catalogFilterData = '';
}

export class BuyerRole {
  buyerRoleId: number;
  buyerId: number;
  name: string;
  description: string;
  permissions: Array<number> = [];
}

export class UserBuyerRole {
  buyerId: number;
  buyerRoleId: number;

  auctionFilterData = '';
  clockFilterData = '';
  catalogFilterData = '';
  buyerRole: BuyerRole;
  availableBuyerRoles : Array<BuyerRole> = [];
}

export class SupplierRole {
  supplierRoleId: number;
  supplierId: number;
  name: string;
  description: string;
  permissions: Array<number> = [];
}

export class UserSupplierRole {
  supplierId: number;
  supplierRoleId: number;

  auctionFilterData = '';
  clockFilterData = '';
  catalogFilterData = '';  

  supplierRole: SupplierRole;

  availableSupplierRoles: Array<SupplierRole> = [];
}

export class UserAuctionClusterReportingRole {
  userAuctionClusterReportingRoleId: number;
  auctionClusterId: number;
  auctionClusterReportingRoleId: number;
}

export class Longtab {
  text: string;
}
