export enum NotificationTypeEnum {
    PublishingForecastCatalog = 0,
    PublishingSupplyCatalog = 1,
    TransactionSummary = 2,
    ErrorReporting = 3,
    CancelledPrebids = 4,
    CancelPrebidOnLotChange = 5,
    CancelPrebidLotDeleted = 6,
    AdjustedByMinMaxAmount = 7,
    AdjustedByMTOAmount = 8,
    IgnoredByMinMaxAmount = 9,
    IgnoredByMTOAmount = 10
}