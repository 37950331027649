export class Cookies {
  static readonly LANGUAGE_COOKIE = 'AUCXIS-WEBCLOCK-lang-v201';
  static readonly ACCESS_TOKEN_COOKIE = 'AUCXIS-WEBCLOCK-accessToken-v201';
  static readonly REFRESH_TOKEN_COOKIE = 'AUCXIS-WEBCLOCK-refreshToken-v201';
  static readonly USER_ID_COOKIE = 'AUCXIS-WEBCLOCK-userId-v201';
  static readonly USER_FIRSTNAME_COOKIE = 'AUCXIS-WEBCLOCK-firstname-v201';
  static readonly USER_LASTNAME_COOKIE = 'AUCXIS-WEBCLOCK-lastname-v201';
  static readonly USER_GUID = 'AUCXIS-WEBCLOCK-userGuid-v201';
  static readonly REJECTED_TOS = 'AUCXIS-WEBCLOCK-rejected-tos-v201';
  static readonly COMPUTER_COOKIE = 'AUCXIS-WEBCLOCK-computer-v201';
  static readonly LANGUAGE_COOKIE_DIRECTION = 'AUCXIS-WEBCLOCK-lang-direction-v201';
  static readonly SUPPLY_EDITOR_MODE = 'SUPPLY-EDITOR-MODE';
  static readonly WEIGHING_SCALE_PROPERTY_ID = 'WEIGHING-SCALE-PROPERTY-ID';
  static readonly MFA_AUTHENTICATION = 'MFA-AUTHENTICATION';
}
