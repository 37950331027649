export class UserFilter {
  showPlatform = true;
  showAuction = false;
  showBuyer = false;
  showSupplier = false;
  showActive = true;
  showInactive = true;
  showBlocked = true;
  showPending = true;
  auctionClusterId: number;
  buyerId: number;
  supplierId: number;
}
