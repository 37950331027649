import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

// services
import { AdditionalMenuItemsService } from '../../services/additional-menu-items.service';
import { ConfigService } from '../../services/config.service';
import { ErrorService } from '../../services/error.service';

const COOKIE_CONSENT_COOKIE_NAME = 'AUCXIS-WEBCLOCK_cookieConsent';

@Component({
  selector: 'cookie-consent-component',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent {
  isShowing = false;
  privacyPolicyUrl: string;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private configService: ConfigService,
    private additionalMenuItemsService: AdditionalMenuItemsService,
    private errorService: ErrorService
  ) {
    const consentCookie = this.cookieService.get(COOKIE_CONSENT_COOKIE_NAME);
    if (!consentCookie) {
      this.isShowing = true;
    }

    this.privacyPolicyUrl = this.configService.config.privacyPolicyUrl;
  }

  ngOnInit() {
    this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {
      let privacyStatementPage = result.find(_ => _.sysName === 'PRIVACYSTATEMENT');
      if (privacyStatementPage) {
        this.privacyPolicyUrl = '/core/menu-item/' + privacyStatementPage.externalAppId;
      }

    },
      error => {
        this.errorService.show(error);
      });
  }

  onClose() {
    this.cookieService.set(COOKIE_CONSENT_COOKIE_NAME, 'true', new Date(2030, 1, 1));
    this.isShowing = false;
  }

  more() {
    this.router.navigate([this.privacyPolicyUrl], { queryParamsHandling: 'merge' });
  }
}
