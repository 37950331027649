import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// models
import { TermsAndConditions } from '../shared/models/terms-and-conditions';

// services
import { ErrorService } from '../../shared/services/error.service';
import { LanguageService } from '../../shared/services/language.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { TermsAndConditionsService } from '../../shared/services/terms-and-conditions.service';
import { TitleService } from '../../shared/services/title.service';


@Component({
  selector: 'terms-and-conditions-component',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnDestroy {
  id: number;
  model: Array<TermsAndConditions> = [];

  @ViewChild('details') detailsComponent: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    private router: Router,
    private dataService: TermsAndConditionsService,
    protected route: ActivatedRoute,
    private spinner: SpinnerService,
    private errorService: ErrorService,
    private title: TitleService,
    private languageService: LanguageService
  ) {
    this.title.set('TERMS_AND_CONDITIONS.TITLE');

    this.id = +this.route.snapshot.params['id'];
    // if id exists it the route, refresh data
    if (this.id) {
      this.getData();
    }
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  getData() {
    this.model = [];
    this.dataService.get(this.id).subscribe(terms => {
      this.model.push(terms);
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  add() {
    this.detailsComponent.open(this.id);
  }
  
  open = () =>  {
    this.spinner.show();
    this.dataService.getWithContent(this.id).subscribe(terms => {
      this.spinner.hide();
      const link = document.createElement('a');
      link.href = 'data:application/octet-stream;base64,' + terms.content;
      link.download=`termsAndConditions_${terms.version}.pdf`;
      link.click();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  onDetailsComponentClosed(isDataChanged: boolean) {
    if (isDataChanged) {
      this.getData();
    }
  }
}
