<div class="additional-menu-item-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #additionalMenuItemEditCreate
            [width]="850"
            [showTitle]="true"
            title="{{ modalTitle }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isOpened"
            (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form #additionalMenuItemForm
               id="additionalMenuItemForm"
               [showValidationSummary]="false"
               [(formData)]="model"
               (onFieldDataChanged)="onFieldDataChanged($event)"
               colCount="auto"
               [minColWidth]="500">


        <dxi-item itemType="tabbed" [tabPanelOptions]="{ swipeEnabled: false }">

          <dxi-tab title="{{ 'SHARED.GENERAL' | translate }}">
            <dxi-item itemType="group">

              <dxi-item itemType="group">
                <dxi-item dataField="name">
                  <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
                  <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="sysName">
                  <dxo-label text="{{ 'SHARED.SYSNAME' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-item>

              <dxi-item dataField="menuType" editorType="dxSelectBox"
                        [editorOptions]="{ items: menuTypes, valueExpr:'value', displayExpr: translateSelectBoxes }">
                <dxi-validation-rule type="required" message="{{ 'ADDITIONAL_MENU_ITEM.MENU_TYPE' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="applicationUrl" [visible]="model.menuType === 1">
                <dxo-label text="{{ 'ADDITIONAL_MENU_ITEM.APPLICATION_URL' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'ADDITIONAL_MENU_ITEM.APPLICATION_URL' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="content" [template]="'menuItemHtmlEditor'" [visible]="model.menuType === 2">
                <dxo-label [visible]="false"></dxo-label>
              </dxi-item>

            </dxi-item>
          </dxi-tab>

          <dxi-tab title="{{ 'SHARED.ACCESS' | translate }}">
            <dxi-item dataField="accessLevels" [template]="'accessTemplate'">
              <dxo-label text="{{ 'ADDITIONAL_MENU_ITEM.APPLICATION_IS_AVAILABLE_FOR' | translate }}"></dxo-label>
            </dxi-item>
          </dxi-tab>

          <dxi-tab title="{{ 'ADDITIONAL_MENU_ITEM.MENU' | translate }}">
            <dxi-item dataField="menuItemName" [template]="'menuItemFieldName'">
              <dxo-label text="{{ 'ADDITIONAL_MENU_ITEM.MENU_ITEM_NAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'ADDITIONAL_MENU_ITEM.MENU_ITEM_NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="menuOption" editorType="dxSelectBox" [visible]="model.menuType !== 3"
                      [editorOptions]="{ items: menuOptions, valueExpr:'value', displayExpr: translateSelectBoxes }">
              <dxo-label text="{{ 'ADDITIONAL_MENU_ITEM.MENU_OPTIONS' | translate }}"></dxo-label>
            </dxi-item>

            <dxi-item dataField="setAsStartPage" editorType="dxCheckBox" [visible]="model.menuOption === 1 && model.menuType !== 3">
              <dxo-label text="{{ 'ADDITIONAL_MENU_ITEM.SET_AS_START_PAGE' | translate }}" location="right"></dxo-label>
            </dxi-item>

            <dxi-item dataField="submenuId" editorType="dxSelectBox" [visible]="model.menuOption === 2 && model.menuType !== 3"
                      [editorOptions]="{ items: parentMenuItems, valueExpr:'externalAppId', displayExpr:'name' }">
              <dxo-label text="{{ 'ADDITIONAL_MENU_ITEM.PARENT_MENU' | translate }}"></dxo-label>
            </dxi-item>
          </dxi-tab>
        </dxi-item>

        <div *dxTemplate="let data of 'menuItemFieldName'" class="translatable-template">
          <translatable-field (change)="onChangeTranslationField($event, 'menuItemName')" [isDevExpress]="true" [isLabelHidden]="true" [caption]="'ADDITIONAL_MENU_ITEM.MENU_ITEM_NAME'"
                              [type]="'text'" [clusterLanguages]="languages" [(data)]="data.component.option('formData')[data.dataField]" [name]="'menuItemName'" #menuItemNameField></translatable-field>
        </div>
        <div *dxTemplate="let data of 'menuItemHtmlEditor'" class="translatable-template">
          <translatable-field (change)="onChangeTranslationField($event, 'content')" [isDevExpress]="true" [isLabelHidden]="true" [clusterLanguages]="languages"
                              [type]="'htmlEditor'" [(data)]="data.component.option('formData')[data.dataField]" [name]="'content'" #menuItemHtmlEditorField></translatable-field>
        </div>

        <div *dxTemplate="let data of 'accessTemplate'">
          <dx-tag-box [dataSource]="accesses"
                      valueExpr="id"
                      displayExpr="value"
                      searchEnabled="true"
                      [(value)]="model.accessLevels">
          </dx-tag-box>
        </div>
      </dx-form>

      <div class="au-dx-popup-padder">
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" disabled="disabled" name="btnAdditionalMenuSubmit">{{ 'SHARED.SAVE' | translate }}</button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
      </div>
    </dx-scroll-view>
  </dx-popup>
</div>
