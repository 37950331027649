import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { AdditionalMenuItem } from '../../../shared/models/additional-menu-item';
import { ApplicationSettings } from '../../../shared/models/application-settings';

// services
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class AdditionalMenuItemsService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'menu-items';
     }

  getMenuItems(): Observable<Array<AdditionalMenuItem>> {
    return this.webApiService.getList(this.apiPath);
  }

  getMenuItem(externalAppId: number): Observable<AdditionalMenuItem> {
    return this.webApiService.get(this.apiPath, externalAppId);
  }

  save(menuItem: AdditionalMenuItem): Observable<any> {
    return this.webApiService.save<AdditionalMenuItem>(this.apiPath, menuItem);
  }

  edit(menuItem: AdditionalMenuItem) {
    return this.webApiService.edit<AdditionalMenuItem>(this.apiPath, menuItem.externalAppId, menuItem);
  }

  delete(menuItemId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, menuItemId);
  }
}
