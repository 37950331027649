import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { TransactionMonitorLot } from '../../../shared/models/transaction-monitor-lot';

// services
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class BuyerTransactionMonitorService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  getLots(auctionClusterId: number, buyerId: number, catalogId: number, date: string, clockId?: number): Observable<TransactionMonitorLot> {
    let url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/catalog/${catalogId}/date/${date}/transactionmonitor`;
    if (clockId && clockId !== 0) {
      url += `?clockId=${clockId}`;
    }
    return this.webApiService.getList(url);
  }

  acceptTransaction(auctionClusterId: number, buyerId: number, transactionId: number, price: number) {
    return this.webApiService.save(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/transaction/${transactionId}/accept`, {
      'Price': price
    });
  }

  rejectTransaction(auctionClusterId: number, buyerId: number, transactionId: number) {
    return this.webApiService.save(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/transaction/${transactionId}/reject`, {});
  }
}
