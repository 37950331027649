import { LotteryItem } from "./lottery-item";

export class Lottery {
  salesDate: Date;
  openingTime: Date;
  sortingTime: Date;
  salesTime: Date;
  remark: string;
  lotteryList: Array<LotteryItem> =[];
}
