<div class="login-wrapper login-component">
  <form class="login" (ngSubmit)="onSubmit()" #loginForm="ngForm">
    <div class="login-group" (capsLock)="capsOn=$event">
      <div class="inner-addon right-addon">
        <input clrInput *ngIf="configService.config.validateEmail" #email class="username" type="email" name="email" placeholder="{{ 'SHARED.EMAIL' | translate }}" [(ngModel)]="model.email" required />
        <input clrInput *ngIf="!configService.config.validateEmail" #email class="username" type="text" name="email" placeholder="{{ 'SHARED.EMAIL' | translate }}" [(ngModel)]="model.email" required />
        <div>
          <i alt="show" class="far fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
          <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showPassword = !showPassword" [class.hide]="!showPassword"></i>
        </div>
      </div>
      <input clrInput class="password" [type]="showPassword ? 'text' : 'password'" name="password" placeholder="{{ 'USER.PASSWORD' | translate }}" [(ngModel)]="model.password" required/> 
      <div>
        <img class="warning-image" *ngIf="capsOn" src="../../../assets/images/warningCapsLock.png"/>
        <span *ngIf="capsOn">{{ 'USER.CAPS_LOCK_WARNING' | translate }}</span>
      </div>
      <div id="mfaSetup" class="login-group mfaSetup">
        <p class="text-justify mx-auto" [innerHTML]="'MFA_SETUP.TITLE' | translate"></p>
        <p class="text-justify mb-2" [innerHTML]="'MFA_SETUP.DOWNLOAD_APP' | translate"></p>
        <section id="qrCode" class="mx-auto"></section>
        <p class="text-justify" [innerHTML]="'MFA_SETUP.INSTRUCTIONS' | translate:getMfaSecret()"></p>
      </div>
      <input clrInput *ngIf="model.isMfaSetupNeeded || model.isMfaCodeNeeded" class="username" type="number" name="mfaCode" #mfaCode placeholder="{{ 'MFA_SETUP.CODE' | translate }}" [(ngModel)]="model.mfaCode" autoFocus />
      <div class="alert alert-danger" *ngIf="message">
        <div class="alert-items">
          <div class="alert-item static" role="alert">
            <div class="alert-icon-wrapper">
              <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
            </div>
            <span class="alert-text">
              {{message}}
            </span>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="(!loginForm.form.valid && loginForm.form.touched) || isLoggingIn">
        <span *ngIf="!isLoggingIn">{{ 'LOG_IN.TITLE' | translate }}</span>
        <span class="spinner spinner-inline" *ngIf="isLoggingIn"></span>
      </button>
    </div>
    <a [routerLink]="['/core/register']" class="register-link">
      {{ 'LOGIN.REGISTER' | translate }}
    </a>
    <a [routerLink]="['/core/request-password-reset']" class="forgot-password-link">
      {{ 'LOGIN.FORGOT_PASSWORD' | translate }}
    </a>
  </form>
</div>

