import { Component, ViewChild, OnInit, OnDestroy, Injector, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { MasterDataFieldComponent } from './master-data-field.component';

// models
import { Language } from '../../shared/models/language';
import { MasterDataListField, FieldType } from '../../shared/models/master-data';
import { ProductPropertyType } from '../../shared/models/product';

// services
import { LanguageService } from '../../shared/services/language.service';


export enum MasterDataListFieldType {
  NONE = 0,
  ORDER_BY = 1,
  NAME = 2,
  NUMBER = 3,
  ADDRESS1 = 4,
  ADDRESS2 = 5,
  ZIP = 6,
  CITY = 7,
  COUNTRY = 8,
  ID = 9,
  SUBNUMBER = 10,
  CODE = 11,
  PRICERANGE = 12,
  SALESUNITDESCRIPTION = 13,
  PACKAGECONTENT = 14,
  LOTID = 15,
  AVAILABLEINTOUCH = 16,
  MULITPLICATOR = 17,
  PACKAGECONTENTREMAININGAMOUNT = 18,
  FIRSTNAME = 19,
  LASTNAME = 20,
  EMAIL = 21,
  DISPLAYNAME = 22
}

@Component({
  selector: 'master-data-fields-component',
  templateUrl: 'master-data-fields.component.html'
})
export class MasterDataFieldsComponent extends SimpleListComponent implements OnInit, OnDestroy {

  items: Array<MasterDataListField> = [];
  languages: Array<Language> = [];
  propertyTypes: Array<ProductPropertyType> = [];
  fieldTypes: Array<FieldType> = [];
  editingIndex: number;
  pageSize = 50;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @Input('data')
  set data(value: Array<MasterDataListField>) {
    this.items = value;
    this.translateMasterDataFields();
    this.matchTypeNames();
  }
  get data(): Array<MasterDataListField> {
    return this.items.filter(f => f.fieldType !== MasterDataListFieldType.ORDER_BY);
  }

  @Input('languages')
  set language(value: Array<Language>) {
    this.languages = value;
  }

  @Input('productPropertyTypes')
  set propertyType(value: Array<ProductPropertyType>) {
    this.propertyTypes = value;
    this.matchTypeNames();
  }

  @Input('productFieldTypes')
  set fieldType(value: Array<FieldType>) {
    this.fieldTypes = value;
  }

  @Output() dataChanged = new EventEmitter<boolean>();

  @ViewChild(MasterDataFieldComponent) detailsComponent: MasterDataFieldComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() { // tslint:disable-line:no-empty
    this.setTranslations('MASTER_DATA');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  edit = (e: any) => {
    const masterDataListField = e.row.data;

    this.editingIndex = this.items.indexOf(masterDataListField);
    this.detailsComponent.modalTitle = this.translations.FIELD.EDIT;
    const clone = { ...masterDataListField };
    this.detailsComponent.open(this.items, clone, this.languages, this.propertyTypes, this.fieldTypes);
  }

  openEditClick = (e: any) => {
    if (e.column.type !== 'buttons') {
      const masterDataListField = e.data;

      this.editingIndex = this.items.indexOf(masterDataListField);
      this.detailsComponent.modalTitle = this.translations.FIELD.EDIT;
      const clone = { ...masterDataListField };
      this.detailsComponent.open(this.items, clone, this.languages, this.propertyTypes, this.fieldTypes);
    }
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.FIELD.ADD_NEW;
    this.detailsComponent.open(this.items, new MasterDataListField(), this.languages, this.propertyTypes, this.fieldTypes);
  }

  isDeleteVisible = (e: any) => {
    if (e.row.data.fieldType === 0) {
      return true;
    } else {
      return false;
    }
  }

  onMasterDataFieldAdded(masterDataListField: MasterDataListField) {
    this.items.push(masterDataListField);
    this.translateMasterDataFields();
    this.matchTypeNames();
    this.dataChanged.emit();
  }

  onMasterDataFieldEdited(masterDataListField: MasterDataListField) {
    this.items[this.editingIndex] = masterDataListField;
    this.translateMasterDataFields();
    this.matchTypeNames();
    this.dataChanged.emit();
  }

  delete = (e: any) => {
    const masterDataListField = e.row.data;
    if (masterDataListField.fieldType !== 0) {
      return;
    }
    this.items.splice(this.items.indexOf(masterDataListField), 1);
    this.dataChanged.emit();
  }

  translateMasterDataFields() {
    this.items.forEach(masterDataListField => {
      masterDataListField.masterDataFieldName = this.languageService.getTranslatableText(masterDataListField.name);
      masterDataListField.masterDataDescription = this.languageService.getTranslatableText(masterDataListField.description);
    });
  }

  matchTypeNames() {
    this.items.forEach(mdf => {
      const propertyType = this.propertyTypes.find(pt => pt.propertyTypeId === mdf.propertyTypeId);
      if (propertyType) {
        mdf.typeName = propertyType.name;
      }
    });
  }
}
