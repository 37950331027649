import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

// components
import { BuyerUserComponent } from './buyer-user.component';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { BuyerRole } from '../../shared/models/buyer-role';
import { Language } from '../../shared/models/language';

import { Buyer } from '../shared/models/buyer-buyer';
import { BuyerUser } from '../shared/models/buyer-user';

// services
import { ServiceFactory } from '../../shared/factory/service-factory';
import { BuyerRoleService } from '../../shared/services/buyer-role.service';
import { LanguageService } from '../../shared/services/language.service';
import { WebApiService } from '../../shared/services/web-api.service';

import { UserService } from '../shared/services/buyer-user.service';
import { BuyerBuyerService } from '../shared/services/buyer-buyer.service';

class UserFilter {
  showActive = true;
  showInactive = true;
  showBlocked = true;
  showPending = true;
}

@Component({
  selector: 'buyer-users-component',
  templateUrl: 'buyer-users.component.html',
  styleUrls: ['./buyer-users.component.scss']
})
export class BuyerUsersComponent extends FullListComponent<BuyerUser, BuyerUserComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: BuyerUserComponent;
  @ViewChild('disable') disable: ConfirmationComponent;

  userService: UserService;

  languages: Array<Language> = [];

  buyers: Array<Buyer> = [];
  buyerRoles: Array<BuyerRole> = [];

  userFilter = new UserFilter();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private languageService: LanguageService,
    private buyerService: BuyerBuyerService,
    private buyerRoleService: BuyerRoleService,
  ) {
    super(injector, BuyerUser);
    this.getServices(route, appSettings, webApiService);

    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('SHARED.USER_MANAGEMENT');
    this.setTranslations('USERS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.userService = ServiceFactory.getUserService(route, appSettings, webApiService) as UserService;
  }

  // tslint:disable:no-magic-numbers
  getData() {

    this.spinner.show();
    forkJoin(
      this.userService.getUsersFiltered(this.userFilter),
      this.languageService.getLanguages(),
      this.buyerService.getBuyers(),
      this.buyerRoleService.getBuyerRolesForBuyer(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];
      this.buyers = result[2];
      this.buyerRoles = result[3];

      this.translateUserRoles();
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }
  // tslint:disable:no-magic-numbers

  edit = (e: any) => {
    const userId = e.row !== undefined ? e.row.data.userId : e.data.userId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, userId, this.languages, this.buyers, this.buyerRoles);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.languages, this.buyers, this.buyerRoles);
  }

  translateUserRoles() {
    this.items.forEach((user) => {
      user.buyerRoles.forEach((role) => {
        const roleId = this.buyerRoles.find(r => r.buyerRoleId === role.buyerRoleId);
        if (user.rolesString) {
          user.rolesString += ', ' + this.languageService.getTranslatableText(roleId.name);
        } else {
          user.rolesString = this.languageService.getTranslatableText(roleId.name);
        }
      });
    });
  }

  deleteSelected() {
    this.spinner.show();
    this.userService.delete(this.itemIdToDelete)
      .subscribe((users: Array<BuyerUser>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.userId;
    this.confirmation.opened = true;
  }
}
