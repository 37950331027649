<div class="item-list-component" [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
        width="90%"
        [showTitle]="true"
        title="{{ title }}"
        [dragEnabled]="false"
        [closeOnOutsideClick]="false"
        [rtlEnabled]="rtlEnabled"
        [(visible)]="opened"
        (onHiding)="opened = false;">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <p>{{ message }}</p>
          <label>{{ 'SHARED.CLEANUP_DATE' | translate }}</label>
        <date-component [data]="date" [required]="true" (modelChanged)="dateValueChanged($event)"></date-component>
<br/><br/>
          <button *ngIf="cancellable" type="button" class="btn btn-outline" (click)="opened = false;">{{ 'SHARED.CANCEL' | translate }}</button>
          <button type="button" class="btn btn-primary" (click)="opened = false; close.emit(true);" [disabled]="disabledButton()">{{ buttonCloseText | translate }}</button>
        </div>
        </dx-scroll-view>
    </dx-popup>
</div>
