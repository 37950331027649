import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import { Observable } from 'rxjs';

// interfaces
import { IAuctionService } from "../../../shared/interfaces/auction";

// models
import { AuctionClusterPermissionEnum } from '../../../shared/models/user-permissions';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Auction } from '../../../shared/models/auction';

// services
import { WebApiService } from '../../../shared/services/web-api.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Injectable()
export class AuctionClusterAuctionService implements IAuctionService{

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getAuctions(auctionClusterId: number): Observable<Array<Auction>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auction');
  }

  getAuctionsForPermissions(auctionClusterId: number, permissionIds: Array<number>): Observable<Array<Auction>> {
    return this.webApiService.save(this.apiPath + '/' + auctionClusterId + '/auction/permission', {'ids': permissionIds});
  }

  getAuction(auctionClusterId: number, auctionId: number): Observable<Auction> {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/auction', auctionId);
  }

  save(auctionClusterId: number, auction: Auction): Observable<any> {
    return this.webApiService.save<Auction>(this.apiPath + '/' + auctionClusterId + '/auction', auction);
  }

  edit(auctionClusterId: number, auction: Auction): Observable<any> {
    return this.webApiService.edit<Auction>(this.apiPath + '/' + auctionClusterId + '/auction', auction.auctionId, auction);
  }

  delete(auctionClusterId: number, auctionId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/' + auctionClusterId + '/auction', auctionId);
  }

  cleanupAuction(auctionId: number, cleanupDate: Date): Observable<any> {
    const dateFormatted = moment(cleanupDate).local().format('YYYY-MM-DD');
    return this.webApiService.save(this.apiPath + '/auction/' + auctionId + '/cleanup?date=' + dateFormatted, null);
  }

  getAuctionsForReports(auctionClusterId: number): Observable<Array<Auction>> {
    return this.webApiService.save(this.apiPath + '/' + auctionClusterId + '/auction/permission', {'ids':  [AuctionClusterPermissionEnum.SupplyForecastCatalogManagement]});  
   }
  
}
