import { Component } from '@angular/core';

import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'access-token',
  template: '',
})
export class AccessTokenComponent {

  constructor(authenticationService: AuthService) {
    
  }
}
