import { Component, OnInit, Injector } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ProductComponent } from './product.component';

// models
import { Language } from '../../shared/models/language';
import { Product } from '../../shared/models/product';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';

import { ProductService } from '../shared/services/product.service';


@Component({
  selector: 'products-component',
  templateUrl: 'products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends FullListComponent<Product, ProductComponent> implements OnInit {

  languages: Array<Language> = [];
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: ProductService,
    private translateService: TranslateService,
  ) {
    super(injector, Product);
    this.title.set('PRODUCTS.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('PRODUCTS');
  }

  // tslint:disable:no-magic-numbers
  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getProducts(this.id, this.translateService.currentLang),
      this.languageService.getAuctionClusterLanguages(this.id),
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }
  // tslint:disable:no-magic-numbers

  add() {
    this.router.navigate(['auction/products/' + this.id + '/product']); // tslint:disable-line:no-floating-promises
  }

  deleteItem = (e: any) => {
    const productId = e.row !== undefined ? e.row.data.productId : e.data.productId;
    this.delete(productId, e);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe((users: Array<Product>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  edit = (e: any) => {
    const productId = e.row !== undefined ? e.row.data.productId : e.data.productId;
    event.stopPropagation();
    this.router.navigate(['auction/products/' + this.id + '/product/' + productId]); // tslint:disable-line:no-floating-promises
  }
}