import { Component, Injector, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ClrTabs } from '@clr/angular';
import { forkJoin, Subscription } from 'rxjs';

// components
import { DxFormComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { Language } from '../../shared/models/language';

import { PlatformRole } from '../shared/models/platform-role';
import { PlatformPermission } from '../shared/models/platform-permission';

// services
import { LanguageService } from '../../shared/services/language.service';

import { PlatformRoleService } from '../shared/services/platform-role.service';

@Component({
  selector: 'role-component',
  templateUrl: 'role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent extends ItemDetailsComponent<PlatformRole> implements OnInit, OnDestroy {

  permissions: Array<PlatformPermission> = [];
  languages: Array<Language> = [];
  requiredPermissions: Array<number> = [];
  validFields: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('roleName') roleName;
  @ViewChild('roleDescription') roleDescription;
  @ViewChild('roleForm', { read: DxFormComponent }) roleForm: DxFormComponent;

  constructor(
    protected injector: Injector,
    private dataService: PlatformRoleService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new PlatformRole();
    this.setTranslations('ROLES');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(roles: Array<PlatformRole>, roleId: number, permissions: Array<PlatformPermission>) {
    this.modalTitle = roleId ? this.translations.EDIT : this.translations.ADD_NEW;
    this.allItems = roles;
    this.permissions = permissions;
    this.requiredPermissions = [];

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    forkJoin(
      this.languageService.getLanguages()
    ).subscribe(result => {
      this.languages = result[0];

      if (roleId != null) {
        this.isEditMode = true;
        this.spinner.show();
        this.dataService.getPlatformRole(roleId)
          .subscribe((res: PlatformRole) => {
            this.model = res;
            this.model.name = this.languageService.updateStringToAllLanguages(this.languages, this.model.name);
            this.model.description = this.languageService.updateStringToAllLanguages(this.languages, this.model.description);

            this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);

            this.requiredPermissions = this.model.permissions;
            this.isOpened = true;
            this.spinner.hide();
          },
            error => {
              this.errorService.show(error);
              this.spinner.hide();
            });
      } else {
        this.model = new PlatformRole();
        this.model.name = this.model.description = this.languageService.updateStringToAllLanguages(this.languages, "{}");
        this.isEditMode = false;
        this.isOpened = true;
      }
    });
  }

  save() {
    this.model.name = this.roleName.data;
    this.model.permissions = this.requiredPermissions;
    this.model.description = this.roleDescription.data;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new PlatformRole();
          this.close(true);
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new PlatformRole();
          this.close(true);
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.roleName.data);
      this.roleForm.instance.updateData(fieldName, this.roleName.data);
    } else if (fieldName === 'description') {
      this.roleForm.instance.updateData(fieldName, this.roleDescription.data);
    }
  }
}