import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Auth0Guard } from './shared/services/auth0-guard.service';

import { AccessTokenComponent } from './access-token.component';

const approutes: Routes = [
  { path: 'access_token', component: AccessTokenComponent, canActivate: [Auth0Guard] },
  { path: '', data: { root: true }, redirectTo: '/core/home', pathMatch: 'full' }
];

@NgModule({
    imports: [
      RouterModule.forRoot(approutes, {onSameUrlNavigation: 'reload'})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
