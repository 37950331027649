import { Directive } from '@angular/core';

// components
import { EditableListComponent } from '../editable-list/editable-list.component';

// interfaces
import { DeletableList } from '../../interfaces/item-list';
import { ItemDetails } from '../../interfaces/item-details';

@Directive()
export abstract class FullListComponent<M, C extends ItemDetails<M>> extends EditableListComponent<M, C> implements DeletableList {

  itemIdToDelete: number;

  delete(itemId: number, event: Event): void { }

  deleteSelected(): void { };

  abstract add();
}
