import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { SupplierComponent } from './supplier.component';

// models
import { Supplier } from '../../shared/models/supplier';
import { User } from '../../shared/models/user';

// services
import { LanguageService } from '../../shared/services/language.service';
import { SupplierService } from '../../shared/services/supplier.service';

import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'suppliers-component',
  templateUrl: 'suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent extends FullListComponent<Supplier, SupplierComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: SupplierComponent;

  users: Array<User> = [];
  supplierToDisable: Supplier;
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private supplierService: SupplierService,
    private userService: UserService,
    private languageService: LanguageService
  ) {
    super(injector, Supplier);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('SHARED.SUPPLIER_MANAGEMENT');
    this.getData();

    this.setTranslations('SUPPLIERS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.supplierService.getSuppliers(),
      this.userService.getUsers()
    ).subscribe(result => {
      this.items = result[0];
      this.items.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      this.users = result[1];
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);  
        this.spinner.hide();
      });
  }

  edit = (e: any) => {
    const supplierId = e.row !== undefined ? e.row.data.supplierId : e.data.supplierId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, supplierId, this.users);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.users);
  }

  deleteSelected() {
    this.spinner.show();
    this.supplierService.delete(this.itemIdToDelete).subscribe((suppliers) => {
      this.getData();
      this.spinner.hide();
    });

    this.confirmation.opened = false;
  }

  enableDisableSupplier = (e: any) => {
    const supplier: Supplier = e.row.data;
    this.supplierToDisable = supplier;
    this.supplierService.enableDisable(this.supplierToDisable).subscribe(() => {
      this.getData();
    },
      error => {
        this.errorService.show(error);
      }
    );
  }

  navigateToUsers = (e: any) => {
// tslint:disable-next-line: no-floating-promises
    this.router.navigate(['/platform/users'], { queryParams: { suppliers: e.row.data.supplierId} });
  }

  calculateDisabledDisplayValue = (e: any) => {
    if (e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
  }

  calculateEnabledDisplayValue = (e: any) => {
    if (!e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.supplierId;
    this.confirmation.opened = true;
  }
}
