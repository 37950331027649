export class Zone {
  zoneId: number;
  locationId: number;
  name: string;
  type: number;
  isActive: boolean;
  buyers: Array<number> = [];

  // displayable fields
  locationName: string;
  typeName: string;
  buyerName: string;
}
