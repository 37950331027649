import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment/moment';

// models
import { ApplicationSettings } from '../models/application-settings';
import { UserLineTimes } from '../models/user-line-times';
import { UserLineTimesFilter } from '../models/user-line-times-filter';
import { UserLogin } from '../models/user-login';
import { UserLoginsFilter } from '../models/user-logins-filter';

// services
import { WebApiService } from './web-api.service';

@Injectable()
export class ReportingService {

  private readonly reportingApiURL: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.reportingApiURL = this.appSettings.reportingApiURL;
  }

  getUserLogins(filter: UserLoginsFilter): Observable<Array<UserLogin>> {
    const from = moment(filter.from).unix();
    const to = moment(filter.to).unix();

    return this.webApiService.getList(
      `${this.reportingApiURL}/api/v1/user-logins?from=${from}&to=${to}&search=${encodeURIComponent(filter.search)}`
    );
  }

  downloadUserLogins(filter: UserLoginsFilter) {
    const from = moment(filter.from).unix();
    const to = moment(filter.to).unix();

    this.webApiService.downloadWithGet(
      `${this.reportingApiURL}/api/v1/user-logins/pdf?from=${from}&to=${to}&search=${encodeURIComponent(filter.search)}`,
      'user-logins.pdf');
  }

  getUserLoginsAuctionCluster(auctionClusterId: number, filter: UserLoginsFilter): Observable<Array<UserLogin>> {
    const from = moment(filter.from).unix();
    const to = moment(filter.to).unix();

    return this.webApiService.getList(
      `${this.reportingApiURL}/api/v1/user-logins/auction-cluster/${encodeURIComponent(auctionClusterId.toString())}`
      + `?from=${from}&to=${to}&search=${encodeURIComponent(filter.search)}`
    );
  }

  getUserLineTimes(filter: UserLineTimesFilter): Observable<Array<UserLineTimes>> {
    const from = moment(filter.from).unix();
    const to = moment(filter.to).unix();

    return this.webApiService.getList(
      `${this.reportingApiURL}/api/v1/user-line-times?from=${from}&to=${to}&search=${encodeURIComponent(filter.search)}`
    );
  }

  getUserLineTimesAuctionCluster(auctionClusterId: number, filter: UserLineTimesFilter): Observable<Array<UserLineTimes>> {
    const from = moment(filter.from).unix();
    const to = moment(filter.to).unix();

    return this.webApiService.getList(
      `${this.reportingApiURL}/api/v1/user-line-times/auction-cluster/${encodeURIComponent(auctionClusterId.toString())}`
      + `?from=${from}&to=${to}&search=${encodeURIComponent(filter.search)}`
    );
  }
}
