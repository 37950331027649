import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { GenericLot } from '../../../shared/models/generic-lot';
import { PagedLots, Lot } from '../../../shared/models/lot';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class SupplierLotService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierlevel/';
  }

  private supplierId: number;

  setId(id: number) {
    this.supplierId = id;
  }

  checkLotActions(auctionId: number, lotId: number): Observable<boolean> {
    return this.webApiService.getSingle(this.apiPath + this.supplierId + '/auction/' + auctionId + '/catalog/lot/' + lotId + '/checkactions');
  }

  getMasterDetails(auctionClusterId: number, catalogId: number, isForecast = false) : Observable<GenericLot> {
    return this.webApiService.getList(this.apiPath + `${this.supplierId}/ac/${auctionClusterId}/catalog/${catalogId}/masterforecast`);
  }

  fillProperties(auctionId: number, productPropertyId: number, masterDetailsLotId: number, lot: Lot, isTouch?: boolean): Observable<Lot> {
    let queryString = '';
    if (masterDetailsLotId) {
      queryString += `?previousLotId=${masterDetailsLotId}`;
    }
    if (isTouch) {
        queryString += (masterDetailsLotId ? '&' : '?') + `isTouch=${true}`;    
      }
    return this.webApiService.save(this.apiPath + this.supplierId + '/auction/' + auctionId + '/catalog/lot/fillproperties/' + productPropertyId + queryString, lot);
  }

  loadCatalogLotSearched(auctionId: number, catalogId: number, language: string,loadOptions: any, lotId?: number ) 
  {
    let queryString = `?timezoneoffset=${new Date().getTimezoneOffset()}`;
    if (language) {
      queryString += `&language=${language}`;
    }

    if (lotId) {
      queryString += `&lotId=${lotId}`;
    }
    
    let params: HttpParams = new HttpParams();
                [
                    "skip",
                    "take",
                    "requireTotalCount",
                    "requireGroupCount",
                    "sort",
                    "filter",
                    "totalSummary",
                    "group",
                    "groupSummary"
                ].forEach(function(i) {
                    if (i in loadOptions && loadOptions[i] != undefined)
                        params = params.set(i, JSON.stringify(loadOptions[i]));
                });
    
    return this.webApiService.getWithParams(this.apiPath + this.supplierId + '/auction/' + auctionId + '/catalog/' +
      catalogId + '/lot/load' + queryString, params);
  }

  getLot(auctionId: number, lotId: number, isTouch?: boolean): Observable<Lot> {
    let queryString = '';
    if (isTouch) {
        queryString = `?isTouch=${true}`;    
      }
    return this.webApiService.getSingle(this.apiPath + this.supplierId + '/auction/' + auctionId + '/catalog/lot/' + lotId + queryString);
  }

  getNew(auctionId: number, catalogId: number, lotId?: number, isTouch?: boolean): Observable<Lot> {
    let queryString = '';
    if (lotId) {
      queryString += `?lotId=${lotId}`;
    }
    if (isTouch) {
        queryString += (lotId ? '&' : '?') + `isTouch=${true}`;    
      }
    return this.webApiService.getSingle(this.apiPath + this.supplierId + '/auction/' + auctionId + '/catalog/' + catalogId + '/lot/new' + queryString);
  }

  save(auctionId: number, lot: Lot, isTouch?: boolean): Observable<any> {
    let queryString = '';
    if (isTouch) {
        queryString = `?isTouch=${true}`;    
      }
    return this.webApiService.save<Object>(this.apiPath + this.supplierId + '/auction/' + auctionId + '/catalog/lot' + queryString, lot);
  }

  edit(auctionId: number, lot: Lot, isTouch?: boolean): Observable<any> {
    let queryString = '';
    if (isTouch) {
        queryString = `?isTouch=${true}`;    
      }
    return this.webApiService.editSingle<Lot>(this.apiPath + this.supplierId + '/auction/' + auctionId + '/catalog/lot/'+ lot.lotId + queryString, lot);
  }

  delete(auctionId: number, lotId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + this.supplierId + '/auction/' + auctionId + '/catalog/lot', lotId);
  }

  multiDelete(auctionId: number, lotIds: Array<number>): Observable<any> {
    return this.webApiService.deleteSingle(this.apiPath + this.supplierId + '/auction/' + auctionId + '/catalog/lot', lotIds);
  }

  printSupply(auctionClusterId: number, catalogId: number,  lotIds: Array<number>, masterLot: boolean): Observable<any> {
    return;
  }

  addLotImage(auctionId: number, formData: FormData): Observable<string> {
    return this.webApiService.uploadImage(this.apiPath + this.supplierId + '/auction/' + auctionId + '/catalog/lot/uploadimage', formData);
  }
}
