
<clr-alert *ngIf="isShowing" [clrAlertType]="'info'" [clrAlertAppLevel]="true" (clrAlertClosedChange)="onClose()" [clrAlertClosable]="true">
    <clr-alert-item>
        <span class="alert-text">
            {{ 'PRIVACY.COOKIE_CONSENT' | translate }}
        </span>
        <a class="learn-more" (click)="more()">
          {{ 'PRIVACY.LEARN_MORE' | translate }}
        </a>
    </clr-alert-item>
</clr-alert>
