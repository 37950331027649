export class AuctionClusterReportingRole {
  auctionClusterReportingRoleId: number;
  auctionClusterId: number;
  name: string;
  description: string;
  reportDesigns: Array<number> = [];

  // displayable fields
  permissionString: string;
}
