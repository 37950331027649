import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../models/application-settings';
import { BuyerRole } from '../models/buyer-role';

// services
import { WebApiService } from './web-api.service';

@Injectable()
export class BuyerRoleService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerrole';
  }

  getBuyerRoles(): Observable<Array<BuyerRole>> {
    return this.webApiService.getList(this.apiPath);
  }

  getBuyerRolesForAuctionCluster(auctionClusterId: number): Observable<Array<BuyerRole>> {
    return this.webApiService.getList(this.appSettings.adminApi + 'auctioncluster/' + auctionClusterId + '/buyerrole');
  }

  getBuyerRolesForAuctionClusterBuyer(auctionClusterId: number, buyerId: number): Observable<Array<BuyerRole>> {
    return this.webApiService.getList(this.appSettings.adminApi + 'auctioncluster/' + auctionClusterId + `/buyerrole/${buyerId}`);
  }

  getBuyerRole(buyerRoleId: number): Observable<BuyerRole> {
    return this.webApiService.get(this.apiPath, buyerRoleId);
  }

  getBuyerRolesForBuyerPlatform(buyerId: number): Observable<Array<BuyerRole>> {
    return this.webApiService.getList(this.apiPath + `/buyer/${buyerId}`);
  }

  getBuyerRolesForBuyer(buyerId: number): Observable<Array<BuyerRole>> {
    return this.webApiService.getList(this.appSettings.adminApi + `buyerlevel/${buyerId}/buyerrole`);
  }

  save(buyerRole: BuyerRole): Observable<any> {
    return this.webApiService.save<BuyerRole>(this.apiPath, buyerRole);
  }

  edit(buyerRole: BuyerRole): Observable<any> {
    return this.webApiService.edit<BuyerRole>(this.apiPath, buyerRole.buyerRoleId, buyerRole);
  }

  delete(buyerRoleId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, buyerRoleId);
  }
}
