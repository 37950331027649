import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../../shared/components/full-list/full-list.component';
import { ProductFunctionkeysExpressionComponent } from './product-functionkeys-expression.component';

// models
import { ProductFunctionKeysExpression, ProductProperty } from '../../../shared/models/product';

// services
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../shared/services/product.service';

@Component({
  selector: 'product-functionkeys-expressions-component',
  templateUrl: './product-functionkeys-expressions.component.html'
})
export class ProductFunctionkeysExpressionsComponent extends FullListComponent<ProductFunctionKeysExpression, ProductFunctionkeysExpressionComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ProductFunctionkeysExpressionComponent;

  auctionClusterId: number;
  productId: number;
  productProperties: Array<ProductProperty> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private translateService: TranslateService,
    private dataService: ProductService,
  ) {
    super(injector, ProductFunctionKeysExpression);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('PRODUCT.FUNCTIONKEYS_EXPRESSION');
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.productId = +this.route.snapshot.params['productId'];

    forkJoin(
      this.dataService.getProductFunctionKeysExpressions(this.auctionClusterId, this.productId),
      this.dataService.getProductProperties(this.productId, this.translateService.currentLang)
    ).subscribe(result => {
      this.items = result[0];
      this.productProperties = result[1];

      this.items.forEach(item => {
        if (item.productPropertyId) {
          var productProperty = this.productProperties.filter(_ => _.productPropertyId == item.productPropertyId)[0];
          item.productPropertyName = productProperty.name;
        }
      });
    },
      error => {
        this.errorService.show(error);
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null);
  }

  edit = (e: any) => {
    const id = e.row !== undefined ? e.row.data.id : e.data.id;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, id);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.id;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.items = this.items.filter(
      (_) => _.productFunctionKeysExpressionId !== this.itemIdToDelete
    );
    this.save();
  }

  onDetailsClosed(expression: ProductFunctionKeysExpression) {
    const existingIndex = this.items.findIndex(item => item.productFunctionKeysExpressionId === expression.productFunctionKeysExpressionId);
    if (existingIndex !== -1) {
      this.items[existingIndex] = expression;
    } else {
      this.items.push(expression);
    }

    this.save();
  }

  save() {
    this.dataService.saveProductFunctionKeysExpressions(this.auctionClusterId, this.productId, this.items)
      .subscribe((res: Array<ProductFunctionKeysExpression>) => {
        this.items = res;
      },
        error => {
          this.errorService.show(error);
        });
  }
}