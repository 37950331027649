import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ClarityModule } from '@clr/angular';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

// Components
import { HeaderComponent } from './header/header.component';
import { TrackCapsDirective } from './components/authentication/authentication.component';
import { CollapsableSectionComponent } from './components/collapsable-section/collapsable-section.component';
import { ContentComponent } from './content/content.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { ExportComponent } from './export/export.component';
import { VersionComponent } from './components/version/version.component';
import { PublicSupplyForecastComponent } from './components/public-supply-forecast/public-supply-forecast.component';
import { PublicSupplyForecastURKComponent } from './components/public-supply-forecast-urk/public-supply-forecast-urk.component';
import { SaveConfirmationComponent } from './components/save-confirmation/save-confirmation.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { AlertComponent } from '../core/alert/alert.component';
import { SpinnerComponent } from '../core/spinner/spinner.component';
import { LongDateComponent } from '../auction/shared/date-time-components/long-date.component';
import { FullDateTimeComponent } from '../auction/shared/date-time-components/full-date-time.component';
import { LongTimeComponent } from '../auction/shared/date-time-components/long-time.component';
import { ShortDateComponent } from '../auction/shared/date-time-components/short-date.component';
import { ShortTimeComponent } from '../auction/shared/date-time-components/short-time.component';
import { DateComponent } from '../auction/shared/date-time-components/date.component';
import { DateTimeComponent } from '../auction/shared/date-time-components/date-time.component';
import { TimeComponent } from '../auction/shared/date-time-components/time.component';
import { ReportComponent } from './components/reporting/report.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { EditUserNotificationsComponent } from './components/edit-user-notifications/edit-user-notifications.component';
import { EditUserNotificationChannelsComponent } from './components/edit-user-notification-channels/edit-user-notification-channels.component';
import { AdditionalMenuItemsContainerComponent } from './components/additional-menu-items-container/additional-menu-items-container.component';
import { VerifyMyPasswordComponent } from './components/verify-my-password/verify-my-password.component';
import { MasterDataGridComponent } from './components/masterdata-grid-component/masterdata-grid.component';
import { SupplyLotsComponent } from './components/catalogs/supply-lots.component';
import { SupplyLotsTouchComponent } from './components/catalogs/supply-lots-touch.component';
import { GroupFilterItemComponent } from './components/catalogs/group-filter-item.component';
import { MasterdataDropdownComponent } from './components/masterdata-dropdown/masterdata-dropdown.component';
import { MasterdataTagboxComponent } from './components/masterdata-tagbox/masterdata-tagbox.component'; 
import { GroupFilterComponent } from './components/catalogs/group-filter.component';
import { MoveLotsComponent } from './components/catalogs/move-lots.component';
import { LotMultiEditComponent } from './components/catalogs/lot-multi-edit.component';
import { PublishLotComponent } from './components/catalogs/publish-lot.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import {
  DxColorBoxModule,
  DxDataGridModule,
  DxTemplateModule,
  DxBulletModule,
  DxButtonModule,
  DxSelectBoxModule,
  DxCheckBoxModule,
  DxTabsModule,
  DxTabPanelModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxFormModule,
  DxValidationGroupModule,
  DxPopupModule,
  DxHtmlEditorModule,
  DxToastModule,
  DxScrollViewModule,
  DxTagBoxModule,
  DxListModule,
  DxBoxModule,
  DxDateBoxModule,
  DxNumberBoxModule,
  DxRadioGroupModule,
  DxResponsiveBoxModule,
  DxSwitchModule
} from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';

// Services
import { AuthGuard } from './services/auth-guard.service';
import { BrowserGuard } from '../shared/services/browser-guard.service';
import { BuyerService } from './services/buyer.service';
import { SupplierService } from './services/supplier.service';
import { AlertService } from './services/alert.service';
import { TitleService } from './services/title.service';
import { ErrorService } from './services/error.service';
import { SpinnerService } from './services/spinner.service';
import { AuctionClusterRoleService } from './services/auction-cluster-role.service';
import { BuyerPermissionService } from './services/buyer-permission.service';
import { BuyerRoleService } from './services/buyer-role.service';
import { LanguageService } from './services/language.service';
import { SupplierPermissionService } from './services/supplier-permission.service';
import { SupplierRoleService } from './services/supplier-role.service';
import { ClockService } from './services/clock.service';
import { AuctionClusterService } from '../platform/shared/services/auction-cluster.service';
import { VersionService } from '../shared/services/version.service';
import { VatService } from './services/vat.service';
import { CurrencyService } from './services/currency.service';
import { PublicDataService } from './services/public-data-service';
import { ReportingService } from './services/reporting.service';
import { UserService } from './services/user.service';
import { DateTimeService } from './services/datetime.service';
import { MailService } from './services/mail.service';
import { LanguagePreferenceService } from './services/language-preference.service';
import { TermsAndConditionsService } from './services/terms-and-conditions.service';
import { ProductPropertyStylingService } from './services/product-property-styling.service';
import { ProductBuyButtonService } from '../auction/shared/services/product-buy-button.service';

// Pipes
import { SearchPipe } from './pipes/search.pipe';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { TranslatableFieldComponent } from './components/translatable-field/translatable-field.component';
import { TranslatableField2Component } from './components/translatable-field2/translatable-field2.component';
import { LocalDatePipe } from './pipes/localdate.pipe';
import { TranslateJsonPipe } from './pipes/translate-pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FormatPipe } from './pipes/format.pipe';

// Validators
import { EmailAddressValidator } from './validators/email.validator';
import { PhoneNumberValidator } from './validators/phone-number.validator';
import { UrlValidator } from './validators/url.validator';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    ClarityModule,
    RouterModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DxColorBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTabsModule,
    DxTabPanelModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxFormModule,
    DxValidationGroupModule,
    DxPopupModule,
    DxHtmlEditorModule,
    DxToastModule,
    DxScrollViewModule,
    DxTagBoxModule,
    DxListModule,
    DxBoxModule,
    DxDateBoxModule,
    DxNumberBoxModule,
    DxRadioGroupModule,
    DxResponsiveBoxModule,
    DxSwitchModule
  ],
  declarations: [
    HeaderComponent,
    ContentComponent,
    CollapsableSectionComponent,
    SearchBoxComponent,
    SearchPipe,
    ConfirmationComponent,
    TranslatableFieldComponent,
    TranslatableField2Component,
    ExportComponent,
    VersionComponent,
    SaveConfirmationComponent,
    PublicSupplyForecastComponent,
    PublicSupplyForecastURKComponent,
    EmailAddressValidator,
    PhoneNumberValidator,
    UrlValidator,
    LocalDatePipe,
    TranslateJsonPipe,
    SafeHtmlPipe,
    FormatPipe,
    PaginationComponent,
    AuthenticationComponent,
    SpinnerComponent,
    AlertComponent, LongDateComponent, FullDateTimeComponent, LongTimeComponent,
    ShortTimeComponent, ShortDateComponent, DateComponent, DateTimeComponent, TimeComponent,
    ReportComponent,
    EditProfileComponent,
    AdditionalMenuItemsContainerComponent,
    VerifyMyPasswordComponent,
    EditUserNotificationsComponent,
    EditUserNotificationChannelsComponent,
    TrackCapsDirective,
    MasterdataDropdownComponent,
    MasterdataTagboxComponent,
    MasterDataGridComponent,
    SupplyLotsComponent,
    SupplyLotsTouchComponent,
    GroupFilterComponent, GroupFilterItemComponent,
    MoveLotsComponent, LotMultiEditComponent, PublishLotComponent
  ],
  providers: [
    AuthGuard,
    BrowserGuard,
    BuyerService,
    AlertService,
    TitleService,
    ErrorService,
    SpinnerService,
    SupplierService,
    AuctionClusterService,
    AuctionClusterRoleService,
    BuyerPermissionService,
    BuyerRoleService,
    LanguageService,
    SupplierPermissionService,
    SupplierRoleService,
    ClockService,
    VersionService,
    VatService,
    LanguagePreferenceService,
    CurrencyService,
    PublicDataService,
    ReportingService,
    UserService,
    DateTimeService,
    MailService,
    TermsAndConditionsService,
    ProductPropertyStylingService,
    FormatPipe,
    ProductBuyButtonService
  ],
  exports: [
    BrowserModule,
    ClarityModule,
    DxPopupModule,
    DxHtmlEditorModule,
    HeaderComponent,
    ContentComponent,
    CollapsableSectionComponent,
    SearchBoxComponent,
    SearchPipe,
    ConfirmationComponent,
    TranslatableFieldComponent,
    TranslatableField2Component,
    ExportComponent,
    VersionComponent,
    PublicSupplyForecastComponent,
    PublicSupplyForecastURKComponent,
    SaveConfirmationComponent,
    EmailAddressValidator,
    PhoneNumberValidator,
    UrlValidator,
    LocalDatePipe,
    TranslateJsonPipe,
    SafeHtmlPipe,
    FormatPipe,
    PaginationComponent,
    AuthenticationComponent,
    SpinnerComponent,
    AlertComponent, LongDateComponent, FullDateTimeComponent, LongTimeComponent,
    ShortTimeComponent, ShortDateComponent, DateComponent, DateTimeComponent, TimeComponent,
    EditProfileComponent, EditUserNotificationsComponent, EditUserNotificationChannelsComponent,
    AdditionalMenuItemsContainerComponent,
    VerifyMyPasswordComponent,
    DxSwitchModule,
    MasterdataDropdownComponent,
    MasterdataTagboxComponent,
    MasterDataGridComponent,
    GroupFilterComponent, GroupFilterItemComponent,
    MoveLotsComponent, LotMultiEditComponent, PublishLotComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
