import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { ProductPropertyStyling } from '../../../shared/models/product-property-styling';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class ProductPropertyStylingService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getProductPropertyStylings(auctionClusterId: number): Observable<Array<ProductPropertyStyling>> {
    return this.webApiService.getList(`${this.apiPath}/${auctionClusterId}/productpropertystyling`);
  }
}
