import { Component, Injector, ViewChild, OnInit, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrTabs } from '@clr/angular';
import { forkJoin, Subscription, firstValueFrom } from 'rxjs';

// components
import { DxPopupComponent } from 'devextreme-angular';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { EditUserNotificationChannelsComponent } from '../../shared/components/edit-user-notification-channels/edit-user-notification-channels.component';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { SaveConfirmationComponent } from '../../shared/components/save-confirmation/save-confirmation.component';
import { VerifyMyPasswordComponent } from '../../shared/components/verify-my-password/verify-my-password.component';

// models
import { Auction } from '../../shared/models/auction';
import { AuctionCluster } from '../../shared/models/auction-cluster';
import { AuctionClusterRole } from '../../shared/models/auction-cluster-role';
import { AuctionClusterReportingRole } from '../../shared/models/auction-cluster-reporting-role';
import { Buyer, BuyerCompareName } from '../../shared/models/buyer';
import { BuyerRole } from '../../shared/models/buyer-role';
import { Language } from '../../shared/models/language';
import { MFATypeEnum } from '../../shared/models/mfaType';
import { Supplier, SupplierCompareName } from '../../shared/models/supplier';
import { SupplierRole } from '../../shared/models/supplier-role';
import { UserAuctionClusterRole, UserAuctionClusterReportingRole, UserBuyerRole, UserSupplierRole } from '../../shared/models/user';
import { UserAuctionClusterItem } from '../../shared/models/user-auction-cluster-item';
import { UserNotification } from '../../shared/models/user-notification';

import { AuctionClusterBuyer } from '../shared/models/auction-cluster-buyer';
import { AuctionClusterUser } from '../shared/models/auction-cluster-user';

// services
import { BuyerRoleService } from '../../shared/services/buyer-role.service';
import { LanguageService } from '../../shared/services/language.service';
import { ConfigService } from '../../shared/services/config.service';
import { MailService } from '../../shared/services/mail.service';
import { NotificationManagerService } from '../../shared/services/notification-manager.service';
import { SupplierRoleService } from '../../shared/services/supplier-role.service';

import { AuctionClusterUserService } from '../shared/services/auction-cluster-user.service';
import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';


class UserBuyerItem extends UserBuyerRole {
  filteredBuyerRoles: Array<BuyerRole> = [];
  auctionFilterData = '-';
  catalogFilterData = '-';
  clockFilterData = '-';
}

class UserSupplierItem extends UserSupplierRole {
  filteredSupplierRoles: Array<SupplierRole> = [];
  auctionFilterData = '-';
  catalogFilterData = '-';
  clockFilterData = '-';
}

class UserReportingItem extends UserAuctionClusterReportingRole {
  filteredReportingRoles: Array<AuctionClusterReportingRole> = [];
}

const ESC_KEYCODE = 27;

@Component({
  selector: 'auction-cluster-user-component',
  templateUrl: 'auction-cluster-user.component.html',
  styleUrls: ['./auction-cluster-user.component.scss']
})
export class AuctionClusterUserComponent extends ItemDetailsComponent<AuctionClusterUser> implements OnInit, OnDestroy {

  // tabs checkers
  isAuctionClusterUser = false;
  isBuyerUser = false;
  isSupplierUser = false;
  isSystemUser = false;
  auctionClusters: Array<AuctionCluster> = [];
  auctions: Array<Auction>;
  auctionClusterRoles: Array<AuctionClusterRole> = [];
  auctionClusterReportingRoles: Array<AuctionClusterReportingRole> = [];
  languages: Array<Language> = [];
  notifications: Array<UserNotification> = [];

  auctionCluster: AuctionCluster;

  buyers: Array<Buyer>;
  buyerRoles: Array<BuyerRole> = [];

  suppliers: Array<Supplier>;
  supplierRoles: Array<SupplierRole> = [];

  emailVerified: boolean = false;
  initialEmail: string;

  isMFAEnabledVisible: boolean = false;
  isMFAResetButtonVisible: boolean = false;

  auctionClusterTable = null;
  supplierTable = null;
  buyerTable = null;
  reportingRolesTable = null;

  buttonOptions: any = {
    text: "Verify Email",
    type: "success",
    stylingMode: "outlined",
    onClick: () => { 
      this.verifyEmail(); 
    }
  };

  mfaResetButtonOptions: any = {
    text: "Reset MFA",
    type: "danger",
    stylingMode: "outlined",    
    onClick: () => { 
      this.verifyMyPasswordComponent.open(this.model.userId, this.auctionCluster.auctionClusterId);
    }
  };

  buyersPaginated: any = {};
  suppliersPaginated: any = {};

  formDirty: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('saveConfirmation') saveConfirmation: SaveConfirmationComponent;
  @ViewChild('verifyMyPassword') verifyMyPasswordComponent: VerifyMyPasswordComponent;
  @ViewChild('confirmationAuctionClusterLicenses') confirmationAuctionClusterLicenses: ConfirmationComponent;
  @ViewChild('notificationChannelsComponent') notificationChannelsComponent: EditUserNotificationChannelsComponent;
  @ViewChild('email', { read: ElementRef }) emailInput: any;
  @ViewChild('licenseConfirmation') licenseConfirmation: ConfirmationComponent;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;
  

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: AuctionClusterUserService,
    private buyerService: AuctionClusterBuyerService,
    private mailService: MailService,
    private route: ActivatedRoute,
    public configService: ConfigService,
    private supplierRoleService: SupplierRoleService,
    private buyerRoleService: BuyerRoleService,
    private notificationManagerService: NotificationManagerService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AuctionClusterUser();
    this.clean();
    this.setTranslations('USER');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  translateSelectBoxes = (item) => {
    if (item) {
      const label = this.getTranslation(item.name);
      return label;
    }
  }

  open(users: Array<AuctionClusterUser>, userId: number, auctionClusterRoles: Array<AuctionClusterRole>,
    auctions: Array<Auction>, languages: Array<Language>,
    buyers: Array<Buyer>,
    suppliers: Array<Supplier>,
    auctionCluster: AuctionCluster,
    auctionClusterReportingRoles: Array<AuctionClusterReportingRole>,
    isSystemUser: boolean) {

    this.allItems = users;

    this.auctionCluster = auctionCluster;
    this.auctionClusters = [this.auctionCluster];
    this.isSystemUser = isSystemUser;

    const allSelectioAuctions  = new Auction();
    allSelectioAuctions.auctionId = null;
    allSelectioAuctions.auctionClusterId = null;
    allSelectioAuctions.name = this.translations.ALL_AUCTIONS;

    this.auctions = [allSelectioAuctions, ...auctions];

    this.isMFAEnabledVisible = this.auctionCluster.mfaTypeId !== MFATypeEnum.None;

    this.auctionClusterRoles = auctionClusterRoles;
    this.auctionClusterReportingRoles = auctionClusterReportingRoles;
    this.languages = languages;
    this.formDirty = false;

    this.buyers = buyers.sort(BuyerCompareName);

    this.buyersPaginated = {
      paginate: true,
      pageSize: 20,
      store: this.buyers,
      sort: 'name'
    };

    this.suppliers = suppliers.sort(SupplierCompareName);

    this.suppliersPaginated = {
      paginate: true,
      pageSize: 20,
      store: this.suppliers,
      sort: 'name'
    };

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;
    this.clean();

    if (userId != null) {
      this.isEditMode = true;
      this.emailVerified = true;
      this.spinner.show();
      this.dataService.getUser(+this.route.snapshot.params['id'], userId)
        .subscribe((res: AuctionClusterUser) => {          
          this.model = res;
          this.initialEmail = this.model.email;
          if(this.model.buyerRoles[0])
            this.buyerRoles = this.model.buyerRoles[0].availableBuyerRoles;
          if(this.model.supplierRoles[0])
            this.supplierRoles = this.model.supplierRoles[0].availableSupplierRoles;
          this.parseChildObjects();
          this.isOpened = true;
          this.isMFAResetButtonVisible = this.model.mfaEnabled && this.auctionCluster.mfaTypeId != MFATypeEnum.None;
          this.getNotificationsGridData();
          this.spinner.hide();          
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    } else {
      

      this.model = new AuctionClusterUser();
      this.model.isActive = true;
      this.model.sendAccountInfo = false;
      this.model.languageId = this.languages.find(_ => true).languageId;
      this.isEditMode = false;
      this.isOpened = true;
    }
    // setTimeout(() => { this.emailInput.nativeElement.focus(); });
  }

  getMatchingSupplier(): Supplier | undefined {
    if (!this.shouldShowSupplierApproval()) {
      return undefined;
    }

    return this.suppliers.find(supplier => supplier.vat === this.model.existingSupplierVat);
  }

  shouldShowSupplierApproval() {
    return this.model && this.model.existingSupplierVat !== '' && this.model.isPending;
  }

  shouldShowBuyerApproval() {
    return this.model && this.model.existingBuyerVat !== '' && this.model.isPending;
  }

  addToSupplierAccount(supplier: Supplier) {
    const alreadyAdded = this.model.supplierRoles.find(sr => sr.supplierId === supplier.supplierId);
    if (alreadyAdded) {
      return;
    }

    this.supplierRoleService.getSupplierRolesForAuctionClusterSupplier(this.auctionCluster.auctionClusterId,supplier.supplierId)
    .subscribe(result => {
      let roles = result;
      this.isSupplierUser = true;
      const supplierRole = new UserSupplierItem();
      supplierRole.supplierId = supplier.supplierId;
      this.supplierRoles = roles;
      this.filterSupplierItem(supplierRole, roles);
      supplierRole.supplierRoleId = supplierRole.filteredSupplierRoles.find(_ => true).supplierRoleId;
      this.model.supplierRoles.push(supplierRole);
    }, error => {
      this.errorService.show(error);
    });
    
    
  }

  getMatchingBuyer(): Buyer | undefined {
    if (!this.shouldShowBuyerApproval()) {
      return undefined;
    }

    return (this.buyers || []).find(buyer => buyer.vat === this.model.existingBuyerVat);
  }

  addToBuyerAccount(buyer: Buyer) {
    const alreadyAdded = this.model.buyerRoles.find(buyerRole => buyerRole.buyerId === buyer.buyerId);
    if (alreadyAdded) {
      return;
    }

    this.buyerRoleService.getBuyerRolesForAuctionClusterBuyer(this.auctionCluster.auctionClusterId,buyer.buyerId).subscribe(result => {
      let roles = result;
      this.isBuyerUser = true;
      const userBuyer = new UserBuyerItem();
      userBuyer.buyerId = buyer.buyerId;
      this.buyerRoles = roles;
      this.filterBuyerItem(userBuyer, roles);
      userBuyer.buyerRoleId = userBuyer.filteredBuyerRoles.find(_ => true).buyerRoleId;
    this.model.buyerRoles.push(userBuyer);
    }, error => {
      this.errorService.show(error);
    });

  }

  private clean() {
    this.emailVerified = false;
    this.initialEmail = null;
    this.isAuctionClusterUser = false;
    this.isBuyerUser = false;
    this.isSupplierUser = false;
  }

  // Parse 'auctionClusterRoles', 'buyerRoles' and 'supplierRoles' fields
  private parseChildObjects() {

    if (!this.model.auctionClusterRoles) {
      this.model.auctionClusterRoles = [];
    } else {
      this.model.auctionClusterRoles = this.model.auctionClusterRoles.map(source => {
        const item = new UserAuctionClusterItem();
        item.auctionClusterId = source.auctionClusterId;
        item.auctionId = source.auctionId;
        item.auctionClusterRoleId = source.auctionClusterRoleId;
        this.filterAuctionClusterItem(item);
        return item;
      });
    }

    this.isAuctionClusterUser = this.model.auctionClusterRoles.length !== 0;

    if (!this.model.userAuctionClusterReportingRoles) {
      this.model.userAuctionClusterReportingRoles = [];
    } else {
      this.model.userAuctionClusterReportingRoles = this.model.userAuctionClusterReportingRoles.map(source => {
        const item = new UserReportingItem();
        item.auctionClusterReportingRoleId = source.auctionClusterReportingRoleId;
        item.auctionClusterId = source.auctionClusterId;
        item.auctionClusterReportingRoleId = source.auctionClusterReportingRoleId;
        this.filterReportingItem(item);
        return item;
      });
    }

    if (!this.model.buyerRoles) {
      this.model.buyerRoles = [];
    } else {
      this.model.buyerRoles = this.model.buyerRoles.map(source => {
        const item = new UserBuyerItem();
        item.buyerId = source.buyerId;
        item.buyerRoleId = source.buyerRoleId;
        this.filterBuyerItem(item, source.availableBuyerRoles);
        return item;
      });
    }

    this.isBuyerUser = this.model.buyerRoles.length !== 0;

    if (!this.model.supplierRoles) {
      this.model.supplierRoles = [];
    } else {
      this.model.supplierRoles = this.model.supplierRoles.map(source => {
        const item = new UserSupplierItem();
        item.supplierId = source.supplierId;
        item.supplierRoleId = source.supplierRoleId;
        this.filterSupplierItem(item, source.availableSupplierRoles);
        return item;
      });
    }

    this.isSupplierUser = this.model.supplierRoles.length !== 0;
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  onSubmitAccountInfo() {
    this.model.sendAccountInfo = true;
    this.save();
  }

  save() {
    
    if (this.auctionClusterTable !== null) {
      this.auctionClusterTable.instance().closeEditCell();
      this.auctionClusterTable.instance().saveEditData();
    }

    if (this.buyerTable !== null) {
      this.buyerTable.instance().closeEditCell();
      this.buyerTable.instance().saveEditData();
    }

    if (this.supplierTable !== null) {
      this.supplierTable.instance().closeEditCell();
      this.supplierTable.instance().saveEditData();
    }

    if (this.reportingRolesTable !== null) {
      this.reportingRolesTable.instance().closeEditCell();
      this.reportingRolesTable.instance().saveEditData();
    }

    // make sure the data is cleared before saving it to the server
    if (this.model.userAuctionClusterReportingRoles) {
      this.model.userAuctionClusterReportingRoles = this.model.userAuctionClusterReportingRoles.filter(
        role => role.auctionClusterId && role.auctionClusterReportingRoleId);
    }

    if (!this.isAuctionClusterUser) {
      this.model.auctionClusterRoles = [];
    } else {
      this.model.auctionClusterRoles = this.model.auctionClusterRoles.filter(
        role => (role.auctionId == null || role.auctionId) && role.auctionClusterRoleId);
    }
    if (!this.isBuyerUser) {
      this.model.buyerRoles = [];
    } else {
      if (this.model.buyerRoles.find(role => !role.buyerId || !role.buyerRoleId)) {
        this.errorService.show(this.errorService.translations.NO_ASSIGNED_BUYER_ROLES);
        return;
      }
      else {
        this.model.buyerRoles = this.model.buyerRoles.filter(role => role.buyerId && role.buyerRoleId);
        // Only 1 buyer to a user is allowed
        if (this.model.buyerRoles.length > 1) {
          this.errorService.show(this.errorService.translations.ONLY_ONE_BUYER_ALLOWED);
          return;
        }
      }
    }
    if (!this.isSupplierUser) {
      this.model.supplierRoles = [];
    } else {
      this.model.supplierRoles = this.model.supplierRoles.filter(role => role.supplierId && role.supplierRoleId);
    }

    if (!this.check()) {
      return;
    }

    if (this.model.auctionClusterRoles.length === 0 && this.model.buyerRoles.length === 0 && this.model.supplierRoles.length === 0) { // tslint:disable-line:max-line-length
      this.errorService.show(this.errorService.translations.AT_LEAST_ONE_LEVEL_NEEDS_TO_BE_SELECTED);
      return;
    }

    this.model.auctionClusterRoles.forEach(item => {
      if (item.catalogFilterData == null) {
        item.catalogFilterData = '-';
      }
      if (item.clockFilterData == null) {
        item.clockFilterData = '-';
      }
      if (item.auctionFilterData == null) {
        item.auctionFilterData = '-';
      }
    });
    
    this.model.domain = document.location.protocol + '//' + document.location.host;

    if (this.isEditMode) {

      this.spinner.show();
      this.dataService.edit(+this.route.snapshot.params['id'], this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterUser();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        async error => {
          if (error.error && error.error.length > 0 && error.error[0].ErrorMessage === 'ALL_BUYER_LICENCES_USED') {
            await this.handleAllBuyerLicencesUsed(error.error[0].ProductPropertyId);
          }
          else if (error.error && error.error.length > 0 && error.error[0].ErrorMessage === 'ALL_AUCTIONCLUSTER_LICENCES_USED') {
            this.errorMessage = null;
            this.spinner.hide();
            this.close(true);

            this.confirmationAuctionClusterLicenses.opened = true;
          } else {
            this.errorService.show(error);      
            this.spinner.hide();
          }
        });
    } else {
      this.spinner.show();
      this.model.password = "Password01";
      this.model.confirmPassword = "Password01"

      this.dataService.save(+this.route.snapshot.params['id'], this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterUser();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          async error => {
            if (error.error && error.error.length > 0 && error.error[0].ErrorMessage === 'ALL_BUYER_LICENCES_USED') {
              await this.handleAllBuyerLicencesUsed(error.error[0].ProductPropertyId);
            }
            else if (error.error && error.error.length > 0 && error.error[0].ErrorMessage === 'ALL_AUCTIONCLUSTER_LICENCES_USED') {
              this.errorMessage = null;
              this.spinner.hide();
              this.close(true);

              this.confirmationAuctionClusterLicenses.opened = true;
            } else {
              this.errorService.show(error);
              this.spinner.hide();
            }
          });
    }
  }
  async handleAllBuyerLicencesUsed(buyerId: number) {
    var buyerInfo: AuctionClusterBuyer = await firstValueFrom(this.buyerService.getBuyer(this.auctionCluster.auctionClusterId, buyerId));
    if (buyerInfo != null) {
      // offer to increase licensecount
      this.licenseConfirmation.title = this.errorService.translations.ALL_BUYER_LICENCES_USED;
      this.licenseConfirmation.message = this.translate.instant('USER.BUYER_ADD_LICENCE', { currentCount: buyerInfo.userLicenseCount, newCount: buyerInfo.userLicenseCount + 1 })
      this.licenseConfirmation.close.subscribe(() => {
        this.incrementLicenceCount(buyerInfo);
        this.save();
      });
      this.licenseConfirmation.opened = true;
    }
    else {
      // can't determine buyer for some reason, show generic message
      this.errorService.show(this.errorService.translations.ALL_BUYER_LICENCES_USED);
    }
    this.spinner.hide();
  }

  addAuctionCluster() {
    const item = new UserAuctionClusterItem();
    item.auctionClusterId = +this.route.snapshot.params['id'];
    this.filterAuctionClusterItem(item);
    this.model.auctionClusterRoles.push(item);
  }

  filterAuctionClusterItem(item: UserAuctionClusterItem) {
    item.filteredAuctions = this.auctions.filter(f => f.auctionClusterId === item.auctionClusterId || f.auctionClusterId === null);
    this.filterAuctionClusterRole(item);
  }

  filterAuctionClusterRole(item: UserAuctionClusterItem) {
    item.filteredAuctionClusterRoles = this.auctionClusterRoles.filter(f => f.auctionClusterId === item.auctionClusterId
      && (!f.isAuctionClusterTypeRole && !item.auctionId !== null || f.isAuctionClusterTypeRole && item.auctionId === null
        || !f.isAuctionClusterTypeRole && item.auctionId === null));

    const role = item.filteredAuctionClusterRoles.find(r => r.auctionClusterRoleId === item.auctionClusterRoleId);
    if (!role) {
      if (item.filteredAuctionClusterRoles.length > 0) {
        if (!item.auctionClusterRoleId) {
          item.auctionClusterRoleId = item.filteredAuctionClusterRoles[0].auctionClusterRoleId;
        }
      } else {
        item.auctionClusterRoleId = null;
      }
    }
  }

  deleteAuctionCluster(index: number) {
    this.model.auctionClusterRoles.splice(index, 1);
  }


  addReportingRole() {
    const item = new UserReportingItem();
    item.auctionClusterId = +this.route.snapshot.params['id'];
    item.userAuctionClusterReportingRoleId = 0;
    this.filterReportingItem(item);
    this.model.userAuctionClusterReportingRoles.push(item);
  }

  deleteReportingRole(index: number) {
    this.model.userAuctionClusterReportingRoles.splice(index, 1);
  }

  addBuyer() {
    this.model.buyerRoles.push(new UserBuyerItem());
  }

  filterReportingItem(item: UserReportingItem) {
    item.filteredReportingRoles = this.auctionClusterReportingRoles;
    if (!item.auctionClusterReportingRoleId || !item.filteredReportingRoles.find(r => r.auctionClusterReportingRoleId === item.auctionClusterReportingRoleId)) {
      item.auctionClusterReportingRoleId = item.filteredReportingRoles.length > 0 ? item.filteredReportingRoles[0].auctionClusterReportingRoleId : null;
    }
  }

  filterBuyerItem(item: UserBuyerItem, buyerRoles:any) {
    item.filteredBuyerRoles = buyerRoles.filter(f => f.buyerId === item.buyerId);
    if (!item.buyerRoleId || !item.filteredBuyerRoles.find(r => r.buyerRoleId === item.buyerRoleId)) {
      item.buyerRoleId = item.filteredBuyerRoles.length > 0 ? item.filteredBuyerRoles[0].buyerRoleId : null;
    }
  }

  deleteBuyer(index: number) {
    this.model.buyerRoles.splice(index, 1);
  }

  addSupplier() {
    this.model.supplierRoles.push(new UserSupplierItem());
  }

  filterSupplierItem(item: UserSupplierItem, supplierRoles: any) {
    item.filteredSupplierRoles = supplierRoles.filter(f => f.supplierId === item.supplierId);
    if (!item.supplierRoleId || !item.filteredSupplierRoles.find(r => r.supplierRoleId === item.supplierRoleId)) {
      item.supplierRoleId = item.filteredSupplierRoles.length > 0 ? item.filteredSupplierRoles[0].supplierRoleId : null;
    }
  }

  deleteSupplier(index: number) {
    this.model.supplierRoles.splice(index, 1);
  }

  editUserNotification = (e: any) => {
    const userNotification = e.row !== undefined ? e.row.data : e.data;

    if(e.column.dataType === "boolean") {
      this.enableDisableNotification(userNotification);
    } else { 
      this.notificationChannelsComponent.open(userNotification, this.languages);
    }
  }

  getNotificationsGridData() {
    this.notificationManagerService.getUserNotifications(this.model.userId).subscribe(result => {
      this.notifications = new Array<UserNotification>();
      result.forEach(element => {
        if(element.auctionClusterNotification.auctionClusterId == this.auctionCluster.auctionClusterId) {
          element.auctionClusterNotification.nameText = this.languageService.getTranslatableText(element.auctionClusterNotification.name);
          this.notifications.push(element);
        }
      });
    }, error => {
      this.errorService.show(error);
    });
  }

  enableDisableNotification = (userNotification: UserNotification) => {    
    this.spinner.show();
    this.notificationManagerService.enableDisableUserNotification(userNotification, !userNotification.isActive).subscribe(() => {
      this.getNotificationsGridData();
    }, error => {
      this.errorService.show(error);
    });
    this.spinner.hide();
  }

  private check(): boolean {

    // create user list without current user
    const userList = this.allItems.filter((u) => {
      return u.userId !== this.model.userId;
    });

    const userNameNotUniqueList = userList.filter((u) => {
      return u.email === this.model.email;
    });

    if (userNameNotUniqueList.length > 0) {
      this.errorService.show(this.errorService.translations.EMAIL_NOT_UNIQUE);
      return false;
    }

    if (this.isAuctionClusterUser && this.model.auctionClusterRoles.length === 0) {
      this.errorService.show(this.errorService.translations.NO_ASSIGNED_AUCTION_ROLES);
      return false;
    }

    if (this.isBuyerUser && this.model.buyerRoles.length === 0) {
      this.errorService.show(this.errorService.translations.NO_ASSIGNED_BUYER_ROLES);
      return false;
    }

    if (this.isSupplierUser && this.model.supplierRoles.length === 0) {
      this.errorService.show(this.errorService.translations.NO_ASSIGNED_SUPPLIER_ROLES);
      return false;
    }

    if (!this.checkAuctionClusterRows(this.model.auctionClusterRoles)) {
      return false;
    }

    if (!this.checkIfAuctionNull(this.model.auctionClusterRoles)) {
      return false;
    }

    if (!this.checkAuctionClusters(this.model.auctionClusterRoles)) {
      return false;
    }

    if (!this.checkBuyers(this.model.buyerRoles)) {
      return false;
    }

    if (!this.checkSuppliers(this.model.supplierRoles)) {
      return false;
    }

    return true;
  }

  private async incrementLicenceCount(buyerInfo: AuctionClusterBuyer) {
    try {
      this.spinner.show();
      buyerInfo.userLicenseCount++;
      buyerInfo = await firstValueFrom(this.buyerService.edit(this.auctionCluster.auctionClusterId, buyerInfo));
    }
    catch (ex) {
      if (ex.error && ex.error.length > 0 && ex.error[0].ErrorMessage === 'BUYER_LICENCE_TOOMANYUSERS') {
        // license count still too low after incrementing. Just rethrow and other handler will show proper dialog
      }
      else {
        this.errorService.show(this.errorService.translations.GENERIC_ERROR);
      }
      throw ex;
    }
    finally {
      this.spinner.hide();
    }
  }

  checkBuyers(buyerRoles: Array<UserBuyerRole>): boolean {

    let hasDuplicate = false;
    for (let i = 0; i < buyerRoles.length - 1; i++) {
      if (buyerRoles[i].buyerId === buyerRoles[i + 1].buyerId) {
        this.errorService.show(this.errorService.translations.BUYER_HAS_ONE_ROLE);
        hasDuplicate = true;
      }
    }
    return !hasDuplicate;
  }

  checkSuppliers(supplierRoles: Array<UserSupplierRole>): boolean {

    let hasDuplicate = false;
    for (let i = 0; i < supplierRoles.length - 1; i++) {
      if (supplierRoles[i].supplierId === supplierRoles[i + 1].supplierId) {
        this.errorService.show(this.errorService.translations.SUPPLIER_HAS_ONE_ROLE);
        hasDuplicate = true;
      }
    }
    return !hasDuplicate;
  }

  checkAuctionClusterRows(auctionClusterRoles: Array<UserAuctionClusterRole>): boolean {
    let rowsValid = false;
    auctionClusterRoles.forEach(row => {
      if (!row.auctionClusterId) {
        row.auctionClusterId = +this.route.snapshot.params['id'];
      }
      if (!row.auctionClusterId || !row.auctionClusterRoleId || (!row.auctionId && row.auctionId !== null)) {
        this.errorService.show(this.errorService.translations.CANNOT_SAVE_ROW_WITH_EMPTY_FIELDS);
        rowsValid = true;
      }
    });
    return !rowsValid;
  }

  checkAuctionClusters(auctionClusters: Array<UserAuctionClusterRole>): boolean {

    let isValid = false;

    const testArray = auctionClusters.map((element, index, self) => {
      const i = auctionClusters.indexOf(element);
      return index !== i ? element : null;
    }).filter(f => f !== null);

    if (testArray.length !== 0) {
      isValid = true;
      this.errorService.show(this.errorService.translations.TWO_IDENTICAL_ROWS);
    }

    return !isValid;
  }

  checkIfAuctionNull(auctionClusterRoles: Array<UserAuctionClusterRole>): boolean {

    let hasNull = false;

    for (let i = 0; i < auctionClusterRoles.length; i++) {
      if (auctionClusterRoles[i].auctionId === null) {
        const findElementId = auctionClusterRoles[i].auctionClusterId;
        let count = 0;
        auctionClusterRoles.forEach(item => {
          if (item.auctionClusterId === findElementId) {
            count++;
            if (count > 1) {
              this.errorService.show(this.errorService.translations.ALL_AUCTIONS_INCLUDES_EVERY_ROLE);
              hasNull = true;
            }
          }
        });
      }
    }
    return !hasNull;
  }

  verifyEmail() {
    if (this.model) {
      this.dataService.verifyEmail(+this.route.snapshot.params['id'], this.model.email).subscribe((user: AuctionClusterUser) => {

        if (this.isEditMode) {
          if (user && user.userId !== this.model.userId) {
            // not allowed
            this.errorService.show(this.errorService.translations.EMAIL_EXISTS);
            this.model.email = this.initialEmail; // roll back stored email address
          }
        } else {
          if (user) {
            // edit another user
            this.isEditMode = true;
            this.modalTitle = this.translations.EDIT;
            this.model = user;
            this.parseChildObjects();
          }
        }

        this.emailVerified = true;
      });
    }
  }

  sendUserLicenseExceededMail() {

    this.mailService.sendUserLicenseExceededMail(+this.route.snapshot.params['id'])
      .subscribe((res: any) => {
        this.model.overruleUserLicenses = true;

        if (this.isEditMode) {
          this.spinner.show();
          this.dataService.edit(+this.route.snapshot.params['id'], this.model)
            .subscribe((res: any) => {
              this.model = new AuctionClusterUser();
              // this.detailsForm.reset();
              this.close(true);
              this.errorMessage = null;
              this.spinner.hide();
            },
              error => {
                this.errorService.show(error);
                this.spinner.hide();
              });
        } else {
          this.spinner.show();
          this.model.password = "Password01";
          this.model.confirmPassword = "Password01"

          this.dataService.save(+this.route.snapshot.params['id'], this.model)
            .subscribe((res: any) => {
              this.model = new AuctionClusterUser();
              // this.detailsForm.reset();
              this.close(true);
              this.errorMessage = null;
              this.spinner.hide();
            },
              error => {
                this.errorService.show(error);
                this.spinner.hide();
              });
        }
      });    
  }

  @HostListener('window:keydown', ['$event'])
  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE) {
      event.target.blur();
      if (this.formDirty) {
        this.saveConfirmation.opened = true;
      } else {
        this.onCancel();
      }
    }
  }

  onFieldDataChanged(e: any) {    
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {      
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnUserSubmitAccountInfo')[0].setAttribute('disabled', 'disabled');
        document.getElementsByName('btnUserSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnUserSubmitAccountInfo')[0].removeAttribute('disabled');
        document.getElementsByName('btnUserSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnUserSubmitAccountInfo')[0].removeAttribute('disabled');
        document.getElementsByName('btnUserSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnUserSubmitAccountInfo')[0].setAttribute('disabled', 'disabled');
        document.getElementsByName('btnUserSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }

    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      if (!this.formDirty) this.formDirty = true;
    }   
  }

  onInitNewRow = (e: any) => {
    if (this.model.buyerRoles.length > 0) {
      window.setTimeout(function () { e.component.cancelEditData(); }, 0);
    }
  }

  onBuyerRowUpdating = (e: any) => {
    for (var i = 0; i < this.model.buyerRoles.length; i++) {
      if (this.model.buyerRoles[i].buyerId == e.oldData.buyerId) {
        if (e.newData.buyerId) {
          this.model.buyerRoles[i].buyerRoleId = null;
          this.model.buyerRoles[i].buyerId = e.newData.buyerId;
        }
      }
    }
  }

  onSupplierRowUpdating = (e: any) => {
    for (var i = 0; i < this.model.supplierRoles.length; i++) {
      if (this.model.supplierRoles[i].supplierId == e.oldData.supplierId) {
        if (e.newData.supplierId) {
          this.model.supplierRoles[i].supplierRoleId = null;
          this.model.supplierRoles[i].supplierId = e.newData.supplierId;
        }
      }
    }
  }

  onEditorPreparing = (e: any) => {
    if (
      e.dataField === 'auctionClusterReportingRoleId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          return new Promise((resolve, reject) => {
            e.component
              .getDataSource()
              .store()
              .load()
              .done(gridData => {
                resolve(this.auctionClusterReportingRoles.filter(f => f.auctionClusterId === +this.route.snapshot.params['id']));
              });
          });
        }
      };
    }

    if (
      e.dataField === 'buyerRoleId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          const observables = [];
          observables.push(this.buyerRoleService
            .getBuyerRolesForAuctionClusterBuyer(this.auctionCluster.auctionClusterId,e.row.data.buyerId));
          return new Promise((resolve, reject) => {

            forkJoin(observables).subscribe(result => {
              let roles = result[0];
              resolve(roles);
            }, error => {
              reject(error);
            });
            
          });
        }
      };
    }

    if (
      e.dataField === 'supplierRoleId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          const observables = [];
          observables.push(this.supplierRoleService
            .getSupplierRolesForAuctionClusterSupplier(this.auctionCluster.auctionClusterId,e.row.data.supplierId));
          return new Promise((resolve, reject) => {

            forkJoin(observables).subscribe(result => {
              let roles = result[0];
              resolve(roles);
            }, error => {
              reject(error);
            });
            
          });
        }
      };
    }

    if (
      e.dataField === 'auctionId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          return new Promise((resolve, reject) => {
            e.component
              .getDataSource()
              .store()
              .load()
              .done(gridData => {
                resolve(this.auctions.filter(f => f.auctionClusterId === +this.route.snapshot.params['id'] || f.auctionClusterId === null));
              });
          });
        }
      };
    }

    if (
      e.dataField === 'auctionClusterRoleId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          return new Promise((resolve, reject) => {
            e.component
              .getDataSource()
              .store()
              .load()
              .done(gridData => {
                resolve(this.auctionClusterRoles
                  .filter(f => f.auctionClusterId === +this.route.snapshot.params['id'] &&
                    (!f.isAuctionClusterTypeRole && !e.row.data.auctionId !== null ||
                      f.isAuctionClusterTypeRole && e.row.data.auctionId === null ||
                      !f.isAuctionClusterTypeRole && e.row.data.auctionId === null)));
              });
          });
        }
      };
    }
  }

  initSupplier = (e: any) => {
    this.supplierTable = e.component;
  }

  initBuyer = (e: any) => {
    this.buyerTable = e.component;
  }

  initAuctionCluster = (e: any) => {
    this.auctionClusterTable = e.component;
  }

  initReportingRoles = (e: any) => {
    this.reportingRolesTable = e.component;
  }

  //setBuyerRoleValue(rowData: any, value: any): void {
  //  //rowData.BuyerRoleId = null;
  //  //(<any>this).defaultSetCellValue(rowData, value);
  //}
}
