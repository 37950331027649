import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrTabs } from '@clr/angular';
import { Subscription } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// services
import { LanguageService } from '../../shared/services/language.service';

import { ShoppingListsService } from '../shared/services/shopping-lists.service';

// models
import { Catalog } from '../../shared/models/catalog';
import { Language } from '../../shared/models/language';

import { ShoppingList } from '../shared/models/shopping-list';


@Component({
  selector: 'shopping-list-component',
  templateUrl: 'shopping-list.component.html',
  styleUrls: ['./shopping-list.component.scss']
})
export class ShoppingListComponent extends ItemDetailsComponent<ShoppingList> implements OnInit, OnDestroy {

  @ViewChild('tabs') tabs: ClrTabs;

  buyerId: number;
  languages: Array<Language> = [];
  catalogs: Array<Catalog> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: ShoppingListsService,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new ShoppingList();
    this.buyerId = +this.route.snapshot.params['id'];
    this.setTranslations('SHOPPING_LIST');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(shoppingLists: Array<ShoppingList>, shoppingListId: number, languages: Array<Language>, catalogs: Array<Catalog>) {
    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    this.allItems = shoppingLists;
    this.languages = languages;

    this.catalogs = catalogs;

    if (shoppingListId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getShoppingList(this.buyerId, shoppingListId)
        .subscribe((res: ShoppingList) => {
          this.model = res;
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new ShoppingList();
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  save() {
    if (this.model.shoppingListId) {
      this.isEditMode = true;
    }
    if (!this.model.description) {
      this.model.description = '';
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.buyerId, this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.model = new ShoppingList();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          err => {
            this.errorService.show(err);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.save(this.buyerId, this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.model = new ShoppingList();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();

          // Open shopping list for edit automatically
          this.router.navigate(['lots/' + res.shoppingListId], { relativeTo: this.route });
        },
          err => {
            this.errorService.show(err);
            this.spinner.hide();
          }
        );
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnShoppingListSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnShoppingListSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnShoppingListSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnShoppingListSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }
}
