import { Component, Output, OnInit, Injector, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { ProductBuyButton, ProductBuyButtonTypes } from '../../shared/models/product';

@Component({
  selector: 'product-buy-button-component',
  templateUrl: 'product-buy-button.component.html',
  styleUrls: ['./product-buy-button.component.scss']
})

export class ProductBuyButtonComponent extends ItemDetailsComponent<ProductBuyButton> implements OnInit {

  productId: number;
  productBuyButtonsTypes: Array<any> = [];

  @Output() onAdded = new EventEmitter<ProductBuyButton>();
  @Output() onUpdated = new EventEmitter<ProductBuyButton>();

  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
  ) {
    super(injector);
    this.productId = +this.route.snapshot.params['productId'];
  }

  ngOnInit() {
    this.model = new ProductBuyButton();
    this.setTranslations('PRODUCT.BUY_BUTTON');
  }

  open(properties: Array<ProductBuyButton>, buyButton: ProductBuyButton) {
    this.productBuyButtonsTypes = (Object.keys(ProductBuyButtonTypes).filter((el) => { return isNaN(Number(el)) })).map((key) => { return { key: key, value: ProductBuyButtonTypes[key] } });
    this.allItems = properties;
    this.model = buyButton;

    // Allowed only one button of type 'Buy', 'All', 'Rest'
    let usedTypes = this.allItems.filter(_ => _.type !== 3);
    usedTypes.forEach(element => {
      this.productBuyButtonsTypes = this.productBuyButtonsTypes.filter(_ => _.value !== element.type);
    });

    this.isOpened = true;

    if (buyButton.productBuyButtonId !== null && buyButton.productBuyButtonId !== undefined) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }
  }

  save(): void {
    if (this.model.type !== 3) {
      this.model.value = null;
    }
    if (this.model.type === 3 && this.model.value <= 0) {
      this.errorService.show(this.errorService.translations.INVALID_VALUE_ERROR_MESSAGE);
      return;
    }

    let duplicateAmountBuyButton = this.allItems.find(_ => _.type === 3 && _.value === this.model.value);
    if (duplicateAmountBuyButton) {
      this.errorService.show(this.errorService.translations.DUPLICATE_VALUE_ERROR_MESSAGE);
      return;
    }
    this.model.productId = this.productId;
    this.model.typeDisplayName = ProductBuyButtonTypes[this.model.type];

    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new ProductBuyButton();
    this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  public onCancel() {
    this.isOpened = false;
    this.errorMessage = null;
    this.model.type = null;
    this.model.value = null;
  }
}
