<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="card">
    <div>
      <div class="filter-column">
        <label for="catalogs">{{ 'IMPORT_AUCTIONCLUSTER.SELECT_FILE' | translate }}</label>
        <input type="file" (change)="fileChangeEvent($event)" onclick="this.value=null;" />
      </div>
    </div>

    <div>
      <div class="filter-column">
        <dx-check-box name="includeAuctionCluster" [(value)]="auctionclusterImport.includeAuctionCluster" text="{{ 'IMPORT_AUCTIONCLUSTER.INCLUDE_AUCTIONCLUSTER' | translate }}"></dx-check-box>
      </div>
      <div class="filter-column">
        <dx-check-box name="includeMasterDataList" [(value)]="auctionclusterImport.includeMasterDataList" text="{{ 'IMPORT_AUCTIONCLUSTER.INCLUDE_MASTERDATALIST' | translate }}"></dx-check-box>
      </div>
      <div class="filter-column">
        <dx-check-box name="includeLookupTable" [(value)]="auctionclusterImport.includeLookupTable" text="{{ 'IMPORT_AUCTIONCLUSTER.INCLUDE_LOOKUPTABLE' | translate }}"></dx-check-box>
      </div>
      <div class="filter-column">
        <dx-check-box name="includeProduct" [(value)]="auctionclusterImport.includeProduct" text="{{ 'IMPORT_AUCTIONCLUSTER.INCLUDE_PRODUCT' | translate }}"></dx-check-box>
      </div>
      <div class="filter-column">
        <dx-check-box name="includeAuction" [(value)]="auctionclusterImport.includeAuction" text="{{ 'IMPORT_AUCTIONCLUSTER.INCLUDE_AUCTION' | translate }}"></dx-check-box>
      </div>
      <div class="filter-column">
        <dx-check-box name="includeCatalog" [(value)]="auctionclusterImport.includeCatalog" text="{{ 'IMPORT_AUCTIONCLUSTER.INCLUDE_CATALOG' | translate }}"></dx-check-box>
      </div>
      <div class="filter-column">
        <dx-check-box name="includeClock" [(value)]="auctionclusterImport.includeClock" text="{{ 'IMPORT_AUCTIONCLUSTER.INCLUDE_CLOCK' | translate }}"></dx-check-box>
      </div>
      <div class="filter-column">
        <dx-check-box name="includeMask" [(value)]="auctionclusterImport.includeMask" text="{{ 'IMPORT_AUCTIONCLUSTER.INCLUDE_MASK' | translate }}"></dx-check-box>
      </div>
      <div class="filter-column">
        <dx-check-box name="includeCalculations" [(value)]="auctionclusterImport.includeCalculation" text="{{ 'IMPORT_AUCTIONCLUSTER.INCLUDE_CALCULATION' | translate }}"></dx-check-box>
      </div>
      <div class="filter-column">
        <dx-check-box name="includeReport" [(value)]="auctionclusterImport.includeReport" text="{{ 'IMPORT_AUCTIONCLUSTER.INCLUDE_REPORT' | translate }}"></dx-check-box>
      </div>
    </div>

    <div>
      <div class="filter-column">
        <div class="filter-button">
          <button type="button" class="btn btn-primary" (click)="handleCompare()">{{ 'IMPORT_AUCTIONCLUSTER.COMPARE' | translate }}</button>
        </div>
      </div>

      <div class="filter-column">
        <div class="filter-button">
          <button type="button" class="btn btn-primary" (click)="handleImport()">{{ 'IMPORT_AUCTIONCLUSTER.IMPORT' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
  <br />
  <dx-data-grid #importClusterGrid
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [dataSource]="items"
                [showBorders]="true"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-pager [visible]="true"
               [showPageSizeSelector]="true"
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true"
               [showInfo]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="propertyName" caption="{{ 'IMPORT_AUCTIONCLUSTER.PROPERTY_NAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="object2TypeName" caption="{{ 'IMPORT_AUCTIONCLUSTER.ACTUAL_TYPE' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" [calculateCellValue]="getSysName2" caption="{{ 'IMPORT_AUCTIONCLUSTER.ACTUAL_SYSNAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="object2Value" caption="{{ 'IMPORT_AUCTIONCLUSTER.ACTUAL_VALUE' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" [calculateCellValue]="getSysName1" caption="{{ 'IMPORT_AUCTIONCLUSTER.IMPORT_SYSNAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="object1Value" caption="{{ 'IMPORT_AUCTIONCLUSTER.IMPORT_VALUE' | translate }}">
    </dxi-column>
  </dx-data-grid>
</div>
