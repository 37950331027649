import { OnInit, Injector, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// services
import { SpinnerService } from '../../services/spinner.service';

@Injectable()
export abstract class SimpleListComponent implements OnInit {

  protected spinner: SpinnerService;
  protected translate: TranslateService;
  translations: any; // make readonly
  pageSize: number;
  pageSizeValue = 10;

  constructor(injector: Injector) {
    this.spinner = injector.get(SpinnerService);
    this.translate = injector.get(TranslateService);
    this.pageSize = this.pageSizeValue;
  }

  abstract ngOnInit(): void;

  setPageSize(event: any) {
    this.pageSizeValue = event;
    this.pageSize = event;
  }

  setTranslations(key: string) {
    this.translate.get(key).subscribe((res: string) => {
      this.translations = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get(key).subscribe((res: string) => {
        this.translations = res;
      });
    });
  }

  moveUpItems(event: Event, items: any) {
    event.stopPropagation();
    var selectedRows = items.filter(_ => _.selected);
    selectedRows.forEach((row) => {
      const index = items.indexOf(row);
      if (index - 1 < 0) {
        return;
      }
      const moveProperty = items[index];
      const orderNumber = moveProperty.orderNumber;
      const bufferProperty = items[index - 1];
      moveProperty.orderNumber = bufferProperty.orderNumber;
      bufferProperty.orderNumber = orderNumber;
      items[index - 1] = moveProperty;
      items[index] = bufferProperty;
    });
  }

  moveDownItems(event: Event, items: any) {
    event.stopPropagation();
    var selectedRows = items.filter(_ => _.selected);
    selectedRows.sort((a, b) => (a.orderNumber < b.orderNumber) ? 1 : -1);
    selectedRows.forEach((row) => {
      const index = items.indexOf(row);
      if (index + 1 >= items.length) {
        return;
      }
      const moveProperty = items[index];
      const orderNumber = moveProperty.orderNumber;
      const bufferProperty = items[index + 1];
      moveProperty.orderNumber = bufferProperty.orderNumber;
      bufferProperty.orderNumber = orderNumber;
      items[index + 1] = moveProperty;
      items[index] = bufferProperty;
    });
  }
}
