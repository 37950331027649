import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ShoppingListComponent } from './shopping-list.component';

// services
import { ServiceFactory } from '../../shared/factory/service-factory';
import { LanguageService } from '../../shared/services/language.service';
import { WebApiService } from '../../shared/services/web-api.service';

import { CatalogService } from '../shared/services/catalog.service';
import { ShoppingListsService } from '../shared/services/shopping-lists.service';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { Catalog } from '../../shared/models/catalog';
import { Language } from '../../shared/models/language';

import { ShoppingList } from '../shared/models/shopping-list';


@Component({
  selector: 'shopping-lists-component',
  templateUrl: 'shopping-lists.component.html',
  styleUrls: ['./shopping-lists.component.scss']
})
export class ShoppingListsComponent extends FullListComponent<ShoppingList, ShoppingListComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ShoppingListComponent;

  catalogService: CatalogService;

  languages: Array<Language> = [];
  catalogs: Array<Catalog> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private languageService: LanguageService,
    private dataService: ShoppingListsService
  ) {
    super(injector, ShoppingList);
    this.getServices(route, appSettings, webApiService);

    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('SHOPPING_LIST.TITLE');
    this.setTranslations('SHOPPING_LIST');

    // initialize shopping list service
    this.dataService.init(this.id);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.dataService.stop();
    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.catalogService = ServiceFactory.getCatalogService(route, appSettings, webApiService) as CatalogService;
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getShoppingLists(this.id),
      this.languageService.getLanguages(),
      this.catalogService.getAllSupplyCatalogs(),
    ).subscribe(result => {
      this.items = result[0];

      this.languages = result[1];
      this.catalogs = result[2];

      this.items.forEach(_ => {
        var catalog = this.catalogs.find(c => c.catalogId === _.shoppingListReferenceCatalogId);
        if (catalog) {
          _.catalogName = catalog.name;
        }
      });

      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  edit = (e: any) => {
    const shoppingListId = e.row !== undefined ? e.row.data.shoppingListId : e.data.shoppingListId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, shoppingListId , this.languages, this.catalogs);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.languages, this.catalogs);
  }

  openShoppingListEditor = (e: any) => {
    const shoppingListId = e.row !== undefined ? e.row.data.shoppingListId : e.data.shoppingListId;
    this.router.navigate(['/buyer/shoppinglists/' + this.id + '/lots/' + shoppingListId]);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete, this.id)
      .subscribe((prebids: Array<ShoppingList>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.shoppingListId;
    this.confirmation.opened = true;
  }
}
