import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

import {
  DxColorBoxModule,
  DxDataGridModule,
  DxTemplateModule,
  DxBulletModule,
  DxButtonModule,
  DxSelectBoxModule,
  DxCheckBoxModule,
  DxTabsModule,
  DxTabPanelModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxFormModule,
  DxValidationGroupModule,
  DxPopupModule,
  DxToastModule,
  DxScrollViewModule,
  DxTagBoxModule,
  DxListModule,
  DxBoxModule,
  DxFileUploaderModule
} from 'devextreme-angular';

import { PlatformRoutingModule } from './platform-routes.module';
import { ImageCropperModule } from 'ngx-image-cropper';

// module components
import { PlatformContentComponent } from './content/platform-content.component';
import { PlatformHomeComponent } from './home/platform-home.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user.component';
import { RolesComponent } from './roles/roles.component';
import { RoleComponent } from './roles/role.component';
import { AuctionsClusterComponent } from './auction-cluster/auction-clusters.component';
import { AuctionClusterComponent } from './auction-cluster/auction-cluster.component';
import { AuctionClusterChannelComponent } from './auction-cluster/auction-cluster-channel/auction-cluster-channel.component';
import { AuctionClusterNotificationComponent } from './auction-cluster/auction-cluster-notification/auction-cluster-notification.component';
import { BuyersComponent } from './buyers/buyers.component';
import { BuyerComponent } from './buyers/buyer.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { SupplierComponent } from './suppliers/supplier.component';
import { AdditionalMenuItemsComponent } from './additional-menu-items/additional-menu-items.component';
import { AdditionalMenuItemComponent } from './additional-menu-items/additional-menu-item.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportComponent } from './reports/report.component';
import { ReportEditorComponent } from './reports/report-editor.component';
import { ImportAuctionClusterComponent } from './import-auctioncluster/import-auctioncluster.component';
import { ExternalPlatformsComponent } from './external-platform/external-platforms.component';
import { ExternalPlatformComponent } from './external-platform/external-platform.component';
import { ProductPropertyStylingsComponent } from './product-property-styling/product-property-stylings.component';
import { ProductPropertyStylingComponent } from './product-property-styling/product-property-styling.component';
import { ConfigurationParameterComponent } from './configuration-parameter/configuration-parameter.component';

// module services
import { AdditionalMenuItemsService } from './shared/services/additional-menu-items.service';
import { PlatformPermissionService } from './shared/services/platform-permission.service';
import { PlatformRoleService } from './shared/services/platform-role.service';
import { UserService } from './shared/services/user.service';
import { AuctionClusterService } from './shared/services/auction-cluster.service';
import { PlatformReportService } from './shared/services/platform-report.service';
import { ExternalPlatformService } from './shared/services/external-platform.service';
import { MFAService } from '../shared/services/mfa.service';
import { ConfigurationParameterService } from './shared/services/configuration-parameter.service';

// shared modules
import { SharedModule } from '../shared/shared.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    BrowserModule,
    PlatformRoutingModule,
    HttpClientModule,
    TranslateModule,
    SharedModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DxReportViewerModule,
    ImageCropperModule,
    DxColorBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTabsModule,
    DxTabPanelModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxFormModule,
    DxValidationGroupModule,
    DxPopupModule,
    DxToastModule,
    DxScrollViewModule,
    DxTagBoxModule,
    DxListModule,
    DxBoxModule,
    DxFileUploaderModule,
    PdfJsViewerModule
  ],
  declarations: [
    PlatformContentComponent,
    PlatformHomeComponent,
    UsersComponent,
    UserComponent,
    RolesComponent,
    RoleComponent,
    AuctionsClusterComponent,
    AuctionClusterComponent,
    AuctionClusterChannelComponent,
    AuctionClusterNotificationComponent,
    BuyersComponent,
    BuyerComponent,
    AuctionsClusterComponent,
    AuctionClusterComponent,
    SuppliersComponent,
    SupplierComponent,
    AdditionalMenuItemsComponent,
    AdditionalMenuItemComponent,
    ReportsComponent,
    ReportComponent,
    ReportEditorComponent,
    ImportAuctionClusterComponent,
    ExternalPlatformsComponent,
    ExternalPlatformComponent,
    ProductPropertyStylingsComponent,
    ProductPropertyStylingComponent,
    ConfigurationParameterComponent
  ],
  providers: [
    AdditionalMenuItemsService,
    AuctionClusterService,
    PlatformPermissionService,
    PlatformRoleService,
    UserService,
    PlatformReportService,
    ExternalPlatformService,
    MFAService,
    ConfigurationParameterService
  ],
  bootstrap: []
})
export class PlatformModule { }
