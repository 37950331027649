import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { Supplier } from '../../shared/models/supplier';
import { User } from '../../shared/models/user';

// services
import { LanguageService } from '../../shared/services/language.service';
import { SupplierService } from '../../shared/services/supplier.service';


@Component({
  selector: 'supplier-component',
  templateUrl: 'supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent extends ItemDetailsComponent<Supplier> implements OnInit, OnDestroy {

  users: Array<User> = [];
  assignAdminUser = true;
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('name', { read: ElementRef }) nameInput: any;

  constructor(
    protected injector: Injector,
    private dataService: SupplierService,
    private languageService: LanguageService) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new Supplier();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(suppliers: Array<Supplier>, id: number, users: Array<User>) {
    this.allItems = suppliers;
    this.users = users;

    if (id != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getSupplier(id)
        .subscribe((res: Supplier) => {
          this.model = res;
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new Supplier();
      this.model.isActive = true;
      this.isEditMode = false;
      this.isOpened = true;
    }

    // setTimeout(() => { this.nameInput.nativeElement.focus(); });
  }

  save() {
    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new Supplier();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new Supplier();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnSupplierSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnSupplierSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnSupplierSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnSupplierSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  returnValues = (e: any) => {
    if (e != null) {
      return e.firstname + ' ' + e.lastname;
    }
  }
}
