import { Component, Output, OnInit, Injector, EventEmitter, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import * as _moment from 'moment';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// models
import { GroupingActionOnPropertyEnum } from '../../shared/enum/groupingActionOnPropertyEnum';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { UrlTypeEnum } from '../../shared/enum/urlTypeEnum';
import { Language } from '../../shared/models/language';
import { MasterData, MasterDataListField, MasterDataValue, MasterDataRow } from '../../shared/models/master-data';
import { ProductProperty, ProductPropertyType, GridProperty, NonGroupingSelection } from '../../shared/models/product';

// services
import { DateTimeService } from '../../shared/services/datetime.service';
import { LanguageService } from '../../shared/services/language.service';

import { MasterDataService } from '../shared/services/master-data.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;
const NOON = 12;

@Component({
  selector: 'generic-product-property-component',
  templateUrl: 'generic-product-property.component.html',
  styleUrls: ['./generic-product-property.component.scss'],
  providers: [{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter }, { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' }]
})
export class GenericProductPropertyComponent extends ItemDetailsComponent<GridProperty> implements OnInit {

  productPropertyTypes: Array<ProductPropertyType> = [];
  masterDatas: Array<MasterData> = [];
  productProperties: Array<ProductProperty> = [];
  isSupplyData: boolean;
  isReport: boolean;
  isMasterData: boolean;
  masterDataListFields: Array<MasterDataListField> = [];
  auctionClusterId: number;
  isMasterDataFieldNeeded = true;
  masterDataListId: number;
  showName = true;
  languages: Array<Language> = [];
  propertyGroupingShowing = false;
  automaticProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  manualProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  automaticGroupingProperties = [];
  manualGroupingProperties = [];
  groupingActions: any = GroupingActionOnPropertyEnum;
  groupingActionsTranslations: any;
  showOnlyAutomaticGrouping = false;
  showFilterOnCurrentLotProperty = false;
  showLastInFullScreenProperty = false;
  showIncludeInSummaryProperty = false;
  isDate = false;
  defaultDateRange: any;
  showWidth = false;
  showColRow = false;
  showComparable = false;
  showRequired = false;
  propertyTypeFilter: ProductPropertyTypeEnum = null;
  urlFormatFilter: UrlTypeEnum = null;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<GridProperty>();
  @Output() onUpdated = new EventEmitter<GridProperty>();
  // tslint:enable:no-output-on-prefix

  @ViewChild('name') name: TranslatableFieldComponent;
  @ViewChild('valueToShowInSummary') valueToShowInSummary: TranslatableFieldComponent;

  @Input('isReport')
  set groupingFlag(value: boolean) {
    this.isReport = value;
  }

  @Input('showComparable')
  set showComparableSetter(value: boolean) {
    this.showComparable = value;
  }

  @Input('showRequired')
  set showRequiredSetter(value: boolean) {
    this.showRequired = value;
  }

  @Input('isSupplyData')
  set supplyData(value: boolean) {
    this.isSupplyData = value;
  }

  @Input('isMasterDataFieldNeeded')
  set mdFieldNeeded(value: boolean) {
    this.isMasterDataFieldNeeded = value;
  }

  @Input('showName')
  set nameShown(value: boolean) {
    this.showName = value;
  }

  @Input('showColRow')
  set showColRowSetter(value: boolean) {
    this.showColRow = value;
  }

  @Input('showWidth')
  set showWidthSetter(value: boolean) {
    this.showWidth = value;
  }

  @Input('showFilterOnCurrentLotProperty')
  set showFilterOnCurrentLotPropertySetter(value: boolean) {
    this.showFilterOnCurrentLotProperty = value;
  }

  @Input('showLastInFullScreenProperty')
  set showLastInFullScreenPropertySetter(value: boolean) {
    this.showLastInFullScreenProperty = value;
  }

  @Input('showIncludeInSummaryProperty')
  set showIncludeInSummaryPropertySetter(value: boolean) {
    this.showIncludeInSummaryProperty = value;
  }

  @Input('propertyGroupingShowing')
  set propertyGroupingShowingSetter(value: boolean) {
    this.propertyGroupingShowing = value;
  }

  @Input('showOnlyAutomaticGrouping')
  set showOnlyAutomaticGroupingSetter(value: boolean) {
    this.showOnlyAutomaticGrouping = value;
  }

  @Input('automaticProductPropertyNonGroupingSelections')
  set automaticProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.automaticProductPropertyNonGroupingSelections = value;
    this.setAutomaticGroupingProperties();
  }

  @Input('manualProductPropertyNonGroupingSelections')
  set manualProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.manualProductPropertyNonGroupingSelections = value;
    this.setManualGroupingProperties();
  }

  @Input('propertyTypeFilter')
  set propertyTypeFiltersValues(value: ProductPropertyTypeEnum) {
    this.propertyTypeFilter = value;
  }

  @Input('urlFormatFilter')
  set urlFormatFiltersValues(value: UrlTypeEnum) {
    this.urlFormatFilter = value;
  }

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private masterDataService: MasterDataService,
    private dateTimeService: DateTimeService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.groupingActions = Object.keys(this.groupingActions).filter(f => !isNaN(Number(f))).map(key => ({ name: this.groupingActions[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.translate.get('GROUPING_ACTIONS').subscribe((res: string) => {
      this.groupingActionsTranslations = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('GROUPING_ACTIONS').subscribe((res: string) => {
        this.groupingActionsTranslations = res;
      });
    });
  }

  ngOnInit() {
    this.model = new GridProperty();
  }

  getGroupingActionTranslated(id: number) {
    const action = this.groupingActions.find(a => a.value === id);
    if (action) {
      return ` - ${this.groupingActionsTranslations[action.name]}`;
    }

    return '';
  }


  setAutomaticGroupingProperties() {
    if (!this.model || !this.model.productPropertyId) {
      return;
    }

    const newArray = [];
    this.automaticProductPropertyNonGroupingSelections.forEach(p => {
      if (p.productPropertyId === this.model.productPropertyId) {
        newArray.push({
          id: p.productPropertyGroupingSelectionId,
          label: `${this.getTranslation(p.productPropertyName)}${this.getGroupingActionTranslated(p.actionOnGrouping)}`
        });
      }
    });

    this.automaticGroupingProperties = newArray;
  }

  setManualGroupingProperties() {
    if (!this.model || !this.model.productPropertyId) {
      return;
    }

    const newArray = [];
    this.manualProductPropertyNonGroupingSelections.forEach(p => {
      if (p.productPropertyId === this.model.productPropertyId) {
        newArray.push({
          id: p.productPropertyGroupingSelectionId,
          label: `${this.getTranslation(p.productPropertyName)}${this.getGroupingActionTranslated(p.actionOnGrouping)}`
        });
      }
    });

    this.manualGroupingProperties = newArray;
  }

  open(properties: Array<GridProperty>, property: GridProperty,
    productProperties: Array<ProductProperty>, productPropertyTypes: Array<ProductPropertyType>,
    masterDataList: Array<MasterData>, languages: Array<Language>) { // tslint:disable-line:max-line-length

    this.allItems = properties;
    this.productProperties = productProperties;
    this.masterDatas = masterDataList;
    this.productPropertyTypes = productPropertyTypes;
    this.languages = languages;

    this.translateProductProperties();

    this.masterDatas.forEach(masterData => {
      masterData.masterDataNameText = this.languageService.getTranslatableText(masterData.name);
    });

    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    if (property !== null) {
      this.isEditMode = true;
      this.model = property;
      this.filterMasterDataFields();
      this.setAutomaticGroupingProperties();
      this.setManualGroupingProperties();

      this.model.dateType = this.model.dateType !== undefined && this.model.dateType !== null ? this.model.dateType.toString() : null;

      this.defaultDateRange = [
        moment.utc(this.model.defaultFromDate),
        moment.utc(this.model.defaultTillDate),
      ];


      this.isOpened = true;
    } else {
      this.model = new GridProperty();
      this.isEditMode = false;
      this.isOpened = true;
    }

    this.model.name = this.model.name || JSON.stringify(emptyTranslation);
    this.model.valueToShowInSummary = this.model.valueToShowInSummary || JSON.stringify(emptyTranslation);
  }

  findMasterDataValue(masterDataValue: MasterDataValue, masterDataListField: MasterDataListField): any {
    if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.DATE) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(masterDataValue.dateTimeValue, masterDataListField.propertyTypeFormatId);
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.DECIMAL) {
      return masterDataValue.decimalValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.NUMBER) {
      return masterDataValue.intValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.TEXT) {
      if (masterDataListField.translatable) {
        return this.getTranslation(masterDataValue.stringValue);
      }
      return masterDataValue.stringValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.IMAGE) {
      return `<img style="max-width: 50px; max-height: 50px;" src=${masterDataValue.imageValue} />`;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN) {
      return masterDataValue.booleanValue ? 'true' : 'false';
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.COLOR) {
      return masterDataValue.stringValue ? masterDataValue.stringValue : '';
    }
  }

  getMasterDataRowValue(row: MasterDataRow, field: MasterDataListField) {
    if (field && field.masterDataListFieldId) {
      const value = row.values.find(v => v.masterDataListFieldId === field.masterDataListFieldId);

      if (value) {
        return this.findMasterDataValue(value, field);
      }
    }
    return row.masterDataListRowId.toString();
  }

  setMasterDataFields(prop: ProductProperty) {
    if (!this.isMasterData) {
      return;
    }

    this.masterDataListId = prop.masterDataListId;
    this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
      if (this.propertyTypeFilter === ProductPropertyTypeEnum.URL && this.urlFormatFilter === UrlTypeEnum.IMAGE_URL) {
        this.masterDataListFields = mdl.fields.filter(field => field.propertyTypeId && field.propertyTypeId === ProductPropertyTypeEnum.URL &&
          field.propertyTypeFormatId && field.propertyTypeFormatId === UrlTypeEnum.IMAGE_URL);
      } else {
        this.masterDataListFields = mdl.fields;
      }
    });
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  translateProductProperties() {
    this.productProperties.forEach(productProperty => {
      productProperty.productPropertyNameText = this.languageService.getTranslatableText(productProperty.name);
    });
  }

  dateSelectionChanged()
  {
    if((!this.model.showTodayDateSelection && this.model.dateType == 0) ||
    (!this.model.showYesterdayDateSelection && this.model.dateType == 1) ||
    (!this.model.showLastWeekDateSelection && this.model.dateType == 2) ||
    (!this.model.showRangeDateSelection && this.model.dateType == 3) ||
    (!this.model.showTomorrowDateSelection && this.model.dateType == 4) ||
    (!this.model.showDateDateSelection && this.model.dateType == 5) 
    )
    {
      if(this.model.showTodayDateSelection)
      {
          this.model.dateType = "0";
          return;
      }
      if(this.model.showYesterdayDateSelection)
      {
          this.model.dateType = "1";
          return;
      }
      if(this.model.showLastWeekDateSelection)
      {
          this.model.dateType = "2";
          return;
      }
      if(this.model.showRangeDateSelection)
      {
          this.model.dateType = "3";
          return;
      }
      if(this.model.showTomorrowDateSelection)
      {
          this.model.dateType = "4";
          return;
      }
      if(this.model.showDateDateSelection)
      {
          this.model.dateType = "5";
          return;
      }
    }
  }

  save() {
    if (this.name) {
      this.model.name = this.name.data;
    }

    if (this.valueToShowInSummary) {
      this.model.valueToShowInSummary = this.valueToShowInSummary.data;
    }

    if (this.model.orderNumber === undefined || this.model.orderNumber === null) {
      let orderNo = 1;
      if (this.allItems.length > 0) {
        const sortedItems = this.allItems.sort((a, b) => a.orderNumber - b.orderNumber);
        orderNo = sortedItems[sortedItems.length - 1].orderNumber + 1;
      }
      this.model.orderNumber = orderNo;
    }
    if (this.model.dateType &&
      (this.model.dateType === 3 || this.model.dateType.toString() === '3')) { // remove string version when api changed
      const dateFrom = this.defaultDateRange[0];
      const dateTo = this.defaultDateRange[1];
      if(moment(dateFrom).isValid() && moment(dateTo).isValid())
      {
        this.model.defaultFromDate = moment(dateFrom).hours(NOON).format();
        this.model.defaultTillDate = moment(dateTo).hours(NOON).format();
      }
      else{
        this.model.defaultFromDate = null;
        this.model.defaultTillDate = null;
      }
      
    }
    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new GridProperty();
    this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  setName() {
    const prodProp = this.productProperties.find(p => p.productPropertyId === this.model.productPropertyId);

    if (prodProp && this.name) {
      this.name.data = prodProp.name;
    }
  }

  onMasterDataChanged() {
    this.filterMasterDataFields();
    this.model.masterDataListFieldId = null;
    this.setAutomaticGroupingProperties();
    this.setManualGroupingProperties();

    this.setName();
  }

  filterMasterDataFields() {
    const prop = this.productProperties.find(pp => pp.productPropertyId === this.model.productPropertyId);
    if (prop && prop.propertyTypeId === ProductPropertyTypeEnum.DATE) {
      this.isDate = true;
    } else {
      this.isDate = false;
    }
    if (prop && prop.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
      this.isMasterData = true;
      this.setMasterDataFields(prop);
    } else {
      this.isMasterData = false;
    }
  }
}
