<div class="sub-buyer-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <div class="modal-body">
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>

      <form #detailsForm="ngForm">
        <section class="form-block">
          <div class="form-group">
            <label class="required">{{ 'SHARED.SUBBUYER' | translate }}</label>
            <div class="select">
              <ng-select name="subBuyer" ngDefaultControl class="filter-select" [(ngModel)]="model.buyerId" [disabled]="isEditMode">
                <ng-option *ngFor="let subBuyer of buyers" [value]="subBuyer.buyerId">{{ subBuyer.name | translateJson }}</ng-option>
              </ng-select>

            </div>
          </div>
          <div class="form-group">
            <label class="required">{{ 'SHARED.SUBNUMBER' | translate }}:</label>
            <label for="orderNumber" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': orderNumber.dirty && orderNumber.invalid}">
              <input clrInput type="number" min="1" max="10" name="orderNumber" id="orderNumber" [(ngModel)]="model.orderNumber" (blur)="checkOrderNumber(orderNumber)" #orderNumber="ngModel" required />
              <span class="tooltip-content" *ngIf="!orderNumberNotUnique && !orderNumberOutOfScope">
                {{ 'SHARED.NUMBER' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}
              </span>
              <span class="tooltip-content" *ngIf="orderNumberNotUnique">
                {{ 'BUYER.NUMBER_UNIQUE' | translate }}
              </span>
              <span class="tooltip-content" *ngIf="orderNumberOutOfScope">
                {{ 'BUYER.NUMBER_OUT_OF_SCOPE' | translate }}
              </span>
            </label>
          </div>
          <div class="form-group">
            <div class="checkbox">
              <clr-checkbox [(ngModel)]="model.buyerSubBuyerOverrule" name="buyerSubBuyerOverrule">{{ 'BUYER.OVERRULE' | translate }}</clr-checkbox>
            </div>
          </div>          
        </section>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid">{{ (isEditMode ? 'SAVE' : 'ADD') | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
