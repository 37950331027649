import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { forkJoin } from 'rxjs';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { TranslateService } from '@ngx-translate/core';
import * as _moment from 'moment';
import { getFileNameFromResponseContentDisposition, saveFile } from '../../helpers/file-download-helper';

// interfaces
import { IAuctionService } from '../../interfaces/auction';
import { ICatalogService } from '../../interfaces/catalog';
import { ILookupTableService } from '../../interfaces/lookup-table';
import { IMasterDataService } from '../../interfaces/master-data';
import { IProductService } from '../../interfaces/product';
import { IReportService } from '../../interfaces/report';

// models
import { ApplicationSettings } from '../../models/application-settings';
import { Auction } from '../../models/auction';
import { Buyer } from '../../models/buyer';
import { Catalog } from '../../models/catalog';
import { DateRanges, ExportTypes } from '../../models/report';
import { Language } from '../../models/language';
import { LookupTable, LookupTableField } from '../../models/lookup-table';
import { Lot, LotProperty } from '../../models/lot';
import { GridProperty, Product, ProductProperty } from '../../models/product';
import { MasterData } from '../../models/master-data';
import { ProductPropertyTypeEnum } from '../../enum/productPropertyTypeEnum';
import { ReportFilterModel, Report } from '../../models/report';

// services
import { ServiceFactory } from '../../factory/service-factory';
import { AuthService } from '../../services/auth.service';
import { BuyerService } from '../../services/buyer.service';
import { ErrorService } from '../../services/error.service';
import { LanguageService } from '../../services/language.service';
import { SpinnerService } from '../../services/spinner.service';
import { TitleService } from '../../services/title.service';
import { WebApiService } from '../../services/web-api.service';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

const NOON = 12;
@Component({
  selector: 'report.component',
  templateUrl: 'report.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./report.component.scss'],
  providers: [{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter }, { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' }]
})
export class ReportComponent {
  @ViewChild('filterForm') filterForm: NgForm;
  @ViewChild('reportViewer') documentViewer: any;
  @ViewChild('pdfViewer') pdfViewer: PdfJsViewerComponent;

  reportUrl: string;
  hostUrl: string;
  invokeAction = '/DXXRDV';
  lastRefresh = '';

  auctionClusterId: number;
  productId: number;
  reportId: number;
  report: Report;
  product: Product;
  productProperties: Array<ProductProperty> = [];
  reportProperties: Array<GridProperty> = [];
  auctions: Array<Auction> = [];
  catalogs: Array<Catalog> = [];
  allCatalogs: Array<Catalog> = [];

  masterDataDropdowns: Array<any> = [];
  productPropertyDropdowns: Array<any> = [];
  comboBoxes: Array<any> = [];
  lookupTables: Array<LookupTable> = [];
  languages: Array<Language> = [];

  buyers: Array<Buyer> = [];

  catalogId: number;
  auctionId: number;

  reportName: string;
  systemReportDesignType: number;

  isCustomReportDesign: boolean;

  filters;
  filterBindings: any;
  dateFiltersCascadingDefinitions;

  newReportName = '';
  isSaveProfileDialogOpened: boolean;

  masterDataService: IMasterDataService;
  reportService: IReportService;
  lookupTableService: ILookupTableService;
  auctionService: IAuctionService;
  catalogService: ICatalogService;
  productService: IProductService;

  comboboxTranslation: any;
  comboboxItems = [];
  dateFilterItems = [];
  dropDownComponents = {};
  firstEnabledDate: Array<Date> = [];
  lastEnabledDate: Array<Date> = [];

  incompleteRequirements = true;
  routeSub: any;

  constructor(
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected titleService: TitleService,
    protected languageService: LanguageService,
    protected router: Router,
    protected errorService: ErrorService,
    protected buyerService: BuyerService,
    protected spinner: SpinnerService,
    protected translateService: TranslateService,
    protected dateTimeAdapter: DateTimeAdapter<any>,
    protected webApiService: WebApiService,
    private authenticationService: AuthService) {
    this.hostUrl = this.appSettings.reportingApiURL;
    dateTimeAdapter.setLocale(translateService.currentLang);
    this.getServices(route, appSettings, webApiService);
    translateService.get(['SHARED.TODAY', 'SHARED.YESTERDAY', 'SHARED.LAST_WEEK', 'SHARED.RANGE', 'SHARED.TOMORROW', 'SHARED.DATE']).subscribe((res: Array<string>) => {
      this.comboboxTranslation = res;
      this.dateFilterItems = [
        {
          value: 0,
          name: res['SHARED.TODAY']
        },
        {
          value: 1,
          name: res['SHARED.YESTERDAY']
        },
        {
          value: 2,
          name: res['SHARED.LAST_WEEK']
        },
        {
          value: 3,
          name: res['SHARED.RANGE']
        }
      ];
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.documentViewer?.bindingSender?.Close();
  }

  getServices(route, appSettings, webApiService) {
    this.masterDataService = ServiceFactory.getMasterDataService(document.location.hash, route, appSettings, webApiService);
    this.reportService = ServiceFactory.getReportService(route, appSettings, webApiService);
    this.lookupTableService = ServiceFactory.getLookupTableService(route, appSettings, webApiService);
    this.auctionService = ServiceFactory.getAuctionService(route, appSettings, webApiService);
    this.catalogService = ServiceFactory.getCatalogService(route, appSettings, webApiService);
    this.productService = ServiceFactory.getProductService(document.location.hash, route, appSettings, webApiService);
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  getPlainText(value: string) {
    if (value) {
      return value.replace(/<[^>]*>/g, '');
    }
    else {
      return '';
    }
  }

  ngOnInit() {
    this.routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.documentViewer?.bindingSender?.Close();
        this.routeSub.unsubscribe();
      }
    });
  }


  init() {

    this.titleService.set('REPORTS.TITLE');

    this.isSaveProfileDialogOpened = false;

    this.filters = [];
    this.filterBindings = new ReportFilterModel();
    this.dateFiltersCascadingDefinitions = [];

    this.auctionClusterId = isNaN(+this.route.snapshot.params['auctionClusterId'])
      ? +this.route.snapshot.params['id']
      : +this.route.snapshot.params['auctionClusterId'];
    this.reportId = +this.route.snapshot.params['reportId'];

    this.reportName = '';

    this.auctionId = null;
    this.catalogId = null;

    this.getDataFromServices();
  }

  getDataFromServices() {
    this.spinner.show();
    forkJoin(
      this.reportService.getReport(this.reportId),
      this.languageService.getLanguages()
    ).subscribe(data => {
      // tslint:disable-next-line: no-magic-numbers
      this.report = data[0];
      this.languages = data[1];
      this.reportName = this.report.description;
      this.productId = this.report.reportDesign.productId;
      this.isCustomReportDesign = this.report.reportDesign.isCustomReportDesign;
      this.systemReportDesignType = this.report.reportDesign.systemReportDesignType;

      if (this.lookupTableService) {
        this.lookupTableService.getLookupTablesForReports(this.auctionClusterId, this.productId).subscribe(lt => {
          this.lookupTables = lt;
        },
          error => {
            this.errorService.show(error);
          });
      }

      if (this.productId) {
        this.parseReportFilterData();

        forkJoin(
          this.productService.getProductForReports(this.auctionClusterId, this.productId),
          this.productService.getProductProperties(this.productId, null),
          this.productService.getReportProperties(this.productId, this.report.reportDesignId)
        ).subscribe((result: Array<any>) => {
          this.product = result[0];
          this.productProperties = result[1];
          this.reportProperties = result[2]
          if (result[0]) {
            this.initReportData(this.auctionClusterId, new Array<MasterData>());
            this.hasIncompleteRequiredFilter();
          }
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
      }

      this.parseReportFilterData();

      this.SpecificHandlingAfterFetchingServices();

      //this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        this.errorService.show(error);
      });
  }

  SpecificHandlingAfterFetchingServices(): void {

  }

  reportStartBuild(e: any) {
    this.spinner.show();
  }

  reportDocumentReady(e: any) {
    e.sender.GetPreviewModel().reportPreview.zoom(-1);
    this.spinner.hide();
  }

  reportOnServerError(e: any) {
    this.spinner.hide();
  }

  reportCustomizeMenuActions(e: any) {
    var searchAction = e.args.GetById("dxxrp-search");
    if (searchAction) {
      searchAction.visible = false;
    }
  }

  reportCustomizeElements(e: any) {
    var toolbarPart = e.args.GetById("dxrd-right-panel-template-base");
    var index = e.args.Elements.indexOf(toolbarPart);
    e.args.Elements.splice(index, 1);
  }

  hasIncompleteRequiredFilter() {
    this.incompleteRequirements = false;
    var filterForServer = this.getFilterForServer();
    for (let i = 0; i < this.reportProperties.length; i += 1) {
      if (this.reportProperties[i].filterRequired) {
        var productPropertyFilterForServer = filterForServer.filter(x => x.productPropertyId === this.reportProperties[i].productPropertyId)
        if ((productPropertyFilterForServer === undefined || productPropertyFilterForServer === null) ||
          (productPropertyFilterForServer[0] === undefined || productPropertyFilterForServer[0] === null) ||
          (productPropertyFilterForServer[0].value === undefined || productPropertyFilterForServer[0].value === null)) {
          this.incompleteRequirements = true;
        }
      }
    }
  }
  disableButton() {
    return this.incompleteRequirements;
  }


  initReportData(auctionClusterId: number, masterDataLists: Array<MasterData>) {
    this.masterDataDropdowns.length = 0;
    this.productPropertyDropdowns.length = 0;
    this.reportProperties.filter(x => x.reportDesignId === this.report.reportDesignId)
      .sort((s, z) => s.orderNumber.toString().localeCompare(z.orderNumber.toString()))
      .forEach(reportProperty => {
        const productProperty = this.productProperties.filter(x => x.productPropertyId === reportProperty.productPropertyId)[0];
        const productPropertyId = productProperty.productPropertyId;
        const productPropertyTypeId = productProperty.propertyTypeId;
        const productPropertyMasterDataListId = productProperty.masterDataListId;
        const reportPropertyMasterDataListFieldId = reportProperty.masterDataListFieldId;

        //#8898: if (productProperty.systemProductPropertyType !== null && productProperty.systemProductPropertyType === 17) { return; }

        if (productPropertyTypeId === ProductPropertyTypeEnum.DATE) {
          if (!reportProperty.showTodayDateSelection &&
            !reportProperty.showYesterdayDateSelection &&
            !reportProperty.showTomorrowDateSelection &&
            !reportProperty.showRangeDateSelection &&
            !reportProperty.showLastWeekDateSelection &&
            !reportProperty.showDateDateSelection) {
            reportProperty.showTodayDateSelection = true;
          }
        }

        const filter = {
          name: reportProperty.name,
          propertyTypeId: productPropertyTypeId,
          sysName: productProperty.sysName,
          productPropertyId,
          masterDataListId: productPropertyMasterDataListId,
          masterDataListFieldId: reportPropertyMasterDataListFieldId,
          options: [],
          showToday: reportProperty.showTodayDateSelection,
          showYesterday: reportProperty.showYesterdayDateSelection,
          showTomorrow: reportProperty.showTomorrowDateSelection,
          showRange: reportProperty.showRangeDateSelection,
          showLastWeek: reportProperty.showLastWeekDateSelection,
          showDate: reportProperty.showDateDateSelection,
          multiSelect: reportProperty.multiSelect,
          filterByDisplayValue: reportProperty.filterByDisplayValue,
          filterRequired: reportProperty.filterRequired,
          filteredKeys: []
        };

        const masterData = masterDataLists.filter(x => x.masterDataListId === productPropertyMasterDataListId
          && x.auctionClusterId === auctionClusterId)[0];
        const masterDataFieldWithFieldTypeId = masterData ? masterData.fields.filter(x => x.fieldType == 9)[0] : null;

        // We show only date, text, number and master data property types.
        if (productPropertyTypeId === ProductPropertyTypeEnum.DATE || productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA || productPropertyTypeId === ProductPropertyTypeEnum.TEXT || productPropertyTypeId == ProductPropertyTypeEnum.NUMBER) {
          this.masterDataDropdowns.push({ productPropertyId: reportProperty.productPropertyId, masterDataListFieldId: reportProperty.masterDataListFieldId, values: filter.options });

          this.filters.push(filter);

          if (filter.propertyTypeId === 4 && !reportProperty.filterStoredProcedure) {
            const object = {
              productPropertyId: filter.productPropertyId,
              items: []
            };

            const objectItems = [];
            if (filter.showToday) {
              objectItems.push({
                value: 0,
                name: this.comboboxTranslation['SHARED.TODAY']
              });
            }
            if (filter.showYesterday) {
              objectItems.push({
                value: 1,
                name: this.comboboxTranslation['SHARED.YESTERDAY']
              });
            }
            if (filter.showLastWeek) {
              objectItems.push({
                value: 2,
                name: this.comboboxTranslation['SHARED.LAST_WEEK']
              });
            }
            if (filter.showRange) {
              objectItems.push({
                value: 3,
                name: this.comboboxTranslation['SHARED.RANGE']
              });
            }
            if (filter.showTomorrow) {
              objectItems.push({
                value: 4,
                name: this.comboboxTranslation['SHARED.TOMORROW']
              });
            }
            if (filter.showDate) {
              objectItems.push({
                value: 5,
                name: this.comboboxTranslation['SHARED.DATE']
              });
            }
            object.items = objectItems;
            this.comboboxItems.push(object);
          }

          this.filterBindings[filter.productPropertyId] = this.filterBindings[filter.productPropertyId] !== undefined
            ? this.filterBindings[filter.productPropertyId]
            : null;

          if (productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA && reportProperty.defaultMasterDataListRowId !== null) {
            this.filterBindings[filter.productPropertyId] = reportProperty.defaultMasterDataListRowId;
          }

          this.dateFiltersCascadingDefinitions[filter.productPropertyId] =
            this.dateFiltersCascadingDefinitions[filter.productPropertyId] !== null ?
              this.dateFiltersCascadingDefinitions[filter.productPropertyId] : null;

          if (productPropertyTypeId === ProductPropertyTypeEnum.DATE) {
            if (this.filterBindings[filter.productPropertyId] === null || this.filterBindings[filter.productPropertyId] === undefined) {
              switch (reportProperty.dateType) {
                case DateRanges.RANGE: {
                  this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.RANGE.toString();
                  this.filterBindings[filter.productPropertyId] = [
                    moment.utc(reportProperty.defaultFromDate),
                    moment.utc(reportProperty.defaultTillDate)
                  ];
                  break;
                }
                case DateRanges.TODAY: {
                  this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.TODAY.toString();
                  this.filterBindings[filter.productPropertyId] = moment.utc().hours(NOON).format();
                  break;
                }
                case DateRanges.YESTERDAY: {
                  this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.YESTERDAY.toString();
                  this.filterBindings[filter.productPropertyId] = moment.utc().subtract(1, 'days').hours(NOON).format();
                  break;
                }
                case DateRanges.LAST_WEEK: {
                  const dateFrom = moment.utc().subtract(1, 'weeks').hours(NOON);
                  const dateTo = moment.utc().hours(NOON);
                  this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.LAST_WEEK.toString();
                  this.filterBindings[filter.productPropertyId] = [dateFrom, dateTo];
                  break;
                }
                case DateRanges.TOMORROW: {
                  this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.TOMORROW.toString();
                  this.filterBindings[filter.productPropertyId] = moment.utc().add(1, 'days').hours(NOON).format();
                  break;
                }
                case DateRanges.DATE: {
                  this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.DATE.toString();
                  this.filterBindings[filter.productPropertyId] = moment.utc().hours(NOON).format();
                  break;
                }
                default:
                  break;
              }
            }
          }
        }
      });

    this.onPropertyChanged(undefined);

    this.spinner.hide();
  }

  registerDropDown(e: any, productPropertyId: number) {
    this.dropDownComponents[productPropertyId] = e.component;
  }

  parseReportFilterData() {
    if (!this.report.data) {
      return;
    }

    const filterData = JSON.parse(this.report.data);

    filterData.forEach(filter => {
      if (filter.productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        if (filter.value){
          if (filter.value.split(',').length > 1) {
            this.filterBindings[filter.productPropertyId] = filter.value.split(',');
          } else {
            this.filterBindings[filter.productPropertyId] = [filter.value];
          }
        }
      } else if (filter.productPropertyTypeId === ProductPropertyTypeEnum.DATE) {
        if (filter.dateRangeType !== null) {
          this.dateFiltersCascadingDefinitions[filter.productPropertyId] = filter.dateRangeType.toString();
          if (filter.dateRangeType === DateRanges.RANGE) {
            this.filterBindings[filter.productPropertyId] = [
              moment.utc(filter.value.split('|')[0]),
              moment.utc(filter.value.split('|')[1]),
            ];
          }
          else if (filter.dateRangeType === DateRanges.TODAY) {
            this.filterBindings[filter.productPropertyId] = moment.utc().hours(NOON);
          }
          else if (filter.dateRangeType === DateRanges.YESTERDAY) {
            this.filterBindings[filter.productPropertyId] = moment.utc().subtract(1, 'days').hours(NOON);
          }
          else if (filter.dateRangeType === DateRanges.TOMORROW) {
            this.filterBindings[filter.productPropertyId] = moment.utc().add(1, 'days').hours(NOON);
          }
          else if (filter.dateRangeType === DateRanges.DATE) {
            this.filterBindings[filter.productPropertyId] = moment.utc(filter.value);
          }
          else if (filter.dateRangeType === DateRanges.LAST_WEEK) {
            const dateFrom = moment.utc().subtract(1, 'weeks').hours(NOON);
            const dateTo = moment.utc().hours(NOON);
            this.filterBindings[filter.productPropertyId] = [dateFrom, dateTo];
          }
        } else {
          this.filterBindings[filter.productPropertyId] = moment.utc(filter.value);
        }
      }
    });
  }

  getFilterForServer() {
    const filterForServer: Array<ReportFilterModel> = [];

    this.getFilterForServerNotFixedFiltering(filterForServer);
    this.getFilterForServerFixedFiltering(filterForServer);

    return filterForServer;
  }

  getFilterForServerNotFixedFiltering(filterForServer) {
    for (const prop of Object.keys(this.filterBindings)) {
      let filterValue = null;
      let filterEntityId = null;
      let filterType = null;

      const filter = this.filters.filter(x => x.productPropertyId === +prop)[0];

      if (filter !== undefined && filter !== null) {
        filterType = filter.propertyTypeId;

        let dateFilter = null;

        var currentProperty = this.reportProperties.filter(x => x.productPropertyId === Number(prop))[0];

        if (filter.propertyTypeId === ProductPropertyTypeEnum.DATE && !currentProperty.filterStoredProcedure) {
          dateFilter = this.dateFiltersCascadingDefinitions[prop] === undefined || this.dateFiltersCascadingDefinitions[prop] === null ? null : +this.dateFiltersCascadingDefinitions[prop];
          if (dateFilter === DateRanges.TODAY) {
            filterValue = moment.utc().hours(NOON).format();
          }
          if (dateFilter === DateRanges.YESTERDAY) {
            filterValue = moment.utc().subtract(1, 'days').hours(NOON).format();
          }
          if (dateFilter === DateRanges.LAST_WEEK) {
            const dateFrom = moment.utc().subtract(1, 'weeks').hours(NOON);
            const dateTo = moment.utc().hours(NOON);
            filterValue = dateFrom.format() + '|' + dateTo.format();
          }
          if (dateFilter === DateRanges.RANGE) {
            const dateFrom = this.filterBindings[prop][0];
            const dateTo = this.filterBindings[prop][1];
            filterValue = moment(dateFrom).hours(NOON).format() + '|' + moment(dateTo).hours(NOON).format();
          }
          if (dateFilter === DateRanges.TOMORROW) {
            filterValue = moment.utc().add(1, 'days').hours(NOON).format();
          }
          if (dateFilter === DateRanges.DATE) {
            const dateFrom = this.filterBindings[prop];
            filterValue = moment(dateFrom).hours(NOON).format();
          }
        }
        else if (filter.propertyTypeId === ProductPropertyTypeEnum.DATE && currentProperty.filterStoredProcedure) {
          let selectedDate = this.filterBindings[prop];
          filterValue = selectedDate ? moment(selectedDate).hours(NOON).format() : null;
        }
        else {
          filterValue = this.filterBindings[prop];

          if (filter.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
            var firstOption = filter.options.filter(x => x.value === filterValue)[0];
            if (firstOption) {
              filterEntityId = firstOption.entityId;
            }
          }
        }

        filterForServer.push({
          productPropertyTypeId: +filterType,
          productPropertyId: +prop,
          dateRangeType: dateFilter,
          key: filter.sysName,
          value: filterValue ? filterValue.toString() : filterValue,
          entityId: filterEntityId
        });
      }
    }
  }

  getFilterForServerFixedFiltering(filterForServer) {
  }

  getBuyerId(): void {
  }

  async buildReportUrl(download: boolean) {
    this.documentViewer?.bindingSender?.Close();
    this.lastRefresh = moment().locale(this.translateService.currentLang).format('DD.MM.YYYY. HH:mm');

    if (this.product == null) {
      if (this.productId && this.productId > 0) {
        this.product = await this.productService.getProductForReports(this.auctionClusterId, this.productId).toPromise();
      }
      else {
        this.product = new Product();
        this.product.reportingPDFOnly = false;
      }
    }

    const reportId = this.report.reportId;
    const filterForServer = this.getFilterForServer();
    const buyerId = this.getBuyerId();
    const reportRequestModel = {
      LanguageCode: this.translateService.currentLang,
      TimeZoneOffset: new Date().getTimezoneOffset(),
      UserId: this.authenticationService.currentUserId,
      BuyerId: buyerId,
      Filters: filterForServer,
      ExportType: this.product.reportingPDFOnly && !download ? ExportTypes.PDF : ExportTypes.None
    };

    if (this.report.reportDesign.refreshIntervalSeconds > 0) {
      setTimeout(() => this.buildReportUrl(download), this.report.reportDesign.refreshIntervalSeconds * 1000);
    }

    if ((!download && !this.product.reportingPDFOnly) && this.isCustomReportDesign !== true) {
      const base64QueryStringFilter = btoa(JSON.stringify(reportRequestModel));
      this.reportUrl = reportId + '?time=' + performance.now() + '&filter=' + base64QueryStringFilter;
    }
    else {
      this.reportService.downloadExport(reportId, reportRequestModel).subscribe(result => {
        if (download || this.isCustomReportDesign === true) {
          var fileName = getFileNameFromResponseContentDisposition(result);
          saveFile(result.body, fileName);
        }
        else {
          this.pdfViewer.pdfSrc = result.body;
          this.pdfViewer.refresh();
        }
        this.spinner.hide();
      }, error => {
        if (error.status == 404) {
          this.errorService.show(this.errorService.translations.REPORT_NOT_FOUND);
        }
        else {
          this.errorService.show(this.errorService.translations.REPORT_EXPORT_ERROR);
        }
        this.spinner.hide();
      });
    }
  }

  save() {
    this.report.data = JSON.stringify(this.getFilterForServer());
    this.spinner.show();
    this.reportService.editReport(this.report).subscribe(report => {
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        this.isSaveProfileDialogOpened = false;
        this.errorService.show(error);
      });
  }

  saveNewReport() {
    const newReport: Report = JSON.parse(JSON.stringify(this.report));
    newReport.data = JSON.stringify(this.getFilterForServer());
    newReport.systemDefault = false;
    var emptyDescription = this.languageService.updateStringToAllLanguages(this.languages, "{}");
    newReport.description = this.languageService.setSameValueToAllLanguages(this.languages, emptyDescription, this.newReportName);

    this.spinner.show();
    this.reportService.saveReport(newReport).subscribe(report => {
      this.spinner.hide();
      this.isSaveProfileDialogOpened = false;
      this.navigate(report);
    },
      error => {
        this.spinner.hide();
        this.isSaveProfileDialogOpened = false;
        this.errorService.show(error);
      });
  }

  navigate(report) { }


  exportReport() {
    this.spinner.show();
    this.buildReportUrl(true);
  }

  filterReport() {
    this.spinner.show();
    this.buildReportUrl(false);
  }

  openSaveAsNewUserReportTemplateDialog() {
    this.isSaveProfileDialogOpened = true;
  }

  closeSaveAsNewUserReportTemplateDialog() {
    this.isSaveProfileDialogOpened = false;
  }

  getPropIdsForMasterDataFields(lookupTableFields: Array<LookupTableField>) {
    const ids = [];
    lookupTableFields.forEach(ltf => {
      if (ltf.isResult) {
        this.productProperties.forEach(pp => {
          if (pp.masterDataListId === ltf.masterDataListId) {
            ids.push(pp.productPropertyId);
          }
        });
      }
    });

    return ids;
  }

  getLookupDependentPropIds(filter: any) {
    let productPropertyId = 0;
    if (filter !== undefined) {
      productPropertyId = filter.productPropertyId;
    }
    let dependentPropIds = [];

    var reportProperties = this.reportProperties.filter(x => x.reportDesignId === this.report.reportDesignId);

    for (let i = 0; i < reportProperties.length; i += 1) {

      if (!dependentPropIds.includes(reportProperties[i].productPropertyId)) {
        if (reportProperties[i].filterStoredProcedure) {
          dependentPropIds.push(reportProperties[i].productPropertyId);
        }
      }
    }

    const productProperty = this.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    const productPropertyMasterData = productProperty.masterDataListId;

    if (productPropertyMasterData) {
      for (let i = 0; i < this.lookupTables.length; i += 1) {
        if (this.lookupTables[i].lookupTableType === 1) {
          continue;
        }
        for (let j = 0; j < this.lookupTables[i].lookupTableFields.length; j += 1) {
          if (!this.lookupTables[i].lookupTableFields[j].isResult
            && this.lookupTables[i].lookupTableFields[j].masterDataListId === productPropertyMasterData) {
            const propIds = this.getPropIdsForMasterDataFields(this.lookupTables[i].lookupTableFields);
            dependentPropIds = [...dependentPropIds, ...propIds];
          }
        }
      }
    }

    return dependentPropIds;
  }

  isProductPropertyWithLookupTable(productPropertyId: number) {
    if (this.product == null) {
      return -1;
    }
    for (let i = 0; i < this.productProperties.length; i += 1) {
      if (this.productProperties[i].productPropertyId === productPropertyId
        && this.productProperties[i].lookupTableId) {
        return this.productProperties[i].lookupTableId;
      }
    }

    return -1;
  }

  isProductPropertyWithStoredProcedure(productPropertyId: number) {
    if (this.product == null) {
      return -1;
    }
    var reportProperties = this.reportProperties.filter(x => x.reportDesignId === this.report.reportDesignId);

    for (let i = 0; i < reportProperties.length; i += 1) {

      if (reportProperties[i].productPropertyId === productPropertyId
        && reportProperties[i].filterStoredProcedure) {
        return reportProperties[i].productPropertyId;
      }
    }

    return null;
  }

  clearLotPropertyValue(productPropertyId: number, e: any) {
    if (e.value == null) {
      this.filterBindings[productPropertyId] = null;
    }
  }

  applyLookup(lookup: LookupTable, i: number, filter: any) {
    let currentFilter = this.filters.find(f => f.productPropertyId == this.masterDataDropdowns[i].productPropertyId);
    if (currentFilter) {
      currentFilter.filteredKeys = [];
      if (lookup) {
        if (lookup.lookupTableRows.length > 0) {
          const newValues = [];
          lookup.lookupTableRows.forEach(row => {
            row.lookupTableValues.forEach(value => {
              newValues.push(value.masterDataListRowId);
            });
          });
          if (newValues.length > 0) {
            if (this.filters[i]) {
              currentFilter.filteredKeys = newValues;
            }
          }
        }
      }
    }
  }

  applyLookupStoredProcedure(lookup: LookupTable, i: number, filter: any) {
    let currentFilters = this.filters.filter(f => f.productPropertyId == this.masterDataDropdowns[i].productPropertyId);
    currentFilters.forEach(currentFilter => {
      currentFilter.filteredKeys = [];
      if (lookup) {
        if (lookup.lookupTableType === 0 && lookup.lookupTableRows.length > 0) {
          const newValues = [];
          lookup.lookupTableRows.forEach(row => {
            row.lookupTableValues.forEach(value => {
              newValues.push(value.masterDataListRowId);
            });
          });
          if (newValues.length > 0) {
            if (this.filters[i]) {
              currentFilter.filteredKeys = newValues;
            }
          }
        }
        else if (lookup.lookupTableType === 0){
          currentFilter.filteredKeys = [0];
        }
      }
    });
  }

  onPropertyChanged(filter: any) {
    const productPropertyIds = this.getLookupDependentPropIds(filter);

    if (productPropertyIds.length === 0) {
      return;
    }

    for (let i = 0; i < this.masterDataDropdowns.length; i += 1) {
      let checkThisProp = true;
      if (filter != undefined)
        checkThisProp = this.masterDataDropdowns[i].productPropertyId !== filter.productPropertyId;
      if (productPropertyIds.length > 0 && productPropertyIds.indexOf(this.masterDataDropdowns[i].productPropertyId) === -1) {
        checkThisProp = false;
      }
      const lookupTableId = this.isProductPropertyWithLookupTable(this.masterDataDropdowns[i].productPropertyId);
      const filterPropertyIdStoredProcedure = this.isProductPropertyWithStoredProcedure(this.masterDataDropdowns[i].productPropertyId);

      const productProperty = this.productProperties ? this.productProperties.filter(x => x.productPropertyId === filterPropertyIdStoredProcedure)[0] : null;

      if (checkThisProp && filterPropertyIdStoredProcedure != null) {
        const filterForServer = this.getFilterForServer();
        const buyerId = this.getBuyerId();
        const reportRequestModel = {
          LanguageCode: this.translateService.currentLang,
          TimeZoneOffset: new Date().getTimezoneOffset(),
          UserId: this.authenticationService.currentUserId,
          BuyerId: buyerId,
          Filters: filterForServer,
          productPropertyId: filterPropertyIdStoredProcedure
        };

        if (productProperty.propertyTypeId === ProductPropertyTypeEnum.NUMBER) {
          this.reportService.getStoredProcedureValuesForNumberProductProperty(this.reportId, reportRequestModel)
            .subscribe(result => {
              this.productPropertyDropdowns[i] = result;
            });
        } else if (productProperty.propertyTypeId === ProductPropertyTypeEnum.TEXT) {
          this.reportService.getStoredProcedureValuesForStringProductProperty(this.reportId, reportRequestModel)
            .subscribe(result => {
              this.productPropertyDropdowns[i] = result;
            });
        }
        else if (productProperty.propertyTypeId === ProductPropertyTypeEnum.DATE) {
          this.reportService.getStoredProcedureValuesForDateProductProperty(this.reportId, reportRequestModel)
            .subscribe(result => {
              if (result) {
                let enabledDates = result.map(_ => moment(_, 'YYYY-MM-DD').toDate());
                this.firstEnabledDate[i] = enabledDates[0];
                this.lastEnabledDate[i] = enabledDates[enabledDates.length - 1];
                this.productPropertyDropdowns[i] = this.getDisabledDates(enabledDates);
              }
            });
        }
        else {
          this.reportService.getStoredProcedureValuesForProductProperty(this.reportId, reportRequestModel)
            .subscribe(lookup => {
              this.applyLookupStoredProcedure(lookup, i, filter);
            }, error => {
              // couldn't filter values, do nothing
            });
        }
      }
      else if (checkThisProp && lookupTableId !== -1) {
        const lot = this.createLotFromBindings();
        this.lookupTableService.getLookupTableForProductPropertyForReports(this.auctionClusterId, this.masterDataDropdowns[i].productPropertyId, lot)
          .subscribe(lookup => {
            this.applyLookup(lookup, i, filter);
          }, error => {
            // couldn't filter values, do nothing
          });
      }
    }
  }

  createLotFromBindings() {
    const lot = new Lot();

    this.filters.forEach(f => {
      if (f.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        const lotProp = new LotProperty();
        lotProp.productPropertyId = f.productPropertyId;
        lotProp.masterDataListRowId = this.filterBindings[f.productPropertyId];
        lot.lotProperties.push(lotProp);
      }
    });

    return lot;
  }

  getDateValue(index: number) {
    return Number(this.dateFiltersCascadingDefinitions[index]);
  }

  getFilterItems(productPropertyId: number) {
    const items = this.comboboxItems.find(i => i.productPropertyId === productPropertyId);
    return items.items;
  }

  setValue(index: number, e: any) {
    this.dateFiltersCascadingDefinitions[index] = e.value;
  }

  handleDate1ValueChange(index: number, e: any) {
    if (e.value != null) {
      this.filterBindings[index] = [];
      this.filterBindings[index].push(moment(e.value));
    }
  }

  handleDate2ValueChange(index: number, e: any) {
    if (e.value != null) {
      this.filterBindings[index] = this.filterBindings[index].slice(0, 1);
      this.filterBindings[index].push(moment(e.value));
    }
  }

  dateValue1(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 0) {
      return moment(this.filterBindings[index][0]).toDate();
    } else {
      return null;
    }
  }

  dateValue2(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 1) {
      return moment(this.filterBindings[index][1]).toDate();
    } else {
      return null;
    }
  }

  getMin(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 0) {
      return moment(this.filterBindings[index][0]).toDate();
    }
  }

  isDisabled(index: number) {
    if (Array.isArray(this.filterBindings[index])) {
      if (this.filterBindings[index].length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  clearDatePeriod(index: number) {
    this.filterBindings[index] = [];
  }

  handleDateValueChange(index: number, e: any) {
    if (e.value != null) {
      this.filterBindings[index] = null;
      this.filterBindings[index] = moment(e.value);
    }
  }

  handleDateValueChangeStoredProcedure(index: number, e: any) {
    if (e.value != null && e.previousValue !== e.value) {
      this.filterBindings[index] = null;
      this.filterBindings[index] = e.value;
    }
  }

  dateValue(index: number) {
    if (this.filterBindings[index] !== '') {
      return moment(this.filterBindings[index]).toDate();
    } else {
      return null;
    }
  }

  getPlainTextSelectBoxes = (item: any) => {
    if (item) {
      const label = this.getPlainText(this.getTranslation(item.text));
      return label;
    }
  }

  prepareData(index: number) {
    return this.comboBoxes[index];
  }

  isDisabledButton() {
    if (this.newReportName !== '') {
      return false;
    } else {
      return true;
    }
  }

  handleDataValueChange(index: number, e: any) {
    if (e.value != null) {
      this.filterBindings[index] = e.value;
    }
  }

  dataValue(index: number) {
    if (this.filterBindings[index] !== '') {
      return this.filterBindings[index];
    } else {
      return null;
    }
  }

  private getDisabledDates(enabledDates: Array<Date>) {
    let dateFrom = new Date(enabledDates[0]);
    let dateTo = enabledDates[enabledDates.length - 1];
    let disabledDates = new Array();

    while (dateFrom <= dateTo) {
      if (!enabledDates.find(d => d.toString() === dateFrom.toString())) {
        disabledDates.push(new Date(dateFrom));
      }
      dateFrom.setDate(dateFrom.getDate() + 1);
    }
    return disabledDates;
  }
}
