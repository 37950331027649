import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { AdditionalMenuItemComponent } from './additional-menu-item.component';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';

// models
import { AdditionalMenuItem } from '../../shared/models/additional-menu-item';
import { Language } from '../../shared/models/language';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AdditionalMenuItemsService } from '../shared/services/additional-menu-items.service';

@Component({
  selector: 'additional-menu-items-component',
  templateUrl: 'additional-menu-items.component.html',
  styleUrls: ['./additional-menu-items.component.scss']
})
export class AdditionalMenuItemsComponent extends FullListComponent<AdditionalMenuItem, AdditionalMenuItemComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: AdditionalMenuItemComponent;

  private _subscription: Subscription;

  languages: Array<Language> = [];
  accesses = [];
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;

  constructor(
    protected injector: Injector,
    private dataService: AdditionalMenuItemsService,
    private languageService: LanguageService
  ) {
    super(injector, AdditionalMenuItem);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.translate.get('ADDITIONAL_MENU_ITEMS').subscribe((res: string) => {
      this.translations = res;

      this.accesses = [
        { id: 0, value: this.translations.PUBLIC },
        { id: 1, value: this.translations.PLATFORM_LEVEL },
        { id: 2, value: this.translations.AUCTION_CLUSTER_LEVEL },
        { id: 3, value: this.translations.BUYER_LEVEL },
        { id: 4, value: this.translations.SUPPLIER_LEVEL }
      ];
    });
  }

  ngOnInit() {
    this.title.set('PLATFORM.ADDITIONAL_MENU_ITEMS');
    this.setTranslations('ADDITIONAL_MENU_ITEMS');

    forkJoin(
      this.languageService.getLanguages()
    ).subscribe(
      result => {
        this.languages = result[0];
        this.spinner.hide();
        this.getData();
      }, error => {
        this.errorService.show(error);
        this.spinner.hide();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    this.dataService.getMenuItems().subscribe(result => {
      this.items = result.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1);
      this.getAccessLevelsString()
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  getAccessLevelsString() {
    this.items.forEach((menuItem) => {
      menuItem.accessLevelsString = [];
      menuItem.accessLevels.forEach(accessLevel => 
        {
        menuItem.accessLevelsString.push( this.accesses.find(_ => _.id === accessLevel).value );
      });
    });
  }

  edit = (e: any) => {
    const externalAppId = e.row !== undefined ? e.row.data.externalAppId : e.data.externalAppId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, externalAppId, this.languages, this.accesses);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.languages, this.accesses);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete)
      .subscribe((menuItem: Array<AdditionalMenuItem>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.externalAppId;
    this.confirmation.opened = true;
  }

  onReorder = (e: any) => {
     var visibleRows = e.component.getVisibleRows(),
         toIndex = this.items.indexOf(visibleRows[e.toIndex].data),
         fromIndex = this.items.indexOf(e.itemData);

    this.items.splice(fromIndex, 1);
    this.items.splice(toIndex, 0, e.itemData);

    this.items.forEach((item, i) => {
      item.orderNumber = i
      this.dataService.edit(item).subscribe();
    });
  }
}
