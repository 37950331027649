<div class="external-platform-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #externalPlatformForm
  [width]="600"
  [height]="250"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #externalPlatformForm
                 id="externalPlatformForm"
                 [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">
          <dxi-item itemType="group">
            <dxi-item dataField="name">
                <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="webServiceUrl">
                <dxo-label text="{{ 'SHARED.WEBSERVICE_URL' | translate }}"></dxo-label>
            </dxi-item>
          </dxi-item>
        </dx-form>
        <br />
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnExternalPlatformSubmit" disabled="disabled">
        {{ 'SHARED.SAVE' | translate }}
        </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
