import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

// components
import { AuthenticationComponent } from '../../shared/components/authentication/authentication.component';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { UserComponent } from './user.component';

// models
import { Auction } from '../../shared/models/auction';
import { AuctionCluster } from '../../shared/models/auction-cluster';
import { AuctionClusterRole } from '../../shared/models/auction-cluster-role';
import { Buyer } from '../../shared/models/buyer';
import { BuyerRole } from '../../shared/models/buyer-role';
import { PlatformPermissionEnum, AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { PlatformRole } from '../shared/models/platform-role';
import { Supplier } from '../../shared/models/supplier';
import { SupplierRole } from '../../shared/models/supplier-role';
import { User } from '../../shared/models/user';

import { UserFilter } from '../shared/models/user-filter';

// services
import { TranslateService } from '@ngx-translate/core';
import { AuctionService } from '../../auction/shared/services/auction.service';
import { AuctionClusterRoleService } from '../../shared/services/auction-cluster-role.service';
import { BuyerService } from '../../shared/services/buyer.service';
import { BuyerRoleService } from '../../shared/services/buyer-role.service';
import { ConfigService } from '../../shared/services/config.service';
import { LanguageService } from '../../shared/services/language.service';
import { SupplierService } from '../../shared/services/supplier.service';
import { SupplierRoleService } from '../../shared/services/supplier-role.service';
import { UserService as UserSharedService } from '../../shared/services/user.service';
import { TokenService } from '../../shared/services/token.service';

import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { PlatformRoleService } from '../shared/services/platform-role.service';
import { UserService } from '../shared/services/user.service';


@Component({
  selector: 'users-component',
  templateUrl: 'users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends FullListComponent<User, UserComponent> implements OnInit, OnDestroy {

  platformRoles: Array<PlatformRole> = [];

  auctionClusters: Array<AuctionCluster> = [];
  auctions: Array<Auction> = [];
  auctionClusterRoles: Array<AuctionClusterRole> = [];

  buyers: Array<Buyer> = [];
  buyerRoles: Array<BuyerRole> = [];

  suppliers: Array<Supplier> = [];
  supplierRoles: Array<SupplierRole> = [];

  userToEnableDisable: User = new User();
  userToBlockUnblock: User = new User();
  user: User = new User();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  userFilter: UserFilter;

  buyersPaginated: any;
  suppliersPaginated: any;
  usersPaginated: any;
  supplierStore = new CustomStore({
    key: 'supplierId',
    load: async (loadOptions) => {
      var result = await this.supplierService.getSuppliersPaged(loadOptions).toPromise();
      return result;
    }
  });

  usersStore = new CustomStore({
    key: 'userId',
    load: async (loadOptions) => {
      var result = await this.dataService.getUsersPaged(this.userFilter, loadOptions).toPromise();
      this.translateUserRoles(result);
      return result;
    }
  });

  @ViewChild(AuthenticationComponent) authComponent: AuthenticationComponent;
  @ViewChild('disable') disable: ConfirmationComponent;
  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: UserComponent;

  constructor(
    protected injector: Injector,
    private platformRoleService: PlatformRoleService,
    private auctionClusterService: AuctionClusterService,
    private buyerService: BuyerService,
    private supplierService: SupplierService,
    private auctionService: AuctionService,
    private dataService: UserService,
    private languageService: LanguageService,
    private auctionClusterRoleService: AuctionClusterRoleService,
    private supplierRoleService: SupplierRoleService,
    private buyerRoleService: BuyerRoleService,
    private tokenService: TokenService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private userService: UserSharedService

  ) {
    super(injector, User);
    this.title.set('SHARED.USERS');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  ngOnInit() {
    this.userFilter = new UserFilter();

    this.route.queryParams.subscribe(params => {
      if (params['auctionCluster']) {
        this.userFilter.showAuction = true;
        this.userFilter.showBuyer = false;
        this.userFilter.showSupplier = false;
        this.userFilter.showPlatform = false;
        this.userFilter.auctionClusterId = +params['auctionCluster'];
      } else if (params['buyers']) {
        this.userFilter.showAuction = false;
        this.userFilter.showSupplier = false;
        this.userFilter.showBuyer = true;
        this.userFilter.showPlatform = false;
        this.userFilter.buyerId = +params['buyers'];
      } else if (params['suppliers']) {
        this.userFilter.showSupplier = true;
        this.userFilter.showAuction = false;
        this.userFilter.showBuyer = false;
        this.userFilter.showPlatform = false;
        this.userFilter.supplierId = +params['suppliers'];
      } else {
        this.userFilter.showActive = true;
        this.userFilter.showAuction = true;
        this.userFilter.showBlocked = true;
        this.userFilter.showBuyer = true;
        this.userFilter.showInactive = true;
        this.userFilter.showPending = true;
        this.userFilter.showPlatform = true;
        this.userFilter.showSupplier = true;
      }
    });

    this.getData();

    this.setTranslations("USERS");
  }

  // tslint:disable:no-magic-numbers
  getData(filter?: boolean) {
    this.spinner.show();

    forkJoin(
      this.platformRoleService.getPlatformRoles(this.translateService.currentLang),

      this.auctionClusterService.getAuctionClusters(),
      this.auctionService.getAuctions(),
      this.auctionClusterRoleService.getAuctionClusterRoles(),

      this.buyerService.getBuyersSimple(),

      //this.supplierService.getSuppliersSimple(),

      //this.dataService.getUsersFiltered(this.userFilter),

      this.translate.get('USERS.FILTER_SUCCESS'),
      this.userService.getCurrentUser()

    ).subscribe((result: Array<any>) => {
      this.platformRoles = result[0];

      this.auctionClusters = result[1];

      this.auctions = result[2];
      this.auctionClusterRoles = result[3];

      this.buyers = result[4];

      //this.suppliers = result[5];

      //this.items = result[5];

      const successMessage = result[5];

      this.user = result[6];

      if (this.buyersPaginated == null) {
        this.buyersPaginated = {
          paginate: true,
          pageSize: 20,
          store: this.buyers,
          sort: 'name'
        };
      }

      if (this.suppliersPaginated == null) {
        this.suppliersPaginated = new DataSource({
          paginate: true,
          pageSize: 20,
          store: this.supplierStore,
          sort: [{ selector: 'name', desc: false }]
        });
      }

      if (this.usersPaginated == null) {
        this.usersPaginated = new DataSource({
          paginate: true,
          pageSize: 20,
          store: this.usersStore,
          sort: [{ selector: 'isPending', desc: true }, { selector: 'isBlocked', desc: true }]
        });
      }
      else {
        this.usersPaginated.reload();
      }


      if (this.platformRoles == null || this.auctionClusterRoles == null) {
        var userPermissions = this.tokenService.getCurrentUserPermissions();
        if (!userPermissions.PlatformPermissions.some(p => p === PlatformPermissionEnum.Administrator)) {
          this.platformRoles = this.platformRoles.filter(_ => !_.permissions.some(p => p === PlatformPermissionEnum.Administrator.valueOf()));
          this.auctionClusterRoles = this.auctionClusterRoles.filter(_ => !_.permissions.some(p => p === AuctionClusterPermissionEnum.Administrator));
        }

        //this.items = this.items.filter(_ => (_.platformRoleId === null || this.platformRoles.some(r => r.platformRoleId === _.platformRoleId)) && (_.auctionClusterRoles.length === 0 || this.auctionClusterRoles.some(r => _.auctionClusterRoles.some(acr => acr.auctionClusterRoleId === r.auctionClusterRoleId))));
      }

      //this.translateUserRoles();
      this.spinner.hide();

      if (filter) {
        notify(successMessage, 'success', 5000);
      }
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }
  // tslint:enable:no-magic-numbers

  editItem = (e: any) => {
    const userId = e.row !== undefined ? e.row.data.userId : e.data.userId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.usersPaginated.items(), userId, this.platformRoles,
    this.auctionClusters, this.auctions, this.auctionClusterRoles,
    this.buyers, this.suppliers, this.user.isSystemUser);
  }

  deleteItem = (e: any) => {

    this.itemIdToDelete = e.row.data.userId;
    this.confirmation.opened = true;
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.usersPaginated.items(), null, this.platformRoles,
      this.auctionClusters, this.auctions, this.auctionClusterRoles,
      this.buyers,
      this.suppliers, this.user.isSystemUser);
  }

  enableDisableUser = (e: any) => {
    const user: User = e.row.data;
    this.userToEnableDisable = user;
    this.dataService.enableDisable(this.userToEnableDisable).subscribe(() => {
      this.getData();
    },
      error => {
        this.errorService.show(error);
      }
    );
  }

  blockUnblockUser = (e: any) => {
    const user: User = e.row.data;
    this.userToBlockUnblock = user;
    this.dataService.blockUnblock(this.userToBlockUnblock).subscribe(() => {
      this.getData();
    },
      error => {
        this.errorService.show(error);
      }
    );
  }

  translateUserRoles(users) {
    users.data.forEach((user) => {
      let items: string[] = [];
      user.roles.forEach((role) => {
        var translated = this.languageService.getTranslatableText(role);
        items.push(translated);
      });
      let unique_values = items.filter((v, i, a) => a.indexOf(v) == i);
      unique_values.forEach((role) => {
        if (user.rolesString) {
          user.rolesString += ', ' + role;
        } else {
          user.rolesString = role;
        }
      });
    });
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete)
      .subscribe((users: Array<User>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  changeUserPassword = (e: any) => {
    this.authComponent.open(e.row.data.userId, null);
  }

  calculateDisabledDisplayValue = (e: any) => {
    if (e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
  }

  calculateEnabledDisplayValue = (e: any) => {
    if (!e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
   }

   calculateBlockedDisplayValue = (e: any) => {
    if (e.row.data.isBlocked) {
      return false;
    } else {
      return true;
    }
  }

  calculateUnblockedDisplayValue = (e: any) => {
    if (!e.row.data.isBlocked) {
      return false;
    } else {
      return true;
    }
  }

  edit(itemId: number, event: Event) { }
}
