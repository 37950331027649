import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// interfaces
import{ IReportService } from '../../../shared/interfaces/report';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Report } from '../../../shared/models/report';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class BuyerLevelReportService implements IReportService {

  private apiPath: string;
  private buyerId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  setId(id: number){
    this.buyerId = id;
  }

  getReports(buyerId: number, langCode: string): Observable<Array<Report>> {
    return this.webApiService.getList(this.apiPath + `buyerlevel/${buyerId}/reports?langCode=${langCode}`);
  }

  save(buyerId: number, report: Report): Observable<any> {
    return this.webApiService.save<Report>(this.apiPath + `buyerlevel/${buyerId}/reports`, report);
  }

  edit(buyerId: number, report: Report): Observable<any> {
    return this.webApiService.editSingle<Report>(this.apiPath + `buyerlevel/${buyerId}/reports`, report);
  }

  delete(buyerId: number, reportId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + `buyerlevel/${buyerId}/reports`, reportId);
  }

  getReportBySystemReportDesignType(buyerId: number, systemReportDesignType: number): Observable<Report> {
    return this.webApiService.getSingle(this.apiPath + `buyerlevel/${buyerId}/report/systemReportDesignType/${systemReportDesignType}`);
  }

  getReportsBySystemReportDesignType(buyerId: number, systemReportDesignType: number): Observable<Array<Report>> {
    return this.webApiService.getList(this.apiPath + `buyerlevel/${buyerId}/reports/systemReportDesignType/${systemReportDesignType}`);
  }

    //IReportService

    getReport(reportId: number): Observable<Report> {
      return this.webApiService.getSingle(this.apiPath + `buyerlevel/${this.buyerId}/reports/${reportId}`);
    }
  
    saveReport(report: Report): Observable<any> {
      return this.webApiService.save<Report>(this.apiPath + `buyerlevel/${this.buyerId}/reports`, report);
    }
  
    editReport(report: Report): Observable<any> {
      return this.webApiService.editSingle<Report>(this.apiPath + `buyerlevel/${this.buyerId}/reports`, report);
    }
  
    downloadExport(reportId: number, reportRequest: any): Observable<any> {
      const url = this.appSettings.reportingApiURL + '/api/v1/buyerlevel/' + this.buyerId + '/report/export?reportId=' + reportId;
      return this.webApiService.downloadWithPost(url, reportRequest);
    }

    requestDownload(reportId: number, reportRequest: any) {
      const url = this.apiPath + 'buyerlevel/' + this.buyerId + '/reports/requestdownload/' + reportId;
      return this.webApiService.downloadWithPost(url, reportRequest);
    }

    getStoredProcedureValuesForProductProperty(reportId: number, filter: any)
    {
      const url = this.appSettings.reportingApiURL + '/api/v1/buyerlevel/' + this.buyerId + '/reportfilter?reportId=' + reportId;
      return this.webApiService.save(url, filter);
    }
    
    getStoredProcedureValuesForNumberProductProperty(reportId: number, filter: any)
    {
      const url = this.appSettings.reportingApiURL + '/api/v1/buyerlevel/' + this.buyerId + '/reportfilter/number?reportId=' + reportId;
      return this.webApiService.save(url, filter);
    }

    getStoredProcedureValuesForStringProductProperty(reportId: number, filter: any)
    {
      const url = this.appSettings.reportingApiURL + '/api/v1/buyerlevel/' + this.buyerId + '/reportfilter/string?reportId=' + reportId;
      return this.webApiService.save(url, filter);
    }

    getStoredProcedureValuesForDateProductProperty(reportId: number, filter: any)
    {
      const url = this.appSettings.reportingApiURL + '/api/v1/buyerlevel/' + this.buyerId + '/reportfilter/date?reportId=' + reportId;
      return this.webApiService.save(url, filter);
    }
}
