<div class="auction-cluster-notification-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #auctionClusterNotification
  [width]="600"
  [showTitle]="true"
  title="{{ 'SHARED.NOTIFICATIONS' | translate}}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [showCloseButton]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #editAuctionClusterNotificationForm
                 id="editAuctionClusterNotificationForm"
                 [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">

          <dxi-item itemType="group">
            <dxi-item dataField="name"
                      [template]="'notificationNameField'">
              <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
            </dxi-item>

            <dxi-item dataField="description"
                      [template]="'notificationDescriptionField'">
              <dxo-label text="{{ 'SHARED.DESCRIPTION' | translate }}"></dxo-label>
            </dxi-item>
          </dxi-item>

          <div *dxTemplate="let data of 'notificationNameField'" class="translatable-template">
            <translatable-field (change)="onChangeTranslationField($event, 'name')" [isDevExpress]="true" [isLabelHidden]="true" [textRequired]="false" [caption]="'SHARED.NAME'" [clusterLanguages]="languages" [(data)]="data.component.option('formData')[data.dataField]" [name]="'name'" #notificationName></translatable-field>  
          </div>
          <div *dxTemplate="let data of 'notificationDescriptionField'" class="translatable-template">
            <translatable-field (change)="onChangeTranslationField($event, 'description')" [isDevExpress]="true" [isLabelHidden]="true" [textRequired]="false" [caption]="'SHARED.DESCRIPTION'" [clusterLanguages]="languages" [type]="'textbox'" [(data)]="data.component.option('formData')[data.dataField]" [name]="'description'" #notificationDescription></translatable-field>  
          </div>
        </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnAuctionClusterNotificationSubmit">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
<save-confirmation-component #saveConfirmation title="{{ 'SHARED.LEAVE_PAGE' | translate }}" buttonCloseText="{{ 'SHARED.OK' | translate }}" cancellable="true" (close)="onCancel();"></save-confirmation-component>
