<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons" *ngIf="isAddEnabled">
    <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
  </div>
  <br />
  <dx-data-grid #masterDataGrid
                id="printSection"
                [dataSource]="customDataSource"
                [rtlEnabled]="rtlEnabled"
                [showBorders]="true"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onRowClick)="edit($event)"
                (onInitialized)="initColumn($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-remote-operations [paging]="true"
                           [filtering]="true"
                           [sorting]="true"></dxo-remote-operations>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true"
               [showInfo]="true"
               [visible]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column type="buttons" [width]="80" [visible]="isEditAllowed() || isDeleteAllowed()">
      <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit" [visible]="isEditAllowed"></dxi-button>
      <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="delete" [visible]="isDeleteAllowed"></dxi-button>
    </dxi-column>

    <div *dxTemplate="let data of 'imageCellTemplate'" class="image-column">
      <img *ngIf="data.value" [src]="data.value + '-thumbnail'" (click)="showLargerImage(data.value);" />
    </div>
  </dx-data-grid>
</div>

<dx-popup [width]="'auto'" [height]="'auto'" [showTitle]="true" title="{{ 'SHARED.SHOW_LARGER_IMAGE' | translate }}" [dragEnabled]="false" [showCloseButton]="true" [(visible)]="popUpShowed" (onHiding)="closePopUp();">
  <div class="au-dx-popup-padder">
    <img (load)="openPopUp();" [src]="largeImageSrc" />
    </div>
</dx-popup>

<confirmation-component #confirmation title="{{ 'MASTER_DATA.DELETE_MASTER_DATA_ROW' | translate }}"
                        message="{{ 'MASTER_DATA.DELETE_MASTER_DATA_ROW_MESSAGE' | translate }}"
                        buttonCloseText="{{ 'SHARED.DELETE' | translate }}"
                        cancellable="true"
                        (close)="deleteSelected();"></confirmation-component>
<save-confirmation-component #saveConfirmation
                             title="{{ 'SHARED.LEAVE_PAGE' | translate }}"
                             buttonCloseText="{{ 'SHARED.OK' | translate }}"
                             cancellable="true"></save-confirmation-component>
<master-data-list-row-editor-component #details
                                       [languages]="languages"
                                       [masterDataListFields]="masterDataListFields"
                                       [masterDataRows]="masterDataRows"
                                       (onClosed)="manualRefresh()"></master-data-list-row-editor-component>
