import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Lot } from '../../../shared/models/lot';

import { Mask } from '../models/mask';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class MaskService {

  masks: Array<Mask> = [];

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getMasks(auctionClusterId: number, langCode: string): Observable<Array<Mask>> {
    return this.webApiService.getList(this.apiPath + `/${auctionClusterId}/mask?langCode=${langCode}`);
  }

   getMask(auctionClusterId: number, maskId: number): Observable<Mask> {
    const url = this.apiPath + '/' + auctionClusterId + '/mask/' + maskId;
    return this.webApiService.getList(url);
  }

  getMasksForPropertyType(auctionClusterId: number, productId: number, propertyTypeId: number, masterDataListId?: number) {
    let url = this.apiPath + '/' + auctionClusterId + '/mask/product/' + productId + '/propertytype/' + propertyTypeId;
    if (masterDataListId) {
      url += `?masterDataListId=${masterDataListId}`;
    }
    return this.webApiService.getList(url);
  }

  getMasksForPropertyId(auctionClusterId: number, productId: number, productPropertyId: number) {
    let url = this.apiPath + '/' + auctionClusterId + '/mask/product/' + productId + '/productproperty/' + productPropertyId;
    return this.webApiService.getList(url);
  }

  getMaskForPropertyTypeId(auctionClusterId: number, productPropertyId: number, lot: Lot): Observable<Array<Lot>> {
    const url = this.apiPath + '/' + auctionClusterId + '/mask/productproperty/' + productPropertyId;
    return this.webApiService.save(url, lot);
  }

  getSecondMaskForPropertyTypeId(auctionClusterId: number, productPropertyId: number, lot: Lot): Observable<Array<Lot>> {
    const url = this.apiPath + '/' + auctionClusterId + '/mask/productproperty/' + productPropertyId + '/second';
    return this.webApiService.save(url, lot);
  }

  save(mask: Mask): Observable<any> {
    const url = this.apiPath + '/' + mask.auctionClusterId + '/mask';
    return this.webApiService.save(url, mask);
  }

  edit(mask: Mask): Observable<any> {
    const url = this.apiPath + '/' + mask.auctionClusterId + '/mask';
    return this.webApiService.edit(url, mask.maskId, mask);
  }

  delete(maskId: number, auctionClusterId: number): Observable<any> {
    const url = this.apiPath + '/' + auctionClusterId + '/mask';
    return this.webApiService.delete(url, maskId);
  }
}
