export class LoginInfo {

  applicationCode: string;
  email: string;
  password: string;
  versionAdminUI: string;
  versionClockUI: string;
  mfaCode: number;
  isMfaCodeNeeded: boolean;
  isMfaSetupNeeded: boolean;
  lastMFALoginDateTime: string;

  constructor(applicationCode?: string) {
    this.applicationCode = applicationCode;
  }
}
