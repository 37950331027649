<div class="user-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup 
  #addUser 
  [width]="600"
  [height]="300"
  [showTitle]="false" 
  [dragEnabled]="false"
  [closeOnOutsideClick]="false" 
  [rtlEnabled]="rtlEnabled" 
  [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #termsForm
                 id="termsForm"
                 [showValidationSummary]="false"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">
          <dxi-item dataField="version"
                    editorType="dxNumberBox"
                    [editorOptions]="{
                showSpinButtons: true,
                mode: 'number',
                format: '#0',
                min: 0,
                step: 1}">
            <dxo-label text="{{ 'TERMS_AND_CONDITIONS.VERSION' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item>
            <dxo-label text="{{ 'TERMS_AND_CONDITIONS.FILE' | translate }}"></dxo-label>
            <div *dxTemplate>
                  <input type="file" id="uploadedfile" (change)="onFileChange($event)" #fileInput><br/>
                  <button type="button" class="btn btn-sm btn-default" (click)="clearFile()">{{ 'SHARED.CLEAR' | translate }}</button>
            </div>
          </dxi-item>

        </dx-form>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnTermsSubmit">
        {{ 'SHARED.SAVE' | translate }}
        </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
    </dx-popup>
</div>
