import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import { Observable } from 'rxjs';

// models
import { NotificationTypeEnum } from '../../../shared/enum/notificationTypeEnum';
import { ApplicationSettings } from '../../../shared/models/application-settings';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()

export class SupplierNotificationService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplier';
  }

  private supplierId: number;

  setId(id: number) {
    this.supplierId = id;
  }

  getAuctionClusterNotificationLastTimeSent(auctionClusterNotificationType: NotificationTypeEnum, auctionClusterId: number, catalogId: number): Observable<Date> {
    return;
  }

  sendNotifications(auctionId: number, auctionClusterNotificationType: NotificationTypeEnum, catalogId: number, clockId: number, senddate: string, timeZoneOffset: number)
  {
    return;
  }
}
