import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// interfaces
import { IAuctionClusterService } from '../../../shared/interfaces/auction-cluster';

// models
import { AuctionCluster } from '../../../shared/models/auction-cluster';
import { ApplicationSettings } from '../../../shared/models/application-settings';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class AuctionClusterService implements IAuctionClusterService {
  private apiPath: string;

  private auctionClusterId: number;

  setId(auctionClusterId: number)
  {
    this.auctionClusterId = auctionClusterId;
  }

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getAuctionCluster(auctionClusterId: number): Observable<AuctionCluster> {
    return this.webApiService.getSingle(this.apiPath + '/' + auctionClusterId + '/auctioncluster');
  }

  getAuctionClustersPerLevel(): Observable<Array<AuctionCluster>> {
    return this.webApiService.getList(this.apiPath+ '/' + this.auctionClusterId + '/auctioncluster').pipe(map(response => [response]));
  }
}
