import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Subject } from 'rxjs';

// components
import { SaveConfirmationComponent } from '../../../shared/components/save-confirmation/save-confirmation.component';

export interface DeactivateGuard {
  modelChanged: boolean;
  saveConfirmation: SaveConfirmationComponent;
}

@Injectable()
export class LeavePageService<T extends DeactivateGuard> implements CanDeactivate<T> {

  canDeactivate(component: T)  {
    if (component.modelChanged) {
      let subject = new Subject<boolean>();

      component.saveConfirmation.opened = true;
      subject = component.saveConfirmation.pageState;
      return subject.asObservable();
    }
    return true;
  }
}
