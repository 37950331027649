<div class="grouping-property-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <div class="modal-body">
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>
      <form #detailsForm="ngForm" clrForm>
        <div class="clr-form-control">
          <label for="productProperty" class="clr-control-label">{{ 'PRODUCT.PROPERTY_NAME' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="productProperty" name="productProperty" [(ngModel)]="model.productPropertyId" required>
                <option></option>
                <option *ngFor="let property of productProperties" [ngValue]="property.productPropertyId">{{property.productPropertyNameText}}</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
