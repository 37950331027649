import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrTabs } from '@clr/angular';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { AuctionCluster } from '../../shared/models/auction-cluster';
import { Language } from '../../shared/models/language';

import { BuyerProduct } from '../shared/models/buyer-product';
import { PrebidOnProduct } from '../shared/models/prebid';

// services
import { TranslateService } from '@ngx-translate/core';
import { ServiceFactory } from '../../shared/factory/service-factory';
import { LanguageService } from '../../shared/services/language.service';
import { WebApiService } from '../../shared/services/web-api.service';

import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { PrebidOnProductService } from '../shared/services/prebid-on-product.service';
import { ProductService } from '../shared/services/product.service';

@Component({
  selector: 'buyer-prebid-component',
  templateUrl: 'buyer-prebid.component.html',
  styleUrls: ['./buyer-prebid.component.scss']
})
export class BuyerPrebidComponent extends ItemDetailsComponent<PrebidOnProduct> implements OnInit {

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('prebidName') prebidName: TranslatableFieldComponent;
  @ViewChild('prebidNameDescription') prebidNameDescription: TranslatableFieldComponent;

  auctionClusterService: AuctionClusterService;
  productService: ProductService;

  buyerId: number;
  languages: Array<Language> = [];
  auctionClusters: Array<AuctionCluster> = [];
  products: Array<BuyerProduct> = [];

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private dataService: PrebidOnProductService,
    private languageService: LanguageService,
    private translateService: TranslateService,
  ) {
    super(injector);
    this.getServices(route, appSettings, webApiService);
  }

  ngOnInit() {
    this.model = new PrebidOnProduct();
    this.buyerId = +this.route.snapshot.params['id'];
  }

  getServices(route, appSettings, webApiService) {
    this.auctionClusterService = ServiceFactory.getAuctionClusterService(document.location.hash, route, appSettings, webApiService) as AuctionClusterService;
    this.productService = ServiceFactory.getProductService(document.location.hash, route, appSettings, webApiService) as ProductService;
  }

  open(prebids: Array<PrebidOnProduct>, prebidId: number, languages: Array<Language>) {

    // Autoselect first tab
    this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    this.allItems = prebids;
    this.languages = languages;

    this.auctionClusterService.getAuctionClustersPerLevelWithoutImage().subscribe(acs => {
      this.auctionClusters = acs;
      // this.auctionClusters = [ { auctionClusterId: 1, name: '{"en-GB":"Test A1","nl-BE":""}' }];
    });

    if (prebidId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getPrebid(this.buyerId, prebidId)
        .subscribe((res: PrebidOnProduct) => {
          this.model = res;
          this.isOpened = true;
          this.onAuctionClusterChanged();
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new PrebidOnProduct();
      this.isEditMode = false;
      this.isOpened = true;

      this.createEmtpyObjectForTranslatableFields();
    }
  }

  onAuctionClusterChanged() {
    if (this.model.auctionClusterId) {
      this.productService.getProductsClocks(this.translateService.currentLang).subscribe(ps => {
        this.products = ps;
      });
    }
  }

  createEmtpyObjectForTranslatableFields() {
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    this.model.name = this.model.description = JSON.stringify(emptyTranslation);
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {

    this.model.name = this.prebidName.data;
    this.model.description = this.prebidNameDescription.data;

    if (this.model.catalogId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.buyerId, this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.model = new PrebidOnProduct();
          this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.save(this.buyerId, this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.model = new PrebidOnProduct();
          this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    }
  }

  saveWithoutClose() {
    this.model.name = this.prebidName.data;
    this.model.description = this.prebidNameDescription.data;

    if (this.model.catalogId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.buyerId, this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.save(this.buyerId, this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    }
    if (this.errorMessage != null) {
      this.errorService.show(this.errorMessage);
    }
  }

  public onCancel() {
    this.prebidName.resetLanguageButtons();
    this.prebidNameDescription.resetLanguageButtons();
    super.onCancel();
  }
}
