import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../models/application-settings';
import { MFAType } from '../models/mfaType';

// services
import { AuthService } from '../services/auth.service';
import { WebApiService } from '../services/web-api.service';


@Injectable()
export class MFAService {

  private adminApiPath: string;
  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private authService: AuthService, private webApiService: WebApiService) {
    this.adminApiPath = this.appSettings.adminApi;
    this.apiPath = this.appSettings.adminApi + 'mfatype';
  }

  getMFATypes(): Observable<Array<MFAType>> {
    return this.webApiService.getList(this.apiPath);
  }
}
