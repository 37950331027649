import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

// services
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from '../../shared/services/error.service';
import { LanguageService } from '../../shared/services/language.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { TitleService } from '../../shared/services/title.service';
import { UserService } from '../../shared/services/user.service';

// models
import { Language } from '../../shared/models/language';
import { LanguagePreferenceService } from '../../shared/services/language-preference.service';
import { User } from '../../shared/models/user';

@Component({
  selector: 'register-profile-component',
  templateUrl: './register-profile.component.html',
  styleUrls: ['./register-profile.component.scss']
})
export class RegisterProfileComponent implements OnInit {

  model: User;
  isOpened: boolean;
  message: string;
  languages: Array<Language> = [];

  constructor(
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private titleService: TitleService,
    private errorService: ErrorService,
    private spinner: SpinnerService,
    private languageService: LanguageService,
    private languagePreferenceService: LanguagePreferenceService,
  ) { }

  ngOnInit() {
    this.model = new User();
    this.titleService.set('REGISTER.TITLE');
    this.spinner.show();
    forkJoin(
      this.languageService.getLanguages(),
      this.userService.getCurrentUser()
    ).subscribe(
      results => {
        this.languages = results[0];
        this.model = results[1];
        var language = this.languageService.getCurrentLanguage(this.languages);
        this.model.languageId = language.languageId;

        this.isOpened = true;
        this.spinner.hide();
      },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      }
    );
  }

  registerProfile() {
    this.userService.edit(this.model)
      .subscribe(result => {
        if (result) {
          this.languagePreferenceService.reload();
          // edit successful
          this.router.navigate(['/core/register-assign-account/'], { queryParamsHandling: 'merge' });
        } else {
          // edit failed
          this.translate.get('LOG_IN.INCORRECT_USERNAME_OR_PASSWORD').subscribe((res: string) => {
            this.message = res;
          });
        }
      }, error => {
        this.translate.get('LOG_IN.INCORRECT_USERNAME_OR_PASSWORD').subscribe((res: string) => {
          this.message = res;
        });
      });
  }
}
