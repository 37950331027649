import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { AuctionClusterBuyer, BuyerSimple, BuyerWithSubbuyers } from '../models/auction-cluster-buyer';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class AuctionClusterBuyerService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getBuyers(auctionClusterId: number): Observable<Array<AuctionClusterBuyer>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/buyer');
  }

  getBuyersSimple(auctionClusterId: number): Observable<Array<BuyerSimple>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/buyer/simple');
  }

  getBuyersPaged(auctionClusterId: number, loadOptions: any)
  {
    const url = this.apiPath + '/' + auctionClusterId + '/buyer/paged';
    let params: HttpParams = new HttpParams();
                [
                    "skip",
                    "take",
                    "requireTotalCount",
                    "requireGroupCount",
                    "sort",
                    "filter",
                    "totalSummary",
                    "group",
                    "groupSummary"
                ].forEach(function(i) {
                    if (i in loadOptions && loadOptions[i] != undefined)
                        params = params.set(i, JSON.stringify(loadOptions[i]));
                });
      let result = this.webApiService.getWithParams(url, params);
      return result;
  }

  getBuyer(auctionClusterId: number, buyerId: number): Observable<AuctionClusterBuyer> {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/buyer', buyerId);
  }

  getSubbuyers(buyers: Array<number>, auctionClusterId: number): Observable<Array<BuyerWithSubbuyers>> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/buyer/buyersubbuyers`, buyers);
  }

  getByVat(auctionClusterId: number, vat: string): Observable<Array<AuctionClusterBuyer>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/buyer/vat/' + vat);
  }

  save(auctionClusterId: number, buyer: AuctionClusterBuyer): Observable<AuctionClusterBuyer> {
    return this.webApiService.save<AuctionClusterBuyer>(this.apiPath + '/' + auctionClusterId + '/buyer', buyer);
  }

  edit(auctionClusterId: number, buyer: AuctionClusterBuyer): Observable<any> {
    return this.webApiService.edit<AuctionClusterBuyer>(this.apiPath + '/' + auctionClusterId + '/buyer', buyer.buyerId, buyer);
  }

  delete(auctionClusterId: number, buyerId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/' + auctionClusterId + '/buyer', buyerId);
  }

  enableDisable(auctionClusterId: number, buyer: AuctionClusterBuyer): Observable<AuctionClusterBuyer> {
    return this.webApiService.editSingle(this.apiPath + '/' + auctionClusterId + '/buyer/' + buyer.buyerId + (buyer.isActiveOnAuctionCluster ? '/disable' : '/enable'), null); // tslint:disable-line:max-line-length
  }

  checkVat(auctionClusterId: number, vat: string): Observable<boolean> {
    return this.webApiService.getSingle(this.apiPath + '/' + auctionClusterId + '/buyer/vat/' + vat + '/check'); // tslint:disable-line:max-line-length
  }
}
