import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { bugsnagClient } from '../../app.module';

// services
import { LanguageService } from '../services/language.service';

@Injectable()
export class ErrorService {
  translations: any;

  constructor(private alert: AlertService,
    public translate: TranslateService,
    public languageService: LanguageService) {
    this.translate.get('ERRORS').subscribe((res: string) => {
      this.translations = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('ERRORS').subscribe((res: string) => {
        this.translations = res;
      });
    });
   }

  public show(error: any) {
    const errorMessage = this.getErrorMessage(error);
    this.alert.alert(errorMessage);

    if (bugsnagClient) {
      bugsnagClient.notify(errorMessage);
    }
  }

  public toString(error: Response) {
    return this.getErrorMessage(error);
  }

  public getErrorMessage(response: any): string {
    if (typeof response === 'string' && response !== '') {
      return response;
    }

    if (response && response.status !== 200) { // tslint:disable-line:no-magic-numbers
      const error = response as HttpErrorResponse;
      if (error.error && error.error.length > 0) {
        if (Array.isArray(error.error)) {
          return this.getTranslation(error.error[0].ErrorMessage);
        }
        else {
          return this.getTranslation(error.error);
        }
      } else if (error.error && (error.error.message || error.error.Message)) {
        if (error.error.message) {
          return this.getTranslation(error.error.message);
        }
        else {
          return this.getTranslation(error.error.Message);
        }
      } else {
        return this.translations.GENERIC_ERROR;
      }
    }
  }

  private getTranslation(message: any) {
    if (this.translations[message]) {
      return this.translations[message];
    }
    else {
      return this.languageService.getTranslatableText(message);
    }
  }
}
