export class BuyerLevelBuyer {
  buyerId: number;
  name: string;
}

export enum ClockOpenType {
  Buy = 1,
  View = 2,
  Projection = 3,
  Any = 4,
  PriceOverview = 5
}

export class BuyerLevelClocks {
  auctionClusterId: number;
  buyerLevelBuyerWithClocks: Array<BuyerLevelBuyerClock> = [];
}

export class BuyerLevelBuyerClock {
  buyerId: number;
  clocks: Array<BuyerClock> = [];

  // displaying fields
  buyerName: string;
}

export class BuyerClock {
  clockId: number;
  catalogId: number;
  auctionId: number;
  auctionClusterId: number;
  name: string;
  description: string;
  layoutData: string;

  // displayable fields
  auctionName: string;
  catalogName: string;
  auctionClusterName: string;
}

export class BuyerClockOpen {
  buyerId: number;
  buyerName: string;
  clockId: number;
  clockName: string;
  isSelected: boolean;

  // displayable fields
  displayName: string;
}

export class BuyerLevelCatalog {
  catalogId: number;
  name: string;

  constructor(catalogId: number, name: string) {
    this.catalogId = catalogId;
    this.name = name;
  }
}

