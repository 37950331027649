<div class="item-list-component shopping-list-lot-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #addShoppingLotList
  [width]="600"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">

        <dx-form #shoppingLotForm
                 id="shoppingLotForm"
                 [showValidationSummary]="false"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">
          <div *dxTemplate="let data of 'translatableField'">
            <translatable-field [isDevExpress]="true" [isLabelHidden]="true" [caption]="''" [(data)]="data.component.option('formData')[data.dataField]"></translatable-field>
          </div>
          <div *dxTemplate="let data of 'fullDateTemplate'">
            <full-date-time-component [data]="dateValue(data)" (modelChanged)="dateChanged(data, $event)"></full-date-time-component>
          </div>
          <div *dxTemplate="let data of 'longDateTemplate'">
            <long-date-component [data]="dateValue(data)" (modelChanged)="dateChanged(data, $event)"></long-date-component>
          </div>
          <div *dxTemplate="let data of 'longTimeTemplate'">
            <long-time-component [data]="dateValue(data)" (modelChanged)="dateChanged(data, $event)"></long-time-component>
          </div>
          <div *dxTemplate="let data of 'shortDateTemplate'">
            <short-date-component [data]="dateValue(data)" (modelChanged)="dateChanged(data, $event)"></short-date-component>
          </div>
          <div *dxTemplate="let data of 'shortTimeTemplate'">
            <short-time-component [data]="dateValue(data)" (modelChanged)="dateChanged(data, $event)"></short-time-component>
          </div>
          <div *dxTemplate="let data of 'masterdataTemplate'">
            <masterdata-dropdown [auctionClusterId]="shoppingList.auctionClusterId"
                                 [listId]="data.name"
                                 [(selectedRowId)]="data.component.option('formData')[data.dataField]"
                                 [filteredKeys]="masterDataDropdownsFiltered[data.name]"
                                 (onValueChanged)="onMasterDataValueChanged($event, data, field)">
            </masterdata-dropdown>
          </div>
        </dx-form>
        <br />
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnShoppingLotListSubmit">
          {{ 'SAVE' | translate }}
        </button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
