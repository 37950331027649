import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// interfaces
import { IMasterDataService } from '../../../shared/interfaces/master-data';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { MasterData } from '../../../shared/models/master-data';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class MasterDataService implements IMasterDataService {

  private apiPath: string;
  private buyerId: number;
  cachedLabels = [];

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  setId(id: number) {
    this.buyerId = id;
  }

  //IMasterDataService

  getMasterDataForReports(auctionClusterId: number, masterDataId: number, includeRows: boolean): Observable<MasterData> {
    var query = `${this.apiPath}/${this.buyerId}/auctioncluster/${auctionClusterId}/masterdatalist/${masterDataId}`;
    if (!includeRows) {
      query += '/withoutrows';
    }
    return this.webApiService.getSingle(query);
  }

  getMasterDataValueForDropdown(masterDataListId: number,
    auctionClusterId: number,
    language: string,
    key: number) {
    if (!this.cachedLabels[key]) {
      var url = `${this.apiPath}/${this.buyerId}/auctioncluster/${auctionClusterId}/masterdatalist/${masterDataListId}/dropdown?language=${language}&keys=${key}`;

      let result = this.webApiService.getSingle(url);
      result.subscribe(l => this.cachedLabels[key] = l.data);
      return result;
    }
    else {
      // return cached data as observable
      return of(
        {
          data: this.cachedLabels[key]
        });
    }
  }

  getMasterDataListByIdsTouchOnly(masterDataListIds: Array<number>, auctionClusterId: number): Observable<Array<MasterData>> {
    return;
  }
}
