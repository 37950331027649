<div class="external-platform-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-form
  #configurationParameter
  id="configurationParameter"
  [showValidationSummary]="false"
  (onFieldDataChanged)="onFieldDataChanged($event)"
  colCount="auto"
  [(formData)]="model"
  [minColWidth]="500">
  <dxi-item itemType="group">
    <dxi-item dataField="expirationPeriodMFA">
      <dxo-label text="{{ 'CONFIGURATION_PARAMETERS.EXPIRATION_PERIOD_MFA' | translate }}"></dxo-label>
      <dxi-validation-rule type="pattern" pattern="^-?\d+$" message="{{ 'CONFIGURATION_PARAMETERS.EXPIRATION_PERIOD_MFA_VALIDATION' | translate }}"></dxi-validation-rule>
      <dxi-validation-rule type="required" message="{{ 'CONFIGURATION_PARAMETERS.EXPIRATION_PERIOD_MFA_REQUIRED' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="platformUrl">
      <dxo-label text="{{ 'CONFIGURATION_PARAMETERS.PLATFORM_URL' | translate }}"></dxo-label>
      <dxi-validation-rule type="required" message="{{ 'CONFIGURATION_PARAMETERS.PLATFORM_URL' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
    </dxi-item>
    <dxi-item
      dataField="typeOfEnvironment"
      editorType="dxSelectBox"
      [editorOptions]="{ items: typeOfEnvironment, valueExpr:'value' ,
      displayExpr: 'name', searchEnabled: 'true', showClearButton: 'true'}">
      <dxo-label text="{{ 'CONFIGURATION_PARAMETERS.TYPE_OF_ENVIRONMENT' | translate }}"></dxo-label>
      <dxi-validation-rule type="required" message="{{ 'CONFIGURATION_PARAMETERS.TYPE_OF_ENVIRONMENT' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
    </dxi-item>
    <dxi-item
      dataField="typeOfPlatform"
      editorType="dxSelectBox"
      [editorOptions]="{ items: typeOfPlatform, valueExpr:'value' ,
      displayExpr: 'name', searchEnabled: 'true', showClearButton: 'true'}">
      <dxo-label text="{{ 'CONFIGURATION_PARAMETERS.TYPE_OF_PLATFORM' | translate }}"></dxo-label>
      <dxi-validation-rule type="required" message="{{ 'CONFIGURATION_PARAMETERS.TYPE_OF_PLATFORM' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
    </dxi-item>
    <dxi-item 
      dataField="rabbitMqUrl">
      <dxo-label text="{{ 'CONFIGURATION_PARAMETERS.RABBITMQ_URL' | translate }}"></dxo-label>
    </dxi-item>
  </dxi-item>
  </dx-form>
  <br/>
  <button type="submit" class="btn btn-primary" (click)="save();" name="btnConfigurationParameterSubmit" disabled="disabled">
    {{ 'SHARED.SAVE' | translate }}
  </button>
</div>
