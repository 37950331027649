<dx-date-box
  type="datetime"
  displayFormat="dd/MM/yyyy, HH:mm"
  #dateInput
  [attr.name]="nameString"
  [attr.tabindex]="tabIndexValue"
  placeholder="Date Time"
  class="focusable"
  [(value)]="time"
  (ngModelChange)="changeModelState()"
  (blur)="onblurEvent()"
  [required]="requiredValue"
  [readonly]="disabledValue"
  [min]="minValue"
  openOnFieldClick="true"
  (onFocusOut)="onblurEvent()"
  (onFocusIn)="onFocusIn()"
  (onInitialized)="onInitialized.emit($event)"           
  (onClosed)="pickerClosed()"
  [disabled]="disabledValue">
</dx-date-box>
