<div class="product-property-styling-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #productPropertyForm
  [width]="600"
  [height]="500"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #userForm
                 id="userForm"
                 [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">
          <dxi-item itemType="group">
            <dxi-item dataField="name">
              <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="sysName">
              <dxo-label text="{{ 'SHARED.SYSNAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.SYSNAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="font">
              <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.FONT' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="fontSize"
                      editorType="dxNumberBox"
                      [editorOptions]="{
                  showSpinButtons: true,
                  mode: 'number',
                  format: '#0',
                  min: 0,
                  step: 1}">
              <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.FONT_SIZE' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="color"
                      editorType="dxColorBox"
                      [editorOptions]="{
                    applyValueMode: 'instantly'}">
              <dxo-label text="{{ 'SHARED.COLOR' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="backgroundColor"
                      editorType="dxColorBox"
                      [editorOptions]="{
                    applyValueMode: 'instantly'}">
              <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.BACKGROUND_COLOR' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="borderColor"
                      editorType="dxColorBox"
                      [editorOptions]="{
                    applyValueMode: 'instantly'}">
              <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.BORDER_COLOR' | translate }}"></dxo-label>
            </dxi-item>
          </dxi-item>
        </dx-form>
        <br />
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnProductPropertyStylingSubmit" disabled="disabled">
          {{ 'SHARED.SAVE' | translate }}
        </button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
