<div class="current-lot-property-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false" [clrModalSize]="editorProperty ? 'xl' : ''">
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <div class="modal-body">
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>

      <form #detailsForm="ngForm" clrForm>
        <section class="columns">
          <div class="column">
            <div class="clr-form-control">
              <label class="clr-control-label">{{ 'NAME' | translate }}</label>
              <div class="clr-control-container">
                <translatable-field [(data)]="model.name" [name]="'name' + model.productPropertyId" [clusterLanguages]="languages" #name></translatable-field>
              </div>
            </div>

            <div class="clr-form-control">
              <label for="productProperty" class="clr-control-label">{{ 'PRODUCT.PROPERTY_NAME' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="productProperty" name="productProperty" (change)="getMasks(); setName();" [(ngModel)]="model.productPropertyId" (ngModelChange)="filterMasterDataFields(); restartMasterDataField();">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let property of productProperties" [ngValue]="property.productPropertyId">{{property.productPropertyNameText}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="clr-form-control" *ngIf="isMasterData && !editorProperty">
              <label for="masterDataField" class="clr-control-label">{{ 'SHARED.MASTER_DATA_FIELD' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="masterDataField" name="masterDataField" (change)="getMasks()" [(ngModel)]="model.masterDataListFieldId" required>
                    <option [ngValue]="null"></option>
                    <option *ngFor="let field of masterDataListFields" [ngValue]="field.masterDataListFieldId">{{ field.name | translateJson }}</option>
                  </select>
                </div>
              </div>
            </div>            

            <div class="clr-form-control" *ngIf="!lotInPreparationProperties">
              <label for="propertyGroup" class="clr-control-label">{{ 'SHARED.GROUP' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="propertyGroup" name="propertyGroup" [(ngModel)]="model.productPropertyGroupId" required>
                    <option [ngValue]="null"></option>
                    <option *ngFor="let group of propertyGroups" [ngValue]="group.productPropertyGroupId">{{ group.name | translateJson }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="clr-form-control" *ngIf="!lotInPreparationProperties" [ngClass]="{ 'clr-error': rowNumber.dirty && rowNumber.invalid }">
              <label for="clockBottomMargin" class="clr-control-label">{{ 'PRODUCT.PROPERTY.ROW_NUMBER' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-input-wrapper">
                  <input id="rowNumber" type="number" name="rowNumber" #rowNumber="ngModel" class="clr-input" [(ngModel)]="model.rowNumber" required />
                </div>
                <clr-control-error *ngIf="rowNumber.dirty && rowNumber.invalid">{{ 'PRODUCT.PROPERTY.ROW_NUMBER' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}</clr-control-error>
              </div>
            </div>

            <div class="clr-form-control" *ngIf="!lotInPreparationProperties" [ngClass]="{ 'clr-error': columnNumber.dirty && columnNumber.invalid }">
              <label for="columnNumber" class="clr-control-label">{{ 'PRODUCT.PROPERTY.COLUMN_NUMBER' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-input-wrapper">
                  <input id="columnNumber" type="number" name="columnNumber" #columnNumber="ngModel" class="clr-input" [(ngModel)]="model.columnNumber" required />
                </div>
                <clr-control-error *ngIf="columnNumber.dirty && columnNumber.invalid">{{ 'PRODUCT.PROPERTY.COLUMN_NUMBER' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}</clr-control-error>
              </div>
            </div>

            <div class="clr-form-control" *ngIf="showWidth || !lotInPreparationProperties">
              <label for="width" class="clr-control-label">{{ 'PRODUCT.PROPERTY.WIDTH_PERCENTAGE' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-input-wrapper">
                  <input id="width" type="number" name="width" #width="ngModel" class="clr-input" [(ngModel)]="model.width" min="0" max="100" />
                </div>
              </div>
            </div>

            <div class="clr-form-control" *ngIf="propertyGroupingShowing">
              <label for="automaticGroupingProperty" class="clr-control-label">{{ 'PRODUCT.PROPERTY.AUTOMATIC_GROUPING_PROPERTY' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="automaticGroupingProperty" name="automaticGroupingProperty" [(ngModel)]="model.automaticProductPropertyGroupingSelectionId">
                    <option></option>
                    <option *ngFor="let prop of automaticGroupingProperties" [ngValue]="prop.id">{{ prop.label }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="clr-form-control" *ngIf="propertyGroupingShowing">
              <label for="manualGroupingProperty" class="clr-control-label">{{ 'PRODUCT.PROPERTY.MANUAL_GROUPING_PROPERTY' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="manualGroupingProperty" name="manualGroupingProperty" [(ngModel)]="model.manualProductPropertyGroupingSelectionId">
                    <option></option>
                    <option *ngFor="let prop of manualGroupingProperties" [ngValue]="prop.id">{{ prop.label }}</option>
                  </select>
                </div>
              </div>
            </div>

            <ng-container *ngIf="editorProperty">
              <div class="clr-form-control">
                <label for="isVisible" class="clr-control-label">{{ 'PRODUCT.PROPERTY.VISIBLE' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-checkbox-wrapper">
                    <input id="isVisible" type="checkbox" clrCheckbox name="isVisible" [(ngModel)]="model.productPropertyEditorSelectionDetails.isVisible" />
                  </div>
                </div>
              </div>

              <div class="clr-form-control">
                <label for="isReadOnly" class="clr-control-label">{{ 'PRODUCT.PROPERTY.READ_ONLY' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-checkbox-wrapper">
                    <input id="isReadOnly" type="checkbox" clrCheckbox name="isReadOnly" [(ngModel)]="model.productPropertyEditorSelectionDetails.isReadOnly" />
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="isMasterData && !lotInPreparationProperties">
                <label for="searchByStartsWith" class="clr-control-label">{{ 'PRODUCT.PROPERTY.SEARCH_STARTS_WITH' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-checkbox-wrapper">
                    <input id="searchByStartsWith" type="checkbox" clrCheckbox name="searchByStartsWith" [(ngModel)]="model.productPropertyEditorSelectionDetails.searchByStartsWith" />
                  </div>
                </div>
              </div>

              <div class="clr-form-control">
                <label for="required" class="clr-control-label">{{ 'SHARED.REQUIRED' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-checkbox-wrapper">
                    <input id="required" type="checkbox" clrCheckbox name="required" [(ngModel)]="model.productPropertyEditorSelectionDetails.required" />
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="!lotInPreparationProperties">
                <label for="activeInMultiEdit" class="clr-control-label">{{ 'PRODUCT.PROPERTY.ACTIVE_IN_MULTIEDIT' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-checkbox-wrapper">
                    <input id="activeInMultiEdit" type="checkbox" clrCheckbox name="activeInMultiEdit" [(ngModel)]="model.productPropertyEditorSelectionDetails.activeInMultiEdit" />
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="!lotInPreparationProperties">
                <label for="focusEditBox" class="clr-control-label">{{ 'PRODUCT.PROPERTY.FOCUS_EDIT_BOX' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-checkbox-wrapper">
                    <input id="focusEditBox" type="checkbox" clrCheckbox name="focusEditBox" [(ngModel)]="model.productPropertyEditorSelectionDetails.focusEditBox" />
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="lotInPreparationProperties">
                <label for="shortkeyLabel" class="clr-control-label">{{ 'PRODUCT.PROPERTY.SHORTKEY_LABEL' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-input-wrapper">
                    <input type="text" name="shortkeyLabel" id="shortkeyLabel" class="clr-input" [(ngModel)]="model.productPropertyEditorSelectionDetails.shortkeyLabel" />
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="lotInPreparationProperties">
                <label for="clockBottomMargin" class="clr-control-label">{{ 'PRODUCT.PROPERTY.SHORTKEY' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-input-wrapper">
                    <input type="text" name="shortkey" id="shortkey" readonly="readonly" class="clr-input" [(ngModel)]="shortkey" />
                    <clr-icon class="clear-shortkey-button" shape="times-circle" size="16" (click)="clearShorkey()"></clr-icon>
                    <clr-icon class="edit-btn key-editor-button" shape="edit" size="16" (click)="manageKeyEditorPopup(true)"></clr-icon>
                  </div>
                </div>
              </div>

              <div class="modal modal-key-editor" tabindex="-1" role="dialog" id="key-bindings-edit-dialog" *ngIf="isKeyEditorOpened">
                <div class="modal-dialog modal-sm" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button aria-label="Close" class="close" type="button">
                        <clr-icon aria-hidden="true" size="20" shape="close" (click)="manageKeyEditorPopup(false)"></clr-icon>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="text-center key-editor-text">
                        <h3>{{ 'PRODUCT.PROPERTY.PRESS_ANY_KEY' | translate }}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="lotInPreparationProperties">
                <label for="nextFocusedFieldId" class="clr-control-label">{{ 'PRODUCT.PROPERTY.NEXT_FOCUSED_FIELD' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-select-wrapper">
                    <ng-select id="nextFocusedFieldId"
                               name="nextFocusedFieldId"
                               [items]="allItems"
                               [closeOnSelect]="true"
                               [searchable]="true"
                               bindLabel="productPropertySelectionName"
                               bindValue="productPropertySelectionId"
                               [(ngModel)]="model.productPropertyEditorSelectionDetails.nextFocusedFieldId">
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="clr-form-control">
                <label for="dependencyProperties" class="clr-control-label">{{ 'PRODUCT.PROPERTY.FIELD_DEPENDENCY' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-select-wrapper">
                    <ng-select id="dependencyProperties"
                               name="dependencyProperties"
                               [items]="productProperties"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindLabel="productPropertyNameText"
                               bindValue="productPropertyId"
                               [(ngModel)]="model.productPropertyEditorSelectionDetails.dependencyProperties">
                    </ng-select>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="column">
            <ng-container *ngIf="editorProperty">
              <div class="clr-form-control" *ngIf="!lotInPreparationProperties">
                <label for="actionOnNewLotOrChangedDepedencyProperty" class="clr-control-label">{{ 'PRODUCT.PROPERTY.ACTION_ON_NEW_LOT' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-select-wrapper">
                    <select id="actionOnNewLotOrChangedDepedencyProperty" name="actionOnNewLotOrChangedDepedencyProperty" [(ngModel)]="model.productPropertyEditorSelectionDetails.actionOnNewLotOrChangedDepedencyProperty" required>
                      <option *ngFor="let action of actionsOnNewLot" [ngValue]="action.value">{{ action.name | translate }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="lotInPreparationProperties">
                <label for="actionOnNewLotOrChangedDepedencyProperty" class="clr-control-label">{{ 'PRODUCT.PROPERTY.ACTION_ON_LOT' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-select-wrapper">
                    <select id="actionOnNewLotOrChangedDepedencyProperty" name="actionOnNewLotOrChangedDepedencyProperty" [(ngModel)]="model.productPropertyEditorSelectionDetails.actionOnNewLotOrChangedDepedencyProperty" required>
                      <option *ngFor="let action of actionsOnLot" [ngValue]="action.value">{{ action.name | translate }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="model.productPropertyEditorSelectionDetails.actionOnNewLotOrChangedDepedencyProperty === 3">
                <label for="clearAfterLastValueFromList" class="clr-control-label">{{ 'PRODUCT.PROPERTY.CLEAR_AFTER_LAST_VALUE' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-checkbox-wrapper">
                    <input id="clearAfterLastValueFromList" type="checkbox" clrCheckbox name="clearAfterLastValueFromList" [(ngModel)]="model.productPropertyEditorSelectionDetails.clearAfterLastValueFromList" />
                  </div>
                </div>
              </div>

              <ng-container *ngIf="model.productPropertyEditorSelectionDetails.actionOnNewLotOrChangedDepedencyProperty === 4">
                <div class="clr-form-control" *ngIf="!lotInPreparationProperties">
                  <label for="retrieveFromMask" class="clr-control-label">{{ 'PRODUCT.PROPERTY.RETRIEVE_FROM_MASK' | translate }}</label>
                  <div class="clr-control-container">
                    <div class="clr-checkbox-wrapper">
                      <input id="retrieveFromMask" type="checkbox" clrCheckbox name="retrieveFromMask" [(ngModel)]="model.productPropertyEditorSelectionDetails.retrieveFromMask" />
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="model.productPropertyEditorSelectionDetails.retrieveFromMask">
                  <div class="clr-form-control">
                    <label for="maskId" class="clr-control-label">{{ 'PRODUCT.PROPERTY.MASK' | translate }}</label>
                    <div class="clr-control-container">
                      <div class="clr-select-wrapper">
                        <select id="maskId" name="maskId" [(ngModel)]="model.productPropertyEditorSelectionDetails.maskId">
                          <option [ngValue]="null"></option>
                          <option *ngFor="let m of masks" [ngValue]="m.maskId">{{ getTranslation(m.name) }}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="clr-form-control" *ngIf="model.productPropertyEditorSelectionDetails.maskId">
                    <label for="effectOnMaskData" class="clr-control-label">{{ 'PRODUCT.PROPERTY.MASK_EFFECT_ON_DATA' | translate }}</label>
                    <div class="clr-control-container">
                      <div class="clr-select-wrapper">
                        <select id="effectOnMaskData" name="effectOnMaskData" [(ngModel)]="model.productPropertyEditorSelectionDetails.effectOnMaskData">
                          <option [ngValue]="null"></option>
                          <option *ngFor="let a of maskEffects" [ngValue]="a.value">{{ a.name | translate }}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="clr-form-control">
                    <label for="secondMaskId" class="clr-control-label">{{ 'PRODUCT.PROPERTY.SECOND_MASK' | translate }}</label>
                    <div class="clr-control-container">
                      <div class="clr-select-wrapper">
                        <select id="secondMaskId" name="secondMaskId" [(ngModel)]="model.productPropertyEditorSelectionDetails.secondMaskId">
                          <option [ngValue]="null"></option>
                          <option *ngFor="let m of masks" [ngValue]="m.maskId">{{ getTranslation(m.name) }}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="clr-form-control" *ngIf="model.productPropertyEditorSelectionDetails.secondMaskId">
                    <label for="effectOnSecondMaskData" class="clr-control-label">{{ 'PRODUCT.PROPERTY.SECOND_MASK_EFFECT_ON_DATA' | translate }}</label>
                    <div class="clr-control-container">
                      <div class="clr-select-wrapper">
                        <select id="effectOnSecondMaskData" name="effectOnSecondMaskData" [(ngModel)]="model.productPropertyEditorSelectionDetails.effectOnSecondMaskData">
                          <option [ngValue]="null"></option>
                          <option *ngFor="let a of maskEffects" [ngValue]="a.value">{{ a.name | translate }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div class="clr-form-control">
                  <label for="retrieveDefaultValue" class="clr-control-label">{{ 'PRODUCT.PROPERTY.RETRIEVE_DEFAULT_VALUE' | translate }}</label>
                  <div class="clr-control-container">
                    <div class="clr-checkbox-wrapper">
                      <input id="retrieveDefaultValue" type="checkbox" clrCheckbox name="retrieveDefaultValue" [(ngModel)]="model.productPropertyEditorSelectionDetails.retrieveDefaultValue" />
                    </div>
                  </div>
                </div>

                <div class="clr-form-control">
                  <label for="clearIfNone" class="clr-control-label">{{ 'PRODUCT.PROPERTY.CLEAR_IF_NONE' | translate }}</label>
                  <div class="clr-control-container">
                    <div class="clr-checkbox-wrapper">
                      <input id="clearIfNone" type="checkbox" clrCheckbox name="clearIfNone" [(ngModel)]="model.productPropertyEditorSelectionDetails.clearIfNone" />
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="clr-form-control" *ngIf="model && model.productPropertyEditorSelectionDetails &&
                                                  model.productPropertyEditorSelectionDetails.dependencyProperties &&
                                                  model.productPropertyEditorSelectionDetails.dependencyProperties.length !== 0 &&
                                                  !lotInPreparationProperties">
                <label for="allowMultipleValuesWithinFieldDependency" class="clr-control-label">{{ 'PRODUCT.PROPERTY.ALLOW_MULTIPLE_VALUES' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-select-wrapper">
                    <select id="allowMultipleValuesWithinFieldDependency" name="allowMultipleValuesWithinFieldDependency" [(ngModel)]="model.productPropertyEditorSelectionDetails.allowMultipleValuesWithinFieldDependency">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let a of allowMultilpleValuesWithingFieldDependency" [ngValue]="a.value">{{ a.name | translate }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="!lotInPreparationProperties">
                <label for="focusType" class="clr-control-label">{{ 'PRODUCT.PROPERTY.FOCUS' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-select-wrapper">
                    <select id="focusType" name="focusType" [(ngModel)]="model.productPropertyEditorSelectionDetails.focusType" required>
                      <option *ngFor="let focus of focusTypes" [ngValue]="focus.value">{{ focus.name | translate }}</option>
                    </select>
                  </div>
                </div>
              </div>
              
              <div class="clr-form-control" *ngIf="isDecimalProperty">
                <label for="useWeighingScale" class="clr-control-label">{{ 'PRODUCT.PROPERTY.USE_WEIGHING_SCALE' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-checkbox-wrapper">
                    <input id="useWeighingScale" type="checkbox" clrCheckbox name="useWeighingScale" [(ngModel)]="model.productPropertyEditorSelectionDetails.useWeighingScale" />
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="model.productPropertyEditorSelectionDetails.useWeighingScale">
                <label for="weighingScaleUrl" class="clr-control-label">{{ 'PRODUCT.PROPERTY.WEIGHING_SCALE_URL' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-input-wrapper">
                    <input type="text" id="weighingScaleUrl" name="weighingScaleUrl" class="clr-input" [(ngModel)]="model.productPropertyEditorSelectionDetails.weighingScaleUrl" />
                  </div>
                </div>
              </div>

              <div class="clr-form-control" *ngIf="model.productPropertyEditorSelectionDetails.useWeighingScale">
                <label for="useWeighingScaleSimulator" class="clr-control-label">{{ 'PRODUCT.PROPERTY.USE_WEIGHING_SCALE_SIMULATOR' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-checkbox-wrapper">
                    <input id="useWeighingScaleSimulator" type="checkbox" clrCheckbox name="useWeighingScaleSimulator" [(ngModel)]="model.productPropertyEditorSelectionDetails.useWeighingScaleSimulator" />
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </section>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
