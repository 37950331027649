<div class="calculations-field-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup 
    #editCalculationField 
    [width]="600" 
    height="450px"
    [showTitle]="true" 
    title="{{ modalTitle }}" 
    [dragEnabled]="false"
    [closeOnOutsideClick]="false" 
    [rtlEnabled]="rtlEnabled" 
    [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #CalculationFieldForm
                 id="masterDataFieldForm"
                 [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">
          <dxi-item dataField="productPropertyId"
                    editorType="dxSelectBox"
                    [editorOptions]="{ items: productProperties, valueExpr:'productPropertyId', displayExpr:'name', onValueChanged: onMasterDataChanged}">
            <dxo-label text="{{ 'PRODUCT.PROPERTY_NAME' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'PRODUCT.PROPERTY_NAME' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item [template]="'masterDataTemplate'"
                    *ngIf="isMasterData"
                    dataField="masterDataListFieldId">
            <dxo-label text="{{ 'SHARED.MASTER_DATA_FIELD' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="isResetProperty" editorType="dxCheckBox">
            <dxo-label text="{{ 'PRODUCT.RESET_PROPERTY' | translate }}" location="right"></dxo-label>
          </dxi-item>
          <div *dxTemplate="let item of 'masterDataTemplate'">
            <dx-select-box #masterDataCombo
                           name="masterData"
                           [items]="masterDataListFields"
                           [displayExpr]="translateFields"
                           valueExpr="masterDataListFieldId"
                           [(value)]="model.masterDataListFieldId"
                           [rtlEnabled]="rtlEnabled"
                           [searchEnabled]="true"
                           [showClearButton]="true"
                placeholder="{{ 'SHARED.SELECT' | translate }} ...">
            </dx-select-box>
          </div>
        </dx-form>
        <br />
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnCalculationFieldSubmit"
        disabled="disabled">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
 
        </div>
    </dx-scroll-view>
    </dx-popup>
</div>
