<clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false" [clrModalSize]="'xl'" class="accept-terms-and-conditions-component">
  <div class="modal-body">
    <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
      <div class="alert-item">
        <span class="alert-text">
          {{errorMessage}}
        </span>
      </div>
    </clr-alert>
    <div id="pdfViewerHolder"></div>
    <form>
      <div class="clr-form-control accept-label">
        <label for="isAccepted" class="clr-control-label">{{ 'TERMS_AND_CONDITIONS.I_AGREE' | translate }}</label>
        <div class="clr-control-container">
          <div class="clr-checkbox-wrapper">
            <input id="isAccepted" type="checkbox" clrCheckbox name="isAccepted" [(ngModel)]="isAccepted" />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="accept();" [disabled]="!isAccepted">{{ 'ACCEPT' | translate }}</button>
    <button type="button" class="btn btn-outline" (click)="cancel();">{{ 'SHARED.CANCEL' | translate }}</button>
  </div>
</clr-modal>
