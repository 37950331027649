import { Component, Output, OnInit, OnDestroy, Injector, ViewChild, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { DxPopupComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { UrlTypeEnum } from '../../shared/enum/urlTypeEnum';
import { Language } from '../../shared/models/language';
import { MasterData } from '../../shared/models/master-data';
import { ProductPropertyType } from '../../shared/models/product';

import { LookupTableField } from '../shared/models/lookup-table';

// services
import { LanguageService } from '../../shared/services/language.service';

const masterDataProperetyTypeId = 5;

@Component({
  selector: 'lookup-table-field-component',
  templateUrl: 'lookup-table-field.component.html',
  styleUrls: ['./lookup-table-field.component.scss']
})
export class LookupTableFieldComponent extends ItemDetailsComponent<LookupTableField> implements OnInit, OnDestroy {

  languages: Array<Language> = [];
  translations: any;
  productPropertyTypes: Array<ProductPropertyType> = [];
  productPropertyFormats: Array<UrlTypeEnum> = [];
  masterDataLists: Array<MasterData> = [];
  lookupTableType: number;
  propertyTypeId: number;
  propertyFormatId: number;
  isEditingDefault: boolean;
  isEditingUnsaved: boolean;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<LookupTableField>();
  @Output() onUpdated = new EventEmitter<LookupTableField>();
  // tslint:enable:no-output-on-prefix
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new LookupTableField();
    this.setTranslations('LOOKUP_TABLE.FIELD');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(lookupTableFieldId: number, lookupTableType: number, lookupTableFields: Array<LookupTableField>, lookupTableField: LookupTableField,
    languages: Array<Language>, productPropertyTypes: Array<ProductPropertyType>, productPropertyFormats: Array<UrlTypeEnum>, masterDataLists: Array<MasterData>) {

    this.allItems = lookupTableFields;
    this.languages = languages;
    this.productPropertyTypes = productPropertyTypes;
    this.productPropertyFormats = productPropertyFormats;
    this.masterDataLists = masterDataLists;
    this.lookupTableType = lookupTableType;

    this.model = lookupTableField;

    if (this.model.propertyTypeId) {
      this.propertyTypeId = this.model.propertyTypeId;

      if (this.model.propertyFormatId) {
        this.propertyFormatId = this.model.propertyFormatId;
      }
      this.isEditingDefault = true;
    } else {
      this.propertyTypeId = masterDataProperetyTypeId;
      this.isEditingDefault = false;
      this.model.propertyTypeId = this.propertyTypeId;
      this.propertyFormatId = null;
    }

    this.isOpened = true;

    if (lookupTableFieldId != null) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }
  }

  getTranslation = (value) => {
    if (value) {
      let label;
      label = this.languageService.getTranslatableText(value.name);
      return label;
    }
  }

  translateSelectBoxes = (item) => {
    if (item) {
      let label = item.name;
      this.translate.get(item.name).subscribe((res: string) => {
        label  = res;
    });

      return label;
    }
  }

  // propertyTypeChanged(value: number) {
  //   if (value === masterDataProperetyTypeId) {
  //     this.model.isResult = false;
  //     this.model.propertyTypeId = null;
  //   } else {
  //     this.model.isResult = true;
  //     this.model.propertyTypeId = value;
  //   }
  // }

  onTypeChanged = (e: any) => {
    if (this.model.propertyTypeId && this.model.propertyTypeId !== e.selectedItem.propertyTypeId) {
      this.model.propertyFormatId = null;
    }
  }

  save() {
    this.errorMessage = null;
    if (this.model.propertyTypeId && !this.isEditingDefault) {
      if (this.model.isResult) {
        this.allItems.forEach(i => {
          if (i.propertyTypeId) {
            this.errorMessage = this.errorService.translations.MULTIPLE_RESULTS_ERROR;
          }
        });
      }
    }

    if (this.errorMessage) {
          this.errorService.show(this.errorMessage);
      return;
    }

    if (this.isEditMode || this.isEditingDefault) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new LookupTableField();
    // this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnLookupTableFieldSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnLookupTableFieldSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnLookupTableFieldSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnLookupTableFieldSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }
}
