export class Mask {
  maskId: number;
  productId: number;
  auctionClusterId: number;
  name: string;
  sourceType: number;
  sourceCatalogId: number;
  sourceMasterDataListId: number;
  publishedOnly: boolean;

  maskProductProperties: Array<MaskProductProperty> = [];
  maskMasterDataListFields: Array<MaskMasterDataListField> = [];

  // displayable fields
  displayName: string;
}

export class MaskProductProperty {
  maskProductPropertyId: number;
  maskId: number;
  productPropertyId: number;
  isResult: boolean;
}

export class MaskMasterDataListField {
  maskMasterDataListFieldId: number;
  maskId: number;
  masterDataListFieldId: number;
  isResult: boolean;
}

export enum MaskTypes {
  'SUPPLY_MASK_TYPE' = 0,
  'SUPPLY_FORECAST_MASK_TYPE' = 1
}
