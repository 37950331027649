export class Supplier {
  supplierId: number;
  name: string;
  address1: string;
  address2: string;
  zip: string;
  city: string;
  country: string;
  vat: string;
  telNumber: string;
  faxNumber: string;
  email: string;
  url: string;
  isActive: boolean;
  administratorId: number;
  isPending: boolean;
}

export class SupplierSimple {
  supplierId: number;
  name: string;
  //vat: string;
}

export function SupplierCompareName(supplier1, supplier2) {
  if (supplier1.name < supplier2.name) {
    return -1;
  }
  else if (supplier1.name > supplier2.name) {
    return 1;
  }
  return 0;
}
