import { Component, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';

@Component({
  selector: 'search-box',
  templateUrl: 'search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @Output() update = new EventEmitter();

  @ViewChild('inputBox') inputBox: any;

  public isSearchActive: boolean;

  ngOnInit() {
    this.update.emit('');
  }

  onSearchClick() {
    this.isSearchActive = true;
    this.inputBox.nativeElement.focus();
  }

  onSearchBlur() {
    this.isSearchActive = false;
  }
}
