import { Component, Injector } from '@angular/core';

// components
import { ContentComponent } from '../../shared/content/content.component';

// models
import { AccessLevel, AdditionalMenuItem } from '../../shared/models/additional-menu-item';

// services
import { PublicDataService } from '../../shared/services/public-data-service';

@Component({
  selector: 'core-content.component',
  templateUrl: './core-content.component.html',
  styleUrls: ['./core-content.component.scss']
})
export class CoreContentComponent extends ContentComponent {

  menuItems: Array<AdditionalMenuItem> = [];
  subMenuItems: Array<AdditionalMenuItem> = [];
  sidebarLogo = null;

  constructor(
    injector: Injector,
    public publicAuctionClusterService: PublicDataService
  ) {
    super(injector);
  }

  public login() {
    this.authenticationService.goToStartPage('/core/login');
  }

  ngOnInit() {
    this.publicAuctionClusterService.getPublicAuctionClusters().subscribe(acs => {
      if (acs && acs.length > 0) {
        localStorage.setItem('AUCXIS_SIDEBAR_LOGO', acs[0].image);
      } else {
        localStorage.removeItem('AUCXIS_SIDEBAR_LOGO')
      }

      const acImage = localStorage.getItem('AUCXIS_SIDEBAR_LOGO');

      if (acImage) {
        this.sidebarLogo = acImage;
      }
    });

    super.ngOnInit();

    this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {

      if (result !== undefined){
        result.forEach( item => {
          if (item.accessLevels.length > 0){
            item.accessLevels.forEach(_ => {
              if (_ === AccessLevel.PUBLIC) {
                if (item.submenuId !== null) {
                  this.subMenuItems.push(item);
                } else this.menuItems.push(item);
              }
            })
          }
        })

        this.menuItems.sort((a, b) => {
          if (a.orderNumber < b.orderNumber) {
            return -1;
          }
          if (a.orderNumber > b.orderNumber) {
            return 1;
          }
          return 0;
        })

        this.subMenuItems.sort((a, b) => {
          if (a.orderNumber < b.orderNumber) {
            return -1;
          }
          if (a.orderNumber > b.orderNumber) {
            return 1;
          }
          return 0;
        })
      }
    },
      error => {
        this.errorService.show(error);
      });
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }
}
