<div class="translatable-field2-component">
  <dx-text-box [(value)]="textInputs[selectedLanguage]" (onBlur)="onTextBoxBlur()"></dx-text-box>

  <dx-radio-group [items]="languages" valueExpr="code" displayExpr="code" layout="horizontal" [(value)]="selectedLanguage">
    <div *dxTemplate="let language of 'item'">
      <dx-radio-button 
        [ngStyle]="{ 'color': textInputs[language.code] && textInputs[language.code] !== '' ? 'green' : 'red' }">
        {{ language.code }}
      </dx-radio-button>
    </div>
  </dx-radio-group>
</div>