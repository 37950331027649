import { HttpInterceptor, HttpRequest,HttpErrorResponse, HttpHandler, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  catchError, of, switchMap ,throwError, BehaviorSubject,filter, take} from "rxjs";
import { CookieService } from 'ngx-cookie-service';

// models
import { Cookies } from '../constants/cookies';

// services
import { AuthService } from './auth.service';
import { TokenService } from './token.service';

export class AuthInterceptor implements HttpInterceptor {
  
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private tokenService: TokenService, 
    private cookieService: CookieService,
    private authService: AuthService
     ) 
    {      
    }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpEventType.Response>> {
    
    let authReq = req;
    const token = this.tokenService.getToken();
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse &&  error.status === 401) {
        return this.handle401Error(authReq, next);
      }

      return throwError(error);
    }));
  }

  private addTokenHeader(request: HttpRequest<any>, token: string)
  {
    const authReq = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        UserGuid: this.cookieService.get(Cookies.USER_GUID)
      }
    });

    return authReq;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshAccessToken().pipe(
        switchMap((res: any) => {
          
          this.isRefreshing = false;
          this.authService.applyToken(res);
          const token = this.tokenService.getToken();
          
          this.refreshTokenSubject.next(token);
          
          return next.handle(this.addTokenHeader(request, token));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.authService.logout();
          return of().toPromise();
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  } 

  
}
