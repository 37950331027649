<div class="location-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addLocation [width]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #locationForm id="locationForm" validationGroup="locationData" [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

          <dxi-item itemType="group">
            <dxi-item dataField="name">
                <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="address1">
                <dxo-label text="{{ 'SHARED.ADDRESS1' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.ADDRESS1' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="address2">
                <dxo-label text="{{ 'SHARED.ADDRESS2' | translate }}"></dxo-label>
            </dxi-item>

            <dxi-item dataField="zip">
                <dxo-label text="{{ 'SHARED.ZIP' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.ZIP' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="city">
                <dxo-label text="{{ 'SHARED.CITY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.CITY' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="country">
                <dxo-label text="{{ 'SHARED.COUNTRY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.COUNTRY' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>
          </dxi-item>
        </dx-form>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnLocationSubmit"
        disabled="disabled">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
