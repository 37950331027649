<div class="user-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addUser [width]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
            [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
            (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <ng-container *ngIf="this.shouldShowBuyerApproval()">
          <div class="alert-box">
            <span *ngIf="getMatchingBuyer()" [innerHTML]="'USER.USER_REQUESTED_ACCESS_TO_BUYER_MATCH' | translate:getMatchingBuyer()"></span>
            <span *ngIf="getMatchingBuyer()">
              <a class="btn-link" role="button" (click)="addToBuyerAccount(getMatchingBuyer())">
                {{ 'USER.ADD_USER_TO_BUYER_ACCOUNT' | translate }}
              </a>
            </span>
            <span *ngIf="!getMatchingBuyer()" [innerHTML]="'USER.USER_REQUESTED_ACCESS_TO_BUYER_NO_MATCH' | translate:{ vat: model.existingBuyerVat }"></span>
          </div>
        </ng-container>
        <ng-container *ngIf="this.shouldShowSupplierApproval()">
          <div class="alert-box">
            <span *ngIf="getMatchingSupplier()" [innerHTML]="'USER.USER_REQUESTED_ACCESS_TO_SUPPLIER_MATCH' | translate:getMatchingSupplier()"></span>
            <span *ngIf="getMatchingSupplier()">
              <a class="btn-link" role="button" (click)="addToSupplierAccount(getMatchingSupplier())">
                {{ 'USER.ADD_USER_TO_SUPPLIER_ACCOUNT' | translate }}
              </a>
            </span>
            <span *ngIf="!getMatchingSupplier()" [innerHTML]="'USER.USER_REQUESTED_ACCESS_TO_SUPPLIER_NO_MATCH' | translate:{ vat: model.existingSupplierVat }"></span>
          </div>
        </ng-container>
      </div>
      <dx-form #userForm id="userForm" [showValidationSummary]="false"
               (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">
        <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'SHARED.GENERAL' | translate }}">

            <dxi-item itemType="group">
              <dxi-item itemType="group">
                <dxi-item dataField="email">
                  <dxi-validation-rule type="required" message="{{ 'SHARED.EMAIL' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                  <dxi-validation-rule *ngIf="configService.config.validateEmail" type="email" message="{{ 'SHARED.EMAIL' | translate }}{{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>
                <dxi-item itemType="button"
                          horizontalAlignment="right"
                          [buttonOptions]="buttonOptions">
                </dxi-item>
              </dxi-item>
              <dxi-item dataField="firstname" [editorOptions]="{disabled: !emailVerified}">
                <dxo-label text="{{ 'SHARED.FIRST_NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.FIRST_NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="lastname" [editorOptions]="{disabled: !emailVerified}">
                <dxo-label text="{{ 'SHARED.LAST_NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.LAST_NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="telNumber" [editorOptions]="{disabled: !emailVerified}">
                <dxo-label text="{{ 'SHARED.TEL_NUMBER' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="languageId"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: languages, valueExpr:'languageId', displayExpr:'name', disabled: !emailVerified}">
                <dxo-label text="{{ 'USER.PREFERRED_LANGUAGE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item itemType="group" [colCount]="2">
                <dxi-item dataField="mfaEnabled" editorType="dxCheckBox" *ngIf="isMFAEnabledVisible">
                  <dxo-label text="{{ 'USER.MFA_ENABLED' | translate }}" location="right"></dxo-label>
                </dxi-item>
                <dxi-item itemType="button" horizontalAlignment="right" [buttonOptions]="mfaResetButtonOptions" *ngIf="isMFAResetButtonVisible && model.mfaEnabled">
                </dxi-item>
              </dxi-item>
              <dxi-item dataField="isSystemUser" editorType="dxCheckBox" *ngIf="isSystemUser">
                <dxo-label text="{{ 'USERS.SYSTEM_USER' | translate }}" location="right"></dxo-label>
              </dxi-item>
            </dxi-item>

            <dxi-item itemType="group" caption="{{ 'USER.USER_LEVEL' | translate }}">

              <dxi-item [editorOptions]="{disabled: !emailVerified}" dataField="isAuctionClusterUser" [template]="'auctionClusterTemplate'">
                <dxo-label text="{{ 'USER.AUCTION_CLUSTER_USER' | translate }}" location="right"></dxo-label>
              </dxi-item>
              <dxi-item [editorOptions]="{disabled: !emailVerified}" dataField="isBuyerUser" [template]="'buyerClusterTemplate'">
                <dxo-label text="{{ 'USER.BUYER_USER' | translate }}" location="right"></dxo-label>
              </dxi-item>
              <dxi-item [editorOptions]="{disabled: !emailVerified}" dataField="isSupplierUser" [template]="'supplierUserTemplate'">
                <dxo-label text="{{ 'USER.SUPPLIER_USER' | translate }}" location="right"></dxo-label>
              </dxi-item>
            </dxi-item>
          </dxi-tab>
          <dxi-tab title="{{ 'SHARED.REPORTING' | translate }}" [disabled]="!emailVerified">
            <dx-data-grid [rtlEnabled]="rtlEnabled"
                          [(dataSource)]="model.userAuctionClusterReportingRoles"
                          [showBorders]="true"
                          [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          (onInitialized)="initReportingRoles($event)"
                          [rowAlternationEnabled]="true">
              <dxo-selection mode="single"></dxo-selection>
              <dxo-editing mode="cell"
                           [allowUpdating]="true"
                           [allowAdding]="true"
                           [allowDeleting]="true"
                           [useIcons]="true">
              </dxo-editing>
              <dxo-pager [showPageSizeSelector]="true"
                         [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                         [showNavigationButtons]="true">
              </dxo-pager>
              <dxi-column dataField="auctionClusterId" caption="{{ 'SHARED.AUCTION_CLUSTER' | translate }}">
                <dxo-lookup [dataSource]="auctionClusters" displayExpr="name" valueExpr="auctionClusterId">
                </dxo-lookup>
              </dxi-column>
              <dxi-column dataField="auctionClusterReportingRoleId"
                          caption="{{ 'SHARED.ROLE' | translate }}">
                <dxo-lookup [dataSource]="auctionClusterReportingRoles" [displayExpr]="translateSelectBoxes"
                            valueExpr="auctionClusterReportingRoleId">
                </dxo-lookup>
              </dxi-column>
            </dx-data-grid>

          </dxi-tab>

          <dxi-tab title="{{ 'SHARED.NOTIFICATIONS' | translate }}" *ngIf="isEditMode">
            <dxi-item>
              <dx-data-grid #auctionClusterUserNotificationsGrid
                            [rtlEnabled]="rtlEnabled"
                            [dataSource]="notifications"
                            [showBorders]="true"
                            (onCellClick)="editUserNotification($event)"
                            [allowColumnReordering]="true"
                            [hoverStateEnabled]="true"
                            [columnAutoWidth]="true"
                            [rowAlternationEnabled]="true">

                <dxo-selection mode="single"></dxo-selection>
    
                <dxi-column dataType="boolean" dataField="isActive" caption="{{ 'SHARED.ACTIVE' | translate }}">
                </dxi-column>
                <dxi-column dataType="string" dataField="auctionClusterNotification.nameText" caption="{{ 'SHARED.NAME' | translate }}">
                </dxi-column>
                <dxi-column type="buttons">
                  <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="editUserNotification"></dxi-button>
                </dxi-column>
              </dx-data-grid>
            </dxi-item>
          </dxi-tab>

          <dxi-tab title="{{ 'SHARED.AUCTION_CLUSTER' | translate }}" [disabled]="!isAuctionClusterUser">
            <div *dxTemplate>
              <dx-data-grid [rtlEnabled]="rtlEnabled" [(dataSource)]="model.auctionClusterRoles" [showBorders]="true"
                            [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true" (onEditorPreparing)="onEditorPreparing($event)"
                            (onInitialized)="initAuctionCluster($event)"
                            [rowAlternationEnabled]="true">
                <dxo-selection mode="single"></dxo-selection>
                <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" [useIcons]="true">
                </dxo-editing>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" [showNavigationButtons]="true">
                </dxo-pager>
                <dxi-column dataField="auctionId" caption="{{ 'SHARED.AUCTION' | translate }}">
                  <dxo-lookup [dataSource]="auctions" displayExpr="name" valueExpr="auctionId">
                  </dxo-lookup>
                </dxi-column>
                <dxi-column dataField="auctionClusterRoleId" caption="{{ 'SHARED.ROLE' | translate }}">
                  <dxo-lookup [dataSource]="auctionClusterRoles" [displayExpr]="translateSelectBoxes"
                              valueExpr="auctionClusterRoleId">
                  </dxo-lookup>
                </dxi-column>
              </dx-data-grid>
            </div>
          </dxi-tab>
          <dxi-tab title="{{ 'SHARED.BUYER' | translate }}" [disabled]="!isBuyerUser">
            <dxi-item itemType="group">
              <dxi-item>
                <div *dxTemplate>
                  <dx-data-grid [rtlEnabled]="rtlEnabled" [(dataSource)]="model.buyerRoles" [showBorders]="true"
                                [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true" (onEditorPreparing)="onEditorPreparing($event)" (onInitialized)="initBuyer($event)"
                                (onInitNewRow)="onInitNewRow($event)" (onRowUpdating)="onBuyerRowUpdating($event)" [rowAlternationEnabled]="true">
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" [useIcons]="true">
                    </dxo-editing>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" [showNavigationButtons]="true">
                    </dxo-pager>
                    <dxi-column dataField="buyerId" caption="{{ 'SHARED.BUYER' | translate }}">
                      <dxo-lookup [dataSource]="buyersPaginated" displayExpr="name" valueExpr="buyerId">
                      </dxo-lookup>
                    </dxi-column>
                    <dxi-column dataField="buyerRoleId" caption="{{ 'SHARED.ROLE' | translate }}">
                      <dxo-lookup [dataSource]="buyerRoles" [displayExpr]="translateSelectBoxes" valueExpr="buyerRoleId">
                      </dxo-lookup>
                    </dxi-column>
                  </dx-data-grid>
                </div>
              </dxi-item>
              <dxi-item dataField="userDisplayName">
                <dxo-label text="{{ 'USER.USER_DISPLAY_NAME' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="pincode" editorType="dxNumberBox">
                <dxo-label text="{{ 'USER.PINCODE' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-item>
          </dxi-tab>
          <dxi-tab title="{{ 'SHARED.SUPPLIER' | translate }}" [disabled]="!isSupplierUser">
            <div *dxTemplate>
              <dx-data-grid [rtlEnabled]="rtlEnabled" [(dataSource)]="model.supplierRoles" [showBorders]="true"
                            [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true" (onEditorPreparing)="onEditorPreparing($event)"
                            (onInitialized)="initSupplier($event)"
                            (onRowUpdating)="onSupplierRowUpdating($event)"
                            [rowAlternationEnabled]="true">
                <dxo-selection mode="single"></dxo-selection>
                <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" [useIcons]="true">
                </dxo-editing>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" [showNavigationButtons]="true">
                </dxo-pager>
                <dxi-column dataField="supplierId" caption="{{ 'SUPPLIER.NAME' | translate }}">
                  <dxo-lookup [dataSource]="suppliersPaginated" displayExpr="name" valueExpr="supplierId">
                  </dxo-lookup>
                </dxi-column>
                <dxi-column dataField="supplierRoleId" caption="{{ 'SHARED.ROLE' | translate }}">
                  <dxo-lookup [dataSource]="supplierRoles" [displayExpr]="translateSelectBoxes"
                              valueExpr="supplierRoleId">
                  </dxo-lookup>
                </dxi-column>
              </dx-data-grid>
            </div>
          </dxi-tab>
        </dxi-item>


        <div *dxTemplate="let data of 'statusTemplate'">
          <div *ngIf="data === null" ; else elseBlock>
            <span>(All)</span>
          </div>
          <div dx-template #elseBlock>
            <span class="middle">{{data.name}}</span>
          </div>
        </div>

        <div *dxTemplate="let data of 'auctionClusterTemplate'">
          <dx-check-box [disabled]="!emailVerified" [(value)]="isAuctionClusterUser"></dx-check-box>
        </div>

        <div *dxTemplate="let data of 'buyerClusterTemplate'">
          <dx-check-box [disabled]="!emailVerified" [(value)]="isBuyerUser"></dx-check-box>
        </div>

        <div *dxTemplate="let data of 'supplierUserTemplate'">
          <dx-check-box [disabled]="!emailVerified" [(value)]="isSupplierUser" [rtlEnabled]="rtlEnabled"></dx-check-box>
        </div>
        <!-- Templates -->
      </dx-form>
      <div class="au-dx-popup-padder">
        <button type="submit" class="btn btn-primary" (click)="onSubmitAccountInfo();" name="btnUserSubmitAccountInfo" disabled="disabled">
          {{ 'USER.SEND_ACC_INFO' | translate }}
        </button>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnUserSubmit" disabled="disabled">
        {{ 'SHARED.SAVE' | translate }}
        </button>
      <!--<button type="button" class="btn btn-primary" (click)="verifyEmail(model.email)" [disabled]="!model.email">{{ 'SHARED.VERIFY' | translate }}</button>-->
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
<save-confirmation-component #saveConfirmation title="{{ 'SHARED.LEAVE_PAGE' | translate }}" buttonCloseText="{{ 'SHARED.OK' | translate }}"
                             cancellable="true" (close)="onCancel();"></save-confirmation-component>
<confirmation-component #confirmationAuctionClusterLicenses title="{{ 'USER.AUCTIONCLUSTER_LICENSES_EXCEEDED_TITLE' | translate }}"
                        message="{{ 'USER.AUCTIONCLUSTER_LICENSES_EXCEEDED_MESSAGE' | translate }}" buttonCancelText="{{ 'SHARED.NO' | translate }}" buttonCloseText="{{ 'SHARED.YES' | translate }}" cancellable="true"
                        (close)="sendUserLicenseExceededMail();"></confirmation-component>
<verify-my-password-component #verifyMyPassword></verify-my-password-component>
<edit-user-notification-channels-component #notificationChannelsComponent></edit-user-notification-channels-component>
<confirmation-component #licenseConfirmation buttonCloseText="{{ 'SHARED.YES' | translate }}" buttonCancelText="{{ 'SHARED.CANCEL' | translate }}" cancellable="true"></confirmation-component>
