import { Component, OnInit, Injector } from '@angular/core';
import { forkJoin } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ReportComponent } from './report.component';

// models
import { Language } from '../../shared/models/language';
import { MasterData } from '../../shared/models/master-data';
import { Product } from '../../shared/models/product';
import { Report } from '../../shared/models/report';

// services
import { LanguageService } from '../../shared/services/language.service';
import { PublicDataService } from '../../shared/services/public-data-service';

import { MasterDataService } from '../../auction/shared/services/master-data.service';


@Component({
  selector: 'reports-component',
  templateUrl: 'reports.component.html'
})
export class ReportsComponent extends FullListComponent<Report, ReportComponent> implements OnInit {

  languages: Array<Language> = [];
  products: Array<Product> = [];
  masterDatas: Array<MasterData> = [];
  reports: Array<Report> = [];

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: PublicDataService,
    //private productService: ProductService,
    private masterDataService: MasterDataService
  ) {
    super(injector, Report);
    this.title.set('REPORTS.TITLE');
  }

  ngOnInit() {
    this.setTranslations('REPORTS');
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getPublicReports(),
      this.languageService.getLanguages()
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];

      this.setReportLabels();
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  setReportLabels() {
    this.items.forEach(report => {
      report.productName = this.languageService.getTranslatableText(report.reportDesign.productName);
    });
  }

  openReport(reportId: number, auctionClusterId: number) {
    this.router.navigate(['/core/reports/' + auctionClusterId + '/report/' + reportId]);
  }

  deleteSelected() { }

  add() { }

  edit(itemId: number, event: Event) { }
}
