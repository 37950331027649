<div class="login-wrapper register-component">
  <form class="login" #registerForm="ngForm" (ngSubmit)="onSubmit()">
    <div class="login-group" (capsLock)="capsOn=$event">
      <div class="inner-addon right-addon">
        <div *ngIf="auctionClusters.length > 1">
          <label class="required" id="label" for="auctionCluster">{{ 'SHARED.AUCTION_CLUSTER' | translate }}</label>
          <div class="select">
            <select clr-combobox name="auctionCluster" [(ngModel)]="model.auctionClusterId" class="clr-input clr-combobox-input">
              <option *ngFor="let auctionCluster of auctionClusters" [ngValue]="auctionCluster.auctionClusterId">{{ auctionCluster.name }}</option>
            </select>
          </div>
          <br />
        </div>
        <input clrInput #email type="email" name="email" class="username" placeholder="{{ 'SHARED.EMAIL' | translate }}" [(ngModel)]="model.email" required />
        <div>
          <i alt="show" class="far fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
          <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showPassword = !showPassword" [class.hide]="!showPassword"></i>
        </div>
        <input clrInput class="password" [type]="showPassword ? 'text' : 'password'" name="password" placeholder="{{ 'USER.PASSWORD' | translate }}" [(ngModel)]="model.password" required />
        <div>
          <i alt="show" class="far fa-eye eye-show" (click)="showRepeatPassword = !showRepeatPassword" [class.hide]="showRepeatPassword"></i>
          <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showRepeatPassword = !showRepeatPassword" [class.hide]="!showRepeatPassword"></i>
        </div>
      </div>
      <input clrInput class="password" [type]="showRepeatPassword ? 'text' : 'password'" name="confirm-password" placeholder="{{ 'USER.REPEAT_PASSWORD' | translate }}" [(ngModel)]="model.confirmPassword" required />
      <div>
        <img class="warning-image" *ngIf="capsOn" src="../../../assets/images/warningCapsLock.png"/>
        <span *ngIf="capsOn">{{ 'USER.CAPS_LOCK_WARNING' | translate }}</span>
      </div>
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>
      <clr-alert *ngIf="message" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{message}}
          </span>
        </div>
      </clr-alert>
      <button type="submit" class="btn btn-primary" [disabled]="!registerForm.form.valid">REGISTER</button>
    </div>
    <a [routerLink]="['/core/login']" class="login-link">
      {{ 'REGISTER.ALREADY_HAVE_AN_ACCOUNT' | translate }}
    </a>
  </form>
</div>

