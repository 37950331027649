<div class="item-list-component">

  <div class="grid-buttons">
    <clr-tooltip *ngIf="allowAdd">
      <clr-icon shape="add" clrTooltipTrigger size="16" (click)="add()"></clr-icon>
      <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
        <span>{{ 'SHARED.ADD' | translate }}</span>
      </clr-tooltip-content>
    </clr-tooltip>
  </div>

  <clr-datagrid id="printSection">
    <clr-dg-column class="no-print two-buttons-column" *ngIf="isOrderNeeded">
      <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
        <clr-icon clrTooltipTrigger class="edit-btn" shape="arrow" dir="up" size="16" (click)="moveUp($event)"></clr-icon>
      </clr-tooltip>
      <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
        <clr-icon clrTooltipTrigger class="edit-btn" shape="arrow" dir="down" size="16" (click)="moveDown($event)"></clr-icon>
      </clr-tooltip>
    </clr-dg-column>
    <clr-dg-column *ngIf="showFilterOnCurrentLotProperty">{{ 'PRODUCT.PROPERTY.FILTER_ON_CURRENT_LOT' | translate }}</clr-dg-column>
    <clr-dg-column *ngIf="showName">{{ 'SHARED.NAME' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'PRODUCT.PROPERTY_NAME' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'SHARED.TYPE' | translate }}</clr-dg-column>
    <clr-dg-column *ngIf="showColRow">{{ 'PRODUCT.PROPERTY.COLUMN_NUMBER' | translate }}</clr-dg-column>
    <clr-dg-column *ngIf="showColRow">{{ 'PRODUCT.PROPERTY.ROW_NUMBER' | translate }}</clr-dg-column>
    <clr-dg-column *ngIf="showWidth">{{ 'PRODUCT.PROPERTY.WIDTH_PERCENTAGE' | translate }}</clr-dg-column>
    <clr-dg-column *ngIf="isMasterDataFieldNeeded">{{ 'PRODUCT.PROPERTY.MASTER_DATA_FIELD_NAME' | translate }}</clr-dg-column>
    <clr-dg-column *ngIf="showRequired">{{ 'PRODUCT.PROPERTY.REQUIRED' | translate }}</clr-dg-column>
    <clr-dg-column class="no-print two-buttons-column">&nbsp;</clr-dg-column>

    <clr-dg-placeholder>{{'SHARED.NO_DATA' | translate }}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let item of items; let i = index">
      <clr-dg-cell class="no-print two-buttons-cell" *ngIf="isOrderNeeded">
        <input type="checkbox" clrCheckbox name="isCollapsed" [(ngModel)]="item.selected" />
      </clr-dg-cell>
       <clr-dg-cell *ngIf="showFilterOnCurrentLotProperty" (click)="edit(item, $event)">
        <clr-icon shape="check" *ngIf="item.filterOnCurrentLot"></clr-icon>
        <clr-icon shape="times" *ngIf="!item.filterOnCurrentLot"></clr-icon>
      </clr-dg-cell> 
      <clr-dg-cell *ngIf="showName" (click)="edit(item, $event)">{{item.name | translateJson}}</clr-dg-cell>
      <clr-dg-cell (click)="edit(item, $event)">{{item.productPropertyName | translateJson}}</clr-dg-cell>
      <clr-dg-cell (click)="edit(item, $event)">{{item.productPropertyTypeName}}</clr-dg-cell>
      <clr-dg-cell *ngIf="showColRow">{{item.columnNumber}}</clr-dg-cell>
      <clr-dg-cell *ngIf="showColRow">{{item.rowNumber}}</clr-dg-cell>
      <clr-dg-cell *ngIf="showWidth">{{item.width | translateJson}}</clr-dg-cell>
      <clr-dg-cell *ngIf="isMasterDataFieldNeeded" (click)="edit(item, $event)">{{item.masterDataFieldName | translateJson}}</clr-dg-cell>
      <clr-dg-cell *ngIf="showRequired">
        <input type="checkbox" clrCheckbox name="required" [(ngModel)]="item.required" disabled/>
      </clr-dg-cell>
      <clr-dg-cell class="no-print two-buttons-cell" (click)="edit(item, $event)">
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="edit-btn" shape="edit" size="16" (click)="edit(item, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'SHARED.EDIT' | translate }}</clr-tooltip-content>
        </clr-tooltip>
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="delete-btn" shape="trash" size="16" (click)="delete(item, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'SHARED.DELETE' | translate }}</clr-tooltip-content>
        </clr-tooltip>
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <pagination-component class="footer-pagination" tootltip-content="{{ 'SHARED.SET_PAGE_SIZE' | translate }}" [pageSizeValue]="pageSizeValue" (changed)="setPageSize($event)"></pagination-component>
      <span class="pagination-fixed footer-pagination">
        <span *ngIf="items?.length > 0">{{ 'PRODUCT.PROPERTY.PAGINATION_TEXT' | translate:{firstItem: pagination.firstItem + 1, lastItem: pagination.lastItem + 1, totalItems: pagination.totalItems} }}</span>
        <span *ngIf="items?.length == 0">{{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate:{totalItems: pagination.totalItems} }}</span>
        <clr-dg-pagination #pagination [clrDgPageSize]="pageSize"></clr-dg-pagination>
      </span>
    </clr-dg-footer>
  </clr-datagrid>
</div>

<generic-product-property-component
  [propertyGroupingShowing]="propertyGroupingShowing" [showOnlyAutomaticGrouping]="showOnlyAutomaticGrouping"
  [propertyTypeFilter]="propertyTypeFilter" [urlFormatFilter]="urlFormatFilter"
  [automaticProductPropertyNonGroupingSelections]="automaticProductPropertyNonGroupingSelections"
  [manualProductPropertyNonGroupingSelections]="manualProductPropertyNonGroupingSelections"
  (onAdded)="onProductPropertyAdded($event)" (onUpdated)="onProductPropertyUpdated($event)" [isReport]="isReport"
  [isSupplyData]="isSupplyData" [isMasterDataFieldNeeded]="isMasterDataFieldNeeded" [showName]="showName" [showComparable]="showComparable" [showRequired]="showRequired"
  [showFilterOnCurrentLotProperty]="showFilterOnCurrentLotProperty" [showIncludeInSummaryProperty]="showIncludeInSummaryProperty" [showLastInFullScreenProperty]="showLastInFullScreenProperty" [showWidth]="showWidth" [showColRow]="showColRow"></generic-product-property-component>
