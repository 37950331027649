import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// services
import { LanguageService } from '../../services/language.service';

// models
import { Language } from '../../models/language';

@Component({
  selector: 'translatable-field2',
  templateUrl: 'translatable-field2.component.html',
  styleUrls: ['./translatable-field2.component.scss']
})
export class TranslatableField2Component implements OnInit {
  @Input() set text(value: string) {    
    if (value) {
      this.textInputs = JSON.parse(value);
    }
    else{
      this.textInputs = {};
    }
  }
  @Output() textUpdated: EventEmitter<any> = new EventEmitter<any>();

  auctionClusterId: number;
  textInputs: { [key: string]: string } = {};
  languages: Array<Language> = [];
  selectedLanguage: string;

  constructor(
    private route: ActivatedRoute,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.auctionClusterId = +this.route.snapshot.params['id'];

    this.languageService.getAuctionClusterLanguages(this.auctionClusterId).subscribe(result => {
      this.languages = result;
      this.selectedLanguage = this.languages[0].code;
    });
  }

  onTextBoxBlur() {
    this.textUpdated.emit(JSON.stringify(this.textInputs));
  }
}