import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { AuctionClusterRoleComponent } from './auction-cluster-role.component';

// models
import { AuctionClusterRole } from '../../shared/models/auction-cluster-role';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';

import { AuctionClusterPermission } from '../shared/models/auction-cluster-permission';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';
import { TokenService } from '../../shared/services/token.service';

import { AuctionClusterLevelRoleService } from '../shared/services/auction-cluster-level-role.service';
import { AuctionClusterPermissionService } from '../shared/services/auction-cluster-permission.service';

@Component({
  selector: 'auction-cluster-roles.component',
  templateUrl: 'auction-cluster-roles.component.html',
  styleUrls: ['./auction-cluster-roles.component.scss']
})
export class AuctionClusterRolesComponent extends FullListComponent<AuctionClusterRole, AuctionClusterRoleComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: AuctionClusterRoleComponent;

  permissions: Array<AuctionClusterPermission> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterLevelRoleService,
    private auctionClusterPermissionService: AuctionClusterPermissionService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private tokenService: TokenService
  ) {
    super(injector, AuctionClusterRole);
    this.title.set('ROLES.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.auctionClusterPermissionService.getAuctionClusterPermissions(this.id, this.translateService.currentLang),
      this.dataService.getAuctionClusterRoles(this.id, this.translateService.currentLang),
    ).subscribe(result => {
      this.permissions = result[0];
      this.items = result[1];

      var userPermissions = this.tokenService.getCurrentUserPermissions();
      if (!userPermissions.AuctionClusterPermissions.some(p => p.Value.some(pp => pp === AuctionClusterPermissionEnum.Administrator))) {
        this.permissions = this.permissions.filter(_ => _.auctionPermissionId !== AuctionClusterPermissionEnum.Administrator.valueOf());
        this.items = this.items.filter(_ => !_.permissions.some(pp => pp === AuctionClusterPermissionEnum.Administrator.valueOf()));
      }

      this.items.forEach(role => {
        role.permissionString = this.buildPermissionsString(role.permissions);
      });
      
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.auctionClusterRoleId;

    this.dataService.checkIfRoleHasUsers(this.id, this.itemIdToDelete).subscribe(res => {
      if (res.length === 0) {
        this.confirmation.opened = true;
      } else {
        this.errorService.show(this.errorService.translations.ROLE_HAS_USERS);
      }
    });
  }

  deleteSelected() {
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe((result: boolean) => {
        this.items = this.items.filter(r => r.auctionClusterRoleId != this.itemIdToDelete);
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
        });
  }

  edit(roleId: number, event: Event) {
    event.stopPropagation();
    this.detailsComponent.open(this.items, roleId, this.permissions, this.id);
  }

  editItem = (e: any) => {
    const auctionClusterRoleId = e.row !== undefined ? e.row.data.auctionClusterRoleId : e.data.auctionClusterRoleId;
    this.detailsComponent.open(this.items, auctionClusterRoleId, this.permissions, this.id);
  }

  add() {
    this.detailsComponent.open(this.items, null, this.permissions, this.id);
  }

  private buildPermissionsString(permissions: Array<number>) {
    const permissionNames: Array<string> = [];
    permissions.forEach(item => {
      const permissionName = this.permissions.find(p => p.auctionPermissionId === item).name;
      permissionNames.push(permissionName);
    });
    return permissionNames.join(', ');
  }
}