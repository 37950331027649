import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination-component',
  templateUrl: 'pagination.component.html',
  providers: []
})
export class PaginationComponent {

  @Output() changed = new EventEmitter<any>();

  @Input('pageSizeValue') pageSizeValue: any;
  @Input('tootltip-content') content: string;

  pages: Array<any>;

  constructor(
  ) {
    this.pages = new Array();
  }

  ngOnInit() {
    this.pages.push(
      { pagination: 10 },
      { pagination: 20 },
      { pagination: 30 },
      { pagination: 40 },
      { pagination: 50 },
      { pagination: 100 },
      { pagination: 200 }
    );
  }
}
