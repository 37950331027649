export class SupplierLevelSupplier {
  supplierId: number;
  name: string;
}

export class SupplierLevelClocks {
  auctionClusterId: number;
  supplierLevelSupplierWithClocks: Array<SupplierLevelSupplierClock> = [];
}

export class SupplierLevelSupplierClock {
  supplierId: number;
  clocks: Array<SupplierClock> = [];

  // displaying fields
  supplierName: string;
}

export class SupplierClock {
  clockId: number;
  catalogId: number;
  auctionId: number;
  auctionClusterId: number;
  name: string;
  description: string;
  layoutData: string;

  // displayable fields
  auctionName: string;
  catalogName: string;
  auctionClusterName: string;
}

export class SupplierClockOpen {
  supplierId: number;
  supplierName: string;
  clockId: number;
  clockName: string;
  isSelected: boolean;

  // displayable fields
  displayName: string;
}

export class SupplierLevelCatalog {
  catalogId: number;
  name: string;

  constructor(catalogId: number, name: string) {
    this.catalogId = catalogId;
    this.name = name;
  }
}

