import { Component, Injector, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { DxFormComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { AuctionClusterReportingRole } from '../../shared/models/auction-cluster-reporting-role';
import { Language } from '../../shared/models/language';
import { ReportDesign, ReportLevels } from '../../shared/models/report';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterReportingRoleService } from '../shared/services/auction-cluster-reporting-role.service';


@Component({
  selector: 'auction-cluster-reporting-role-component',
  templateUrl: 'auction-cluster-reporting-role.component.html',
  styleUrls: ['./auction-cluster-reporting-role.component.scss']
})
export class AuctionClusterReportingRoleComponent extends ItemDetailsComponent<AuctionClusterReportingRole> implements OnInit, OnDestroy {

  reportDesigns: Array<ReportDesign> = [];
  requiredReportDesigns: Array<number> = [];
  languages: Array<Language> = [];
  auctionClusterId: number;
  validFields: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('roleName') roleName;
  @ViewChild('roleDescription') roleDescription;
  @ViewChild('reportingRoleForm', { read: DxFormComponent }) roleForm: DxFormComponent;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterReportingRoleService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AuctionClusterReportingRole();
    this.setTranslations('ROLES');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(roles: Array<AuctionClusterReportingRole>, roleId: number, reportDesigns: Array<ReportDesign>, auctionClusterId: number) {
    this.modalTitle = roleId ? this.translations.EDIT : this.translations.ADD_NEW;
    this.allItems = roles;
    this.reportDesigns = reportDesigns.sort((a, b) => a.reportLevel - b.reportLevel).map(source => {
      if (!source.name.startsWith("(")) {
        if (source.reportLevel === ReportLevels.AuctionCluster) {
          source.name = "(" + this.translate.instant('SHARED.AUCTION_CLUSTER') + ") " + source.name;
        }
        else if (source.reportLevel === ReportLevels.Buyer) {
          source.name = "(" + this.translate.instant('SHARED.BUYER') + ") " + source.name;
        }
        else if (source.reportLevel === ReportLevels.Supplier) {
          source.name = "(" + this.translate.instant('SHARED.SUPPLIER') + ") " + source.name;
        }
      }
      return source;
    });
    this.requiredReportDesigns = [];
    this.auctionClusterId = auctionClusterId;

    forkJoin(
      this.languageService.getAuctionClusterLanguages(this.auctionClusterId)
    ).subscribe(result => {
      this.languages = result[0];
      if (roleId != null) {
        this.isEditMode = true;
        this.spinner.show();
        this.dataService.getAuctionClusterReportingRole(this.auctionClusterId, roleId)
          .subscribe((res: AuctionClusterReportingRole) => {
            this.model = res;
            this.model.name = this.languageService.updateStringToAllLanguages(this.languages, this.model.name);
            this.model.description = this.languageService.updateStringToAllLanguages(this.languages, this.model.description);
            this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);
            this.requiredReportDesigns = this.model.reportDesigns;

            this.isOpened = true;
            this.spinner.hide();
          },
            error => {
              this.errorService.show(error);
              this.spinner.hide();
            });
      } else {
        this.model = new AuctionClusterReportingRole();
        this.model.name = this.model.description = this.languageService.updateStringToAllLanguages(this.languages, "{}");
        this.isEditMode = false;
        this.isOpened = true;
      }
    });
  }

  save() {
    this.model.name = this.roleName.data;
    this.model.description = this.roleDescription.data;
    this.model.reportDesigns = this.requiredReportDesigns;

    if (this.isEditMode) {
      this.spinner.show();

      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterReportingRole();
          this.close(true);
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();

      this.model.auctionClusterId = this.auctionClusterId;

      this.dataService.save(this.model.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterReportingRole();
          this.close(true);
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  onCancel() {
    this.roleName.resetLanguageButtons();
    this.roleDescription.resetLanguageButtons();
    super.onCancel();
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.roleName.data);
      this.roleForm.instance.updateData(fieldName, this.roleName.data);
    } else if (fieldName === 'description') {
      this.roleForm.instance.updateData(fieldName, this.roleDescription.data);
    }
  }
}