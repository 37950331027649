import { Component, Output, OnInit, Injector, ViewChild, EventEmitter } from '@angular/core';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { Language } from '../../shared/models/language';
import { ProductPropertyGroup } from '../../shared/models/product';

// services
import { LanguageService } from '../../shared/services/language.service';


@Component({
  selector: 'product-property-group-component',
  templateUrl: 'product-property-group.component.html',
  styleUrls: ['./product-property-group.component.scss']
})
export class ProductPropertyGroupComponent extends ItemDetailsComponent<ProductPropertyGroup> implements OnInit {

  languages: Array<Language> = [];

  @ViewChild('propertyGroupName') propertyGroupName;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<ProductPropertyGroup>();
  @Output() onUpdated = new EventEmitter<ProductPropertyGroup>();
  // tslint:enable:no-output-on-prefix

  constructor(
    protected injector: Injector,
    private languageService: LanguageService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = new ProductPropertyGroup();
  }

  open(propertyGroups: Array<ProductPropertyGroup>, propertyGroup: ProductPropertyGroup, languages: Array<Language>) {

    this.allItems = propertyGroups;
    this.languages = languages;

    if (propertyGroup !== null) {
      this.isEditMode = true;
      this.model = propertyGroup;
      this.isOpened = true;
    } else {
      this.model = new ProductPropertyGroup();
      this.isEditMode = false;
      this.isOpened = true;

      // Create empty JSON object for translation fields
      const emptyTranslation = {};
      this.languages.forEach(lang => {
        emptyTranslation[lang.code] = '';
      });

      this.model.name = JSON.stringify(emptyTranslation);
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {

    this.model.orderNumber = this.allItems.length > 0 ? this.allItems[this.allItems.length - 1].orderNumber + 1 : 1;
    this.model.name = this.propertyGroupName.data;

    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new ProductPropertyGroup();
    this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }
}
