import { Injectable } from '@angular/core';
import { CanActivateChild, CanActivate, Router} from '@angular/router';

@Injectable()
export class BrowserGuard implements CanActivateChild, CanActivate {

  constructor(
      private router: Router
  ) { }

  checkBrowser() {
    const chromeAgent = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    if (!chromeAgent) {
      this.redirectToBrowserInfoPage();
      return false;
    } else {
      const version = parseInt(chromeAgent[2], 10);

      if (version < 72) {
        this.redirectToBrowserInfoPage();
        return false;
      }
    }

    return true;
  }

  canActivate() {
    return this.checkBrowser();
  }

  canActivateChild() {
    return this.checkBrowser();
  }

  redirectToBrowserInfoPage() {
    // tslint:disable-next-line: no-floating-promises
    this.router.navigate(['/browser-info/']);
  }
}
