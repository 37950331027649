<div class="edit-user-notifications-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #editUserNotifications
  [width]="600"
  [showTitle]="true"
  title="{{ 'SHARED.NOTIFICATIONS' | translate}}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [showCloseButton]="true"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #editUserNotificationsForm
                 id="editUserNotificationsForm"
                 [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">

          <dxi-item>
            <dx-data-grid #userNotificationsGrid
                          [rtlEnabled]="rtlEnabled"
                          [dataSource]="notifications"
                          [showBorders]="true"
                          (onCellClick)="editUserNotification($event)"
                          [allowColumnReordering]="true"
                          [hoverStateEnabled]="true"
                          [columnAutoWidth]="true"
                          [rowAlternationEnabled]="true">

              <dxo-selection mode="single"></dxo-selection>
  
              <dxi-column dataType="boolean" dataField="isActive" caption="{{ 'SHARED.ACTIVE' | translate }}">
              </dxi-column>
              <dxi-column dataType="string" dataField="auctionClusterNotification.nameText" caption="{{ 'SHARED.NAME' | translate }}">
              </dxi-column>
              <dxi-column dataType="string" dataField="auctionClusterNotification.auctionCluster.name" caption="{{ 'SHARED.AUCTION_CLUSTER' | translate }}" [visible]="auctionClusterColumnVisible">
              </dxi-column>
              <dxi-column type="buttons">
                <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="editUserNotification"></dxi-button>
              </dxi-column>
            </dx-data-grid>
          </dxi-item>

        </dx-form>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CLOSE' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
<edit-user-notification-channels-component></edit-user-notification-channels-component>
