<div class="masterdata-grid-component">
  <div class="grid-buttons">
    <clr-tooltip>
      <clr-icon shape="add" clrTooltipTrigger size="16" (click)="add()"></clr-icon>
      <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
        <span>{{ 'SHARED.ADD' | translate }}</span>
      </clr-tooltip-content>
    </clr-tooltip>
  </div>
  <br />
  <clr-datagrid id="masterdataFieldsGrid">
    <clr-dg-column class="no-print two-buttons-column" *ngIf="isOrderNeeded">
      <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
        <clr-icon clrTooltipTrigger class="edit-btn" shape="arrow" dir="up" size="16" (click)="moveUp($event)"></clr-icon>
        <clr-tooltip-content class="tooltip-content">{{ 'SHARED.MOVE_UP' | translate }}</clr-tooltip-content>
      </clr-tooltip>
      <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
        <clr-icon clrTooltipTrigger class="edit-btn" shape="arrow" dir="down" size="16" (click)="moveDown($event)"></clr-icon>
        <clr-tooltip-content class="tooltip-content">{{ 'SHARED.MOVE_DOWN' | translate }}</clr-tooltip-content>
      </clr-tooltip>
    </clr-dg-column>
    <clr-dg-column>{{ 'PRODUCT.PROPERTY.BUYERFIELDS_FIELD' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'PRODUCT.PROPERTY.BUYERFIELDS_NAME' | translate }}</clr-dg-column>
    <clr-dg-column class="no-print two-buttons-column">&nbsp;</clr-dg-column>
    <clr-dg-placeholder>{{'SHARED.NO_DATA' | translate }}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let item of selectedFields">
      <clr-dg-cell class="no-print two-buttons-cell" *ngIf="isOrderNeeded">
        <input type="checkbox" clrCheckbox name="isCollapsed" [(ngModel)]="item.selected" />
      </clr-dg-cell>
      <clr-dg-cell>{{getMasterDataFieldValue(item.masterDataListFieldId)}}</clr-dg-cell>
      <clr-dg-cell>{{item.name}}</clr-dg-cell>
      <clr-dg-cell class="no-print two-buttons-cell">
        <clr-tooltip clrTooltipTrigger role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="edit-btn" shape="edit" size="16" (click)="edit(item, $event)"></clr-icon>
          <span class="tooltip-content">{{ 'SHARED.EDIT' | translate }}</span>
        </clr-tooltip>
        <clr-tooltip clrTooltipTrigger role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="delete-btn" shape="trash" size="16" (click)="delete(item, $event)"></clr-icon>
          <span class="tooltip-content">{{ 'SHARED.DELETE' | translate }}</span>
        </clr-tooltip>
      </clr-dg-cell>
    </clr-dg-row>
  </clr-datagrid>

  <dx-popup #buyerFieldDetails [width]="600" [showTitle]="true" title="{{ 'EDIT ITEM' }}" [dragEnabled]="false"
            [closeOnOutsideClick]="false">
    <div class="au-dx-popup-padder">
      <dx-form #buyerFieldDetailsForm [showValidationSummary]="false" colCount="auto" [(formData)]="currentItem" [minColWidth]="500">
        <dxi-item dataField="masterDataListFieldId" editorType="dxSelectBox"
                  [editorOptions]="{ dataSource: fieldTypes, valueExpr:'id', displayExpr:'description' }">
          <dxo-label text="{{ 'PRODUCT.PROPERTY.BUYERFIELDS_FIELD' | translate }}"></dxo-label>
          <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="name">
          <dxo-label text="{{ 'PRODUCT.PROPERTY.BUYERFIELDS_NAME' | translate }}"></dxo-label>
          <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
        </dxi-item>
      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnmasterDataGridSubmit">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </div>
    </dx-popup>
</div>
