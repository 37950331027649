export enum ProductPropertyTypeEnum {
  'NUMBER' = 1,
  'DECIMAL' = 2,
  'TEXT' = 3,
  'DATE' = 4,
  'MASTER_DATA' = 5,
  'IMAGE' = 6,
  'BOOLEAN' = 7,
  'URL' = 8,
  'COLOR' = 9
}