import { Component, ViewChild, OnInit, Injector, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { SaleUnitComponent } from './sale-unit.component';

// models
import { Language } from '../../shared/models/language';
import { SaleUnit } from '../../shared/models/product';

import { PriceUnit } from '../shared/models/price-unit';

// services
import { LanguageService } from '../../shared/services/language.service';


@Component({
  selector: 'sale-units-component',
  templateUrl: 'sale-units.component.html'
})
export class SaleUnitsComponent extends SimpleListComponent implements OnInit {

  auctionClusterId: number;
  languages: Array<Language> = [];
  items: Array<SaleUnit> = [];
  editingIndex: number;
  pageSize = 50;

  @Input('data')
  set data(value: Array<SaleUnit>) {
      this.items = value;
      this.translateSaleUnits();
  }

  @Input('languages')
  set language(value: Array<Language>) {
    this.languages = value;
  }

  @Input('priceUnit') priceUnit: PriceUnit;

  @ViewChild(SaleUnitComponent) detailsComponent: SaleUnitComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private route: ActivatedRoute
  ) {
    super(injector);
  }

  ngOnInit() {
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.setTranslations('SALE_UNIT');
  }

  edit(saleUnit: SaleUnit, event: Event) {
    this.editingIndex = this.items.indexOf(saleUnit);
    event.stopPropagation();

    this.detailsComponent.modalTitle = this.translations.EDIT;
    const clone = { ...saleUnit };
    this.detailsComponent.open(this.items, clone, this.priceUnit, this.languages);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, new SaleUnit(), this.priceUnit, this.languages);
  }

  onSaleUnitAdded(saleUnit: SaleUnit) {
    this.items.push(saleUnit);
    this.translateSaleUnits();
  }

  onSaleUnitUpdated(saleUnit: SaleUnit) {
    this.items[this.editingIndex] = saleUnit;
    this.translateSaleUnits();
  }

  delete(saleUnit: SaleUnit) {
    this.items.splice(this.items.indexOf(saleUnit), 1);
  }

  translateSaleUnits() {
    this.items.forEach(saleUnit => {
      saleUnit.saleUnitNameText = this.languageService.getTranslatableText(saleUnit.name);
    });
  }

  getTranslation(value: string) {
    if (value) {
      return this.languageService.getTranslatableText(value);
    }
  }
}
