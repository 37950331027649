import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { Lottery } from '../models/lottery';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class LotteryService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getExistingLottery(auctionClusterId: number, catalogId: number, lastXSupplierCount: number, salesDate: Date): Observable<Lottery> {

    const url = this.apiPath + '/' + auctionClusterId + '/lottery?catalogId=' + catalogId + '&lastXSupplierCount=' + lastXSupplierCount + '&salesDate=' + ((salesDate.getMonth() + 1).toString().padStart(2, '0')) + '-' + ((salesDate.getDate()).toString().padStart(2, '0')) + '-' + salesDate.getFullYear();

    return this.webApiService.getSingle(url);
  }

  getLottery(auctionClusterId: number, catalogId: number, lastXSupplierCount: number, returnLottery: boolean, salesDate: Date): Observable<Lottery> {

    const url = this.apiPath + '/' + auctionClusterId + '/lottery?catalogId=' + catalogId + '&lastXSupplierCount=' + lastXSupplierCount + '&returnLottery=' + returnLottery + '&salesDate=' + ((salesDate.getMonth() + 1).toString().padStart(2, '0')) + '-' + ((salesDate.getDate()).toString().padStart(2, '0')) + '-' + salesDate.getFullYear();
     
    return this.webApiService.getSingle(url);
  }

  saveLottery(auctionClusterId: number, catalogId: number, isLottery: boolean, lottery: Lottery) {
    const url = this.apiPath + '/' + auctionClusterId + '/lottery?catalogId=' + catalogId + '&isLottery=' + isLottery;
    return this.webApiService.save(url, lottery);
  }
}
