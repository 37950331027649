export enum DesiredAccess {
  BUYER = 0,
  SUPPLIER = 1,
}

export enum AccountType {
  EXISTING = 1,
  NEW = 2,
}

export class RegisterAssignAccount {
  desiredAccess: number;
  buyerAccountType: number;
  supplierAccountType: number;

  buyerId: number;
  buyerRoleId: number;
  buyerName: string;
  buyerAddress1: string;
  buyerAddress2: string;
  buyerZip: string;
  buyerCity: string;
  buyerCountry: string;
  buyerVat: string;
  buyerTelNumber: string;
  buyerFaxNumber: string;
  buyerEmail: string;
  buyerUrl: string;

  supplierId: number;
  supplierRoleId: number;
  supplierName: string;
  supplierAddress1: string;
  supplierAddress2: string;
  supplierZip: string;
  supplierCity: string;
  supplierCountry: string;
  supplierVat: string;
  supplierTelNumber: string;
  supplierFaxNumber: string;
  supplierEmail: string;
  supplierUrl: string;

  static default() {
    const registerAssignAccount = new RegisterAssignAccount();
    registerAssignAccount.desiredAccess = DesiredAccess.BUYER;
    registerAssignAccount.buyerAccountType = AccountType.EXISTING;
    registerAssignAccount.supplierAccountType = AccountType.EXISTING;

    return registerAssignAccount;
  }
}
