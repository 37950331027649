// models
import { AuctionCluster } from "../../../shared/models/auction-cluster";
import { Calculation } from "../../../shared/models/calculation";
import { Report } from "../../../shared/models/report";


export class AuctionClusterImport {
  auctionCluster: AuctionCluster;
  calculations: Array<Calculation>;
  reports: Array<Report>;
  includeAuction: boolean;
  includeAuctionCluster: boolean;
  includeCalculation: boolean;
  includeCatalog: boolean;
  includeClock: boolean;
  includeLookupTable: boolean;
  includeMask: boolean;
  includeMasterDataList: boolean;
  includeProduct: boolean;
  includeReport: boolean;

  constructor() {
    this.includeAuction = false;
    this.includeAuctionCluster = false;
    this.includeCalculation = false;
    this.includeCatalog = false;
    this.includeClock = false;
    this.includeLookupTable = false;
    this.includeMask = false;
    this.includeMasterDataList = false;
    this.includeProduct = false;
    this.includeReport = false;
  }
}
