import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';

// services
import { AuthService } from './auth.service';

@Injectable()
export class Auth0Guard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.authService.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.authService.handleAuth0SetSession(authResult, environment.version);
      }
      else if (err) {
        console.log('Auth0 error in parseHash: ' + err);
        this.authService.auth0Logout('');
      }
    });

    return true;
  }
}
