import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { Calculation } from '../../../shared/models/calculation';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class CalculationsService {
  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster/';
  }

  getCalculations(auctionClusterId: number, langCode: string): Observable<Array<Calculation>> {
    return this.webApiService.getList(this.apiPath + `${auctionClusterId}/calculation?langCode=${langCode}`);
  }

  getCalculation(auctionClusterId: number, calculationId: number, langCode: string): Observable<Calculation> {
    return this.webApiService.getList(this.apiPath + `${auctionClusterId}/calculation/${calculationId}?langCode=${langCode}`);
  }

  getCalculationForPropertyType(auctionClusterId: number, productId: number, propertyTypeId: number) {
    return this.webApiService.getList(this.apiPath + `${auctionClusterId}/calculation/product/${productId}/propertytype/${propertyTypeId}`);
  }

  save(calculation: Calculation): Observable<any> {
    return this.webApiService.save(this.apiPath + `${calculation.auctionClusterId}/calculation`, calculation);
  }

  edit(calculation: Calculation): Observable<any> {
    return this.webApiService.edit(this.apiPath + `${calculation.auctionClusterId}/calculation`, calculation.calculationId, calculation);
  }

  delete(calculationId: number, auctionClusterId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + `${auctionClusterId}/calculation`, calculationId);
  }
}