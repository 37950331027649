<div class="shopping-list-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #addShoppingList
  [width]="600"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #userForm
                 id="userForm"
                 [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">
            <dxi-item itemType="group" caption="{{ 'SHARED.GENERAL' | translate }}">
            <dxi-item dataField="name">
                <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="description" editorType="dxTextArea" [editorOptions]="{ height: 90 }" [colSpan]="2">
                <dxo-label text="{{ 'SHARED.DESCRIPTION' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="shoppingListReferenceCatalogId"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: catalogs, valueExpr:'catalogId' ,
                  displayExpr:'name'}">
                <dxo-label text="{{ 'SHARED.CATALOG' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.CATALOG' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
          </dxi-item>

        </dx-form>

        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnShoppingListSubmit" disabled="disabled">
        {{ 'SHARED.SAVE' | translate }}
        </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
