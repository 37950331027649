<dx-data-grid #auctionClusterNotificationsGrid
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [columnAutoWidth]="true"
                [rtlEnabled]="rtlEnabled"
                [dataSource]="items"
                [showBorders]="true"
                (onCellClick)="edit($event)"
                [hoverStateEnabled]="true"
                [rowAlternationEnabled]="true">
          <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
          <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
          <dxo-filter-panel [visible]="true"></dxo-filter-panel>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-search-panel [visible]="true"></dxo-search-panel>
          <dxo-selection mode="single"></dxo-selection>
          <dxi-column dataType="boolean" dataField="isActive" caption="{{ 'SHARED.ACTIVE' | translate }}">
          </dxi-column>
          <dxi-column dataType="string" dataField="nameText" caption="{{ 'SHARED.NAME' | translate }}">
          </dxi-column>
          <dxi-column dataType="string" dataField="titleText" caption="{{ 'NOTIFICATION.TITLE' | translate }}">
          </dxi-column>
          <dxi-column dataType="string" dataField="activeChannelsString" caption="{{ 'NOTIFICATION.ASSIGNED_CHANNELS' | translate }}">
          </dxi-column>
          <dxi-column dataType="string" dataField="reportName" caption="{{ 'NOTIFICATION.REPORT_NAME' | translate }}">
          </dxi-column>
          <dxi-column dataType="date" dataField="lastTimeSent" caption="{{ 'NOTIFICATION.LAST_SENT' | translate }}">
          </dxi-column>
          <dxi-column type="buttons">
            <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
            <dxi-button hint="{{ 'NOTIFICATION.RESET_CHANNELS' | translate }}" icon="refresh"  [onClick]="resetChannelsForAllUsersToDefault" ></dxi-button>    
          </dxi-column> 
</dx-data-grid>

<notification-component #details
                            (onClosed)="onDetailsComponentClosed($event)"></notification-component>
