<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
    <dx-button hint="{{ 'SHARED.PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
  </div>
  <br />
  <dx-data-grid #masksGrid
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [dataSource]="items"
                [showBorders]="true"
                (onRowClick)="onRowClick($event)"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true" [showInfo]="true" [visible]="true" infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="sourceType" caption="{{ 'MASK.SOURCE_TYPE' | translate }}" [customizeText]="getMaskSourceTypeName">
    </dxi-column>
    <dxi-column type="buttons" [width]="90">
      <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="editItem"></dxi-button>
      <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
<mask-component #details (onClosed)="onDetailsComponentClosed($event)"></mask-component>
<confirmation-component #confirmation title="{{ 'MASK.DELETE' | translate }}"
                        message="{{ 'MASK.DELETE_MESSAGE' | translate }}"
                        buttonCloseText="{{ 'SHARED.DELETE' | translate }}" cancellable="true" (close)="deleteSelected();"></confirmation-component>
