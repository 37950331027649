import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, map, Subscription } from 'rxjs';
import DataGrid from 'devextreme/ui/data_grid';
import { ActivatedRoute } from '@angular/router';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ShoppingListLotComponent } from './shopping-list-lot.component';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { Language } from '../../shared/models/language';
import { Lot, LotProperty } from '../../shared/models/lot';
import { MasterData } from '../../shared/models/master-data';
import { Product } from '../../shared/models/product';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';

import { ShoppingList } from '../shared/models/shopping-list';
import { ShoppingListUpdated } from '../shared/models/shopping-list-updated';

// services
import { TranslateService } from '@ngx-translate/core';
import { ServiceFactory } from '../../shared/factory/service-factory';
import { DateTimeService } from '../../shared/services/datetime.service';
import { LanguageService } from '../../shared/services/language.service';
import { WebApiService } from '../../shared/services/web-api.service';

import { MasterDataService } from '../shared/services/master-data.service';
import { ShoppingListsService } from '../shared/services/shopping-lists.service';

export class MasterDataDropdown {
  value: any;
  label: string;
}

@Component({
  selector: 'shopping-list-lots-component',
  templateUrl: 'shopping-list-lots.component.html',
  styleUrls: ['./shopping-list-lots.component.scss']
})
export class ShoppingListLotsComponent extends FullListComponent<Lot, ShoppingListLotComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ShoppingListLotComponent;

  masterDataService: MasterDataService;

  languages: Array<Language> = [];
  catalogId: number;
  shoppingList: ShoppingList = new ShoppingList();
  product: Product = new Product();
  shoppingListPropertyFields = [];
  masterDatas: Array<MasterData> = [];
  masterDataDropdowns: Array<any> = [];
  firstLoad = true;
  JSON: any = JSON;
  purchased100PercentColorCode = '#d1ffda';
  purchased75OrMorePercentColorCode = '#a8bee0';
  purchasedLessThan75PercentColorCode = '#f2d5d5';

  dataGridInstance: DataGrid;
  initGrid = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private languageService: LanguageService,
    private dataService: ShoppingListsService,
    private dateTimeService: DateTimeService,
    private translateService: TranslateService
  ) {
    super(injector, Lot);
    this.getServices(route, appSettings, webApiService);

    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
      this.refreshColumnHeaders();
    });
  }

  ngOnInit() {
    this.catalogId = +this.route.snapshot.params['catalogId'];
    this.setTranslations('SHOPPING_LIST');

    this.dataService.shoppingListUpdated.subscribe((shoppingListUpdated: ShoppingListUpdated) => {
      if (shoppingListUpdated.buyerId !== this.id) {
        return;
      }

      // refresh list
      this.getData();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.masterDataService = ServiceFactory.getMasterDataService(document.location.hash, route, appSettings, webApiService) as MasterDataService;
  }

  getData = () => {
    this.spinner.show();
    this.dataService.getShoppingList(this.id, +this.route.snapshot.params['catalogId']).subscribe(pb => {
      pb.shoppingListProductDefinitionBuyer.forEach(productPropertyOrder => {
        let name = this.getTranslation(productPropertyOrder.name);
        if (productPropertyOrder.fieldName) {
          const fieldName = this.getTranslation(productPropertyOrder.fieldName);
          name = `${name} (${fieldName})`;
        }
        productPropertyOrder.name = name;
      });

      this.shoppingList = pb;
      this.title.set('SHOPPING_LIST.TITLE');
      this.title.add(this.shoppingList.name);

      forkJoin(
        this.dataService.getLots(this.id, this.shoppingList.auctionClusterId, this.shoppingList.shoppingListId),
        this.languageService.getLanguages()
      ).subscribe((result: Array<any>) => {
        this.items = result[0];
        this.languages = result[1];
        // tslint:disable-next-line:no-magic-numbers

        this.generateTable();
        if (this.firstLoad) {
          this.getMasterDataLists();
          this.firstLoad = false;
        } else {
          this.parseData();
        }
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  getMasterDataLists() {
    const observables = [];

    this.shoppingList.shoppingListProductDefinitionBuyer.filter(ppo => ppo.masterDataListId != null).forEach(productPropertyOrder => {
      observables.push(this.masterDataService.getMasterDataForReports(this.shoppingList.auctionClusterId, productPropertyOrder.masterDataListId, false)
        .pipe(map((result: MasterData) => result)));
    });

    forkJoin(observables).subscribe(res => {
      res.forEach((result: MasterData) => {
        this.masterDatas.push(result);
      });
      this.parseData();
    });
  }

  async parseData() {
    for await (var i of this.items) {
      for await (var productPropertyOrder of this.shoppingList.shoppingListProductDefinitionBuyer) {
        const lotProperty = i.lotProperties.find(lp => lp.productPropertyId === productPropertyOrder.productPropertyId);
        if (lotProperty) {
          const displayLotProp = JSON.parse(JSON.stringify(lotProperty));
          displayLotProp.masterDataListId = productPropertyOrder.masterDataListId;
          displayLotProp.displayValue = await this.findValue(lotProperty, productPropertyOrder);
          if (!i.displayLotProperties) {
            i.displayLotProperties = [];
          }
          i.displayLotProperties.push(displayLotProp);
        }
      };

      i.percentageBought = i.amountBought > 0 ? i.amountBought / i.amountWanted : 0;

      if (i.percentageBought >= 1) {
        i.rowColor = this.purchased100PercentColorCode;
      } else if (i.percentageBought < 1 && i.percentageBought >= 0.75) {
        i.rowColor = this.purchased75OrMorePercentColorCode;
      } else {
        i.rowColor = this.purchasedLessThan75PercentColorCode;
      }
    };

    let sortBy = [];
    this.shoppingList.shoppingListProductDefinitionBuyer.forEach((column, i) => {
      sortBy.push({ prop: 'key' + i, direction: 1 });
    });

    this.items.sort(function (a, b) {
      let i = 0, result = 0;
      while (i < sortBy.length && result === 0) {
        result = sortBy[i].direction * (a.displayLotProperties[i].displayValue < b.displayLotProperties[i].displayValue ? -1 : (a.displayLotProperties[i].displayValue > b.displayLotProperties[i].displayValue ? 1 : 0));
        i++;
      }
      return result;
    })
  }

  async findValue(value: any, prop: any): Promise<any> {
    if (prop.propertyTypeId === ProductPropertyTypeEnum.DECIMAL && value.decimalValue) {
      return value.decimalValue;
    } else if (prop.propertyTypeId === ProductPropertyTypeEnum.NUMBER && value.intValue) {
      return value.intValue;
    } else if (prop.propertyTypeId === ProductPropertyTypeEnum.TEXT && value.stringValue) {
      return prop.translatable ? this.getTranslation(value.stringValue) : value.stringValue;
    } else if (prop.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN) {
      return value.booleanValue ? 'true' : 'false';
    } else if (prop.propertyTypeId === ProductPropertyTypeEnum.DATE && value.dateTimeValue) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(value.dateTimeValue, prop.propertyTypeFormatId);
    } else if (prop.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA && value.masterDataListRowId) {
      return await this.getMasterDataValue(value, prop.masterDataListId);
    }
    return '';
  }

  async getMasterDataValue(lotProperty: LotProperty, masterdataListId: number) {
    var masterDataListRowId = lotProperty.masterDataListRowId;
    if (masterDataListRowId == undefined || masterdataListId == undefined) {
      return '';
    }
    var result = await this.masterDataService.getMasterDataValueForDropdown(masterdataListId, this.shoppingList.auctionClusterId, this.translateService.currentLang, masterDataListRowId).toPromise();
    if (result.data && result.data.length > 0) {
      return result.data[0].label;
    }
    return '';
  }

  edit = (e: any) => {
    this.detailsComponent.modalTitle = this.translations.EDIT;
    const lot = this.items.find(_ => _.lotId === e.row.data.lotId);
    this.detailsComponent.open(lot, this.languages, this.shoppingList, this.masterDataDropdowns);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(null, this.languages, this.shoppingList, this.masterDataDropdowns);
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.deleteLot(this.id, this.shoppingList.auctionClusterId, this.itemIdToDelete)
      .subscribe((lots: Array<Lot>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  generateTable = () => {
    if (!this.initGrid) {
      this.initGrid = true;
      this.shoppingList.shoppingListProductDefinitionBuyer.sort((a, b) => a.orderNumber - b.orderNumber).forEach((row: any, i: number) => {

        this.dataGridInstance.addColumn({
          dataField: 'displayLotProperties[' + i + '].displayValue',
          caption: this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          dataType: this.getDataType(row.productPropertyId, row.propertyTypeFormatId),
          encodeHtml: false,
          editorOptions: {
            showClearButton: true
          }
        });
      });
    }
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  onRowPrepared = (e: any) => {
    if (this.items && e.rowType === 'data') {
      let lot = this.items.find(_ => _.lotId === e.data.lotId);
      if (lot && lot.rowColor) {
        e.rowElement.style.backgroundColor = lot.rowColor;
      }
    }
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.lotId;
    this.confirmation.opened = true;
  }

  refreshColumnHeaders = () => {

    this.dataService.getShoppingList(this.id, +this.route.snapshot.params['catalogId']).subscribe(pb => {
      pb.shoppingListProductDefinitionBuyer.forEach((productPropertyOrder, i) => {
        let name = this.getTranslation(productPropertyOrder.name);
        if (productPropertyOrder.fieldName) {
          const fieldName = this.getTranslation(productPropertyOrder.fieldName);
          name = `${name} (${fieldName})`;
        }
        this.shoppingList.shoppingListProductDefinitionBuyer[i].name = name;
        this.dataGridInstance.columnOption('displayLotProperties[' + i + '].displayValue', 'caption', name);
      });
    });
  }
}
