<dx-date-box
  type="datetime"
  displayFormat="dd/MM/yyyy, HH:mm:ss"
  #dateInput
  [attr.name]="nameString"
  [attr.tabindex]="tabIndexValue"
  placeholder="Full datetime"
  class="focusable"
  [(value)]="time"
  (ngModelChange)="changeModelState()"
  (blur)="onblurEvent()"
  [required]="requiredValue"
  [readonly]="disabledValue"
  [min]="minValue"
  openOnFieldClick="true"
  (onFocusOut)="onblurEvent()"
  (onFocusIn)="onFocusIn()"
  (onInitialized)="onInitialized.emit($event)"
  [disabled]="disabledValue">
</dx-date-box>
