<div class="report-component">
  <div class="card" *ngIf="filters.length > 0">
    <div class="card-header">
      {{ reportName }}
    </div>
    <div class="card-block">
      <div *ngIf="showFilterTemplateSection">
        <div class="filter-template-column">
          <ng-select [items]="userReportTemplates"
                     class="filter-template-select"
                     bindLabel="name"
                     (change)="onCurrentUserReportTemplateChanged($event)"
                     [(ngModel)]="currentUserReportTemplate">
          </ng-select>
        </div>
        <div class="filter-template-column">
          <button type="button" class="btn btn-icon btn-primary" (click)="saveAsCurrentUserReportTemplate()">
            <clr-icon shape="floppy"></clr-icon>{{ 'SHARED.SAVE' | translate }}
          </button>
        </div>
        <div class="filter-template-column">
          <button type="button" class="btn btn-icon btn-primary" (click)="openSaveAsNewUserReportTemplateDialog()">
            <clr-icon shape="plus"></clr-icon>{{ 'SHARED.SAVE_AS_NEW' | translate }}
          </button>
        </div>
        <div class="filter-template-column">
          <button type="button" class="btn btn-icon btn-primary" (click)="openUserReportTemplateManagerDialog()">
            <clr-icon shape="list"></clr-icon>{{ 'REPORTING.USER_REPORT_TEMPLATE.MANAGER' | translate }}
          </button>
        </div>
      </div>
      <div *ngFor="let filter of filters" class="filter-column">
        <!-- Date -->
        <div *ngIf="filter.propertyTypeId == 4">
          <label>{{ getTranslation(filter.name) }}</label>
          <ng-select ngDefaultControl class="filter-select" name="filter.{{filter.name}}"
                     [(ngModel)]="dateFiltersCascadingDefinitions[filter.productPropertyId]">
            <ng-option value="today">Today</ng-option>
            <ng-option value="yesterday">Yesterday</ng-option>
            <ng-option value="last-week">Last week</ng-option>
            <ng-option value="range">Range</ng-option>
          </ng-select>
          <div *ngIf="dateFiltersCascadingDefinitions[filter.productPropertyId] == 'range'">
            <!-- <label>Date</label> -->
            <input placeholder="Date Time Range:" [style.width]="'350px'"
                   [(ngModel)]="filterBindings[filter.productPropertyId]" [selectMode]="'range'"
                   [owlDateTimeTrigger]="dtRange1" [owlDateTime]="dtRange1">
            <owl-date-time [pickerType]="'calendar'" #dtRange1></owl-date-time>
          </div>
        </div>
        <!-- Master data value -->
        <div *ngIf="filter.propertyTypeId == 5">
          <label>
            {{ getTranslation(filter.name) }}
          </label>
          <ng-select ngDefaultControl class="filter-select" [(ngModel)]="filterBindings[filter.productPropertyId]">
            <ng-option *ngFor="let option of filter.options" [value]="option.value">{{option.text}}</ng-option>
          </ng-select>
        </div>
      </div>
      <div *ngIf="hasAuctionFilter" class="filter-column">
        <label>
          {{ 'SHARED.AUCTION' | translate }}
        </label>
        <ng-select ngDefaultControl class="filter-select" [(ngModel)]="auctionId" (change)="onAuctionChanged($event)" (clear)="onAuctionCleared()">
          <ng-option *ngFor="let auction of auctionOptions" [value]="auction.auctionId">{{auction.name}}</ng-option>
        </ng-select>
      </div>
      <div *ngIf="hasCatalogFilter" class="filter-column">
        <label>
          {{ 'SHARED.CATALOG' | translate }}
        </label>
        <ng-select ngDefaultControl class="filter-select" [(ngModel)]="catalogId">
          <ng-option *ngFor="let catalog of catalogOptions" [value]="catalog.catalogId">{{catalog.name}}</ng-option>
        </ng-select>
      </div>
      <div class="filter-button">
        <button type="button" class="btn btn-primary"
                (click)="filterReport()">
          {{ 'REPORTING.FILTER' | translate }}
        </button>
      </div>
      <div class="card-header">
        {{ reportName }}
      </div>
    </div>
  </div>

  <dx-report-viewer #reportViewer [reportUrl]="reportUrl" height="800px">
    <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
    <dxrv-export-settings [showPrintNotificationDialog]="false"></dxrv-export-settings>
  </dx-report-viewer>
</div>
