import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { GenericLot } from '../../../shared/models/generic-lot';
import { PagedLots, Lot } from '../../../shared/models/lot';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class LotService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getCatalogLot(auctionId: number, catalogId: number): Observable<Array<Lot>> {
    return this.webApiService.getSingle(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/lot');
  }

  checkLotActions(auctionId: number, lotId: number): Observable<boolean> {
    return this.webApiService.getSingle(this.apiPath + '/auction/' + auctionId + '/catalog/lot/' + lotId + '/checkactions');
  }

  getMasterDetails(auctionClusterId: number, catalogId: number, isForecast = false) : Observable<GenericLot> {
    return this.webApiService.getList(this.apiPath + `/${auctionClusterId}/catalog/${catalogId}/${isForecast ? 'masterforecast' : 'mastersupply'}`);
  }

  fillProperties(auctionId: number, productPropertyId: number, masterDetailsLotId: number, lot: Lot, isTouch?: boolean): Observable<Lot> {
    let queryString = '';
    if (masterDetailsLotId) {
      queryString += `?previousLotId=${masterDetailsLotId}`;
    }
    if (isTouch) {
        queryString += (masterDetailsLotId ? '&' : '?') + `isTouch=${true}`;    
      }
    return this.webApiService.save(this.apiPath + '/auction/' + auctionId + '/catalog/lot/fillproperties/' + productPropertyId + queryString, lot);
  }

  getCatalogLotPaged(auctionId: number, catalogId: number, from: number, pageSize: number,
    sortBy?: number, ascending?: boolean, search?: string, lotId?: number): Observable<Array<Lot>> {
    let queryString = '';
    if (sortBy || search) {
      queryString += '?';
      if (sortBy) {
        queryString += `sortPropertySelectionId=${sortBy}&sortAscending=${ascending}`;
      }
      if (search) {
        if (sortBy) {
          queryString += '&';
        }
        queryString += `searchString=${search}`;
      }
    }
    return this.webApiService
    .getSingle(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/lot/sort/' + from + '/' + pageSize + queryString);
  }

  getCatalogLotSearched(auctionId: number, catalogId: number, language: string, from: number, pageSize: number,
    sort?: any, filter?: Array<any>, lotId?: number): Observable<PagedLots> {
    let queryString = `?timezoneoffset=${new Date().getTimezoneOffset()}`;
    if (language) {
      queryString += `&language=${language}`;
    }
    if (sort) {
      if (sort.by) {
        queryString += `&sortPropertySelectionId=${sort.by}&sortAscending=${!!sort.reverse}`;
      }
    }
    const selections = [];
    if (filter) {
      filter.forEach(f => {
        selections.push({
          'key': f.property,
          'value': f.value
        });
      });
    }

    if (lotId) {
      queryString += `&lotId=${lotId}`;
    }
    return this.webApiService.save(this.apiPath + '/auction/' + auctionId + '/catalog/' +
      catalogId + '/lot/search/' + from + '/' + pageSize + queryString, { selections });
  }

  loadCatalogLotSearched(auctionId: number, catalogId: number, language: string,loadOptions: any, lotId?: number ) 
  {
    let queryString = `?timezoneoffset=${new Date().getTimezoneOffset()}`;
    if (language) {
      queryString += `&language=${language}`;
    }

    if (lotId) {
      queryString += `&lotId=${lotId}`;
    }
    
    let params: HttpParams = new HttpParams();
                [
                    "skip",
                    "take",
                    "requireTotalCount",
                    "requireGroupCount",
                    "sort",
                    "filter",
                    "totalSummary",
                    "group",
                    "groupSummary"
                ].forEach(function(i) {
                    if (i in loadOptions && loadOptions[i] != undefined)
                        params = params.set(i, JSON.stringify(loadOptions[i]));
                });
    
    return this.webApiService.getWithParams(this.apiPath + '/auction/' + auctionId + '/catalog/' +
      catalogId + '/lot/load' + queryString, params);
  }

  getLot(auctionId: number, lotId: number, isTouch?: boolean): Observable<Lot> {
    let queryString = '';
    if (isTouch) {
        queryString = `?isTouch=${true}`;    
      }
    return this.webApiService.getSingle(this.apiPath + '/auction/' + auctionId + '/catalog/lot/' + lotId + queryString);
  }

  getNew(auctionId: number, catalogId: number, lotId?: number, isTouch?: boolean): Observable<Lot> {
    let queryString = '';
    if (lotId) {
      queryString += `?lotId=${lotId}`;
    }
    if (isTouch) {
        queryString += (lotId ? '&' : '?') + `isTouch=${true}`;    
      }
    return this.webApiService.getSingle(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/lot/new' + queryString);
  }

  save(auctionId: number, lot: Lot, isTouch?: boolean): Observable<any> {
    let queryString = '';
    if (isTouch) {
        queryString = `?isTouch=${true}`;    
      }
    return this.webApiService.save<Object>(this.apiPath + '/auction/' + auctionId + '/catalog/lot' + queryString, lot);
  }

  edit(auctionId: number, lot: Lot, isTouch?: boolean): Observable<any> {
    let queryString = '';
    if (isTouch) {
        queryString = `?isTouch=${true}`;    
      }
    return this.webApiService.editSingle<Lot>(this.apiPath + '/auction/' + auctionId + '/catalog/lot/'+ lot.lotId + queryString, lot);
  }

  delete(auctionId: number, lotId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/auction/' + auctionId + '/catalog/lot', lotId);
  }

  multiDelete(auctionId: number, lotIds: Array<number>): Observable<any> {
    return this.webApiService.deleteSingle(this.apiPath + '/auction/' + auctionId + '/catalog/lot', lotIds);
  }

  calculateAutoIncrement(auctionId: number, calculationId: number, lot: Lot): Observable<number> {
    return this.webApiService.save(this.apiPath + '/auction/' + auctionId + '/catalog/lot/autoincrement/' + calculationId, lot);
  }

  getMultiEdit(auctionId: number, catalogId: number, lotId: number, productPropertyIds: Array<number>): Observable<any> { 
    return this.webApiService.save(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/lot/' + lotId + '/getmultiedit', { 'ids': productPropertyIds });
  }

  applyMultiEdit(auctionId: number, catalogId: number, lotId: number, lot: Lot, productProperties: Array<number>, lotIds: Array<number>): Observable<Array<Lot>> { 
    return this.webApiService.save(this.apiPath + '/auction/' + auctionId + '/catalog/' + catalogId + '/lot/' + lotId + '/applymultiedit',
     {
       'productProperties': productProperties,
       'lotIds': lotIds,
       'lot': lot
     });
  }

  printSupply(auctionClusterId: number, catalogId: number,  lotIds: Array<number>, masterLot: boolean): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/catalog/${catalogId}/printsupplytickets/masterLot/${masterLot}`, { 'ids': lotIds });
  }

  addLotImage(auctionId: number, formData: FormData): Observable<string> {
    return this.webApiService.uploadImage(this.apiPath + '/auction/' + auctionId + '/catalog/lot/uploadimage', formData);
  }
}
