export class ProductPropertyStyling {
  productPropertyStylingId: number;
  name: string;
  sysName: string;

  fontSize: number;
  backgroundColor: string;
  borderColor: string;
  color: string;
  font: string;
}
