<div class="buyer-clocks-component">
  <div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
    <dx-data-grid #buyerClocksGrid
                  id="printSection"
                  [rtlEnabled]="rtlEnabled"
                  [dataSource]="products"
                  [showBorders]="true"
                  (onRowClick)="onRowClick($event)"
                  [allowColumnReordering]="true"
                  [hoverStateEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true">
      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-pager [visible]="true"
                 [showPageSizeSelector]="true"
                 [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showNavigationButtons]="true"
                 [showInfo]="true"
                 infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="auctionClusterName" caption="{{ 'SHARED.AUCTION_CLUSTER' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'BUYER.PRODUCT_NAME' | translate }}" [customizeText]="translateField">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="description" caption="{{ 'BUYER.PRODUCT_DESCRIPTION' | translate }}" [customizeText]="translateField">
      </dxi-column>
      <dxi-column type="buttons" [width]="120" cellTemplate="diffCellTemplate">
      </dxi-column>

      <div *dxTemplate="let data of 'diffCellTemplate'">
        <a (click)="startClock(id, data.row.data.productId, data, 1)" class="dx-link" *ngIf="isBuyOnClock();"><i title="{{ 'BUYER.START_CLOCK' | translate }}" class="dx-icon-clock"></i></a>
        <a (click)="startClock(id, data.row.data.productId, data, 2)" class="dx-link" *ngIf="isViewer() || isBuyOnClock() || isProjection();"><i title="{{ 'BUYER.START_CLOCK_AS_VIEWER' | translate }}" class="fa fa-eye"></i></a>
        <a (click)="startClock(id, data.row.data.productId, data, 3)" class="dx-link" *ngIf="isProjection();"><i title="{{ 'BUYER.START_CLOCK_AS_PROJECTION' | translate }}" class="dx-icon-video"></i></a>
        <a (click)="startClock(id, data.row.data.productId, data, 5)" class="dx-link" *ngIf="isBuyOnClock();"><i title="{{ 'BUYER.START_CLOCK_AS_PRICE_OVERVIEW' | translate }}" class="dx-icon-detailslayout"></i></a>
      </div>
    </dx-data-grid>
  </div>
</div>
