<div class="transaction-property-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <div class="modal-body">
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>

      <form #detailsForm="ngForm" clrForm>

        <div class="clr-form-control">
          <label class="clr-control-label">{{ 'SHARED.NAME' | translate }}</label>
          <div class="clr-control-container">
            <translatable-field [(data)]="model.name" [name]="'name' + model.productPropertyId" [clusterLanguages]="languages" #name></translatable-field>
          </div>
        </div>

        <div class="clr-form-control">
          <label for="productProperty" class="clr-control-label">{{ 'PRODUCT.PROPERTY_NAME' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="productProperty" name="productProperty" [(ngModel)]="model.productPropertyId" (ngModelChange)="filterMasterDataFields(); setName();" required>
                <option *ngFor="let property of productProperties" [ngValue]="property.productPropertyId">{{ property.productPropertyNameText }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="isMasterData">
          <label for="masterDataField" class="clr-control-label required">{{ 'SHARED.MASTER_DATA_FIELD' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="masterDataField" name="masterDataField" [(ngModel)]="model.masterDataListFieldId" required>
                <option *ngFor="let field of masterDataListFields" [ngValue]="field.masterDataListFieldId">{{ field.name | translateJson }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="propertyGroupingShowing">
          <label for="automaticGroupingProperty" class="clr-control-label required">{{ 'PRODUCT.PROPERTY.AUTOMATIC_GROUPING_PROPERTY' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="automaticGroupingProperty" name="automaticGroupingProperty" [(ngModel)]="model.automaticProductPropertyGroupingSelectionId">
                <option></option>
                <option *ngFor="let prop of automaticGroupingProperties" [ngValue]="prop.id">{{ prop.label }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="propertyGroupingShowing">
          <label for="manualGroupingProperty" class="clr-control-label required">{{ 'PRODUCT.PROPERTY.MANUAL_GROUPING_PROPERTY' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="manualGroupingProperty" name="manualGroupingProperty" [(ngModel)]="model.manualProductPropertyGroupingSelectionId">
                <option></option>
                <option *ngFor="let prop of manualGroupingProperties" [ngValue]="prop.id">{{ prop.label }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showColRow">
          <label for="rowNumber" class="clr-control-label">{{ 'PRODUCT.PROPERTY.ROW_NUMBER' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input type="number" name="rowNumber" id="rowNumber" class="clr-input" [(ngModel)]="model.rowNumber" #rowNumber="ngModel" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="transactionInfoGroupingShow">
          <label for="transactionInfoGroupingShow" class="clr-control-label">{{ 'PRODUCT.PROPERTY.TRANSACTION_INFO_GROUPING' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input type="checkbox" clrCheckbox id="groupTransactionsByProperty" class="clr-input" name="groupTransactionsByProperty" [(ngModel)]="model.groupTransactionsByProperty" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showColRow">
          <label for="columnNumber" class="clr-control-label">{{ 'PRODUCT.PROPERTY.COLUMN_NUMBER' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input type="number" name="columnNumber" id="columnNumber" class="clr-input" [(ngModel)]="model.columnNumber" #columnNumber="ngModel" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showWidth">
          <label for="width" class="clr-control-label">{{ 'PRODUCT.PROPERTY.WIDTH_PERCENTAGE' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input id="width" type="number" name="width" #width="ngModel" class="clr-input" [(ngModel)]="model.width" min="0" max="100" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid">{{ (isEditMode ? 'SAVE' : 'ADD') | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
