export class Location {
  locationId: number;
  auctionClusterId: number;
  name: string;
  address1: string;
  address2: string;
  zip: string;
  city: string;
  country: string;
  isActive: boolean;
}
