import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import * as _moment from 'moment';

// models
import { Auction } from '../../shared/models/auction';
import { Catalog } from '../../shared/models/catalog';
import { Product } from '../../shared/models/product';
import { Cookies } from '../../shared/constants/cookies';

// services
import { LanguageService } from '../../shared/services/language.service';
import { AuctionService } from '../shared/services/auction.service';
import { CatalogService } from '../shared/services/catalog.service';
import { ProductService } from '../shared/services/product.service';
import { CookieService } from 'ngx-cookie-service';

import { ItemListComponent } from '../../shared/components/item-list/item-list.component';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'supplier-forecast-catalogs-component',
  templateUrl: 'supplier-forecast-catalogs.component.html',
  styleUrls: ['./supplier-forecast-catalogs.component.scss']
})
export class SupplierForecastCatalogsComponent extends ItemListComponent<Catalog> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;

  auctionClusterId: number;
  auctions: Array<Auction> = [];
  products: Array<Product> = [];
  selectedAuction: number = -1;
  catalogToCleanup: Catalog;
  catalogCleanupDate = new Date();
  confirmationErrorMessage: string;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: CatalogService,
    private auctionService: AuctionService,
    private productService: ProductService,
    private languageService: LanguageService,
    private cookieService: CookieService
  ) {
    super(injector, Catalog);
    this.title.set('AUCTION.FORECAST_CATALOG_MANAGEMENT');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('CATALOGS');  
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  // tslint:disable:no-magic-numbers
  getData() {
    this.spinner.show(); 
    this.auctionClusterId = +this.route.snapshot.paramMap.get('auctionClusterId');    
    this.dataService.setId(this.id);

    forkJoin(
      this.dataService.getAllForecastCatalogs(this.auctionClusterId),
      this.auctionService.getAuctions(this.id),
      this.productService.getProducts(this.id, this.auctionClusterId)
    ).subscribe(result => {
      this.items = result[0];
      this.auctions = result[1];
      this.products = result[2];
      this.spinner.hide();
      this.items.forEach(item => {
        item.auctionName = this.matchAuctionName(item.auctionId);
        item.productName = this.matchProductName(item.productId);
      });
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }
  // tslint:enable:no-magic-numbers

  private matchProductName(productId: number) {
    const found = this.products.find(f => f.productId === productId);
    return found ? this.languageService.getTranslatableText(found.name) : '';
  }

  private matchAuctionName(auctionId: number) {
    const found = this.auctions.find(f => f.auctionId === auctionId);
    return found ? found.name : '';
  }

  getSelectedAuctions() {
    if (this.selectedAuction === null) {
      this.getData();
    } else {
      this.dataService.getAuctionForecastCatalog(this.auctionClusterId, this.selectedAuction).subscribe(catalogs => {
        this.items = catalogs;
        this.items.forEach(item => {
          item.auctionName = this.matchAuctionName(item.auctionId);
          item.productName = this.matchProductName(item.productId);
        });
      });
    }
  }

  openLots(catalogId: number, event: Event) {
    event.stopPropagation();

    this.cookieService.set(Cookies.SUPPLY_EDITOR_MODE, 'desktop');
    this.router.navigate(['/supplier/catalogs/' + this.id + '/ac/' + this.auctionClusterId + '/supplyforecastlots/' + catalogId]);
  }

  onRowClick = (e: any) => {
    this.openLots(e.data.catalogId, new Event('click'));
  }

  clickOpenLots = (e: any) => {
    this.openLots(e.row.data.catalogId, new Event('click'));
  }
}
