<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
  </div>
  <dx-data-grid #masterDatFieldsGrid id="printSection" [rtlEnabled]="rtlEnabled" [dataSource]="items"
                [showBorders]="true"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onCellClick)="openEditClick($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" 
    [showNavigationButtons]="true" [showInfo]="true" infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column dataField="masterDataFieldName" caption="{{ 'SHARED.NAME' | translate }}" dataType="string">
    </dxi-column>
    <dxi-column dataField="typeName" caption="{{ 'SHARED.TYPE' | translate }}" dataType="string">
    </dxi-column>
    <dxi-column dataField="masterDataDescription" caption="{{ 'SHARED.DESCRIPTION' | translate }}" dataType="string">
    </dxi-column>
    <dxi-column type="buttons" class="btns-column">
      <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
      <dxi-button [visible]="isDeleteVisible" hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="delete"></dxi-button>
    </dxi-column>
</dx-data-grid>
</div>
<master-data-field-component (onAdded)="onMasterDataFieldAdded($event)" (onUpdated)="onMasterDataFieldEdited($event)"></master-data-field-component>
