<div class="main-container content-component">
  <div class="content-container">
    <div class="content-area">
      <login-header-component *ngIf="!authenticationService.isLoggedIn()"></login-header-component>
      <header-component *ngIf="authenticationService.isLoggedIn()"></header-component>
      <div class="contents">
        <alert-component></alert-component>
        <router-outlet></router-outlet>
      </div>
    </div>
    <nav class="sidenav" [ngClass]="{'collapsed': !expanded && !pin}">
      <section class="sidenav-content" *ngIf="expanded || pin">
        <div class="collapsed-btn" (click)="navCollapse()">
          <clr-icon shape="arrow left" size="40" style="padding-left: 16px;padding-bottom: 16px"></clr-icon>
        </div>

        <a href="./" class="logo-wrapper">
          <img *ngIf="!sidebarLogo" src="assets/images/Aucxis_w.png" class="aucxis-logo" />
          <img *ngIf="!sidebarLogo" src="assets/images/efice.png" class="efice-logo" style="display: none;" />
          <img *ngIf="sidebarLogo" [src]="sidebarLogo" />
        </a>

        <div class="au-sidebar-menu-item nav-item">
          <a routerLinkActive="active" class="au-sidebar-menu-item-link nav-link" (click)="login()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-key"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.LOGIN' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item">
          <a [routerLink]="['/core/register']" routerLinkActive="active" class="au-sidebar-menu-item-link nav-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-tags"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.REGISTER' | translate }}</p>
          </a>
        </div>

        <a [routerLink]="['/core/supplyforecast']" routerLinkActive="active" class="nav-link public-supply-forecast-urk">{{ 'CONTENT.SUPPLY_FORECAST' | translate }}</a>

        <ng-container *ngFor="let item of menuItems">
          <collapsable-section *ngIf="item.menuType === 3" [caption]="getTranslation(item.menuItemName)" sectionId="item.menuItemName" (clicked)="onRouterLinkClick('item.menuItemName')">
            <ul class="nav-list">
              <ng-container *ngFor="let subItem of subMenuItems">
                <ng-container *ngIf="item.externalAppId === subItem.submenuId">
                  <li class="au-sidebar-menu-item">
                    <a *ngIf="!subItem.openInNewTab"
                       [routerLink]="['/core/menu-item/' + subItem.externalAppId]"
                       routerLinkActive="active"
                       class="au-sidebar-menu-item-link nav-link">
                      <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                      <p class="au-sidebar-menu-item-text">{{ getTranslation(subItem.menuItemName) }}</p>
                    </a>

                    <a *ngIf="subItem.openInNewTab"
                       [href]="subItem.applicationUrl"
                       [target]="'_blank'"
                       routerLinkActive="active"
                       class="au-sidebar-menu-item-link nav-link">
                      <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                      <p class="au-sidebar-menu-item-text">{{ getTranslation(subItem.menuItemName) }}</p>
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </collapsable-section>

          <ng-container *ngIf="item.menuType === 1">
            <div class="au-sidebar-menu-item">
              <a *ngIf="!item.openInNewTab"
                 [routerLink]="['/core/menu-item/' + item.externalAppId]"
                 routerLinkActive="active"
                 class="au-sidebar-menu-item-link nav-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>

              <a *ngIf="item.openInNewTab"
                 [href]="item.applicationUrl"
                 [target]="'_blank'"
                 routerLinkActive="active"
                 class="au-sidebar-menu-item-link nav-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>
            </div>
          </ng-container>

          <ng-container *ngIf="item.menuType === 2">
            <div class="au-sidebar-menu-item">
              <a [routerLink]="['/core/menu-item/' + item.externalAppId]"
                 routerLinkActive="active"
                 class="au-sidebar-menu-item-link nav-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>
            </div>
          </ng-container>
        </ng-container>

        <!--<collapsable-section sectionId="public" [caption]="'CONTENT.PUBLIC_MENU_ITEM' | translate" (clicked)="onRouterLinkClick('public')">
      <ul class="nav-list">
        <li><a class="nav-link">{{'CONTENT.PUBLIC_MENU_SUBITEM1' | translate }}</a></li>
        <li><a class="nav-link">{{'CONTENT.PUBLIC_MENU_SUBITEM2' | translate }}</a></li>
      </ul>
    </collapsable-section>-->
        <!--<a [routerLink]="['/core/reports']" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick()">
      {{ 'CONTENT.REPORTING' | translate }}
    </a>-->

      </section>
      <div class="au-sidebar-bottom-brand-area">
        <a *ngIf="expanded || pin" href="http://www.aucxis.com/" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/kosmos.png" />
        </a>
      </div>

        <section class="sidenav-content" *ngIf="!expanded && !pin">
          <a [routerLink]="['/core/home']" class="small-logo-wrapper">
            <!--<img src="assets/images/europool_logo_small.png" />-->
          </a>
          <div class="expand-btn" (click)="navExpand()">
            <clr-icon shape="arrow right" size="32"></clr-icon>
          </div>
          <a href="http://www.aucxis.com/" target="_blank" class="bottom-logo-wrapper">
            <img src="assets/images/kosmos_vert.png" />
          </a>
        </section>
    </nav>
  </div>
</div>
<spinner-component></spinner-component>

