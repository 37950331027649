import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, map } from 'rxjs';

// components
import { BuyerPrebidLotComponent } from './buyer-prebid-lot.component';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { Language } from '../../shared/models/language';
import { Lot, LotProperty } from '../../shared/models/lot';
import { GridProperty, ProductProperty } from '../../shared/models/product';
import { MasterData, MasterDataValue, MasterDataListField } from '../../shared/models/master-data';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { Supplier } from '../../shared/models/supplier';

import { PrebidOnProduct } from '../shared/models/prebid';

// services
import { ServiceFactory } from '../../shared/factory/service-factory';
import { DateTimeService } from '../../shared/services/datetime.service';
import { LanguageService } from '../../shared/services/language.service';
import { TitleService } from '../../shared/services/title.service';
import { WebApiService } from '../../shared/services/web-api.service';

import { MasterDataService } from '../shared/services/master-data.service';
import { PrebidOnProductService } from '../shared/services/prebid-on-product.service';
import { ProductService } from '../shared/services/product.service';
import { SupplierService } from '../shared/services/supplier.service';


export class MasterDataDropdown {
  value: any;
  label: string;
}

@Component({
  selector: 'buyer-prebid-lots-component',
  templateUrl: 'buyer-prebid-lots.component.html',
  styleUrls: ['./buyer-prebid-lots.component.scss']
})
export class BuyerPrebidLotsComponent extends FullListComponent<Lot, BuyerPrebidLotComponent> implements OnInit {

  @ViewChild('details') detailsComponent: BuyerPrebidLotComponent;

  masterDataService: MasterDataService;
  productService: ProductService;

  languages: Array<Language> = [];
  catalogId: number;
  prebidOnProduct: PrebidOnProduct = new PrebidOnProduct();
  productProperties: Array<ProductProperty> = [];
  prebidOnProductProperties: Array<GridProperty> = [];
  prebidPropertyFields = [];
  masterDatas: Array<MasterData> = [];
  masterDataDropdowns: Array<any> = [];
  suppliers: Array<Supplier> = [];
  firstLoad = true;
  JSON: any = JSON;

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private titleService: TitleService,
    private languageService: LanguageService,
    private dataService: PrebidOnProductService,
    private supplierService: SupplierService,
    private dateTimeService: DateTimeService
  ) {
    super(injector, Lot);
    this.getServices(route, appSettings, webApiService);
  }

  ngOnInit() {
    this.titleService.set('PREBID_ON_PRODUCT.TITLE');
    this.setTranslations('PREBID_ON_PRODUCT');
    this.catalogId = +this.route.snapshot.params['catalogId'];
  }

  getServices(route, appSettings, webApiService) {
    this.masterDataService = ServiceFactory.getMasterDataService(document.location.hash, route, appSettings, webApiService) as MasterDataService;
    this.productService = ServiceFactory.getProductService(document.location.hash, route, appSettings, webApiService) as ProductService;
  }

  getData() {
    this.spinner.show();
    this.dataService.getPrebid(this.id, +this.route.snapshot.params['catalogId']).subscribe(pb => {
      this.prebidOnProduct = pb;

      forkJoin(
        this.dataService.getLots(this.id, this.prebidOnProduct.auctionClusterId, this.prebidOnProduct.catalogId),
        this.languageService.getLanguages(),
        this.productService.getProductProperties(this.prebidOnProduct.productId),
        this.productService.getPrebidOnProductProperties(this.prebidOnProduct.auctionClusterId, this.prebidOnProduct.productId)
      ).subscribe((result: Array<any>) => {
        this.items = result[0];
        this.languages = result[1];
        // tslint:disable-next-line:no-magic-numbers
        this.productProperties = result[2];
        this.prebidOnProductProperties = result[3];
        if (this.firstLoad) {
          this.getMasterDataLists();
          this.getSuppliers();
          this.firstLoad = false;
        } else {
          this.parseData();
          this.parseMasterDataDropdown();
        }
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
    },
    error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  getSuppliers() {
    this.supplierService.getSuppliers(this.prebidOnProduct.auctionClusterId).subscribe(s => {
      this.suppliers = s;
    });
  }

  getLotPropertyDisplayValue(lotProperties: Array<LotProperty>, propField: any) {
    const lp = lotProperties.find(p => p.productPropertyId === propField.productPropertyId);

    if (lp) {
      return lp.displayValue;
    }

    return '-';
  }

  parseMasterDataDropdown() {
    this.prebidPropertyFields.forEach(lotProp => {
      const dropdownValues: Array<MasterDataDropdown> = [];
      if (lotProp.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        const masterData = this.masterDatas
          .find(md => md.masterDataListId === this.productProperties
            .find(pp => pp.productPropertyId === lotProp.productPropertyId).masterDataListId);

        const useInCatalogFields = masterData.fields.filter(f => f.useFieldInCatalog);

        if (useInCatalogFields.length === 0) {
          useInCatalogFields.push(masterData.fields[0]);
        }

        useInCatalogFields.forEach(field => {
            masterData.rows.forEach(row => {
              row.values.forEach(rowValue => {
                if (rowValue.masterDataListFieldId === field.masterDataListFieldId) {
                  switch (field.propertyTypeId) {
                    case ProductPropertyTypeEnum.NUMBER:
                      if (rowValue.intValue !== null) {
                        dropdownValues.push({ value: row.masterDataListRowId, label: (rowValue.intValue).toString() });
                      }
                      break;
                    case ProductPropertyTypeEnum.TEXT:
                      if (rowValue.stringValue !== null) {
                        let text = rowValue.stringValue;
                        if (field.translatable) {
                          text = this.getTranslation(text);
                        }
                        if (text) {
                          dropdownValues.push({ value: row.masterDataListRowId, label: text });
                        }
                      }
                      break;
                    case ProductPropertyTypeEnum.DECIMAL:
                      if (rowValue.decimalValue !== null) {
                        dropdownValues.push({ value: row.masterDataListRowId, label: (rowValue.decimalValue).toString() });
                      }
                      break;
                    case ProductPropertyTypeEnum.DATE:
                      if (rowValue.dateTimeValue !== null) {
                        const dateString = this.dateTimeService.getDateStringByFormatAnyUtc(rowValue.dateTimeValue, field.propertyTypeFormatId);
                        dropdownValues.push({ value: row.masterDataListRowId, label: dateString });
                      }
                      break;
                    case ProductPropertyTypeEnum.BOOLEAN:
                      if (rowValue.booleanValue !== null) {
                        dropdownValues.push({ value: row.masterDataListRowId,
                          label: `${rowValue.booleanValue ? 'true' : 'false' }`  });
                      }
                      break;
                    default: break;
                  }
                }
              });
            });
        });
      }

      for (let i = 0; i < dropdownValues.length; i++) {
        for (let j = i + 1; j < dropdownValues.length; j++) {
          if (dropdownValues[i].value === dropdownValues[j].value) {
            if (dropdownValues[j].label !== '') {
              dropdownValues[i].label = dropdownValues[i].label + ' - ' + dropdownValues[j].label;
            }
            dropdownValues.splice(j, 1);
          }
        }
      }
      this.masterDataDropdowns.push({ productPropertyId: lotProp.productPropertyId, values: dropdownValues });
    });
  }

  getMasterDataLists() {
    const masterDataListIds: Array<number> = [];
    this.productProperties.forEach(property => {
      if (property.masterDataListId) {
        if (masterDataListIds.indexOf(property.masterDataListId) === -1) {
          masterDataListIds.push(property.masterDataListId);
        }
      }
    });

    const observables = [];

    masterDataListIds.forEach(id => {
      observables.push(this.masterDataService.getMasterDataForReports(this.prebidOnProduct.auctionClusterId, id, true)
        .pipe(map((result: MasterData) => result)));
    });

    forkJoin(
      observables
    ).subscribe(res => {
      res.forEach((result: MasterData) => {
        this.masterDatas.push(result);
        const catalogMasterDataIds: Array<number> = [];
        this.masterDatas.forEach(masterData => {
          masterData.fields.forEach(masterDataListField => {
            if (masterDataListField.useFieldInCatalog) {
              catalogMasterDataIds.push(masterDataListField.masterDataListFieldId);
            }
          });
        });
      });
      this.parseData();
      this.parseMasterDataDropdown();
    });
  }

  getMasterDataFieldName(selection: GridProperty) {
    for (let i = 0; i < this.masterDatas.length; i += 1) {
      for (let j = 0; j < this.masterDatas[i].fields.length; j += 1) {
        if (this.masterDatas[i].fields[j].masterDataListFieldId === selection.masterDataListFieldId) {
          return this.getTranslation(this.masterDatas[i].fields[j].name);
        }
      }
    }
  }

  parseData() {
    const prebidPropertyFields = [];
    const prebidSelection = this.prebidOnProductProperties.sort((a, b) => a.orderNumber - b.orderNumber);
    prebidSelection.forEach(selection => {
      let fieldObj = null;

      const productProperty = this.productProperties.find(p => p.productPropertyId === selection.productPropertyId);
      if (productProperty) {
        let name = this.getTranslation(productProperty.name);
        if (productProperty.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
          const fieldName = this.getMasterDataFieldName(selection);
          name = `${name} (${fieldName})`;
        }
        fieldObj =  {
          ...selection,
          name,
          propertyTypeId: productProperty.propertyTypeId,
          propertyTypeFormatId: productProperty.propertyTypeFormatId,
          translatable: productProperty.translatable
        };
      }

      if (fieldObj) {
        prebidPropertyFields.push(fieldObj);
      }
    });

    this.prebidPropertyFields = prebidPropertyFields;

    this.items.forEach(i => {
      this.prebidPropertyFields.forEach(pp => {
        const lotProperty = i.lotProperties.find(lp => lp.productPropertyId === pp.productPropertyId);
        if (lotProperty) {
          const displayLotProp = JSON.parse(JSON.stringify(lotProperty));
          displayLotProp.displayValue = this.findPropertyValue(lotProperty, pp);
          if (!i.displayLotProperties) {
            i.displayLotProperties = [];
          }
          i.displayLotProperties.push(displayLotProp);
        }
      });
    });
  }

  findMasterDataValue(masterDataValue: MasterDataValue, masterDataListField: MasterDataListField): any {
    if (masterDataValue.decimalValue) {
      return masterDataValue.decimalValue;
    } else if (masterDataValue.intValue) {
      return masterDataValue.intValue;
    } else if (masterDataValue.stringValue) {
      if (masterDataListField.translatable) {
        return this.getTranslation(masterDataValue.stringValue);
      }
      return masterDataValue.stringValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN) {
      return masterDataValue.booleanValue ? 'true' : 'false';
    } else if (masterDataValue.dateTimeValue) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(masterDataValue.dateTimeValue, masterDataListField.propertyTypeFormatId);
    }
  }

  getMasterDataValue(lotProperty: LotProperty, masterDataListFieldId) {
    const masterDataListRowId = lotProperty.masterDataListRowId;
    let value = masterDataListRowId.toString();
    this.masterDatas.forEach(md => {
      md.rows.forEach(r => {
        if (r.masterDataListRowId === masterDataListRowId) {
          r.values.forEach(v => {
            if (v.masterDataListFieldId === masterDataListFieldId) {
              value = this.findMasterDataValue(v, md.fields.find(f => f.masterDataListFieldId === v.masterDataListFieldId));
            }
          });
        }
      });
    });
    return value;
  }

  findPropertyValue(lotProperty: LotProperty, prop: any): any {
    if (lotProperty.dateTimeValue) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(lotProperty.dateTimeValue, prop.propertyTypeFormatId);
    } else if (lotProperty.decimalValue) {
      return lotProperty.decimalValue;
    } else if (lotProperty.intValue) {
      return lotProperty.intValue;
    } else if (lotProperty.stringValue) {
      if (prop.translatable) {
        return this.getTranslation(lotProperty.stringValue);
      }
      return lotProperty.stringValue;
    } else if (prop.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN) {
      return lotProperty.booleanValue ? 'true' : 'false';
    } else if (lotProperty.masterDataListRowId) {
      // return lotProperty.masterDataListRowId;
      return this.getMasterDataValue(lotProperty, prop.masterDataListFieldId);
    }

    return '';
  }

  edit(lotId: number, event: Event) {
    event.stopPropagation();

    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, lotId, this.languages, this.prebidOnProduct,
      this.prebidPropertyFields, this.masterDatas, this.masterDataDropdowns, this.suppliers);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.languages, this.prebidOnProduct,
      this.prebidPropertyFields, this.masterDatas, this.masterDataDropdowns, this.suppliers);
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.deleteLot(this.id, this.prebidOnProduct.auctionClusterId, this.itemIdToDelete)
      .subscribe((lots: Array<Lot>) => {
        this.getData();
        this.spinner.hide();
      },
      error => {
        this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
        this.spinner.hide();
      });
  }
}
