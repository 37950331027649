<div class="pause-message-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false" [clrModalSize]="'lg'" class="modal-dialog pause-message-dialog">
    <h3 class="modal-title">
      {{ modalTitle }}
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>
    </h3>
    <div class="modal-body" [ngClass]="{'edit-mode': isEditMode}">
      <form #detailsForm="ngForm">
        <section class="form-block">
          <div class="form-group">
            <label class="required">{{ 'CLOCK.PAUSE_MESSAGE' | translate }}:</label>
            <label for="message" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': pauseMessageInput.dirty && pauseMessageInput.invalid}">
              <textarea rows="3" name="message" id="message" placeholder="{{ 'CLOCK.PAUSE_MESSAGE' | translate }}" [(ngModel)]="model" #pauseMessageInput="ngModel" required></textarea>
              <span class="tooltip-content">
                {{ 'CLOCK.PAUSE_MESSAGE' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}
              </span>
            </label>
          </div>
        </section>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid">
        {{ 'SHARED.SAVE' | translate }}
      </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
