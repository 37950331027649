export class GallerySeat {
  gallerySeatId: number;

  auctionId: number;
  buyerId: number;
  
  seatNumber: number;
  reserved: boolean;

  auctionName: string;
  buyerNumber: string;
}
