export class MasterData {
  masterDataListId: number;
  name: string;
  auctionClusterId: number;
  description: string;
  masterDataFieldUsedInValidation: number;
  useInOrder: boolean;
  systemListType: number;
  orderPriority: number;
  rowCount: number;
  fields: Array<MasterDataListField> = [];

  rows: Array<MasterDataRow> = [];

  // displayableFields
  masterDataNameText: string;
  masterDataDescriptionText: string;
  enableCreate: boolean;
  enableUpdate: boolean;
  enableDelete: boolean;
}

export class FieldType {
  systemMasterDataListFieldId: number;
  name: string;
}

export class MasterDataListField {
  masterDataListFieldId: number;
  name: string;
  description: string;
  unique: boolean;
  required: boolean;
  useFieldInCatalog: boolean;
  minValue: number;
  maxValue: number;
  maxLength: number;
  translatable: boolean;
  fieldType: number;
  propertyTypeId: number;
  propertyTypeFormatId: number;
  sysName: string;
  imageCaptionId: number;

  // displayableFields
  masterDataFieldName: string;
  masterDataDescription: string;
  typeName: string;
}

export class MasterDataRow {
  masterDataListId: number;
  masterDataListRowId: number;
  values: Array<MasterDataValue> = [];
}

export class MasterDataValue {
  masterDataListValueId: number;
  masterDataListFieldId: number;
  intValue: number;
  decimalValue: number;
  stringValue: string;
  dateTimeValue: Date;
  imageValue: string;
  booleanValue = false;
  urlValue: string;

  // displayable fields
  dateString: string;
  displayUrlImageValue: string;
  displayUrlTextValue: string;
}

export class MasterDataValidations {
  masterDataListRowId: number;
  value: any;
  checked = false;
}
