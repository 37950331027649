import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'format' })
export class FormatPipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: any, decimalPlaces: number, useGrouping: boolean = true) {
    if (value !== undefined && value !== null && !isNaN(value) && !isNaN(parseFloat(value))) {
      return this.format(value, decimalPlaces, useGrouping);
    }
    else {
      return value;
    }
  }

  format(value: number, decimalPlaces: number = null, useGrouping: boolean = true) {
    let minDecimalPlaces = (decimalPlaces === undefined || decimalPlaces === null) ? 0 : decimalPlaces;
    let maxDecimalPlaces = (decimalPlaces === undefined || decimalPlaces === null) ? 18 : decimalPlaces;
    let culture = this.translateService.currentLang;

    var numberFormatter = new Intl.NumberFormat(culture, { useGrouping: useGrouping, minimumFractionDigits: minDecimalPlaces, maximumFractionDigits: maxDecimalPlaces });

    return numberFormatter.format(value);
  }
}
