import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { Buyer } from '../models/buyer-buyer';

// services
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class BuyerBuyerService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  getBuyers(): Observable<Array<Buyer>> {
    return this.webApiService.getList(this.apiPath + '/buyer');
  }
}
