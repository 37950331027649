<div class="master-data-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #editMasterDataList [width]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" *ngIf="isOpened" [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">

      <dx-form #masterDataListForm id="masterDataListForm" [showValidationSummary]="false"
               (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

        <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'SHARED.GENERAL' | translate }}">

            <dxi-item itemType="group">

              <dxi-item dataField="name" [template]="'masterDataListNameField'">
                <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="description" [template]="'masterDataListDescriptionField'">
                <dxo-label text="{{ 'SHARED.DESCRIPTION' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="useInOrder" editorType="dxCheckBox">
                <dxo-label text="{{ 'MASTER_DATA.USE_IN_ORDER' | translate }}" location="right"></dxo-label>
              </dxi-item>

              <dxi-item dataField="orderPriority" editorType="dxNumberBox" [editorOptions]="{visible: model.useInOrder}">
                <dxo-label [visible]="model.useInOrder" text="{{ 'MASTER_DATA.ORDER_PRIORITY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'MASTER_DATA.NAME_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="enableCreate" editorType="dxCheckBox">
                <dxo-label text="{{ 'SHARED.ENABLE_CREATE' | translate }}" location="right"></dxo-label>
              </dxi-item>

              <dxi-item dataField="enableDelete" editorType="dxCheckBox">
                <dxo-label text="{{ 'SHARED.ENABLE_DELETE' | translate }}" location="right"></dxo-label>
              </dxi-item>

              <dxi-item dataField="enableUpdate" editorType="dxCheckBox">
                <dxo-label text="{{ 'SHARED.ENABLE_UPDATE' | translate }}" location="right"></dxo-label>
              </dxi-item>
            </dxi-item>


          </dxi-tab>
          <dxi-tab title="{{ 'SHARED.FIELDS' | translate }}">
            <master-data-fields-component [(data)]="model.fields" [languages]="languages" [productPropertyTypes]="productPropertyTypes" [productFieldTypes]="productFieldTypes" (dataChanged)="saveWithoutClose();"></master-data-fields-component>
          </dxi-tab>
        </dxi-item>

        <div *dxTemplate="let data of 'masterDataListNameField'" class="translatable-template">
          <translatable-field (change)="onChangeTranslationField($event, 'name')" [isDevExpress]="true" [isLabelHidden]="true" [caption]="'SHARED.NAME'" [clusterLanguages]="languages" [(data)]="data.component.option('formData')[data.dataField]" [name]="'name'" #masterDataName></translatable-field>  
        </div>
        <div *dxTemplate="let data of 'masterDataListDescriptionField'" class="translatable-template">
          <translatable-field (change)="onChangeTranslationField($event, 'description')" [isDevExpress]="true" [isLabelHidden]="true" [caption]="'SHARED.DESCRIPTION'" [clusterLanguages]="languages"  [type]="'textbox'" [(data)]="data.component.option('formData')[data.dataField]" [name]="'description'" #masterDataDescription></translatable-field>  
        </div>
      </dx-form>
      <div class="au-dx-popup-padder">
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnMasterDataListSubmit"
        disabled="disabled">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
      </div>
    </dx-scroll-view>
  </dx-popup>
</div>
