import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'browser-info-component',
  templateUrl: './browser-info.component.html',
  styleUrls: ['./browser-info.component.scss']
})
export class BrowserInfoComponent {
  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
    if (this.checkBrowser()) {
      this.redirectToHome();
    }
  }

  checkBrowser() {
    const chromeAgent = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    if (!chromeAgent) {
      return false;
    } else {
      const version = parseInt(chromeAgent[2], 10);

      if (version < 72) {
        return false;
      }
    }

    return true;
  }

  redirectToHome() {
    // tslint:disable-next-line: no-floating-promises
    this.router.navigate(['/'], { queryParamsHandling: 'merge' });
  }
}
