import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { GallerySeat } from '../models/gallery-seat';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class GalleryService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getGallerySeats(auctionClusterId: number): Observable<Array<GallerySeat>> {
    return this.webApiService.getList(this.apiPath + '/auction/' + auctionClusterId + '/galleryseat');
  }

  getGallerySeat(auctionId: number, gallerySeatId: number): Observable<GallerySeat> {
    return this.webApiService.get(this.apiPath + '/auction/' + auctionId + '/galleryseat', gallerySeatId);
  }

  save(auctionId: number, gallerySeat: GallerySeat): Observable<any> {
    return this.webApiService.save<GallerySeat>(this.apiPath + '/auction/' + auctionId + '/galleryseat', gallerySeat);
  }

  edit(auctionId: number, gallerySeat: GallerySeat): Observable<any> {
    return this.webApiService.edit<GallerySeat>(this.apiPath + '/auction/' + auctionId + '/galleryseat', gallerySeat.gallerySeatId, gallerySeat);
  }

  delete(auctionId: number, gallerySeatId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/auction/' + auctionId + '/galleryseat', gallerySeatId);
  }
}
