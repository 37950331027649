import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { DxPopupComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { Location } from '../../shared/models/location';

// services
import { LocationService } from '../shared/services/location.service';


@Component({
  selector: 'location-component',
  templateUrl: 'location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent extends ItemDetailsComponent<Location> implements OnInit, OnDestroy {

  @ViewChild('name', { read: ElementRef }) nameInput: any;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  auctionClusterId: number;

  constructor(
    protected injector: Injector,
    private dataService: LocationService,
  ) {
    super(injector);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new Location();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(auctions: Array<Location>, locationId: number, auctionClusterId: number) {
    this.allItems = auctions;
    this.auctionClusterId = auctionClusterId;

    if (locationId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getLocation(this.auctionClusterId, locationId)
        .subscribe((res: Location) => {
          this.model = res;
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new Location();
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  save() {
    this.model.auctionClusterId = this.auctionClusterId;
     if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Location();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
     } else {
      this.spinner.show();
       this.dataService.save(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Location();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
     }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnLocationSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnLocationSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnLocationSubmit')[0].removeAttribute('disabled');
      }
    }
  }
}
