export class NetworkLatencyTiming {
  clockId: number;
  networkLatencies: Array<NetworkLatency> = [];
}

export class NetworkLatency {
  userId: number;
  buyerId: number;
  name: string;
  buyerNumber: string;
  networkLatencyTiming: number;
  lastResponseTimestamp: number;
}
