import { Injectable, OnDestroy } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, IReconnectPolicy, JsonHubProtocol, LogLevel } from '@aspnet/signalr';
import { of, Subscription } from 'rxjs';

// models
import { ApplicationSettings } from '../models/application-settings';
import { PushNotificationMessage } from '../models/push-notification-message';

// services
import { AuthService } from './auth.service';
import { TokenService } from './token.service';

const DEFAULT_RECONNECT_TIMEOUT_MS = 5000;

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {

  private notificationUrl: string;
  private hubConnection: HubConnection;
  private isStopped = true;
  private isAuthorized: Subscription;

  constructor(
    private appSettings: ApplicationSettings,
    private auth: AuthService,
    private tokenService: TokenService) {
    this.notificationUrl = this.appSettings.notificationURL;
    this.isAuthorized = this.auth.isAuthorized.subscribe(isAuthorized => {
      if (isAuthorized)
        this.start();
      else
        this.stop();
    });
  }

  ngOnDestroy() {
    // Subscriptions cleanup
    if (this.isAuthorized)
      this.isAuthorized.unsubscribe();
  }

  private start() {
    if (!this.notificationUrl)
      return of().toPromise();

    if (this.hubConnection &&
      this.hubConnection.state === HubConnectionState.Connected) {
      this.stop();
    }

    // Create hub connection if required
    this.createHubConnection(this.notificationUrl);

    // Register method handlers
    this.hubConnection.on('Notify', (message) => {
      if (message !== '' && JSON.parse(message)) {
        let data : PushNotificationMessage = JSON.parse(message);
        this.pushNotification(data.title, data.body);
      }
    });

    this.isStopped = false;
    return this.hubConnection.start()
      .then(_ => console.log('[Notification service]: Connected.'))
      .catch(error => console.error(`[Notification service]: Error connecting: ${JSON.stringify(error)}`));
  }

  private stop() {
    if (!this.hubConnection ||
      this.hubConnection.state === HubConnectionState.Disconnected)
      return

    // Remove handlers
    this.hubConnection.off('Notify');

    // Stop connection
    this.isStopped = true;
    return this.hubConnection.stop()
      .then(_ => console.log('[Notification service]: Disconnected.'))
      .catch(error => console.error(`[Notification service]: Error disconnecting: ${JSON.stringify(error)}`));
  }

  // Create and build Hub connection
  private createHubConnection(socketUrl: string) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(socketUrl, { transport: HttpTransportType.WebSockets, skipNegotiation: true, accessTokenFactory: () => this.tokenService.getToken() })
      .configureLogging(LogLevel.Error)
      .withHubProtocol(new JsonHubProtocol())
      .withAutomaticReconnect(<IReconnectPolicy>{
        nextRetryDelayInMilliseconds: this.nextRetryDelayInMilliseconds
      })
      .build();

    this.hubConnection.onreconnected((connectionId: string) => {
      console.log('[Notification service]: Reconnected.');
    });
  }

  private nextRetryDelayInMilliseconds(previousRetryCount: number, elapsedMilliseconds: number) {
    if (this.isStopped)
      return null;
    else {
      console.log(`[Notification service]: Reconnecting in ${DEFAULT_RECONNECT_TIMEOUT_MS}ms`);
      return DEFAULT_RECONNECT_TIMEOUT_MS;
    }
  }

  pushNotification(title: string, message: string, image: string = '') {
    if (!('Notification' in window)) {
      console.log("This browser does not support notifications");
    }
    else {
      if (Notification.permission === 'granted') {
        this.displayNotification(title, message, image);
      }
      else {
        Notification.requestPermission().then(() => this.displayNotification(title, message, image));
      }
    }
  }

  private displayNotification(title: string, message: string, image: string) {
    if (Notification.permission === 'granted') {
      var notification = new Notification(title, { body: message, icon: image });
    }
  }
}
