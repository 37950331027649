import { NotificationChannelType } from "../enum/notificationChannelTypeEnum";
import { ProviderTypeEnum } from "../enum/providerTypeEnum";

export class AuctionClusterChannel {
    auctionClusterChannelId: number;
    notificationChannelType: NotificationChannelType;
    provider: ProviderTypeEnum;
    isActive: boolean;
    name: string;

    //Displayable fields
    nameText: string;
    defaultName: string;
}