export class LotteryItem {
  supplierId: number;
  supplierDisplayName: string;
  supplierName: string;
  supplierNumber: string;
  lastLotteryDate: Date;
  lastLotteryDateString: string;
  lastFirstPlaceDate: Date;
  lastFirstPlaceDateString: string;
  isBalticSeaSupplier: boolean;
  previousLotteryPosition: number;
  automaticPosition: number;
  orderNumber: number;
  selected: boolean;
  arrivalTime: Date;
  arrivalTimeString: string;
}
