import { Component, Injector, ViewChild, OnInit, ViewEncapsulation, HostListener, ElementRef } from '@angular/core';
import { ClrTabs } from '@clr/angular';
import { Subscription } from 'rxjs';

// components
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { ItemDetailsComponent } from '../item-details/item-details.component';
import { SaveConfirmationComponent } from '../save-confirmation/save-confirmation.component';

// services
import { AuthService } from '../../services/auth.service';
import { LanguageService } from '../../services/language.service';
import { UserService } from '../../services/user.service';

// models
import { Language } from '../../models/language';
import { User } from '../../models/user';

const ESC_KEYCODE = 27;

@Component({
  selector: 'edit-profile-component',
  templateUrl: 'edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditProfileComponent extends ItemDetailsComponent<User> implements OnInit {

  // tabs checkers
  isPlatformUser: boolean;
  isAuctionClusterUser: boolean;
  isBuyerUser: boolean;
  isSupplierUser: boolean;

  languages: Array<Language> = [];

  purchased100PercentColorCode = '#d1ffda';
  purchased75OrMorePercentColorCode = '#a8bee0';
  purchasedLessThan75PercentColorCode = '#f2d5d5';

  auctionClusterTable: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('saveConfirmation') saveConfirmation: SaveConfirmationComponent;
  @ViewChild('email', { read: ElementRef }) emailInput: any;
  @ViewChild('confirmation') confirmation: ConfirmationComponent;
  
  formDirty: boolean = false;
  

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: UserService,
    public authenticationService: AuthService,
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  async ngOnInit() {
    this.model = new User();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(
    userId: number,
    languages: Array<Language>
  ) {
    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    this.languages = languages;
    this.formDirty = false;

    this.spinner.show();

    this.dataService.getUser()
      .subscribe(result => {
        this.model = result;

        //this.parseShoppingListSettings();

        if (this.model.platformRoleId) {
          this.isPlatformUser = true;
        }
        this.isAuctionClusterUser = this.model.auctionClusterRoles.length !== 0;
        this.isBuyerUser = this.model.buyerRoles.length !== 0;
        this.isSupplierUser = this.model.supplierRoles.length !== 0;

        this.isOpened = true;
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);

          this.spinner.hide();
        });
  }

  //parseShoppingListSettings() {
  //  if (this.model.shoppingListSettings) {
  //    const settObj = JSON.parse(this.model.shoppingListSettings);
  //    if (settObj && typeof settObj === 'object') {
  //      if (settObj.purchased100PercentColorCode) {
  //        this.purchased100PercentColorCode = settObj.purchased100PercentColorCode;
  //      }
  //      if (settObj.purchased75OrMorePercentColorCode) {
  //        this.purchased75OrMorePercentColorCode = settObj.purchased75OrMorePercentColorCode;
  //      }
  //      if (settObj.purchased100PercentColorCode) {
  //        this.purchasedLessThan75PercentColorCode = settObj.purchasedLessThan75PercentColorCode;
  //      }
  //    }
  //  }
  //}

  //setShoppingListSettings() {
  //  const newSettingsObj = {
  //    purchased100PercentColorCode: this.purchased100PercentColorCode,
  //    purchased75OrMorePercentColorCode: this.purchased75OrMorePercentColorCode,
  //    purchasedLessThan75PercentColorCode: this.purchasedLessThan75PercentColorCode
  //  }

  //  this.model.shoppingListSettings = JSON.stringify(newSettingsObj);
  //}

  getTranslation(value: any) {
    if (value) {
      return this.languageService.getTranslatableText(value.name);
    }
    
  }

  save() {
    this.spinner.show();
    //this.setShoppingListSettings();
    this.dataService.edit(this.model)
      .subscribe((res: any) => {
        // this.detailsForm.reset();
        this.close(true);
        this.errorMessage = null;
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorMessage);
          this.spinner.hide();
        });
  }

  public onCancel() {
    this.isOpened = false;
    this.errorMessage = null;
  }

  public onDelete() {
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.model)
      .subscribe((res: any) => {
        // this.detailsForm.reset();
        this.close(true);
        this.errorMessage = null;
        this.spinner.hide();
        this.authenticationService.logout();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
      });
  }

  @HostListener('window:keydown', ['$event'])
  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE) {
      event.target.blur();
      if (this.formDirty) {
        this.saveConfirmation.opened = true;
      } else {
        this.onCancel();
      }
    }
  }

  initAuctionCluster = (e: any) => {
    this.auctionClusterTable = e.component;
  }

  onFieldDataChanged(e) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnEditProfileSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnEditProfileSubmit')[0].removeAttribute('disabled');
      }

      if (!this.formDirty) this.formDirty = true;
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnEditProfileSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnEditProfileSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

}
