export class TransactionMonitorLot {
  columnTitles: Array<TransactionMonitorLotColumn>;
  rows: Array<TransactionMonitorLotRow>;
}

export class TransactionMonitorLotColumn {
  name: string;
  propertyTypeId: number;
  propertyTypeFormatId: number;
  uniqueName: string;
}

export class TransactionMonitorLotRow {
  id: number;
  clockStartGuid: string;
  groupedIds: Array<number>;
  values: Array<string>;
  selected = false;
}

export enum LotStatus {
  NotSold = 0,
  InSale = 1,
  Sold = 2
}

export enum ConfirmationStatus {
  None = 0,
  WaitingForConfirmation = 1,
  Confirmed = 2,
  Rejected = 3,
  Disabled = 4
}
