export class Language {
  languageId: number;
  name: string;
  code: string;
  isEnabled: boolean;
  isDefault: boolean;
  csvDelimiter: string;
  direction: boolean;
}


export class AuctionClusterLanguage {
  languageId: number;
  auctionClusterId: number;
  auctionClusterLanguageId: number;
  isDefault: boolean;
}