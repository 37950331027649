import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../models/application-settings';
import { TermsAndConditions } from '../models/terms-and-conditions';

// services
import { WebApiService } from './web-api.service';

@Injectable()
export class TermsAndConditionsService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  get(auctionClusterId: number): Observable<TermsAndConditions> {
    return this.webApiService.getSingle(this.apiPath + '/' + auctionClusterId + '/termsofuse');
  }

  getWithContent(auctionClusterId: number): Observable<TermsAndConditions> {
    return this.webApiService.getSingle(this.apiPath + '/' + auctionClusterId + '/termsofuse/content');
  }

  acceptTerms(auctionClusterId: number): Observable<any> {
    return this.webApiService.put(this.appSettings.adminApi + 'auth/acceptterms/' + auctionClusterId, null);
  }

  save(auctionClusterId: number, version: number, formData: any): Observable<any> {
    return this.webApiService.put(this.apiPath + '/' + auctionClusterId + '/termsofuse/' + version, formData);
  }
}
