import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// interfaces
import{ IReportService } from '../../../shared/interfaces/report';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Report, ReportDesign } from '../../../shared/models/report';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class AuctionClusterReportService implements IReportService{

  private apiPath: string;
  private auctionClusterId: number;

  setAuctionClusterId(auctionClusterId: number)
  {
    this.auctionClusterId = auctionClusterId;
  }

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  getReports(auctionClusterId: number, langCode: string): Observable<Array<Report>> {
    return this.webApiService.getList(this.apiPath + `auctioncluster/${auctionClusterId}/reports?langCode=${langCode}`);
  }

  getDesigns(auctionClusterId: number): Observable<Array<ReportDesign>> {
    return this.webApiService.getList(this.apiPath + `auctioncluster/${auctionClusterId}/reportdesigns`);
  }

  getAllAuctionClusterDesigns(auctionClusterId: number, langCode: string): Observable<Array<ReportDesign>> {
    return this.webApiService.getList(this.apiPath + `auctioncluster/${auctionClusterId}/reportdesignsall?langCode=${langCode}`);
  }

  getReportBySystemReportDesignType(auctionClusterId: number, systemReportDesignType: number): Observable<Report> {
    return this.webApiService.getSingle(this.apiPath + `auctioncluster/${auctionClusterId}/report/systemReportDesignType/${systemReportDesignType}`);
  }

  getReportsBySystemReportDesignType(auctionClusterId: number, systemReportDesignType: number): Observable<Array<Report>> {
    return this.webApiService.getList(this.apiPath + `auctioncluster/${auctionClusterId}/reports/systemReportDesignType/${systemReportDesignType}`);
  }

  save(auctionClusterId: number, report: Report): Observable<any> {
    return this.webApiService.save<Report>(this.apiPath + 'auctioncluster/' + auctionClusterId + '/reports', report);
  }

  edit(auctionClusterId: number, report: Report): Observable<any> {
    return this.webApiService.editSingle<Report>(this.apiPath + 'auctioncluster/' + auctionClusterId + '/reports', report);
  }

  delete(auctionClusterId: number, reportId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + 'auctioncluster/' + auctionClusterId + '/reports', reportId);
  }

  getProductReports(auctionClusterId: number, productId: number): Observable<Array<Report>> {
    if (!productId) {
      return of([]);
    }
    const url = `${this.apiPath}auctioncluster/${auctionClusterId}/product/${productId}/report`;
    return this.webApiService.getList(url);
  }

  /**
   * gets the productreports without data
   * @param auctionClusterId
   * @param productId
   */
  getProductReportsSummary(auctionClusterId: number, productId: number): Observable<Array<Report>> {
    if (!productId) {
      return of([]);
    }
    const url = `${this.apiPath}auctioncluster/${auctionClusterId}/product/${productId}/reportsummary`;
    return this.webApiService.getList(url);
  }

  //IReportService

  getReport(reportId): Observable<Report> {
    return this.webApiService.getSingle(this.apiPath + `auctioncluster/${this.auctionClusterId}/reports/${reportId}`);
  }

  saveReport(report: Report): Observable<any> {
    return this.webApiService.save<Report>(this.apiPath + 'auctioncluster/' + this.auctionClusterId + '/reports', report);
  }

  editReport(report: Report): Observable<any> {
    return this.webApiService.editSingle<Report>(this.apiPath + 'auctioncluster/' + this.auctionClusterId + '/reports', report);
  }

  downloadExport(reportId: number, reportRequest: any): Observable<any> {
    const url = this.appSettings.reportingApiURL + '/api/v1/auctioncluster/' + this.auctionClusterId + '/report/export?reportId=' + reportId;
    return this.webApiService.downloadWithPost(url, reportRequest);
  }

  requestDownload(reportId: number, reportRequest: any) {
    const url = this.apiPath + 'auctioncluster/' + this.auctionClusterId + '/reports/requestdownload/' + reportId;
    return this.webApiService.downloadWithPost(url, reportRequest);
  }

  getStoredProcedureValuesForProductProperty(reportId: number, filter: any)
  {
    const url = this.appSettings.reportingApiURL + '/api/v1/auctioncluster/' + this.auctionClusterId + '/reportfilter?reportId=' + reportId;
    return this.webApiService.save(url, filter);
  }

  getStoredProcedureValuesForNumberProductProperty(reportId: number, filter: any)
  {
    const url = this.appSettings.reportingApiURL + '/api/v1/auctioncluster/' + this.auctionClusterId + '/reportfilter/number?reportId=' + reportId;
    return this.webApiService.save(url, filter);
  }

  getStoredProcedureValuesForStringProductProperty(reportId: number, filter: any)
  {
    const url = this.appSettings.reportingApiURL + '/api/v1/auctioncluster/' + this.auctionClusterId + '/reportfilter/string?reportId=' + reportId;
    return this.webApiService.save(url, filter);
  }

  getStoredProcedureValuesForDateProductProperty(reportId: number, filter: any)
  {
    const url = this.appSettings.reportingApiURL + '/api/v1/auctioncluster/' + this.auctionClusterId + '/reportfilter/date?reportId=' + reportId;
    return this.webApiService.save(url, filter);
  }
}
