import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { RoleComponent } from './role.component';

// models
import { Language } from '../../shared/models/language';
import { PlatformPermissionEnum } from '../../shared/models/user-permissions';

import { PlatformPermission } from '../shared/models/platform-permission';
import { PlatformRole } from '../shared/models/platform-role';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';
import { TokenService } from '../../shared/services/token.service';

import { PlatformRoleService } from '../shared/services/platform-role.service';
import { PlatformPermissionService } from '../shared/services/platform-permission.service';

@Component({
  selector: 'roles-component',
  templateUrl: 'roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent extends FullListComponent<PlatformRole, RoleComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: RoleComponent;

  permissions: Array<PlatformPermission> = [];
  languages: Array<Language> = [];
  roleHasUsers: boolean;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: PlatformRoleService,
    private platformPermissionService: PlatformPermissionService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private tokenService: TokenService
  ) {
    super(injector, PlatformRole);
    this.title.set('ROLES.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }
  
  getData() {
    this.spinner.show();

    forkJoin(
      this.platformPermissionService.getPlatformPermissions(this.translateService.currentLang),
      this.dataService.getPlatformRoles(this.translateService.currentLang)
    ).subscribe(result => {
      this.permissions = result[0];
      this.items = result[1];

      var userPermissions = this.tokenService.getCurrentUserPermissions();

      if (!userPermissions.PlatformPermissions.some(p => p === PlatformPermissionEnum.Administrator)) {
        this.permissions = this.permissions.filter(_ => _.platformPermissionId !== PlatformPermissionEnum.Administrator.valueOf());
        this.items = this.items.filter(_ => !_.permissions.some(pp => pp === PlatformPermissionEnum.Administrator.valueOf()));
      }

      this.items.forEach(role => {
        role.permissionString = this.buildPermissionsString(role.permissions);
      });

      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.platformRoleId;

    this.dataService.checkIfRoleHasUsers(this.itemIdToDelete).subscribe(res => {
      if (res.length === 0) {
        this.confirmation.opened = true;
      } else {
        this.errorService.show(this.errorService.translations.ROLE_HAS_USERS);
      }
    });
  }

  deleteSelected() {
    this.dataService.delete(this.itemIdToDelete)
      .subscribe((result: boolean) => {
        this.items = this.items.filter(r => r.platformRoleId != this.itemIdToDelete);
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
        });
  }

  edit(roleId: number, event: Event) {
    event.stopPropagation();
    this.detailsComponent.open(this.items, roleId, this.permissions);
  }

  editItem = (e: any) => {
    const platformRoleId = e.row !== undefined ? e.row.data.platformRoleId : e.data.platformRoleId;
    this.detailsComponent.open(this.items, platformRoleId, this.permissions);
  }

  add() {
    this.detailsComponent.open(this.items, null, this.permissions);
  }

  private buildPermissionsString(permissions: Array<number>) {
    const permissionNames: Array<string> = [];
    permissions.forEach(item => {
      const permissionName = this.permissions.find(p => p.platformPermissionId === item).name;
      permissionNames.push(permissionName);
    });
    return permissionNames.join(', ');
  }
}
