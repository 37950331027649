<div class="sale-unit-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <div class="modal-body">
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>

      <form #detailsForm="ngForm" clrForm>
        <div class="clr-form-control">
          <label class="clr-control-label">{{ 'PRODUCT.SALE_UNIT' | translate }}</label>
          <div class="clr-control-container">
            <translatable-field [(data)]="model.name" [name]="'saleUnitName'" [clusterLanguages]="languages" #saleUnitName></translatable-field>
          </div>
        </div>

        <div class="clr-form-control">
          <label for="saleAmount" class="clr-control-label">{{ 'PRODUCT.AMOUNT' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input type="number" id="saleAmount" name="saleAmount" placeholder="{{ 'PRODUCT.AMOUNT' | translate }}" class="clr-input" [(ngModel)]="model.amount" #saleUnitAmount numeric required />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid || !saleUnitName.valid">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
