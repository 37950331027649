import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { AuctionClusterComponent } from './auction-cluster.component';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';

// models
import { AuctionCluster } from '../../shared/models/auction-cluster';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterService } from '../shared/services/auction-cluster.service';


@Component({
  selector: 'auction-clusters-component',
  templateUrl: 'auction-clusters.component.html',
  styleUrls: ['./auction-clusters.component.scss']
})
export class AuctionsClusterComponent extends FullListComponent<AuctionCluster, AuctionClusterComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: AuctionClusterComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterService,
    private languageService: LanguageService,
  ) {
    super(injector, AuctionCluster);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('SHARED.AUCTION_CLUSTER_MANAGEMENT');
    this.setTranslations('AUCTIONS_CLUSTERS');
    this.getData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    this.dataService.getAuctionClusters().subscribe((auctionClusters) => {
      this.items = auctionClusters;
      this.items.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null);
  }

  edit = (e: any) => {
    const auctionClusterId = e.row !== undefined ? e.row.data.auctionClusterId : e.data.auctionClusterId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, auctionClusterId);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.auctionClusterId;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete).subscribe(() => {
      this.getData();
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
    });
    this.confirmation.opened = false;
  }

  enableDisableCluster = (e: any) => {
    const auctionCluster: AuctionCluster = e.row.data;
    this.dataService.enableDisable(auctionCluster).subscribe(() => {
      this.getData();
    }, error => {
      this.errorService.show(error);
    });
  }

  exportCluster = (e: any) => {
    this.dataService.export(e.row.data.auctionClusterId);
  }

  navigateToUsers = (e: any) => {
    this.router.navigate(['/platform/users'], { queryParams: { auctionCluster: e.row.data.auctionClusterId } });
  }

  calculateDisabledDisplayValue = (e: any) => {
    return e.row.data.isActive;
  }

  calculateEnabledDisplayValue = (e: any) => {
    return !e.row.data.isActive;
  }
}