export class AuctionClusterSupplier {
  supplierId: number;
  name: string;
  supplierName: string;
  supplierNumber: string;
  isActive: boolean;
  isActiveOnAuctionCluster: boolean;
  usedOnOtherAuctionCluster: boolean;

  address1: string;
  address2: string;
  zip: string;
  city: string;
  country: string;
  vat: string;
  telNumber: string;
  faxNumber: string;
  email: string;
  url: string;
  administratorId: number;
  isPending: boolean;
  displayName: string;

  selected: boolean;
}

export class SupplierSimple {
  supplierId: number;
  name: string;
  vat: string;
}
