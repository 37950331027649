import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

// components
import { DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

import { AuctionClusterNotificationService } from '../shared/services/auction-cluster-notification.service';

// models
import { AuctionClusterNotification } from '../../shared/models/auction-cluster-notification';
import { AuctionClusterChannel } from '../../shared/models/auction-cluster-channel';
import { Language } from '../../shared/models/language';
import { Report } from '../../shared/models/report';

// services
import { LanguageService } from '../../shared/services/language.service';


@Component({
  selector: 'notification-component',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss']
})

export class NotificationComponent extends ItemDetailsComponent<AuctionClusterNotification> implements OnInit, OnDestroy {

  @ViewChild(DxPopupComponent) popup: DxPopupComponent;
  @ViewChild('notificationTitle') notificationTitle;
  @ViewChild('notificationBody') notificationBody;
  @ViewChild('notificationForm', { read: DxFormComponent}) notificationForm: DxFormComponent;

  languages: Array<Language> = [];
  auctionClusterId: number;
  channels: Array<AuctionClusterChannel> = [];
  assignedChannels: Array<AuctionClusterChannel> = [];
  reports: Array<Report> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    private notificationService: AuctionClusterNotificationService,
    private languageService: LanguageService,
    protected injector: Injector,
    private route: ActivatedRoute
  ) {
      super(injector);
      this._subscription = this.languageService.direction.subscribe(dir => {
        this.rtlEnabled = dir;
      });
    }

  ngOnInit() {
    this.model = new AuctionClusterNotification();
    this.auctionClusterId = +this.route.snapshot.params['id'];
  }

  init()  {
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(auctionClusterNotifications: Array<AuctionClusterNotification>, auctionClusterNotificationId: number, languages: Array<Language>, channels: Array<AuctionClusterChannel>, reports: Array<Report>) {
    this.allItems = auctionClusterNotifications;
    this.languages = languages;
    this.channels = channels;    
    this.reports = reports;

    this.translateChannelsAndReports();   

    this.isEditMode = true;
    this.spinner.show();
    this.notificationService.getAuctionClusterNotificationForEdit(auctionClusterNotificationId, this.auctionClusterId)
      .subscribe((res: AuctionClusterNotification) => {

        this.model = res;
         
        this.createEmptyObjectForTranslatableFields();

        this.translateNotification();        
        this.getAssignedChannels();

        this.isOpened = true;
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  save() {

    this.spinner.show();
    this.notificationService.editAuctionClusterNotification(this.auctionClusterId, this.model)
      .subscribe((res: any) => {
        this.model = res;
        this.close(true);
        this.errorMessage = null;
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'title') {
      this.notificationForm.instance.updateData(fieldName, this.notificationTitle.data);
    }
    else if (fieldName === 'body') {
      this.notificationForm.instance.updateData(fieldName, this.notificationBody.data);
    }
  }

  translateChannelsAndReports() {
    this.channels.forEach(_ => _.name = this.languageService.getTranslatableText(_.name));
    this.reports.forEach(_ => _.reportDesign.name = this.languageService.getTranslatableText(_.reportDesign.name));
  }

  translateNotification() {
    this.model.nameText = this.languageService.getTranslatableText(this.model.name);
    this.model.descriptionText = this.languageService.getTranslatableText(this.model.description);
  }
  
  getAssignedChannels() {
    this.assignedChannels = this.channels.filter(_ => this.model.activeAuctionClusterNotificationChannelIds.indexOf(_.auctionClusterChannelId) !== -1);

    if (this.model.defaultAuctionClusterNotificationChannelIds.length !== 0) {
      this.updateDefaultChannels();
    }
  }

  updateDefaultChannels() {
    let defaultChannels = [];

    this.model.defaultAuctionClusterNotificationChannelIds.forEach(channel => {
      if (this.assignedChannels.find(_ => _.auctionClusterChannelId === channel)) {
        defaultChannels.push(channel);
      }
    });

    this.model.defaultAuctionClusterNotificationChannelIds = defaultChannels;
  }

  createEmptyObjectForTranslatableFields() {
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    if(!this.model.title || !this.IsJson(this.model.title))
    {
      this.model.title = JSON.stringify(emptyTranslation);
    }
    

    if(!this.model.body || !this.IsJson(this.model.body))
    {
          this.model.body = JSON.stringify(emptyTranslation);
    }
  }

  private IsJson(str: any) {
    try {
      const o = JSON.parse(str);
      if (o && typeof o === 'object') {
        return true;
      }
    } catch (e) {
      //
    }
    return false;
  }
}
