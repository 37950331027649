import { Component, Injector, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import * as _moment from 'moment';
import { Moment } from 'moment';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { Auction } from '../../shared/models/auction';
import { Catalog, AllowLowestStopClockPrice, DeletedTransactionPositionEnum, PrebidEnabled, PrebidOnProductEnum, PrebidPriority, PrintTimingEnum, SplitLotToMultipleSubbuyersEnum, TypeOfGrouping, ManualGroupingBehaviour, PrebidMinMaxAmountsEnum, PresalesModeEnum, BuyBackIncludedEnum, PrebidMTOAmountsEnum, MTORoundingBehaviorEnum } from '../../shared/models/catalog';
import { Product } from '../../shared/models/product';
import { User } from '../../shared/models/user';

import { AuctionClusterBuyer } from '../shared/models/auction-cluster-buyer';
import { AuctionClusterUser } from '../shared/models/auction-cluster-user';

// services
import { LanguageService } from '../../shared/services/language.service';
import { UserService } from '../../shared/services/user.service';

import { CatalogService } from '../shared/services/catalog.service';
import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';
import { AuctionClusterUserService } from '../shared/services/auction-cluster-user.service';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'supply-catalog-component',
  templateUrl: 'supply-catalog.component.html',
  styleUrls: ['./supply-catalog.component.scss']
})
export class SupplyCatalogComponent extends ItemDetailsComponent<Catalog> implements OnInit, OnDestroy {

  @ViewChild('name', { read: ElementRef }) nameInput: any;
  @ViewChild('confirmation') confirmation: ConfirmationComponent;

  auctionClusterId: number;
  catalog: Catalog;
  cleanupTime: Date;
  translationSelectBoxes: any;
  user: User = new User();
  auctions: Array<Auction> = [];
  buyers: Array<AuctionClusterBuyer> = [];
  demoAdminUsers: Array<AuctionClusterUser> = [];
  demoCatalogs: Array<Catalog> = [];
  filteredForecastCatalogs: Array<Catalog> = [];
  forecastCatalogs: Array<Catalog> = [];
  filteredDemoCatalogs: Array<Catalog> = [];
  products: Array<Product> = [];

  allowLowestStopClockPriceOptions: any = AllowLowestStopClockPrice;
  cleanupPrebidOnProduct: any = PrebidOnProductEnum;
  deletedTransactionPosition: any = DeletedTransactionPositionEnum;
  groupingTypes: any = TypeOfGrouping;
  groupingBehaviour: any = ManualGroupingBehaviour;
  prebidAllowedEnum: any = PrebidEnabled;
  prebidAllowedOptions: any = PrebidEnabled;
  prebidPriorities: any = PrebidPriority;
  prebidMinMaxAmountsEnum: any = PrebidMinMaxAmountsEnum;
  printTiming: any = PrintTimingEnum;
  splitLotToMultipleSubbuyers: any = SplitLotToMultipleSubbuyersEnum;
  prebidOnProductDataField: Array<number> = [];
  prebidMTOAmountsEnum: any = PrebidMTOAmountsEnum;
  MTORoundingBehaviorEnum: any = MTORoundingBehaviorEnum;

  presalesModeEnum: any = PresalesModeEnum;
  presalesModeOptions: any = PresalesModeEnum;
  presalesBuybackIncludedOptions: any = BuyBackIncludedEnum;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: CatalogService,
    private auctionClusterUserService: AuctionClusterUserService,
    private buyerService: AuctionClusterBuyerService,
    private languageService: LanguageService,
    private userService: UserService
  ) {
    super(injector);
    this.allowLowestStopClockPriceOptions = Object.keys(this.allowLowestStopClockPriceOptions).filter(f => !isNaN(Number(f))).map(key => ({ name: this.allowLowestStopClockPriceOptions[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.cleanupPrebidOnProduct = Object.keys(this.cleanupPrebidOnProduct).filter(f => !isNaN(Number(f))).map(key => ({ name: this.cleanupPrebidOnProduct[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.deletedTransactionPosition = Object.keys(this.deletedTransactionPosition).filter(f => !isNaN(Number(f))).map(key => ({ name: this.deletedTransactionPosition[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.groupingTypes = Object.keys(this.groupingTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.groupingTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.groupingBehaviour = Object.keys(this.groupingBehaviour).filter(f => !isNaN(Number(f))).map(key => ({ name: this.groupingBehaviour[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.prebidAllowedOptions = Object.keys(this.prebidAllowedOptions).filter(f => !isNaN(Number(f))).map(key => ({ name: this.prebidAllowedOptions[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.prebidPriorities = Object.keys(this.prebidPriorities).filter(f => !isNaN(Number(f))).map(key => ({ name: this.prebidPriorities[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.prebidMinMaxAmountsEnum = Object.keys(this.prebidMinMaxAmountsEnum).filter(f => !isNaN(Number(f))).map(key => ({ name: this.prebidMinMaxAmountsEnum[key], value: Number(key) }));
    this.presalesModeOptions = Object.keys(this.presalesModeOptions).filter(f => !isNaN(Number(f))).map(key => ({ name: this.presalesModeOptions[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.presalesBuybackIncludedOptions = Object.keys(this.presalesBuybackIncludedOptions).filter(f => !isNaN(Number(f))).map(key => ({ name: this.presalesBuybackIncludedOptions[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.printTiming = Object.keys(this.printTiming).filter(f => !isNaN(Number(f))).map(key => ({ name: this.printTiming[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.splitLotToMultipleSubbuyers = Object.keys(this.splitLotToMultipleSubbuyers).filter(f => !isNaN(Number(f))).map(key => ({ name: this.splitLotToMultipleSubbuyers[key], value: Number(key) })); // tslint:disable-line:max-line-length

    this.prebidMTOAmountsEnum = Object.keys(this.prebidMTOAmountsEnum).filter(f => !isNaN(Number(f))).map(key => ({name: this.prebidMTOAmountsEnum[key], value: Number(key)}));
    this.MTORoundingBehaviorEnum = Object.keys(this.MTORoundingBehaviorEnum).filter(f => !isNaN(Number(f))).map(key => ({name: this.MTORoundingBehaviorEnum[key], value: Number(key)}));
    
    var dateTimeNow = new Date();
    this.cleanupTime = new Date(dateTimeNow.getFullYear(), dateTimeNow.getMonth(), dateTimeNow.getDate());
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.isEditMode = false;

    this.presalesModeChanged = this.presalesModeChanged.bind(this);
  }

  ngOnInit() {
    this.model = new Catalog();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(catalogs: Array<Catalog>, catalogId: number, forecastCatalogs: Array<Catalog>,
    products: Array<Product>, auctions: Array<Auction>, auctionClusterId: number) {
    this.allItems = catalogs;
    this.forecastCatalogs = forecastCatalogs;
    this.filteredForecastCatalogs = forecastCatalogs;
    this.demoCatalogs = catalogs.filter(c => !c.isDemoCatalog);
    this.filteredDemoCatalogs = catalogs.filter(c => c.isDemoCatalog);
    this.products = products;
    this.auctions = auctions;
    this.auctionClusterId = auctionClusterId;

    this.catalog = catalogs.find(f => f.catalogId === catalogId);

    forkJoin(
      this.buyerService.getBuyers(auctionClusterId),
      this.auctionClusterUserService.getDemoUsers(auctionClusterId),
      this.userService.getCurrentUser()
    ).subscribe(result => {
      this.buyers = result[0];
      this.demoAdminUsers = result[1];
      this.user = result[2];

      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });

    if (catalogId !== null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getAuctionCatalog(this.catalog.auctionId, catalogId)
        .subscribe((res: Catalog) => {
          this.model = res;
          // mapping one number to enum values
          this.prebidOnProductDataField = this.calculatePrebidsOnProduct(res.cleanupPrebidsOnProduct);
          this.isOpened = true;
          if (this.model.scheduledCleanupTime) {
            this.cleanupTime = new Date(this.model.scheduledCleanupTime);
          }
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new Catalog();
      this.prebidOnProductDataField = this.calculatePrebidsOnProduct(PrebidOnProductEnum.ALL);
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  save() {
    this.model.auctionClusterId = this.auctionClusterId;

    if (!this.model.enableTransactionBuffer) {
      this.model.transactionBufferSize = 0;
    }
    this.model.cleanupPrebidsOnProduct = this.calculatePrebidsOnProductValue(this.prebidOnProductDataField);

    if (this.model.typeOfGrouping !== TypeOfGrouping.MANUAL_GROUPING && this.model.typeOfGrouping !== TypeOfGrouping.COMBINED_GROUPING) {
      this.model.groupingBehaviour = null;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;

      var dateTimeNow = new Date();
      this.model.scheduledCleanupTime = new Date(dateTimeNow.getFullYear(), dateTimeNow.getMonth(), dateTimeNow.getDate(), this.cleanupTime.getHours(), this.cleanupTime.getMinutes());

      this.dataService.edit(this.model.auctionId, this.model)
        .subscribe((res: any) => {
          this.model = new Catalog();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;
      this.dataService.save(this.model.auctionId, this.model)
        .subscribe((res: any) => {
          this.model = new Catalog();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  filterCatalogs() {
    if (this.forecastCatalogs) {
      this.filteredForecastCatalogs = this.forecastCatalogs.filter(c => c.productId === this.model.productId);
    }
    if (this.demoCatalogs) {
      this.filteredDemoCatalogs = this.demoCatalogs.filter(c => c.productId === this.model.productId);
    }
  }

  presalesModeChanged = (e: any) => {
    if (this.isOpened && this.model.presalesMode != this.catalog.presalesMode) {
    //if(e.value != e.previousValue) {
      this.confirmation.opened = true;
    }
  }


  dateChanged(dateProperty: string, dateTime: Moment) {
    var date = null;
    if (dateProperty === 'cleanupTime') {
      date = this.cleanupTime;
    }

    if (moment(date).isSame(dateTime)) {
      return;
    }

    if (dateProperty === 'cleanupTime') {
      this.cleanupTime = moment(dateTime).toDate();
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnSupplyCatalogSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnSupplyCatalogSubmit')[0].removeAttribute('disabled');
      }

    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnSupplyCatalogSubmit')[0].removeAttribute('disabled');
      }
    }
  }


  dateValue(property: any) {
    return this.cleanupTime;
  }

  comboboxName = (e: any) => {
    if (e !== null) {
      const name = e.firstname + ' ' + e.lastname;
      return name;
    }
  }

  translateSelectBoxes = (item) => {
    if (item) {
      let label = item.name;
      this.translate.get('SHARED.' + label).subscribe((res: string) => {
        label = res;
      });
      return label;
    }
  }

  calculatePrebidsOnProduct(prebid: number) {

    // tslint:disable-next-line: max-line-length
    const enumValues = Object.keys(PrebidOnProductEnum).map(key => PrebidOnProductEnum[key]).filter(value => typeof value === 'number') as Array<number>;
    const prebids: Array<number> = [];

    enumValues.forEach(element => {
      // tslint:disable-next-line: no-bitwise
      const result = prebid & element;
      if (result !== 0) {
        prebids.push(element);
      }
    });

    return prebids;
  }

  calculatePrebidsOnProductValue(arrayOfPrebids: Array<number>) {

    let cleanupPrebidOnProduct = 0;
    arrayOfPrebids.forEach(element => cleanupPrebidOnProduct += element);

    return cleanupPrebidOnProduct;
  }

  resetPresalesMode(e: any) {
    this.model.presalesMode = this.catalog.presalesMode;
  }
}
