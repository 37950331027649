<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="item-list-component">
    <div class="grid-buttons">
      <dx-button hint="{{ 'SHARED.ADD' | translate }}"
                 icon="add"
                 (onClick)="add()"></dx-button>
      <dx-button hint="{{ 'SHARED.PRINT' | translate }}"
                 icon="print"
                 (onClick)="print()"></dx-button>
    </div>
    <br />
    <dx-data-grid #buyerShoppingListsGrid
                  id="printSection"
                  [rtlEnabled]="rtlEnabled"
                  [dataSource]="items"
                  [showBorders]="true"
                  (onRowClick)="openShoppingListEditor($event)"
                  [allowColumnReordering]="true"
                  [hoverStateEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  (onExporting)="onExporting($event)">
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-pager [visible]="true"
                 [showPageSizeSelector]="true"
                 [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showNavigationButtons]="true"
                 [showInfo]="true"
                 infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="description" caption="{{ 'SHARED.DESCRIPTION' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="catalogName" caption="{{ 'SHARED.CATALOG' | translate }}">
      </dxi-column>
      <dxi-column type="buttons" [width]="120">
        <dxi-button hint="{{ 'SHOPPING_LIST.OPEN_SHOPPING_LIST_EDITOR' | translate }}" icon="detailslayout" [onClick]="openShoppingListEditor"></dxi-button>
        <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
        <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </div>
</div>

<shopping-list-component #details
                         (onClosed)="onDetailsComponentClosed($event)"></shopping-list-component>
<confirmation-component #confirmation title="{{ 'SHOPPING_LIST.DELETE_SHOPPING_LIST' | translate }}"
                        message="{{ 'SHOPPING_LIST.DELETE_SHOPPING_LIST_MESSAGE' | translate }}"
                        buttonCloseText="{{ 'SHARED.DELETE' | translate }}" cancellable="true" (close)="deleteSelected();"></confirmation-component>
