<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
    <dx-button hint="{{ 'SHARED.PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
  </div>
  <br />
  <dx-data-grid #auctionGrid id="printSection" [rtlEnabled]="rtlEnabled" [dataSource]="items" [showBorders]="true" (onRowClick)="edit($event)"
                [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-pager [visible]="true"
               [showPageSizeSelector]="true"
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true"
               [showInfo]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}">
    </dxi-column>
    <dxi-column type="buttons" [width]="75" class="no-print">
      <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
      <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>

<auction-component #details (onClosed)="onDetailsComponentClosed($event)"></auction-component>
<name-confirmation-component #confirmation title="{{ 'AUCTIONS.DELETE_AUCTION' | translate }}"
                             infoText="{{ 'AUCTIONS.DELETE_AUCTION_INFOTEXT' | translate:{auction: itemToDelete?.name} }}"
                             message="{{ 'AUCTIONS.DELETE_AUCTION_MESSAGE' | translate }}" buttonCloseText="{{ 'SHARED.DELETE' | translate }}" cancellable="true"
                             (close)="onNameConfirmationClosed($event);"></name-confirmation-component>
<cleanup-confirmation-component #confirmationCleanup title="{{ 'AUCTIONS.CLEANUP' | translate }}"
                                message="{{ 'AUCTIONS.CLEANUP_MESSAGE' | translate }}" buttonCloseText="{{ 'SHARED.CLEANUP' | translate }}" cancellable="true"
                                [date]="auctionCleanupDate" (dateChanged)="auctionCleanupDateChanged($event)" (close)="cleanupAuction();"></cleanup-confirmation-component>
