import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';

// models
import { Language } from '../../models/language';

// services
import { LanguageService } from '../../services/language.service';

class TranslatableField {
  constructor(public language: string, public value: string, public languageText: string, public id: string, public isSelected: boolean = false) { }
}

@Component({
  selector: 'translatable-field',
  styleUrls: ['./translatable-field.component.scss'],
  templateUrl: 'translatable-field.component.html'
})
export class TranslatableFieldComponent implements OnInit {

  items: Array<TranslatableField> = [];
  defaultLang: Language;
  isDisabled: boolean;
  languages: Array<Language> = [];
  content: string;
  currentLanguage: string;
  editorValue: string;
  popupVisible: boolean;
  sizeFormatValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  sizeFormatOptions = { width: 60 };
  fontFormatValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
  fontFormatOptions = { width: 120};
  isFirstLoad: boolean = true;

  @ViewChild('textinput') textinput: ElementRef;
  @ViewChild('textbox') textbox: ElementRef;

  get instance() {
    if (this.type === 'text')
      return this.textinput.nativeElement;
    else
      return this.textbox.nativeElement;
  }

  @Output() dataChange = new EventEmitter();
  @Output() elementIsFocused = new EventEmitter();
  @Output() valueChanged = new EventEmitter<boolean>();
  @Output() onFocus = new EventEmitter<boolean>();
  @Output() onInitialized = new EventEmitter<any>();
  @Output() onContentChanged = new EventEmitter<string>();

  @Output('valid') get valid(): boolean {
    let valid = false;
    this.items.every(item => {
      if ((item.language === this.defaultLang.code) && (item.value === '' || item.value === undefined)) {
        return valid = false;
      } else {
        return valid = true;
      }
    });
    return valid;
  }

  @Input('componentIndex') componentIndex = 0;
  @Input('name') name = '';

  @Input('caption') caption = '';
  @Input('type') type = 'text';
  @Input('isFocusable') isFocusable: boolean;

  @Input('insideLotEditor') insideLotEditor: boolean;

  @Input('isLabelHidden') isLabelHidden = false;
  @Input('isDevExpress') isDevExpress = false;

  @Input('clusterLanguages') clusterLanguages = [];

  @Input('textRequired') textRequired: boolean = false;

  @Input('disable')
  set disabledValue(value: boolean) {
    this.isDisabled = value;
  }

  @Input('data')
  // in translatable field format, e.g. {"en":"User management", "nl":"Gebruikersbeheer"}
  get data(): string {
    return this.export();
  }
  set data(value: string) {
    this.items = [];
    if (value) {
      const field = JSON.parse(value);
      
      if (this.clusterLanguages.length) {
        this.languages = this.clusterLanguages;
        this.filterClusterLanguages(field);
      }

      this.ensureAllLanguages(field);

      const properties = Object.getOwnPropertyNames(field);
      if (properties.length > 0) {
        properties.forEach(property => {
          const item = new TranslatableField(property, field[property], this.matchLanguageText(property), UUID.UUID());
          this.items.push(item);
        });

        let item = this.items.find(_ => _.language === this.currentLanguage);
        if (item) {
          item.isSelected = true;
        }
        else {
          this.items[0].isSelected = true;
        }
      }
    }
    if (this.isFirstLoad) {
      this.resetLanguageButtons();
      this.isFirstLoad = false;
    }
  }

  @ViewChild('lang', { read: ElementRef }) langInput: any;

  constructor(private translateService: TranslateService, private languageService: LanguageService) {
    this.textRequired = true;
    this.languageService.getLanguages().subscribe(langs => {
      this.languages = langs;
      this.defaultLang = langs.find(f => f.isDefault);
    });
  }

  ngOnInit() {
    this.onInitialized.emit(this);
  }

  private ensureAllLanguages(field) {
    this.languages.forEach(l => {
      if (field[l.code] == null) {
        field[l.code] = '';
      }
    });
  }

  private filterClusterLanguages(field) {
    for (const key in field) {
      if (!this.languages.some(lang => lang.code === key)) {
        delete field[key];
      }
    }
  }

  private matchLanguageText(language): string {
    return this.translateService.instant('LANGUAGES.' + language.toUpperCase());
  }

  onItemChecked(item: any) {
    this.dataChange.emit(this.export());
  }

  changeLanguage(index: number) {
    this.items.forEach((item, i) => {
      item.isSelected = false;
    });

    this.items[index].isSelected = true;
    this.currentLanguage = this.items[index].language;
    this.onContentChanged.emit(this.content);
  }

  resetLanguageButtons() {
    this.items.forEach(item => {
      if (item.language === this.translateService.currentLang) {
        item.isSelected = true;
        this.currentLanguage = item.language;
      } else {
        item.isSelected = false;
      }
    });
    if (this.items.length > 0 && !this.items.some(item => item.isSelected === true)) {
      this.items[0].isSelected = true;
      this.currentLanguage = this.items[0].language;
    }
  }

  public export(): string {
    const field = {};
    for (const item of this.items) {
      field[item.language] = item.value;
    }
    return JSON.stringify(field);
  }

  elementFocus() {
    // setTimeout(() => { this.langInput.nativeElement.focus(); });
  }

  onFocused() {
    this.onFocus.emit();
  }
}
