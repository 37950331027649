<div class="lookup-table-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #editLookupTable [width]="600" height="550px" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
            [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
            (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">

      <dx-form #lookupTableForm id="lookupTableForm" [showValidationSummary]="false"
               (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

        <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'SHARED.GENERAL' | translate }}">
            <dxi-item dataField="name" [template]="'lookupTableNameField'">
              <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="lookupTableType"
                      editorType="dxSelectBox"
                      [editorOptions]="{ disabled: isEditMode, items: lookupTableTypes, valueExpr:'value',
                        displayExpr: translateSelectBoxes}">
              <dxo-label text="{{ 'SHARED.TYPE' | translate }}"></dxo-label>
            </dxi-item>

            <dxi-item dataField="hasPeriodFilter" editorType="dxCheckBox">
              <dxo-label text="{{ 'LOOKUP_TABLE.HAS_PERIOD_FILTER' | translate }}" location="right"></dxo-label>
            </dxi-item>

            <dxi-item dataField="enableCreate" editorType="dxCheckBox">
              <dxo-label text="{{ 'SHARED.ENABLE_CREATE' | translate }}" location="right"></dxo-label>
            </dxi-item>

            <dxi-item dataField="enableDelete" editorType="dxCheckBox">
              <dxo-label text="{{ 'SHARED.ENABLE_DELETE' | translate }}" location="right"></dxo-label>
            </dxi-item>

            <dxi-item dataField="enableUpdate" editorType="dxCheckBox">
              <dxo-label text="{{ 'SHARED.ENABLE_UPDATE' | translate }}" location="right"></dxo-label>
            </dxi-item>
          </dxi-tab>
          <dxi-tab title="{{ 'SHARED.FIELDS'  | translate }}">
            <lookup-table-fields-component [(data)]="model.lookupTableFields"
                                           [lookupTableType]="model.lookupTableType"
                                           [isAnyRowSet]="isAnyRowSet"
                                           [languages]="languages"
                                           [productPropertyTypes]="productPropertyTypes"
                                           [productPropertyFormats]="productPropertyFormats"
                                           [masterDataLists]="masterDataLists"
                                           (dataChanged)="saveWithoutClose()">
            </lookup-table-fields-component>
          </dxi-tab>
        </dxi-item>

        <div *dxTemplate="let data of 'lookupTableNameField'" class="translatable-template">
          <translatable-field (change)="onChangeTranslationField($event, 'name')" [isDevExpress]="true" [isLabelHidden]="true" [caption]="'SHARED.NAME'" [clusterLanguages]="languages" [(data)]="data.component.option('formData')[data.dataField]" [name]="'name'" #lookupTableName></translatable-field>
        </div>

      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnLookupTableSubmit" disabled="disabled">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
