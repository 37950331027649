import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// interfaces
import{ IReportService } from '../../../shared/interfaces/report';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Report } from '../../../shared/models/report';

// services
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class PlatformReportService implements IReportService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  getReports(): Observable<Array<Report>> {
    return this.webApiService.getList(this.apiPath + 'reports');
  }

  save(report: Report): Observable<any> {
    return this.webApiService.save<Report>(this.apiPath + 'reports', report);
  }

  edit(report: Report): Observable<any> {
    return this.webApiService.editSingle<Report>(this.apiPath + 'reports', report);
  }

  delete(reportId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + 'reports', reportId);
  }

  filterReport(model: any) {
    const url = this.appSettings.reportingApiURL + '/api/v1/filter';
    return this.webApiService.save(url, model);
  }

  //IReportService

  getReport(reportId: number): Observable<Report> {
    return this.webApiService.getSingle(this.apiPath + `reports/${reportId}`);
  }

  saveReport(report: Report): Observable<any> {
    return this.webApiService.save<Report>(this.apiPath + 'reports', report);
  }

  editReport(report: Report): Observable<any> {
    return this.webApiService.editSingle<Report>(this.apiPath + 'reports', report);
  }

  requestDownload(reportId: number, reportRequest: any) {
    const url = this.apiPath + 'reports/requestdownload/' + reportId;
    return this.webApiService.downloadWithPost(url, reportRequest);
  }

  downloadExport(reportId: number, reportRequest: any) { }

  getStoredProcedureValuesForProductProperty(reportId: number, filter: any) { }

  getStoredProcedureValuesForNumberProductProperty(reportId: number, filter: any) { }

  getStoredProcedureValuesForStringProductProperty(reportId: number, filter: any) { }

  getStoredProcedureValuesForDateProductProperty(reportId: number, filter: any) { }
}
