import { Component } from '@angular/core';

// services
import { AlertService } from '../../shared/services/alert.service';

@Component({
    selector: 'alert-component',
    templateUrl: 'alert.component.html'
})
export class AlertComponent {

    constructor(public alertService: AlertService) {}

    onClose() {
        this.alertService.close();
    }
}
