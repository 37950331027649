<header class="header header-1 header-component" id="au-title-bar" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="branding">
    <span *ngFor="let url of title.urls; let i = index">
      <span class="title au-title-bar-text" [class.title-link]="i !== title.urls.length - 1" (click)="title.goBack(i)" [innerHtml]="url"></span>
      <span *ngIf="i !== title.urls.length - 1">&nbsp;>&nbsp;</span>
    </span>
  </div>
  <div class="header-actions">
    <clr-dropdown class="dropdown bottom-right">
      <button class="nav-icon" clrDropdownToggle>
        <div>{{ authenticationService?.displayName }}&nbsp;{{ curentMode() }}</div>
        <clr-icon shape="user"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu>
        <a (click)="openAuthentication(authenticationService?.currentUserId, $event)" clrDropdownItem>{{ 'HEADER.AUTHENTICATION' | translate }}</a>
        <a (click)="editProfile(authenticationService?.currentUserId, $event)" clrDropdownItem>{{ 'HEADER.CHANGE_PROFILE' | translate }}</a>
        <a *ngIf="appModeEnabled" (click)="appModeDialogOpened = true" clrDropdownItem>{{ 'SHARED.MODE' | translate }}</a>
        <a (click)="editNotifications(authenticationService?.currentUserId, $event)" clrDropdownItem>{{ 'SHARED.NOTIFICATIONS' | translate }}</a>
        <a (click)="logout()" clrDropdownItem>{{ 'HEADER.LOG_OUT' | translate }}</a>
      </clr-dropdown-menu>
    </clr-dropdown>

    <clr-dropdown class="dropdown bottom-right" [clrCloseMenuOnItemClick]="true">
      <button class="nav-icon" clrDropdownToggle>
        <div>{{ currentLanguage.name | translate }}</div>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu>
        <div *ngFor="let l of languages;">
          <button clrDropdownItem type="submit" class="btn btn-link" [class.active]="currentLanguage.code == l.code" (click)="setLanguage(l.code)">
            {{ l.name }}
          </button>
        </div>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
  <dx-popup
        #appMode
        width="40%"
        height="500px"
        [showTitle]="true"
        title="{{ 'HEADER.SELECT_APP_MODE' | translate }}"
        [dragEnabled]="false"
        [closeOnOutsideClick]="false"
        [showCloseButton]="false"
        [rtlEnabled]="rtlEnabled"
        [visible]="appModeDialogOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <div class="clr-row">
          <div class="clr-col-md-6 clr-offset-md-6" *ngIf="isPlatformUser">
            <button type="submit" class="btn btn-secondary" (click)="navigateToPlatformLevel()">
              {{ 'HEADER.APP_MODE_PLATFORM' | translate }}
            </button>
            <br /><br />
          </div>
        </div>
        <div class="clr-row">
          <div *ngIf="isAuctionClusterUser" class="clr-col-md-6">
            <dx-select-box name="auctions" class="select-mode-dropdown-options" [items]="auctionClusters" valueExpr="auctionClusterId"
                           [(value)]="auctionClusterId" displayExpr="name" [rtlEnabled]="rtlEnabled">
            </dx-select-box>
          </div>
          <div class="clr-col-md-6">
            <button type="submit" class="btn btn-secondary" (click)="navigateToAuctionClusterLevel()" [disabled]="auctionClusterId == 0">
                {{ 'SHARED.AUCTION_CLUSTER_MANAGEMENT' | translate }}
            </button>
          </div>
        </div>
        <br />
        <div *ngIf="isBuyerUser">
          <div class="clr-row">
            <div class="clr-col-md-6">
              <dx-select-box name="buyers" class="select-mode-dropdown-options" [items]="buyers" valueExpr="buyerId"
                             [(value)]="buyerId" displayExpr="name" [rtlEnabled]="rtlEnabled">
              </dx-select-box>
            </div>
            <div class="clr-col-md-6">
              <button type="submit" class="btn btn-secondary" (click)="navigateToBuyerLevel()" [disabled]="buyerId === 0">
                {{ 'HEADER.APP_MODE_BUYER' | translate }}
              </button>
            </div>
          </div>
        </div>
        <br />
        <div *ngIf="isSupplierUser">
          <div class="clr-row">
            <div class="clr-col-md-6">
              <dx-select-box name="suppliers" class="select-mode-dropdown-options" [items]="suppliers" valueExpr="supplierId"
                             [(value)]="supplierId" displayExpr="name" [rtlEnabled]="rtlEnabled">
              </dx-select-box>
            </div>
            <div class="clr-col-md-6">
              <button type="submit" class="btn btn-secondary" (click)="navigateToSupplierLevel()" [disabled]="supplierId === 0">
                {{ 'HEADER.APP_MODE_SUPPLIER' | translate }}
              </button>
            </div>
          </div>
        </div>
          <br/><br/>
          <button type="button" class="btn btn-outline" (click)="appModeDialogOpened = false">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
    </dx-popup>
  <authentication-component></authentication-component>
</header>

<edit-profile-component></edit-profile-component>
<edit-user-notifications-component></edit-user-notifications-component>
