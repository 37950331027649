import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, timer } from 'rxjs';

const SEPARATOR_CHAR: string = '>';
const APPENT_SEPARATOR_CHAR_CODE: string = '&gt;';

@Injectable()
export class TitleService {

  text: BehaviorSubject<string> = new BehaviorSubject('');
  urls: Array<string> = [];

  constructor(
    private translate: TranslateService) {
    this.text.subscribe(text => {
      this.urls = text.split(SEPARATOR_CHAR).map(url => url.trim());
    });
  }

  goBack(index: number) {
    index = index - (this.urls.length - 1);

    if (index === 0)
      return;

    history.go(index);
    timer(50).subscribe(() => {
      history.replaceState({ navigationId: 0 }, document.title, location.href);
    });
  }

  public set(key: string) {
    this.translate.get(key).subscribe((res: string) => {
      this.text.next(res);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get(key).subscribe((res: string) => {
        this.text.next(res);
      });
    });
  }

  public add(key: string) {
    this.translate.get(key).subscribe((res: string) => {
      this.text.next(`${this.text.value} ${SEPARATOR_CHAR} ${res}`);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get(key).subscribe((res: string) => {
        this.text.next(`${this.text.value} ${SEPARATOR_CHAR} ${res}`);
      });
    });
  }

  public append(key: string) {
    this.translate.get(key).subscribe((res: string) => {
      this.text.next(`${this.text.value} ${APPENT_SEPARATOR_CHAR_CODE} ${res}`);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get(key).subscribe((res: string) => {
        this.text.next(`${this.text.value} ${SEPARATOR_CHAR} ${res}`);
      });
    });
  }
}
