import { Injectable, Output, EventEmitter } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

// signalr
import { HubConnection, HubConnectionBuilder, LogLevel, JsonHubProtocol, IReconnectPolicy, HttpTransportType } from '@aspnet/signalr';

// services
import { CookieService } from 'ngx-cookie-service';
import { ConfigService } from '../../../shared/services/config.service';
import { ErrorService } from '../../../shared/services/error.service';
import { TokenService } from '../../../shared/services/token.service';
import { WebApiService } from '../../../shared/services/web-api.service';

import { AuctionClusterBuyerService } from './auction-cluster-buyer.service';
import { AuctionClusterUserService } from './auction-cluster-user.service';

// models
import { Cookies } from '../../../shared/constants/cookies';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { UserType } from '../../../shared/models/clock';

import { AuctionClusterBuyer } from '../models/auction-cluster-buyer';
import { AuctionClusterUser } from '../models/auction-cluster-user';
import { ClockConfigInfo } from '../models/clock-config-info';
import { NetworkLatencyTiming } from '../models/network-latency-timing';
import { UserFilter } from '../models/user-filter';

const DEFAULT_RECONNECT_TIMEOUT_MS = 5000;

@Injectable()
export class LineMonitorService {

  private hubConnection: HubConnection;
  private buyers: Array<AuctionClusterBuyer> = [];
  private users: Array<AuctionClusterUser>;
  private clockId = 0;
  private isAutheticated = false;
  private isStopped = true;

  @Output('networkLatencyUpdate') networkLatencyUpdate: EventEmitter<NetworkLatencyTiming> = new EventEmitter();
  @Output('clockConfigInfo') clockConfigInfo: EventEmitter<ClockConfigInfo> = new EventEmitter();

  constructor(
    private appSettings: ApplicationSettings,
    private webApiService: WebApiService,
    private errorService: ErrorService,
    private cookieService: CookieService,
    private buyerService: AuctionClusterBuyerService,
    private configService: ConfigService,
    private tokenService: TokenService,
    private auctionClusterUserService: AuctionClusterUserService
  ) { }

  init(auctionClusterId: number): Observable<any> {
    let userFilter = new UserFilter();
    userFilter.showAuction = true;
    userFilter.showBuyer = true;
    userFilter.showSupplier = true;
    userFilter.showInactive = false;
    userFilter.showActive = true;
    userFilter.showBlocked = false;
    userFilter.showPending = false;

    return forkJoin(
      this.buyerService.getBuyers(auctionClusterId),
      this.auctionClusterUserService.getUsersFiltered(auctionClusterId, userFilter),
      this.webApiService.getSingle(`${this.getUrlWithoutTrailingSlash(this.appSettings.clockURL)}/aucxis.config.json?d=${Date.now()}`)
    ).pipe(tap(results => {
      // Get buyers
      this.buyers = results[0];
      // Get users
      this.users = results[1];

      // Create hub connection if required
      if (!this.hubConnection) {
        let socketUrl = this.configService.getClockServiceUrl(results[2]);
        this.createHubConnection(socketUrl);
      }
    }), catchError(error => {
      this.errorService.show(error);
      return of(error);
    }));
  }

  start(clockId: number) {
    this.clockId = clockId;

    // Register method handlers
    this.hubConnection.on('NetworkLatencyTimingsResponse', (response: NetworkLatencyTiming) => this.parseResponse(response));
    this.hubConnection.on('ClockConfigInfo', (response: ClockConfigInfo) => { this.clockConfigInfo.emit(response) });

    this.hubConnection.on('UserAuthorizationResponse', (response: any) => {
      this.isAutheticated = response.isAuthenticationSuccess;
      if (response.isAuthenticationSuccess) {
        this.refreshNetworkLatencies(this.clockId);
        this.getClockConfigInfo(this.clockId);
      }
    });

    this.isStopped = false;

    this.hubConnection.start().then(_ => this.authenticate());
  }

  stop() {
    this.isStopped = true;
    if (this.hubConnection) {
      // Remove handlers
      this.hubConnection.off('NetworkLatencyTimingsResponse');
      this.hubConnection.off('ClockConfigInfo');
      this.hubConnection.off('UserAuthorizationResponse');
      // Stop connection
      this.hubConnection.stop();
    }
  }

  // Create and build Hub connection
  private createHubConnection(socketUrl: string) {
    const userId = this.cookieService.get(Cookies.USER_ID_COOKIE);
    const url = `${socketUrl}auction?userId=${userId}&type=${UserType.ADMINISTRATOR}&clockId=0`;

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url, { transport: HttpTransportType.WebSockets, skipNegotiation: true, })
      .configureLogging(LogLevel.Error)
      .withHubProtocol(new JsonHubProtocol())
      .withAutomaticReconnect(<IReconnectPolicy>{
        nextRetryDelayInMilliseconds: this.nextRetryDelayInMilliseconds
      })
      .build();

    this.hubConnection.onreconnected((connectionId: string) => {
      this.authenticate();
    });
  }

  private nextRetryDelayInMilliseconds(previousRetryCount: number, elapsedMilliseconds: number) {
    if (this.isStopped)
      return null;
    else {
      console.log(`Reconnecting in ${DEFAULT_RECONNECT_TIMEOUT_MS}ms`);
      return DEFAULT_RECONNECT_TIMEOUT_MS;
    }
  }

  private authenticate() {
    const userId = this.cookieService.get(Cookies.USER_ID_COOKIE);
    const token = this.tokenService.getToken();

    // Request auth from engine
    this.hubConnection.send('ActorMessage', 'UserAuthorizationRequest', { userId, token });
  }

  // Parse network latency response
  private parseResponse(response: NetworkLatencyTiming) {
    response.networkLatencies.forEach(item => {
      const buyer = this.buyers.find(b => b.buyerId === item.buyerId);

      if (buyer) {
        item.buyerNumber = buyer.buyerNumber;
      }

      const user = this.users.find(u => u.userId === item.userId);
      if (user) {
        item.name = user.firstname + " " + user.lastname;
      }
      else {
        item.name = "System";
      }
    });

    this.networkLatencyUpdate.emit(response);
  }

  // Send request for refresh online users
  refreshNetworkLatencies(clockId: number) {
    // Ignore request if user is not authenticated
    if (!this.isAutheticated)
      return;

    if (this.clockId !== clockId) {
      this.clockId = clockId;
      this.networkLatencyUpdate.emit(new NetworkLatencyTiming());
    }

    // Call Hub message
    this.hubConnection.send('ActorMessage', 'NetworkLatencyTimingsRequest', { clockId: this.clockId });
  }

  getClockConfigInfo(clockId: number) {
    // Ignore request if user is not authenticated
    if (!this.isAutheticated)
      return;

    if (this.clockId !== clockId) {
      this.clockId = clockId;
    }

    // Call Hub message
    this.hubConnection.send('ActorMessage', 'ClockConfigInfoRequest', { clockId: this.clockId });
  }

  kickOutLineMonitorBuyer(clockId: number, buyerId: number, userId: number) {
    const model = {
      clockId,
      buyerId,
      userId
    };

    this.hubConnection.send('ActorMessage', 'KickOutLineMonitorBuyer', model);
  }

  // Strips trailing slash "/" from given url
  private getUrlWithoutTrailingSlash(url: string) {
    return url.replace(/\/+$/, "");
  }
}
