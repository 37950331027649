<div class="item-list-component supply-forecast-public-urk-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.PRINT' | translate }}"
               icon="print"
               (onClick)="print()"></dx-button>
  </div>

  <div class="card">
    <div class="card-header">
      {{ 'SHARED.FILTER' | translate }}
    </div>
    <div class="filter-column">
      <label for="auctionCluster">{{ 'SHARED.AUCTION' | translate }}</label>
      <dx-select-box name="auctions"
                     [items]="auctions"
                     valueExpr="auctionId"
                     [(value)]="auctionId"
                     displayExpr="name"
                     [rtlEnabled]="rtlEnabled"
                     [searchEnabled]="true"
                     [showClearButton]="true"
                     placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                     cssClass="filter-lot-select">
      </dx-select-box>
    </div>

    <div class="filter-column">
      <label>{{ 'SHARED.DATE' | translate }}</label>
      <label for="date" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
        <long-date-component class="dt-input"
                             [name]="date"
                             [data]="date"
                             [required]="true"
                             (modelChanged)="dateChanged($event)">
        </long-date-component>
      </label>
    </div>

    <div class="filter-column">
      <button type="button" class="btn btn-primary" (click)="getData()">{{ 'SHARED.FILTER' | translate }}</button>
    </div>
  </div>

  <div class="datagrid-narrow-wrapper">
    <div class="datagrid-narrow">
      <dx-data-grid #publicForecast1Grid
                    id="printSection"
                    [rtlEnabled]="rtlEnabled"
                    [dataSource]="model.speciesAmounts"
                    [showBorders]="true"
                    [allowColumnReordering]="true"
                    [hoverStateEnabled]="true"
                    [columnAutoWidth]="true"
                    [rowAlternationEnabled]="true"
                    (onExporting)="onExporting($event)">
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" [showNavigationButtons]="true"></dxo-pager>
        <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'PUBLIC_FORECAST.FISH_SPECIES' | translate }}" [customizeText]="translateField">
        </dxi-column>
        <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="amount" caption="{{ 'PUBLIC_FORECAST.AMOUNT_KG' | translate }}" [customizeText]="amountField">
        </dxi-column>
      </dx-data-grid>
    </div>

    <div class="datagrid-narrow">
      <dx-data-grid #publicForecast2Grid
                    id="printSection"
                    [rtlEnabled]="rtlEnabled"
                    [dataSource]="model.supplierLandingTimes"
                    [showBorders]="true"
                    [allowColumnReordering]="true"
                    [hoverStateEnabled]="true"
                    [columnAutoWidth]="true"
                    [rowAlternationEnabled]="true"
                    (onExporting)="onExporting($event)">
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" [showNavigationButtons]="true"></dxo-pager>
        <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="supplier" caption="{{ 'SHARED.SUPPLIER' | translate }}">
        </dxi-column>
        <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="landingTime" caption="{{ 'PUBLIC_FORECAST.LANDING_TIME' | translate }}" [customizeText]="timeField">
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>
  <div class="datagrid-wide">
    <dx-data-grid #publicForecast3Grid
                  id="printSection"
                  [rtlEnabled]="rtlEnabled"
                  [dataSource]="model.auctionSupplierAmounts"
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [hoverStateEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  (onExporting)="onExporting($event)">
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-pager [visible]="true"
                 [showPageSizeSelector]="true"
                 [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showNavigationButtons]="true"
                 [showInfo]="true"
                 infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="auctionName" caption="{{ 'SHARED.AUCTION' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="amount" caption="{{ 'PUBLIC_FORECAST.AMOUNT_KG' | translate }}" [customizeText]="amountField">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="numberOfSuppliers" caption="{{ 'PUBLIC_FORECAST.NUMBER_OF_SUPPLIERS' | translate }}" [customizeText]="amountField">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="exitOpenTime" caption="{{ 'PUBLIC_FORECAST.OPEN_TIME' | translate }}" [customizeText]="timeField">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="sortTime" caption="{{ 'PUBLIC_FORECAST.SORT_TIME' | translate }}" [customizeText]="timeField">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="saleTime" caption="{{ 'PUBLIC_FORECAST.SALE_TIME' | translate }}" [customizeText]="timeField">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="remark" caption="{{ 'SHARED.REMARK' | translate }}">
      </dxi-column>
    </dx-data-grid>
  </div>
</div>
