import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, map, Observable, throwError, catchError, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import * as auth0 from 'auth0-js';

// models
import { Cookies } from '../constants/cookies';
import { ApplicationSettings } from '../models/application-settings';
import { LoginInfo } from '../models/login-info';
import { Refresh } from '../models/refresh';
import { RegisterInfo } from '../models/register-info';
import { RequestPasswordReset } from '../models/request-password-reset';
import { ResetPassword } from '../models/reset-password';

// services
import { DynamicRoutingService } from './dynamic-routing.service';
import { SpinnerService } from './spinner.service';
import { TokenService } from './token.service';


@Injectable()
export class AuthService {

  auth0: any;
  public isAuthorized = new BehaviorSubject(false);
  public useAuth0: boolean = false;
  private userId: number;
  private accessToken: string;
  private refreshToken: string;
  private userGuid: string;
  private jwtHelper = new JwtHelperService();
  public token: any;
  public redirectUrl: string = '/';

  public get displayName(): any {
    const firstName = this.cookies.get(Cookies.USER_FIRSTNAME_COOKIE);
    const lastName = this.cookies.get(Cookies.USER_LASTNAME_COOKIE);
    return firstName + ' ' + lastName;
  }

  public get currentUserId(): any {
    return this.cookies.get(Cookies.USER_ID_COOKIE);
  }

  constructor(
    private router: Router,
    private cookies: CookieService,
    private tokenService: TokenService,
    private spinner: SpinnerService,
    private appSettings: ApplicationSettings,
    private dynamicRouting: DynamicRoutingService,
    private http: HttpClient
  ) {
    this.dynamicRouting.doLogout.subscribe(ev => this.logout());

    this.initialize();
  }

  initialize()
  {
    if(this.appSettings.authZeroSettings) {
      this.useAuth0 = this.appSettings.authZeroSettings.useAuthZero;
      this.auth0 = new auth0.WebAuth({
        clientID: this.appSettings.authZeroSettings.clientID,
        domain: this.appSettings.authZeroSettings.domain,
        responseType: this.appSettings.authZeroSettings.responseType,
        redirectUri: this.appSettings.authZeroSettings.redirectUri
      });
    }
    else {
      this.useAuth0 = false;
    }

    this.userId = +this.cookies.get(Cookies.USER_ID_COOKIE);

    let isLoggedIn = this.isLoggedIn();
    this.isAuthorized.next(isLoggedIn);
  }

  auth0Login() {
    this.auth0.authorize();
  }

  handleAuth0SetSession(authResult, versionAdminUI: string) {
    this.auth0SetSession(authResult, versionAdminUI)
      .subscribe(authResult => {
        if (authResult.result === true) {
          this.dynamicRouting.updateDefaultRoutes().then(result => {
            if (result) {
              if (this.redirectUrl == undefined) {
                this.redirectUrl = '/';
              }
              this.router.navigate([this.redirectUrl], { queryParamsHandling: 'merge' });
              this.redirectUrl = '/';
            }
          });
        } else if (!authResult.isEmailVerified) {
          console.log('Auth0 error in auth0SetSession: ' + authResult.message);
          this.auth0Logout('#/core/email-not-verified');
        } else {
          console.log('Auth0 error in auth0SetSession');
          this.auth0Logout('');
        }
      }, error => {
        console.log('Auth0 error in auth0SetSession: ' + error);
        this.auth0Logout('#/core/account-not-found/');
      });
  }

  auth0SetSession(authResult, versionAdminUI: string): Observable<any> {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    return this.auth0LoginToAdminApi(authResult, versionAdminUI);
  }

  auth0LoginToAdminApi(authResult: any, versionAdminUI: string) {

    return this.http.post(this.appSettings.adminApi + 'auth/auth0-login',
      {
        accessToken: authResult.accessToken,
        idToken: authResult.idToken,
        versionAdminUI: versionAdminUI,
        versionClockUI: "0.0.0.0"
      })
      .pipe(
        map((res: any) => {
          return this.processAuthenticationInfo(res);
        }),
        catchError(error => throwError(error))
      );
  }

  auth0Logout(page: string): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.cookies.delete(Cookies.REJECTED_TOS);

    this.auth0.logout({
      returnTo: this.appSettings.authZeroSettings.redirectUri + page,
    });
  }

  auth0IsAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    return new Date().getTime() < expiresAt;
  }

  login(loginInfo: LoginInfo) {
    return this.http.post(this.appSettings.adminApi + 'auth/login', loginInfo)
      .pipe(
        map((res: any) => {
          return this.processAuthenticationInfo(res);
        }),
        catchError(error => throwError(error))
      );
  }

  processAuthenticationInfo(res: any) {
    if (res.email && !res.isEmailVerified) {
      //this.startLogoutTimeout(true);
      return { result: false, isEmailVerified: res.isEmailVerified };
    }

    if (res.isMFASetupNeeded) {
      return { result: false, isMFASetupNeeded: true, mFATotpAuthLink: res.mfaTotpAuthLink, isMFACodeOk: res.isMFACodeOk };
    }

    if (res.isMFACodeNeeded) {
      return { result: false, isMFACodeNeeded: true, isMFACodeOk: res.isMFACodeOk };
    }

    if (res.token != null) {
      this.userId = res.userId;
      this.accessToken = res.token;
      this.refreshToken = res.refreshToken;
      this.userGuid = res.userGuid;

      //let expirationDate = this.getExpirationDate(this.accessToken);
      let refreshExpirationDate = this.getExpirationDate(this.refreshToken);

      this.tokenService.setToken(this.accessToken, refreshExpirationDate);
      this.cookies.set(Cookies.USER_ID_COOKIE, this.userId.toString(), refreshExpirationDate, '/');
      this.cookies.set(Cookies.REFRESH_TOKEN_COOKIE, this.refreshToken, refreshExpirationDate, '/');
      this.cookies.set(Cookies.USER_FIRSTNAME_COOKIE, res.firstname, refreshExpirationDate, '/');
      this.cookies.set(Cookies.USER_LASTNAME_COOKIE, res.lastname, refreshExpirationDate, '/');
      this.cookies.set(Cookies.USER_GUID, this.tokenService.userGuid, refreshExpirationDate, '/');

      this.isAuthorized.next(true);

      //this.startLogoutTimeout(true);
      return { result: true, isEmailVerified: '' };
    } else {
      this.isAuthorized.next(false);
      //this.startLogoutTimeout(true);
      return { result: false, isEmailVerified: true };
    }
  }

  refreshAccessToken()
  {
    this.refreshToken = this.cookies.get(Cookies.REFRESH_TOKEN_COOKIE);
    this.accessToken = this.tokenService.getToken();

    if(!this.isTokenValid(this.refreshToken))
    {
      return throwError(false);
    }

    const refresh = new Refresh(this.userId, this.refreshToken, this.accessToken);

    return this.http.post(this.appSettings.adminApi + 'auth/refresh', refresh);
  }

  applyToken(res: any)
  {
    this.accessToken = res.token;
    this.refreshToken = res.refreshToken;
    this.userId = res.userId;

    //let expirationDate = this.getExpirationDate(this.accessToken);
    let refreshExpirationDate = this.getExpirationDate(this.refreshToken);

    this.tokenService.setToken(this.accessToken, refreshExpirationDate);
    this.cookies.set(Cookies.REFRESH_TOKEN_COOKIE, this.refreshToken, refreshExpirationDate, '/');

    this.cookies.set(Cookies.USER_ID_COOKIE, this.userId.toString(), refreshExpirationDate, '/');
    this.cookies.set(Cookies.USER_FIRSTNAME_COOKIE, this.cookies.get(Cookies.USER_FIRSTNAME_COOKIE), refreshExpirationDate, '/');
    this.cookies.set(Cookies.USER_LASTNAME_COOKIE, this.cookies.get(Cookies.USER_LASTNAME_COOKIE), refreshExpirationDate, '/');
    this.cookies.set(Cookies.USER_GUID, this.cookies.get(Cookies.USER_GUID), refreshExpirationDate, '/');

    this.isAuthorized.next(true);
  }

  logout() {
    this.userId = null;
    this.accessToken = null;
    this.refreshToken = null;

    this.cookies.delete(Cookies.USER_ID_COOKIE);
    this.tokenService.deleteToken();
    this.cookies.delete(Cookies.REFRESH_TOKEN_COOKIE);
    this.cookies.delete(Cookies.REJECTED_TOS);

    localStorage.removeItem('AUCXIS_SIDEBAR_LOGO');

    this.isAuthorized.next(false);

    if (this.spinner.isShowing.value) {
      this.spinner.hide();
    }   

    if (this.useAuth0) {
      this.auth0Logout('');
    }
    else {
      if (this.router.isActive('/core/reset-password', false)) return;

      this.goToStartPage('/core/login'); 
    }
  }

  goToStartPage(startPageRoute: string) {
    if (this.useAuth0) {
      this.auth0Login();
    }
    else {
      this.router.navigate([startPageRoute], { queryParamsHandling: 'merge' });
    }
  }

  isAccessTokenValid()
  {
    const accessToken = this.tokenService.getToken();
      
    if (accessToken) {
        try {
          if (this.jwtHelper.isTokenExpired(accessToken)) {
             return false;
            } else {
              return true;
            }
          }catch (e) {
            return false;
          }
    }

    return false;
  }

  isRefreshTokenValid() {
    const refreshToken = this.cookies.get(Cookies.REFRESH_TOKEN_COOKIE);

    if (refreshToken) {
      try {
        if (this.jwtHelper.isTokenExpired(refreshToken)) {
          return false;
        } else {
          return true;
        }
      } catch (e) {
        return false;
      }
    }

    return false;
  }

  isTokenValid(token)
  {
    if (token) {
        try {
          if (this.jwtHelper.isTokenExpired(token)) {
             return false;
            } else {
              return true;
            }
          }catch (e) {
            return false;
          }
    }

    return false;
  }

  isLoggedIn() {
    if (this.useAuth0) {
      return this.auth0IsAuthenticated();
    }
    else {
      const accessToken = this.tokenService.getToken();
     
      if (accessToken) {
        try {
          if (this.jwtHelper.isTokenExpired(accessToken)) {
            return false;
          } else {
            return true;
          }
        } catch (e) {
          this.logout();
        }
      } else {
        return false;
      }
    }
  }

  register(registerInfo: RegisterInfo) {
    return this.http.post(this.appSettings.adminApi + 'auth/register', registerInfo)
      .pipe(
        map((res: any) => {
          return this.processAuthenticationInfo(res).result;
        }),
        catchError(error => throwError(error))
      );
  }

  requestPasswordReset(requestPasswordReset: RequestPasswordReset) {
    return this.http.post(this.appSettings.adminApi + 'auth/request-password-reset', requestPasswordReset)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http.post(this.appSettings.adminApi + 'auth/reset-password', resetPassword)
      .pipe(
        map((res: any) => {
          return this.processAuthenticationInfo(res).result;
        }),
        catchError(error => throwError(error))
      );
  }

  getBannedPasswords() {
    return this.http.get(this.appSettings.adminApi + 'auth/banned-passwords')
      .pipe(
        catchError((error => throwError(error)))
      );
  }

  private getExpirationDate(token: string) {
    if (token) {
      try {
        return this.jwtHelper.getTokenExpirationDate(token);
      }
      catch (e) {
        return new Date();
        }
      }
      else
      {
        return new Date();
      }
  }
}
