<div class="item-list-component terms-and-conditions" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
  </div>
  <br />
  <dx-data-grid #auctionTerms
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [dataSource]="model"
                [showBorders]="true"
                (onRowClick)="open()"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true"
               [visible]="true">
    </dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="version" caption="{{ 'TERMS_AND_CONDITIONS.VERSION' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="date" dataField="dateUpdated" format="shortDate" caption="{{ 'TERMS_AND_CONDITIONS.DATE_UPDATED' | translate }}">
    </dxi-column>
    <dxi-column type="buttons" [width]="60">
      <dxi-button hint="{{ 'OPEN' | translate }}"
                  icon="activefolder"
                  [onClick]="open"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
<terms-and-conditions-add-component #details
                                    (onClosed)="onDetailsComponentClosed($event)"></terms-and-conditions-add-component>
