import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { ConfigurationParameter } from '../models/configuration-parameter';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class ConfigurationParameterService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'configurationparameter';
  }


  getConfigurationParameter(configurationParameterId: number): Observable<ConfigurationParameter> {
    return this.webApiService.get(this.apiPath, configurationParameterId);
  }

 
  edit(configurationParameter: ConfigurationParameter) {
    return this.webApiService.edit<ConfigurationParameter>(this.apiPath, configurationParameter.configurationParameterId, configurationParameter);
  }


}
