import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { saveFile } from '../helpers/file-download-helper';
import { catchError, Observable, throwError } from 'rxjs';

const STATUS_UNAUTHORIZED = 401;
const STATUS_FORBIDDEN = 403;

@Injectable()
export class WebApiService {
  constructor(private http: HttpClient) { }

  getList(apiPath: string) {
    return this.http.get(apiPath)
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  getWithParams(apiPath: string, params: any) {
    return this.http.get(apiPath, { params: params })
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  downloadWithGet(apiPath: string, filename: string) {
    return this.http.get(apiPath, { responseType: 'blob' })
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      ).subscribe(res => {
        saveFile(res, filename);
      });
  }

  downloadWithPost<T>(apiPath: string, data: T) {
    return this.http.post(apiPath, data, { responseType: 'blob', observe: 'response' })
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  get(apiPath: string, id: number) {
    return this.http.get(apiPath + '/' + id)
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      )
  }

  getSingle(apiPath: string) {
    return this.http.get(apiPath)
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  save<T>(apiPath: string, data: T) {
    return this.http.post(apiPath, data)
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  put<T>(apiPath: string, data: T) {
    return this.http.put(apiPath, data)
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  edit<T>(apiPath: string, id: number, data: T) {
    return this.http.put(apiPath + '/' + id, data)
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  editSingle<T>(apiPath: string, data: T) {
    return this.http.put(apiPath, data)
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  delete(apiPath: string, id: any) {
    return this.http.delete(apiPath + '/' + id)
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  deleteSingle<T>(apiPath: string, data: T) {
    // Delete method had to be manually implemented since 'http.delete'
    // doesn't support sending body containing 'data' object out of the box
    return this.http.request('delete', apiPath, { body: data })
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  enableDisable(apiPath: string, id: number) {
    return this.http.put(apiPath + '/' + id, '')
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  verifyEmail(apiPath: string, email: string) {
    return this.http.get(apiPath + '/' + email)
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  uploadImage(apiPath: string, data: FormData) {
    return this.http.post(apiPath, data, { responseType: 'text' })
      .pipe(
        catchError((error, caught) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  downloadPost<T>(apiPath: string, data: T) {
    return this.http.post(apiPath, data, { responseType: 'arraybuffer' })
      .pipe(
        catchError((error: any, caught: Observable<ArrayBuffer>) => {
          return this.catchAuthError(error, caught);
        })
      );
  }

  getParamsFromLoadOptions(loadOptions: any) {
    let params: HttpParams = new HttpParams();
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "searchExpr",
      "searchOperation",
      "searchValue"
    ].forEach(function (i) {
      if (i in loadOptions && loadOptions[i] != undefined)
        params = params.set(i, JSON.stringify(loadOptions[i]));
    });
    return params;
  }

  private catchAuthError<T>(err: any, caught: Observable<T>): Observable<any> {
    if (err.status === STATUS_UNAUTHORIZED) {
      return throwError(err);
    } else if (err.status === STATUS_FORBIDDEN) {
      return throwError(err);
    } else {
      return throwError(err);
    }
  }
}
