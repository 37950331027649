import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { ProductPropertyType } from '../../../shared/models/product';

// services
import { WebApiService } from '../../../shared/services/web-api.service';

const CACHE_SIZE = 1;

@Injectable()
export class ProductPropertyTypeService {
  private cache$: Observable<Array<ProductPropertyType>>;

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'propertytype';
  }

  getProductProperties(): Observable<Array<ProductPropertyType>> {
    if (!this.cache$) {
      this.cache$ = this.requestProductProperties().pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cache$;
  }

  private requestProductProperties() {
    return this.webApiService.getList(this.apiPath).pipe(
      map(response => response)
    );
  }

  getFormats(propertyTypeId: number) {
    return this.webApiService.getList(this.apiPath + '/' + propertyTypeId + '/' + 'formats');
  }
}
