import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { CalculationComponent } from './calculation.component';

// models
import { Calculation, CalculationFieldTypes } from '../../shared/models/calculation';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';

import { CalculationsService } from '../shared/services/calculations.service';


@Component({
  selector: 'calculations-component',
  templateUrl: 'calculations.component.html',
  styleUrls: ['./calculations.component.scss']
})
export class CalculationsComponent extends FullListComponent<Calculation, CalculationComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: CalculationComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: CalculationsService,
    private translateService: TranslateService
  ) {
    super(injector, Calculation);
    this.title.set('CALCULATIONS.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('SHARED');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getCalculations(this.id, this.translateService.currentLang),
    ).subscribe(result => {
      this.items = result[0];
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  getCalculationTypeName = (e: any) => {
    const sourceType = e.value;
    switch (sourceType) {
      case CalculationFieldTypes.AUTO_INCREMENT:
        return this.translations.AUTO_INCREMENT;
      case CalculationFieldTypes.FRONTEND:
        return this.translations.FRONTEND;
      case CalculationFieldTypes.EXPRESSION:
        return this.translations.EXPRESSION;
      case CalculationFieldTypes.JSONCALC:
        return this.translations.JSONCALC;
      default:
        return this.translations.AUTO_INCREMENT;
    }
  }

  edit = (calculationId: number) => {
    this.detailsComponent.open(calculationId);
  }

  add() {
    this.detailsComponent.open(null);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete, this.id)
      .subscribe(() => {
        this.getData();
        this.spinner.hide();
      },
        () => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.calculationId;
    this.confirmation.opened = true;
  }

  onRowClick = (e: any) => {
    this.edit(e.data.calculationId);
  }

  editItem = (e: any) => {
    this.edit(e.row.data.calculationId);
  }
}