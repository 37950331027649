<div class="buyer-transaction-monitor-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }"
     dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">

  <div class="card">
    <div class="card-header">
      {{ 'TRANSACTION_MONITOR.FILTER_TRANSACTIONS' | translate }}
    </div>
    <div class="filter-column">
      <label for="catalogs">{{ 'SHARED.CATALOGS' | translate }}</label>
      <dx-select-box name="catalog" [items]="catalogs" valueExpr="catalogId" [(value)]="catalogId" displayExpr="name"
                     [rtlEnabled]="rtlEnabled" [searchEnabled]="false" [showClearButton]="false"
                     placeholder="{{ 'SHARED.SELECT' | translate }} ..." cssClass="filter-lot-select">
      </dx-select-box>
    </div>

    <div class="filter-column">
      <label>{{ 'SHARED.DATE' | translate }}</label>
      <label for="date" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
        <long-date-component class="dt-input" [name]="date" [data]="date" [required]="true"
                             (modelChanged)="dateChanged($event)">
        </long-date-component>
      </label>
    </div>

    <div>

      <div class="filter-column">
        <div class="filter-button">
          <button type="button" class="btn btn-primary"
                  (click)="filterLots()">
            {{ 'TRANSACTION_MONITOR.FILTER_TRANSACTIONS' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>


  <dx-data-grid #transactionMonitorGrid id="printSection" [rtlEnabled]="rtlEnabled" [showBorders]="true"
                (onInitialized)="initColumn($event)"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                [(selectedRowKeys)]="selectedRows"
                (onSelectionChanged)="selectionChangedHandler($event)"
                (onRowPrepared)="editBackground($event)"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true" [showInfo]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="{{ 'storage_buyertransactionmonitor_' + auctionClusterId }}"></dxo-state-storing>
    <!--<dxi-column cellTemplate="cellTemplate"></dxi-column>-->
    <div *dxTemplate="let cell of 'cellTemplate'">
      <span [innerHtml]="cell.text | format | safeHtml"></span>
    </div>
    <div *dxTemplate="let cell of 'textCellTemplate'">
      <span [innerHtml]="cell.text | safeHtml"></span>
    </div>
    <dxi-column type="buttons" [width]="100" [visible]="buttonsVisible()">
      <dxi-button [visible]="isAwaitingConfirmation" hint="{{ 'TRANSACTION_MONITOR.ACCEPTTRANSACTION' | translate }}" icon="check" [onClick]="requestAcceptTransaction"></dxi-button>
      <dxi-button cssClass="rejectButton" [visible]="isAwaitingConfirmation" hint="{{ 'TRANSACTION_MONITOR.REJECTTRANSACTION' | translate }}" icon="close" [onClick]="requestRejectTransaction"></dxi-button>
    </dxi-column>
  </dx-data-grid>
  <confirmation-component #acceptTransactionDialog title="{{ 'Transaction status' | translate }}"
                          message="{{ 'Do you want to accept this transaction?' | translate }}" buttonCancelText="{{ 'SHARED.NO' | translate }}" buttonCloseText="{{ 'SHARED.YES' | translate }}" cancellable="true" (close)="acceptTransaction();"></confirmation-component>
  <confirmation-component #rejectTransactionDialog title="{{ 'Transaction status' | translate }}"
                          message="{{ 'Do you want to reject this transaction?' | translate }}" buttonCancelText="{{ 'SHARED.NO' | translate }}" buttonCloseText="{{ 'SHARED.YES' | translate }}" cancellable="true" (close)="rejectTransaction();"></confirmation-component>
</div>
