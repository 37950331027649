import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CoreContentComponent } from './content/core-content.component';
import { HomeComponent } from './home/home.component';
import { AccountNotFoundComponent } from './account-not-found/account-not-found.component';
import { EmailNotVerifiedComponent } from './email-not-verified/email-not-verified.component';
import { LoginComponent } from './login/login.component';
import { BrowserInfoComponent } from './browser-info/browser-info.component';
import { RegisterComponent } from './register/register.component';
import { RegisterProfileComponent } from './register-profile/register-profile.component';
import { RegisterAssignAccountComponent } from './register-assign-account/register-assign-account.component';
import { RegisterAwaitingApprovalComponent } from './register-awaiting-approval/register-awaiting-approval.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { BrowserGuard } from '../shared/services/browser-guard.service';
import { AdditionalMenuItemsContainerComponent } from '../shared/components/additional-menu-items-container/additional-menu-items-container.component';
import { PublicSupplyForecastURKComponent } from '../shared/components/public-supply-forecast-urk/public-supply-forecast-urk.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportEditorComponent } from './reports/report-editor.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ReportExportComponent } from './reports/report-export.component';

const appRoutes: Routes = [
  {
    path: 'browser-info',
    component: BrowserInfoComponent
  },
  {
    path: 'core',
    component: CoreContentComponent,
    canActivateChild: [BrowserGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'account-not-found', component: AccountNotFoundComponent },
      { path: 'email-not-verified', component: EmailNotVerifiedComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'request-password-reset', component: RequestPasswordResetComponent },
      { path: 'reset-password/:code', component: ResetPasswordComponent },
      { path: 'menu-item/:menuId', component: AdditionalMenuItemsContainerComponent },
      { path: 'supplyforecast', component: PublicSupplyForecastURKComponent },
      { path: 'register-profile', component: RegisterProfileComponent },
      { path: 'register-assign-account', component: RegisterAssignAccountComponent },
      { path: 'register-awaiting-approval', component: RegisterAwaitingApprovalComponent },
      {
        path: 'reports',
        children: [
          {
            path: '',
            component: ReportsComponent
          },
          {
            path: ':auctionClusterId/report/:reportId',
            component: ReportEditorComponent
          },
          { 
            path: ':auctionClusterId/report/:reportId/export', 
            component: ReportExportComponent
          },
        ]
      },
      { path: 'privacy', component: PrivacyComponent },
      { path: 'terms-and-conditions/:id', component: TermsAndConditionsComponent }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CoreRoutingModule { }
