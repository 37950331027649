import { Auction } from './auction';
import { AuctionClusterRole } from './auction-cluster-role';
import { UserAuctionClusterRole } from './user';

export class UserAuctionClusterItem extends UserAuctionClusterRole {

  filteredAuctions: Array<Auction> = [];
  filteredAuctionClusterRoles: Array<AuctionClusterRole> = [];
  auctionFilterData = '-';
  catalogFilterData = '-';
  clockFilterData = '-';
}
