import { GridProperty } from "../../../shared/models/product";

export class ShoppingList {
  shoppingListId: number;
  shoppingListReferenceCatalogId: number;
  productId: number;
  auctionClusterId: number;
  name: string;
  catalogName: string;
  description: string;
  shoppingListProductDefinitionBuyer: Array<GridProperty> = [];
}
