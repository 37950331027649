export enum WidgetType {
  None = 0,
  Clock = 2,
  UpcomingLots = 4,
  SoldLots = 8,
  SupplyMonitor = 16,
  PauseMessageBox = 32,
  CurrentLot = 64,
  MessageBox = 128,
  PriceOverview = 256,
  SalesOverview = 512,
  PriceOverviewFull = 1024,
  BiddingInfo = 2048,
  TransactionRegistration = 4096,
  Images = 8192,
  LotInPreparation = 16384,
  PackageType = 32768,
  TransactionValidation = 65536,
  BuybackInfo = 131072,
  ClockVideo = 262144
}
