<div class="supplier-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addSupplier [width]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <div class="form" *ngIf="!isEditMode">
          <div class="dx-fieldset">
            <div class="dx-field">
              <div class="dx-field-label">{{ 'SHARED.VAT' | translate }}</div>
              <div class="dx-field-value">
                <dx-text-box [(value)]="vatValue" (onValueChanged)="searchByVat($event)"></dx-text-box>
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">{{ 'SHARED.SEARCH' | translate }}</div>
              <div class="dx-field-value">
                <dx-button icon="search" (onClick)="searchByVat($event)"></dx-button>
              </div>
            </div>
          </div>
        </div>

        <div class="dx-fieldset" *ngIf="!isEditMode">
          <div class="dx-field">
            <div class="dx-field-value">
              <dx-select-box [items]="filteredSuppliers" displayExpr="name" valueExpr="supplierId"
                             [(value)]="model.supplierId" [rtlEnabled]="rtlEnabled" (onOptionChanged)="suppliersChange($event)">
              </dx-select-box>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="isEditMode">
        <label class="supplier-name" for="supplierName">{{'SHARED.SUPPLIER_NAME' | translate}}:&nbsp;{{model.name}}</label>
        </div>
        </div>
        <dx-form #supplierForm id="supplierForm" validationGroup="supplierData" [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

          <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'SHARED.GENERAL' | translate }}">

              <dxi-item itemType="group">
                <dxi-item dataField="vat" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.VAT' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.VAT' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="name" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="address1" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.ADDRESS1' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.ADDRESS1' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="address2" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.ADDRESS2' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.ADDRESS2' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="zip" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.ZIP' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.ZIP' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="city" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.CITY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.CITY' | translate }}  {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="country" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.COUNTRY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.COUNTRY' | translate }}  {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="telNumber" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.TEL_NUMBER' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.TEL_NUMBER' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="faxNumber" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.FAX_NUMBER' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="email" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.EMAIL' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.EMAIL' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                <dxi-validation-rule type="email" message="{{ 'SHARED.EMAIL' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="url" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.URL' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="isActiveOnAuctionCluster" editorType="dxCheckBox"
                          [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SHARED.IS_ACTIVE' | translate }}" location="right"></dxo-label>
                </dxi-item>

                <dxi-item name="show-administrator" [visible]="!isEditMode">
                  <dxo-label text="{{ 'AUCTIONS_CLUSTERS.SUPPLIER_USER_LABEL' | translate }}"></dxo-label>
                  <div *dxTemplate>
                    <dx-check-box [(value)]="administrator.visible">
                    </dx-check-box>
                  </div>
                </dxi-item>

                <dxi-item #administrator name="administrator"
                          dataField="administratorId"
                          [visible]="!isEditMode"
                          editorType="dxSelectBox"
                          [editorOptions]="{ items: users, valueExpr:'userId', displayExpr:'lastname'}"
                          [rtlEnabled]="rtlEnabled">
                </dxi-item>

              </dxi-item>
            </dxi-tab>
          <dxi-tab title="{{ 'SHARED.AUCTION_CLUSTER' | translate }}">
              <dxi-item itemType="group">
                <dxi-item dataField="supplierNumber">
                  <dxo-label text="{{ 'SUPPLIER.NUMBER' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIER.NUMBER' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="sendSmsTransactionInfo" editorType="dxCheckBox">
                <dxo-label text="{{ 'SHARED.SEND_SMS_TRANSACTION_INFO' | translate }}" location="right"></dxo-label>
                </dxi-item>
              </dxi-item>
            </dxi-tab>
          </dxi-item>
        </dx-form>
        <div class="au-dx-popup-padder">
         <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnSupplierSubmit"
        disabled="disabled">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
   
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
