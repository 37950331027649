export class Refresh {

  refreshToken: string;
  token: string;
  userId: number;

  constructor(userId: number,refreshToken: string, accessToken: string) {
    this.userId = userId;
    this.refreshToken = refreshToken;
    this.token = accessToken;
  }
}
