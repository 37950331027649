<div class="edit-user-notification-channels-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
    <dx-popup
    #editUserNotificationChannels
    [width]="600"
    [showTitle]="true"
    title="{{ 'NOTIFICATION.CHANNELS' | translate}}"
    [dragEnabled]="false"
    [closeOnOutsideClick]="false"
    [showCloseButton]="false"
    [rtlEnabled]="rtlEnabled"
    [(visible)]="isOpened">
      <dx-scroll-view width="100%" height="100%">
        <div class="au-dx-popup-padder">
          <dx-form #editUserNotificationChannelsForm
                   id="editUserNotificationChannelsForm"
                   [showValidationSummary]="false"
                   (onFieldDataChanged)="onFieldDataChanged($event)"
                   colCount="auto"
                   [(formData)]="model"
                   [minColWidth]="500">

            <dxi-item itemType="group" caption="{{notificationName}}">
              <dxi-item *ngIf="model.channelEmail" dataField="channelEmail.isActive" editorType="dxCheckBox">
                <dxo-label text="{{ 'NOTIFICATION.CHANNEL_TYPE_EMAIL' | translate }}" location="right"></dxo-label>
              </dxi-item>
              <dxi-item *ngIf="model.channelSMS" dataField="channelSMS.isActive" editorType="dxCheckBox">
                <dxo-label text="{{ 'NOTIFICATION.CHANNEL_TYPE_SMS' | translate }}" location="right"></dxo-label>
              </dxi-item>
              <dxi-item *ngIf="model.channelPush" dataField="channelPush.isActive" editorType="dxCheckBox">
                <dxo-label text="{{ 'NOTIFICATION.CHANNEL_TYPE_PUSH' | translate }}" location="right"></dxo-label>
              </dxi-item>
            </dxi-item>

          </dx-form>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnEditUserNotificationChannelsSubmit">{{ 'SHARED.SAVE' | translate }}</button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
          </div>
      </dx-scroll-view>
    </dx-popup>
  </div>
