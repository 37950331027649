import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DxReportViewerModule } from 'devexpress-reporting-angular';

import { CoreRoutingModule } from './core-routes.module';
import { SharedModule } from '../shared/shared.module';

// module components
import { LoginComponent } from './login/login.component';
import { TrackCapsDirective } from './login/login.component';
import { BrowserInfoComponent } from './browser-info/browser-info.component';
import { RegisterComponent } from './register/register.component';
import { RegisterProfileComponent } from './register-profile/register-profile.component';
import { RegisterAssignAccountComponent } from './register-assign-account/register-assign-account.component';
import { RegisterAwaitingApprovalComponent } from './register-awaiting-approval/register-awaiting-approval.component';
import { CoreContentComponent } from './content/core-content.component';
import { HomeComponent } from './home/home.component';
import { AccountNotFoundComponent } from './account-not-found/account-not-found.component';
import { EmailNotVerifiedComponent } from './email-not-verified/email-not-verified.component';
import { LoginHeaderComponent } from './header/login-header.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportEditorComponent } from './reports/report-editor.component';
import { ReportComponent } from './reports/report.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AccepTermsAndConditionsComponent } from './terms-and-conditions/accept-terms-and-conditions.component';

// module services
import { UserService } from '../shared/services/user.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    CoreRoutingModule,
    HttpClientModule,
    TranslateModule,
    SharedModule,
    RouterModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DxReportViewerModule
  ],
  declarations: [
    LoginComponent,
    BrowserInfoComponent,
    RegisterComponent,
    RegisterProfileComponent,
    RegisterAssignAccountComponent,
    RegisterAwaitingApprovalComponent,
    CoreContentComponent,
    HomeComponent,
    AccountNotFoundComponent,
    EmailNotVerifiedComponent,
    LoginHeaderComponent,
    RequestPasswordResetComponent,
    ResetPasswordComponent,
    ReportsComponent,
    ReportEditorComponent,
    ReportComponent,
    PrivacyComponent,
    TermsAndConditionsComponent,
    AccepTermsAndConditionsComponent,
    TrackCapsDirective
  ],
  providers: [
    UserService
  ],
  bootstrap: []
})
export class CoreModule { }
