<div class="item-list-component">

  <div class="grid-buttons">
    <clr-tooltip>
      <search-box clrTooltipTrigger (update)="searchText = $event"></search-box>
      <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
        <span>{{ 'SHARED.SEARCH' | translate }}</span>
      </clr-tooltip-content>
    </clr-tooltip>
    <clr-tooltip>
      <clr-icon shape="printer" clrTooltipTrigger size="24" (click)="print();"></clr-icon>
      <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
        <span>{{ 'SHARED.PRINT' | translate }}</span>
      </clr-tooltip-content>
    </clr-tooltip>
  </div>

  <clr-datagrid id="printSection">
    <clr-dg-column [clrDgField]="'productName'">{{ 'SHARED.PRODUCT' | translate }}</clr-dg-column>
    <clr-dg-column [clrDgField]="'reportDesign.name'">{{ 'SHARED.NAME' | translate }}</clr-dg-column>
    <clr-dg-column [clrDgField]="'description'">{{ 'SHARED.DESCRIPTION' | translate }}</clr-dg-column>
    <clr-dg-column [clrDgField]="'filterLabel'">{{ 'SHARED.FILTER' | translate }}</clr-dg-column>
    <clr-dg-column class="no-print three-buttons-column">&nbsp;</clr-dg-column>

    <clr-dg-placeholder>{{'SHARED.NO_DATA' | translate }}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let item of items | search: searchText" (click)="openReport(item.reportId, item.reportDesign.auctionClusterId, $event)">
      <clr-dg-cell>{{ item.productName | translateJson }}</clr-dg-cell>
      <clr-dg-cell>{{ item.reportDesign.name | translateJson}}</clr-dg-cell>
      <clr-dg-cell>{{ item.description | translateJson}}</clr-dg-cell>
      <clr-dg-cell>{{ item.filterLabel }}</clr-dg-cell>
      <clr-dg-cell class="no-print three-buttons-cell">
        <clr-tooltip role="tooltip" aria-haspopup="true" *ngIf="!item.systemDefault">
          <clr-icon clrTooltipTrigger shape="trash" class="delete-btn" size="16" (click)="delete(item.reportId, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
            <span>{{ 'SHARED.DELETE' | translate }}</span>
          </clr-tooltip-content>
        </clr-tooltip>
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <pagination-component class="footer-pagination" tootltip-content="{{ 'SHARED.SET_PAGE_SIZE' | translate }}" [pageSizeValue]="pageSizeValue" (changed)="setPageSize($event)"></pagination-component>
      <span class="pagination-fixed footer-pagination">
        <span *ngIf="items?.length > 0">{{ 'REPORTS.PAGINATION_TEXT' | translate:{firstItem: pagination.firstItem + 1, lastItem: pagination.lastItem + 1, totalItems: pagination.totalItems} }}</span>
        <span *ngIf="items?.length == 0">{{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate:{totalItems: pagination.totalItems} }}</span>
        <clr-dg-pagination #pagination [clrDgPageSize]="pageSize"></clr-dg-pagination>
      </span>
    </clr-dg-footer>
  </clr-datagrid>
</div>

<confirmation-component #confirmation title="{{ 'REPORTS.DELETE_REPORT' | translate }}" message="{{ 'REPORTS.DELETE_REPORT_MESSAGE' | translate }}" buttonCloseText="{{ 'SHARED.DELETE' | translate }}" cancellable="true" (close)="deleteSelected();"></confirmation-component>

