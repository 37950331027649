import { Component, Injector, ViewChild, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

@Component({
  selector: 'pause-message-component',
  templateUrl: 'pause-message.component.html',
  styleUrls: ['./pause-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PauseMessageComponent extends ItemDetailsComponent<string> implements OnInit {

  index: number;
  @ViewChild('pauseMessageInput', { read: ElementRef }) pauseMessageInput: any;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = '';
  }

  open(messages: Array<string>, index: number) {

    this.allItems = messages;
    this.index = index;

    if (index !== -1) {
      this.model = messages[index];
      this.isEditMode = true;
      this.isOpened = true;
    } else {
      this.model = '';
      this.isEditMode = false;
      this.isOpened = true;
    }
    setTimeout(() => { this.pauseMessageInput.nativeElement.focus(); });
  }

  save() {
    if (this.isEditMode) {
      this.allItems[this.index] = this.model;
    } else {
      this.allItems.push(this.model);
    }

    this.detailsForm.reset();
    this.close(true);
  }

  public onCancel() {
    this.close(false);
    this.model = '';
    this.detailsForm.reset();
    this.isOpened = false;
    this.errorMessage = null;
  }
}
