<div class="buyer-clocks-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="item-list-component">
    <dx-data-grid #supplierUsersGrid
                  id="printSection"
                  [rtlEnabled]="rtlEnabled"
                  [dataSource]="products"
                  [showBorders]="true"
                  (onRowClick)="startClock($event)"
                  [allowColumnReordering]="true"
                  [hoverStateEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true">
      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-pager [visible]="true"
                 [showPageSizeSelector]="true"
                 [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showNavigationButtons]="true"
                 [showInfo]="true"
                 infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" [customizeText]="translateField" dataField="name" caption="{{ 'BUYER.PRODUCT_NAME' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" [customizeText]="translateField" dataField="description" caption="{{ 'BUYER.PRODUCT_DESCRIPTION' | translate }}">
      </dxi-column>
      <dxi-column type="buttons">
        <dxi-button hint="{{ 'BUYER.START_CLOCK_AS_VIEWER' | translate }}" icon="fa fa-eye" [onClick]="startClock"></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </div>
</div>
