<div class="mask-field-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup 
    #editMaskField 
    [width]="600" 
    height="450px"
    [showTitle]="true" 
    title="{{ modalTitle }}" 
    [dragEnabled]="false"
    [closeOnOutsideClick]="false" 
    [rtlEnabled]="rtlEnabled" 
    [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #maskFieldForm
                 id="maskFieldForm"
                 [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">
         <dxi-item *ngIf="maskType === 2"
                    dataField="masterDataListFieldId"
                    editorType="dxSelectBox"
                    [editorOptions]="{ items: masterDataListFields, valueExpr:'masterDataListFieldId', displayExpr:translateFields }">
            <dxo-label text="{{ 'SHARED.MASTER_DATA_FIELD' | translate }}"></dxo-label>
          </dxi-item>
         <dxi-item *ngIf="maskType === 0 || maskType === 1"
                    dataField="productPropertyId"
                    editorType="dxSelectBox"
                    [editorOptions]="{ items: productProperties, valueExpr:'productPropertyId', displayExpr:translateFields }">
            <dxo-label text="{{ 'SHARED.PRODUCT_PROPERTY' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="isResult" editorType="dxCheckBox">
            <dxo-label text="{{ 'MASK.IS_RESULT' | translate }}" location="right"></dxo-label>
          </dxi-item>
        </dx-form>
        <br />
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnMaskFieldSubmit"
        disabled="disabled">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
      
        </div>
    </dx-scroll-view>
    </dx-popup>
</div>
