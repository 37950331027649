import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { CommonModule, LocationStrategy, Location, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// third-party
import bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { environment } from '../environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { OwlDateTimeModule, OwlNativeDateTimeModule, DateTimeAdapter, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule, MomentDateTimeAdapter, OWL_MOMENT_DATE_TIME_FORMATS } from 'ng-pick-datetime-moment';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

export const bugsnagClient = (environment.bugsnagApiKey)
  ? bugsnag({
    apiKey: environment.bugsnagApiKey,
    releaseStage: environment.bugsnagReleaseStage,
    appVersion: environment.version,
  })
  : null;

export function errorHandlerFactory() {
  if (bugsnagClient) {
    return new BugsnagErrorHandler(bugsnagClient);
  }

  return new ErrorHandler();
}

// modules
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { PlatformModule } from './platform/platform.module';
import { AuctionModule } from './auction/auction.module';
import { BuyerModule } from './buyer/buyer.module';
import { SupplierModule } from './supplier/supplier.module';
import { AppRoutingModule } from './app-routes.module';

// components
import { AppComponent } from './app.component';
import { AccessTokenComponent } from './access-token.component';
import { CookieConsentComponent } from './shared/components/cookie-consent/cookie-consent.component';

// models
import { ApplicationSettings } from './shared/models/application-settings';

// services
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/services/auth-guard.service';
import { BrowserGuard } from './shared/services/browser-guard.service';
import { Auth0Guard } from './shared/services/auth0-guard.service';
import { ConfigService } from './shared/services/config.service';
import { LanguageService } from './shared/services/language.service';
import { AuthInterceptor } from './shared/services/auth-interceptor.service';
import { WebApiService } from './shared/services/web-api.service';
import { TokenService } from './shared/services/token.service';
import { DynamicRoutingService } from './shared/services/dynamic-routing.service';
import { AlertService } from './shared/services/alert.service';
import { AdditionalMenuItemsService } from './shared/services/additional-menu-items.service';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${environment.version}`);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    PlatformModule,
    AuctionModule,
    BuyerModule,
    SupplierModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    OwlMomentDateTimeModule,
    PdfJsViewerModule
  ],
  declarations: [
    AppComponent,
    AccessTokenComponent,
    CookieConsentComponent
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    },
    WebApiService,
    AlertService,
    TokenService,
    BrowserGuard,
    AuthGuard,
    Auth0Guard,
    LanguageService,
    Location,
    {
        provide: ConfigService,
        useClass: ConfigService,
    },
    {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (config: ConfigService) => () => {return config.load();},
        deps: [ConfigService],
        multi: true
    },
    {
      provide: ApplicationSettings,
      useFactory: (configService: ConfigService) => {
          return configService.config;
      },
      deps: [ConfigService]
  },
    CookieService,
    AuthService,
    
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (tokenService: TokenService, cookieService: CookieService, authService: AuthService) => {
        
        return new AuthInterceptor(tokenService, cookieService, authService);
      },
      deps: [TokenService, CookieService, AuthService],
      multi: true
    },
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE]
    },
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: OWL_MOMENT_DATE_TIME_FORMATS
    },
    AdditionalMenuItemsService,
    DynamicRoutingService,
  ],
  bootstrap: [AppComponent]
})


export class AppModule {

  constructor(dynamicRouting: DynamicRoutingService) {
    dynamicRouting.updateDefaultRoutesWithoutRerouting();
  }
} 
