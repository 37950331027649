import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../../shared/components/full-list/full-list.component';
import { ProductFunctionkeysActionComponent } from './product-functionkeys-action.component';

// models
import { ProductFunctionKeysAction, ProductFunctionKeysActionEnum } from '../../../shared/models/product';

// services
import { ProductService } from '../../shared/services/product.service';

@Component({
  selector: 'product-functionkeys-actions-component',
  templateUrl: './product-functionkeys-actions.component.html'
})
export class ProductFunctionkeysActionsComponent extends FullListComponent<ProductFunctionKeysAction, ProductFunctionkeysActionComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ProductFunctionkeysActionComponent;

  auctionClusterId: number;
  productId: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: ProductService,
  ) {
    super(injector, ProductFunctionKeysAction);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('PRODUCT.FUNCTIONKEYS_ACTION');
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.productId = +this.route.snapshot.params['productId'];

    this.dataService.getProductFunctionKeysActions(this.auctionClusterId, this.productId)
      .subscribe((res: Array<ProductFunctionKeysAction>) => {
        this.items = res;
      },
        error => {
          this.errorService.show(error);
        });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null);
  }

  edit = (e: any) => {
    const id = e.row !== undefined ? e.row.data.id : e.data.id;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, id);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.id;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.items = this.items.filter((_) => _.productFunctionKeysActionId !== this.itemIdToDelete);
    this.save();
  }

  onDetailsClosed(action: ProductFunctionKeysAction) {    
    const existingIndex = this.items.findIndex(item => item.productFunctionKeysActionId === action.productFunctionKeysActionId);
    if (existingIndex !== -1) {
      this.items[existingIndex] = action;
    } else {
      this.items.push(action);
    }

    this.save();
  }

  save() {
    this.dataService.saveProductFunctionKeysActions(this.auctionClusterId, this.productId, this.items)
      .subscribe((res: Array<ProductFunctionKeysAction>) => {
        this.items = res;
      },
        error => {
          this.errorService.show(error);
        });
  }

  getActionName = (e: any) => {
    const sourceType = e.value;
    switch (sourceType) {
      case ProductFunctionKeysActionEnum.JUMP_TO:
        return this.translations.JUMP_TO;
      case ProductFunctionKeysActionEnum.SET_COINCODE:
        return this.translations.SET_COINCODE;
      default:
        return this.translations.JUMP_TO;
    }
  }
}
