import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { ProductPropertyGroupComponent } from './product-property-group.component';

// models
import { Language } from '../../shared/models/language';
import { ProductPropertyGroup } from '../../shared/models/product';

// services
import { LanguageService } from '../../shared/services/language.service';


@Component({
  selector: 'product-property-groups-component',
  templateUrl: 'product-property-groups.component.html'
})
export class ProductPropertyGroupsComponent extends SimpleListComponent implements OnInit {

  editingIndex: number;
  pageSize = 50;
  items: Array<ProductPropertyGroup> = [];
  languages: Array<Language> = [];

  @Input('data')
  set data(value: Array<ProductPropertyGroup>) {
    this.items = value;
    this.items.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
    this.translatePropertyGroupNames();
  }

  @Input('languages')
  set language(value: Array<Language>) {
    this.languages = value;
  }

  @Output() dataChanged = new EventEmitter<Array<ProductPropertyGroup>>();

  @ViewChild(ProductPropertyGroupComponent) detailsComponent: ProductPropertyGroupComponent;

  constructor(
    protected injector: Injector,
    protected languageService: LanguageService
  ) {
    super(injector);
  }

  ngOnInit() { // tslint:disable-line:no-empty
    this.setTranslations('PRODUCT');
  }

  edit(property: ProductPropertyGroup, event: Event) {
    this.editingIndex = this.items.indexOf(property);
    event.stopPropagation();
    this.detailsComponent.modalTitle = this.translations.EDIT_GROUP;
    const clone = { ...property };
    this.detailsComponent.open(this.items, clone, this.languages);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW_GROUP;
    this.detailsComponent.open(this.items, null, this.languages);
  }

  onProductPropertyAdded(property: ProductPropertyGroup) {
    this.items.push(property);
    this.translatePropertyGroupNames();
    this.dataChanged.emit(this.items);
  }

  onProductPropertyUpdated(property: ProductPropertyGroup) {
    this.items[this.editingIndex] = property;
    this.translatePropertyGroupNames();
    this.dataChanged.emit(this.items);
  }

  moveUp(event: Event) {
    this.moveUpItems(event, this.items);
  }

  moveDown(event: Event) {
    this.moveDownItems(event, this.items);
  }

  delete(propertyGroup: ProductPropertyGroup) {
    this.items.splice(this.items.indexOf(propertyGroup), 1);
    this.dataChanged.emit();
  }

  private translatePropertyGroupNames() {
    this.items.forEach(propertyGroup => {
      propertyGroup.propertyGroupName = this.languageService.getTranslatableText(propertyGroup.name);
    });
  }
}
