import { Component, Injector, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { DxFormComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { AuctionClusterRole } from '../../shared/models/auction-cluster-role';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { Language } from '../../shared/models/language';

import { AuctionClusterPermission } from '../shared/models/auction-cluster-permission';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterLevelRoleService } from '../shared/services/auction-cluster-level-role.service';


@Component({
  selector: 'auction-cluster-role-component',
  templateUrl: 'auction-cluster-role.component.html',
  styleUrls: ['./auction-cluster-role.component.scss']
})
export class AuctionClusterRoleComponent extends ItemDetailsComponent<AuctionClusterRole> implements OnInit, OnDestroy {

  permissions: Array<AuctionClusterPermission> = [];
  permissionsCluster: Array<AuctionClusterPermission> = [];
  languages: Array<Language> = [];
  requiredPermissions: Array<number> = [];
  requiredPermissionsCluster: Array<number> = [];
  auctionClusterId: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  validFields: boolean = false;

  @ViewChild('roleName') roleName;
  @ViewChild('roleDescription') roleDescription;
  @ViewChild('roleForm', { read: DxFormComponent }) roleForm: DxFormComponent;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterLevelRoleService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AuctionClusterRole();
    this.setTranslations('ROLES');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(roles: Array<AuctionClusterRole>, roleId: number, permissions: Array<AuctionClusterPermission>, auctionClusterId: number) {
    this.modalTitle = roleId ? this.translations.EDIT : this.translations.ADD_NEW;
    this.allItems = roles;
    this.permissions = permissions.filter(_ => !this.isAuctionClusterPermission(_.auctionPermissionId));
    this.permissionsCluster = permissions.filter(_ => this.isAuctionClusterPermission(_.auctionPermissionId));
    this.requiredPermissions = [];
    this.requiredPermissionsCluster = [];
    this.auctionClusterId = auctionClusterId;

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    forkJoin(
      this.languageService.getAuctionClusterLanguages(this.auctionClusterId)
    ).subscribe(result => {
      this.languages = result[0];

      if (roleId != null) {
        this.isEditMode = true;
        this.spinner.show();
        this.dataService.getAuctionClusterRole(this.auctionClusterId, roleId)
          .subscribe((res: AuctionClusterRole) => {
            this.model = res;
            this.model.name = this.languageService.updateStringToAllLanguages(this.languages, this.model.name);
            this.model.description = this.languageService.updateStringToAllLanguages(this.languages, this.model.description);
            this.requiredPermissions = this.model.permissions.filter(_ => !this.isAuctionClusterPermission(_));
            this.requiredPermissionsCluster = this.model.permissions.filter(_ => this.isAuctionClusterPermission(_));
            this.isOpened = true;

            this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);

            this.spinner.hide();
          },
            error => {
              this.errorService.show(error);
              this.spinner.hide();
            });
      } else {
        this.model = new AuctionClusterRole();
        this.model.name = this.model.description = this.languageService.updateStringToAllLanguages(this.languages, "{}");
        this.isEditMode = false;
        this.isOpened = true;
      }
    });
  }

  save() {
    this.model.name = this.roleName.data;
    this.model.permissions = this.requiredPermissions.concat(this.requiredPermissionsCluster);
    this.model.description = this.roleDescription.data;

    if (this.isEditMode) {
      this.spinner.show();

      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterRole();
          this.close(true);
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();

      this.model.auctionClusterId = this.auctionClusterId;
      this.model.isAuctionClusterTypeRole = true;

      this.dataService.save(this.model.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterRole();
          this.close(true);
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  isAuctionClusterPermission(permissionId: number) {
    return permissionId === AuctionClusterPermissionEnum.UserManagement
      || permissionId === AuctionClusterPermissionEnum.Administrator.valueOf()
      || permissionId === AuctionClusterPermissionEnum.LocationManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.MasterdataManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.LookuptableManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.BuyerConfiguration.valueOf()
      || permissionId === AuctionClusterPermissionEnum.SupplierConfiguration.valueOf()
      || permissionId === AuctionClusterPermissionEnum.RoleManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.ZoneManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.ExternalManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.TermsOfUse.valueOf()
      || permissionId === AuctionClusterPermissionEnum.Notifications.valueOf()
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.roleName.data);
      this.roleForm.instance.updateData(fieldName, this.roleName.data);
    } else if (fieldName === 'description') {
      this.roleForm.instance.updateData(fieldName, this.roleDescription.data);
    }
  }
}
