export class AuctionClusterRole {
  auctionClusterRoleId: number;
  auctionClusterId: number;
  name: string;
  description: string;
  permissions: Array<number> = [];
  isAuctionClusterTypeRole: true;

  // displayable fields
  permissionString: string;
}
