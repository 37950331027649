import { Component, OnInit, AfterViewInit, ViewChild, Injectable } from '@angular/core';
import { Directive } from '@angular/core';
import { Output, HostListener, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

// external libs
import QrCreator from 'qr-creator';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { Cookies } from '../../shared/constants/cookies';
import { LoginInfo } from '../../shared/models/login-info';

// services
import { AuthService } from '../../shared/services/auth.service';
import { ConfigService } from '../../shared/services/config.service';
import { DynamicRoutingService } from '../../shared/services/dynamic-routing.service';
import { LanguagePreferenceService } from '../../shared/services/language-preference.service';
import { TitleService } from '../../shared/services/title.service';

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, AfterViewInit {

  message: string;
  model: LoginInfo;
  isLoggingIn = false;
  mfaSecret = '';
  public showPassword: boolean;
  capsOn;

  @ViewChild('email') email: any;
  @ViewChild('mfaCode') mfaCode: any;

  constructor(
    private router: Router,
    private appSettings: ApplicationSettings,
    private authenticationService: AuthService,
    private translate: TranslateService,
    private titleService: TitleService,
    private dynamicRouting: DynamicRoutingService,
    private languagePreferenceService: LanguagePreferenceService,
    public configService: ConfigService,
    private cookieService: CookieService
  ) {
    this.dynamicRouting.doLogout.subscribe(ev => this.authenticationService.logout());
  }

  ngOnInit() {
    this.titleService.set('LOGIN.TITLE');
    this.model = new LoginInfo(this.appSettings.applicationCode);

    if (this.authenticationService.useAuth0) {
      this.authenticationService.auth0Login();
    }    
  }

  ngAfterViewInit() {
    this.email.nativeElement.focus();
    document.getElementById('mfaSetup').style.display = 'none';
  }

  onSubmit() {
    this.message = null;
    this.isLoggingIn = true;
    this.login();
  }

  getMfaSecret(): any | undefined {    
    return { mfaSecret: this.mfaSecret};
  }

  login() {
    this.model.versionAdminUI = environment.version;
    this.loginService();
  }
  
  loginService() {
    let cookieMFA = this.cookieService.get(Cookies.MFA_AUTHENTICATION);
    this.model.lastMFALoginDateTime = cookieMFA ? moment(new Date(cookieMFA.split('Email: ')[1].split('MFALoginTime: ')[1])).local().format('YYYY-MM-DD HH:mm:ss') : null;
    this.authenticationService.login(this.model)
      .subscribe(res => {
        if (res.result === true) {
          // login successful
          this.languagePreferenceService.reload();
          this.dynamicRouting.updateDefaultRoutes().then(result => {
            if (result) {
              if(this.authenticationService.redirectUrl == undefined) {
                this.authenticationService.redirectUrl = '/';
              }
              this.router.navigate([this.authenticationService.redirectUrl], { queryParamsHandling: 'merge' });
              this.authenticationService.redirectUrl = '/';
            }
          });
        } else if (res.isMFASetupNeeded) {
          this.model.isMfaSetupNeeded = true;

          document.getElementById('mfaSetup').style.display = 'inherit';
          document.querySelector('#qrCode').innerHTML = '';

          this.mfaSecret = res.mFATotpAuthLink.split('secret=')[1].split('&issuer=')[0];

          QrCreator.render({
            text: res.mFATotpAuthLink,
            radius: 0.5,
            ecLevel: 'H',
            fill: '#00AAA7',
            background: null,
            size: 128
          }, document.querySelector('#qrCode'));

          if (!res.isMFACodeOk) {
            notify('MFA code is not valid', 'error', 3000);
          }

        } else if (res.isMFACodeNeeded) {
          this.model.isMfaCodeNeeded = true;
          if (!res.isMFACodeOk) {
            notify('MFA code is not valid', 'error', 3000);
          }
        } else {
          // login failed
          this.translate.get('LOG_IN.LOGIN_FAILED').subscribe((res: string) => {
            this.message = res;
          });
        }

        this.isLoggingIn = false;

        if (this.model.mfaCode && res.result) {
          let lastMFALoginDateTime = moment(new Date()).local().format('YYYY-MM-DD HH:mm:ss');
          this.cookieService.set(Cookies.MFA_AUTHENTICATION, `Email: ${this.model.email}, MFALoginTime: ${lastMFALoginDateTime}`, new Date(2030, 1, 1));
        }
      },
        error => {
          this.translate.get(`LOG_IN.${error.error}`).subscribe((res: string) => {
            if (res.indexOf('_') === -1) {
              this.message = res;
            } else {
              this.translate.get('LOG_IN.LOGIN_FAILED').subscribe((def: string) => {
                this.message = def;
              });
            }
          });

          this.isLoggingIn = false;
        });
  };
}

@Directive({ selector: '[capsLock]' })
export class TrackCapsDirective {
  @Output('capsLock') capsLock = new EventEmitter<Boolean>();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
}
