import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ReportComponent } from './report.component';

// models
import { Report } from '../../shared/models/report';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';

import { SupplierReportService } from '../shared/services/report.service';


@Component({
  selector: 'reports-component',
  templateUrl: 'reports.component.html',
  styleUrls: ['reports.component.scss'],
})
export class ReportsComponent extends FullListComponent<Report, ReportComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;

  supplierId: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: SupplierReportService,
    private translateService: TranslateService
  ) {
    super(injector, Report);
    this.supplierId = +this.route.snapshot.params['id'];
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('REPORTS.TITLE');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getReports(this.supplierId, this.translateService.currentLang)
    ).subscribe(result => {
      this.items = result[0];
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  openReport = (e: any) => {
    const reportId = e.row !== undefined ? e.row.data.reportId : e.data.reportId;
    const auctionClusterId = e.row !== undefined ? e.row.data.reportDesign.auctionClusterId : e.data.reportDesign.auctionClusterId;

    this.router.navigate(['/supplier/reports/' + this.id + '/ac/' + auctionClusterId + '/report/' + reportId]);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.supplierId, this.itemIdToDelete)
      .subscribe(() => {
        this.getData();
        this.spinner.hide();
      },
        () => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
      });
  }
  
  deleteButtonVisible = (e: any) => {
    return !e.row.data.systemDefault;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.reportId;
    this.confirmation.opened = true;
  }

  add() { }

  edit(_itemId: number, _event: Event) { }
}
