import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// interfaces
import { IProductService } from '../../../shared/interfaces/product';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { GridProperty, Product, ProductProperty } from '../../../shared/models/product';

import { BuyerProduct } from '../models/buyer-product';

// services
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class ProductService implements IProductService {

  private apiPath: string;
  private buyerId: number;

  setId(buyerId: number)
  {
    this.buyerId = buyerId;
  }

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  getProductsClocks(langCode: string): Observable<Array<BuyerProduct>> {
    return this.webApiService.getList(`${this.apiPath}/${this.buyerId}/product/clock?langCode=${langCode}`);
  }

  getPrebidOnProductProperties(auctionClusterId: number, productId: number): Observable<Array<GridProperty>> {
    return this.webApiService.getSingle(`${this.apiPath}/${this.buyerId}/auctioncluster/${auctionClusterId}/product/${productId}/prebidonproductproperties`);
  }

  //IProductService

  getProductForReports(auctionClusterId: number, productId: number)
  {
    return this.webApiService.getSingle(`${this.apiPath}/${this.buyerId}/auctioncluster/${auctionClusterId}/product/${productId}`);
  }

  getProductProperties(productId: number): Observable<Array<ProductProperty>> {
    return this.webApiService.getSingle(`${this.appSettings.adminApi}product/${productId}/productproperties`);
  }

  getReportProperties(productId: number, reportDesignId: number): Observable<Array<GridProperty>> {
    return this.webApiService.getSingle(`${this.appSettings.adminApi}product/${productId}/reportproperties/${reportDesignId}`);
  }
  
  getProduct(auctionClusterId: number, productId: number): Observable<Product> {
    return;
  }
}
