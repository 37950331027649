export class PlatformRole {

  platformRoleId: number;
  name: string;
  description: string;
  permissions: Array<number>;

  // displayable fields
  permissionString: string;
}
