import { Component, Output, OnInit, Injector, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { Language } from '../../shared/models/language';

import { AuctionClusterBuyer, SubBuyer } from '../shared/models/auction-cluster-buyer';

// services
import { LanguageService } from '../../shared/services/language.service';


@Component({
  selector: 'sub-buyer-component',
  templateUrl: 'sub-buyer.component.html',
  styleUrls: ['./sub-buyer.component.scss']
})
export class SubBuyerComponent extends ItemDetailsComponent<SubBuyer> implements OnInit {

  languages: Array<Language> = [];
  buyers: Array<AuctionClusterBuyer> = [];
  orderNumberNotUnique: boolean;
  orderNumberOutOfScope: boolean;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<SubBuyer>();
  @Output() onUpdated = new EventEmitter<SubBuyer>();
  // tslint:enable:no-output-on-prefix

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    protected route: ActivatedRoute
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = new SubBuyer();
  }

  open(subbuyers: Array<SubBuyer>, buyers: Array<AuctionClusterBuyer>, buyer: SubBuyer, languages: Array<Language>) {

    this.allItems = subbuyers;
    this.buyers = buyers.sort((a, b) => a.name.localeCompare(b.name));
    this.languages = languages;

    if (buyer.buyerId !== null && buyer.buyerId !== undefined) {
      this.isEditMode = true;
      this.model = buyer;
      this.isOpened = true;
    } else {
      this.model = buyer;
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new SubBuyer();
    this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  checkOrderNumber(inputElem: NgForm) {
    const buyer = this.allItems.find(b => b.orderNumber === this.model.orderNumber && b.buyerId !== this.model.buyerId);
    if (buyer) {
      this.orderNumberNotUnique = true;
      inputElem.control.setErrors({ 'invalid': true });
    } else {
      this.orderNumberNotUnique = false;
    }

    // tslint:disable-next-line:no-magic-numbers
    if (this.model.orderNumber < 1 || this.model.orderNumber > 10) {
      this.orderNumberOutOfScope = true;
      inputElem.control.setErrors({ 'invalid': true });
    } else {
      this.orderNumberOutOfScope = false;
    }
  }
}
