import { forkJoin, Subscription } from 'rxjs';
import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

// components
import { AuctionClusterChannelComponent } from './auction-cluster-channel/auction-cluster-channel.component';
import { AuctionClusterNotificationComponent } from './auction-cluster-notification/auction-cluster-notification.component';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { AuctionCluster } from '../../shared/models/auction-cluster';
import { AuctionClusterChannel } from '../../shared/models/auction-cluster-channel';
import { AuctionClusterNotification } from '../../shared/models/auction-cluster-notification';
import { Buyer } from '../../shared/models/buyer';
import { Currency } from '../../shared/models/currency';
import { Language, AuctionClusterLanguage } from '../../shared/models/language';
import { MFAType } from '../../shared/models/mfaType';
import { NotificationTypeEnum } from '../../shared/enum/notificationTypeEnum';
import { NotificationChannelType } from '../../shared/enum/notificationChannelTypeEnum';
import { TimeZoneInfo } from '../../shared/models/timeZoneInfo';
import { User } from '../../shared/models/user';

// services
import { BuyerService } from '../../shared/services/buyer.service';
import { CurrencyService } from '../../shared/services/currency.service';
import { LanguageService } from '../../shared/services/language.service';
import { MFAService } from '../../shared/services/mfa.service';
import { NotificationManagerService } from '../../shared/services/notification-manager.service';

import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { UserService } from '../shared/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'auction-cluster-component',
  templateUrl: 'auction-cluster.component.html',
  styleUrls: ['./auction-cluster.component.scss']
})
export class AuctionClusterComponent extends ItemDetailsComponent<AuctionCluster> implements OnInit, OnDestroy {

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  auctionClusterId: number;
  assignAdminUser = true;
  croppedImage: any = '';
  faviconImage: any = '';
  imageChangedEvent: any = '';
  saving = false;
  auctionClusterLanguageIds: Array<number> = [];
  auctionClusterLanguages: Array<AuctionClusterLanguage> = [];
  auctionClusterMasterData: Array<any> = [];
  buyers: Array<Buyer> = [];
  channels: Array<AuctionClusterChannel> = [];
  currencies: Array<Currency> = [];
  languages: Array<Language> = [];
  mfaTypes: Array<MFAType> = [];
  notifications: Array<AuctionClusterNotification> = [];
  timeZones: Array<TimeZoneInfo> = [];
  users: Array<User> = [];

  buyerMasterDataGridListTypes = [
    4, // BUYER
    13 // USER
  ];

  financiallyResponsible = [
    {
      value: 0,
      name: 'Buyer'
    },
    {
      value: 1,
      name: 'Subbuyer'
    }
  ];

  @ViewChild('inputCropper') inputCropper: ElementRef;
  @ViewChild('name', { read: ElementRef }) nameInput: any;
  @ViewChild(AuctionClusterNotificationComponent) auctionClusterNotificationComponent: AuctionClusterNotificationComponent;
  @ViewChild(AuctionClusterChannelComponent) auctionClusterChannelComponent: AuctionClusterChannelComponent;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterService,
    private buyerService: BuyerService,
    private currencyService: CurrencyService,
    private languageService: LanguageService,
    private mfaService: MFAService,
    private notificationManagerService: NotificationManagerService,
    private userService: UserService,
    private sanitizer: DomSanitizer
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AuctionCluster();
    this.setTranslations('NOTIFICATION');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  translateSelectBoxes = (item) => {
    if (item) {
      const l = this.languages.find(l => l.languageId === item.languageId);
      if (l) {
        return l.name;
      }
      else {
        return '';
      }
    }
  }

  onACLanguagesChange() {
    this.auctionClusterLanguageIds.forEach(id => {
      var existingLanguage = this.auctionClusterLanguages.find(acLanguage => acLanguage.languageId == id);
      var lang = this.languages.find(l => l.languageId == id);
      if (lang) {
        if (!existingLanguage) {
          const newLang = new AuctionClusterLanguage();
          newLang.auctionClusterId = this.auctionClusterId;
          newLang.languageId = lang.languageId;
          newLang.isDefault = lang.languageId === this.model.auctionClusterDefaultLanguage
          this.auctionClusterLanguages.push(newLang);
        }
        else {
          existingLanguage.isDefault = lang.languageId === this.model.auctionClusterDefaultLanguage;
        }
      }
    });

    this.auctionClusterLanguages = this.auctionClusterLanguages.filter(acLanguage => this.auctionClusterLanguageIds.find(id => id == acLanguage.languageId));
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getAuctionCluster(this.auctionClusterId),
      this.notificationManagerService.getAuctionClusterNotifications(this.auctionClusterId),
      this.notificationManagerService.getAuctionClusterChannels(this.auctionClusterId)
    ).subscribe(result => {
      this.model = result[0] as AuctionCluster;
      this.notifications = result[1];
      this.channels = result[2];

      this.croppedImage = this.model.image;
      this.faviconImage = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.favicon);
      this.auctionClusterLanguages = this.model.languages;
      this.auctionClusterLanguageIds = [];

      this.auctionClusterMasterData = this.model.masterDataList.map(md => {
        md.name = this.languageService.getTranslatableText(md.name);
        return md;
      });

      this.model.languages.forEach(l => {
        this.auctionClusterLanguageIds.push(l.languageId);
        if (l.isDefault) {
          this.model.auctionClusterDefaultLanguage = l.languageId;
        }
      });
      
      this.translateChannelAndNotificationLists();
      this.isOpened = true;
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  open(auctionClusters: Array<AuctionCluster>, id: number) {
    this.allItems = auctionClusters;
    this.auctionClusterId = id;
    this.auctionClusterMasterData = [];

    forkJoin(
      this.userService.getUsers(),
      this.currencyService.getCurrencies(),
      this.languageService.getLanguages(),
      this.buyerService.getBuyers(),
      this.mfaService.getMFATypes(),
      this.dataService.getTimeZones()
    ).subscribe(result => {
      this.users = result[0];
      this.currencies = result[1];
      this.languages = result[2];
      this.buyers = result[3];
      this.mfaTypes = result[4];
      this.timeZones = result[5];

      if (id != null) {
        this.isEditMode = true;
        this.getData();
      } else {
        this.model = new AuctionCluster();
        this.isEditMode = false;
        this.isOpened = true;
      }

      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  handleSubmit() {
    if (this.saving) {
      return;
    }

    this.onSubmit();

    this.inputCropper.nativeElement.value = "";
    this.imageChangedEvent = null;
    this.croppedImage = null;
    this.faviconImage = null;
    this.auctionClusterLanguageIds = [];
  }

  save() {
    this.saving = true;
    this.model.image = this.croppedImage;

    //Set defaultlanguage
    this.onACLanguagesChange();

    this.model.languages = this.auctionClusterLanguages;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new AuctionCluster();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          }, () => this.saving = false);
    } else {
      this.spinner.show();

      // Force reset of administratorId if assign admin user is unchecked
      if (!this.assignAdminUser) {
        this.model.administratorId = null;
      }

      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new AuctionCluster();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          }, () => this.saving = false);
    }
  }

  editNotification = (e: any) => {
    const auctionClusterNotification = e.row !== undefined ? e.row.data : e.data;

    if (e.column.dataType === "boolean") {
      this.enableDisableNotification(auctionClusterNotification);
    } else {
      this.auctionClusterNotificationComponent.open(auctionClusterNotification, this.languages);
    }
  }

  editChannel = (e: any) => {
    const auctionClusterChannel = e.row !== undefined ? e.row.data : e.data;

    if (e.column.dataType === "boolean") {
      this.enableDisableChannel(auctionClusterChannel);
    } else {
      this.auctionClusterChannelComponent.open(auctionClusterChannel, this.languages);
    }
  }

  onCancel() {
    this.inputCropper.nativeElement.value = '';
    this.imageChangedEvent = null;
    this.croppedImage = null;
    this.faviconImage = null;
    this.auctionClusterLanguageIds = [];

    super.onCancel();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnAuctionClusterSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnAuctionClusterSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnAuctionClusterSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnAuctionClusterSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  enableDisableNotification = (auctionClusterNotification: AuctionClusterNotification) => {
    this.notificationManagerService.enableDisableAuctionClusterNotification(auctionClusterNotification, !auctionClusterNotification.isEnabled).subscribe(() => {
      this.getData();
    }, error => {
      this.errorService.show(error);
    });
  }

  enableDisableChannel = (auctionClusterChannel: AuctionClusterChannel) => {
    this.notificationManagerService.enableDisableAuctionClusterChannel(auctionClusterChannel, !auctionClusterChannel.isActive).subscribe(() => {
      this.getData();
    }, error => {
      this.errorService.show(error);
    });
  }

  translateChannelAndNotificationLists() {
    this.notifications.forEach(item => {
      item.nameText = this.languageService.getTranslatableText(item.name);

      if (item.notificationType === NotificationTypeEnum.PublishingForecastCatalog) item.defaultName = this.translations.TYPE_PUBLISHING_FORECAST_CATALOG;
      else if (item.notificationType === NotificationTypeEnum.PublishingSupplyCatalog) item.defaultName = this.translations.TYPE_PUBLISHING_SUPPLY_CATALOG;
      else if (item.notificationType === NotificationTypeEnum.TransactionSummary) item.defaultName = this.translations.TYPE_TRANSACTION_SUMMARY;
      else if (item.notificationType === NotificationTypeEnum.ErrorReporting) item.defaultName = this.translations.TYPE_ERROR_REPORTING;
      else if (item.notificationType === NotificationTypeEnum.CancelledPrebids) item.defaultName = this.translations.TYPE_CANCELLED_PREBIDS;
      else if (item.notificationType === NotificationTypeEnum.CancelPrebidOnLotChange) item.defaultName = this.translations.TYPE_CANCEL_PREBID_ON_LOT_CHANGE;
      else if (item.notificationType === NotificationTypeEnum.CancelPrebidLotDeleted) item.defaultName = this.translations.TYPE_CANCEL_PREBID_LOT_DELETED;
      else if (item.notificationType === NotificationTypeEnum.AdjustedByMinMaxAmount) item.defaultName = this.translations.TYPE_ADJUSTED_BY_MIN_MAX_AMOUNT;
      else if (item.notificationType === NotificationTypeEnum.AdjustedByMTOAmount) item.defaultName = this.translations.TYPE_ADJUSTED_BY_MTO_AMOUNT;
      else if (item.notificationType === NotificationTypeEnum.IgnoredByMinMaxAmount) item.defaultName = this.translations.TYPE_IGNORED_BY_MIN_MAX_AMOUNT;
      else if (item.notificationType === NotificationTypeEnum.IgnoredByMTOAmount) item.defaultName = this.translations.TYPE_IGNORED_BY_MTO_AMOUNT;
    });
    this.channels.forEach(item => {
      item.nameText = this.languageService.getTranslatableText(item.name);

      if (item.notificationChannelType === NotificationChannelType.Email) item.defaultName = this.translations.CHANNEL_TYPE_EMAIL;
      else if (item.notificationChannelType === NotificationChannelType.SMS) item.defaultName = this.translations.CHANNEL_TYPE_SMS;
      else if (item.notificationChannelType === NotificationChannelType.Push) item.defaultName = this.translations.CHANNEL_TYPE_PUSH;
    });
  }

  returnValues = (e: any) => {
    if (e != null) {
      return e.firstname + ' ' + e.lastname;
    }
  }

  onFaviconChanged(event: any) {
    const files: File[] = event.value;

    this.fileToBase64(files[0])
    .then((base64String: string) =>{
      this.model.favicon = base64String;
      this.faviconImage = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.favicon);
    })
    .catch((error) => {
      this.errorService.show(error);
    });
  }

  fileToBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
}
