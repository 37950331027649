import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Supplier } from '../../../shared/models/supplier';

// services
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class SupplierService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  getSuppliers(auctionClusterId: number): Observable<Array<Supplier>> {
    return this.webApiService.getList(`${this.apiPath}/auctioncluster/${auctionClusterId}/supplier`);
  }
}
