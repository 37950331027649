import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { SaveConfirmationComponent } from '../../shared/components/save-confirmation/save-confirmation.component';

// models
import { Buyer } from '../../shared/models/buyer';
import { User } from '../../shared/models/user';

// services
import { BuyerService } from '../../shared/services/buyer.service';
import { LanguageService } from '../../shared/services/language.service';

@Component({
  selector: 'buyer-component',
  templateUrl: 'buyer.component.html',
  styleUrls: ['./buyer.component.scss']
})
export class BuyerComponent extends ItemDetailsComponent<Buyer> implements OnInit, OnDestroy {

  users: Array<User> = [];
  assignAdminUser = true;
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('name', { read: ElementRef }) nameInput: any;
  @ViewChild('licenseConfirmation') licenseConfirmation: SaveConfirmationComponent;

  constructor(
    protected injector: Injector,
    private dataService: BuyerService,
    private languageService: LanguageService) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new Buyer();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(buyers: Array<Buyer>, id: number, users: Array<User>) {
    this.allItems = buyers;
    this.users = users;

    if (id != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getBuyer(id)
        .subscribe((res: Buyer) => {
          this.model = res;
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    } else {
      this.model = new Buyer();
      this.model.isActive = true;
      this.isEditMode = false;
      this.isOpened = true;
    }

    // setTimeout(() => { this.nameInput.nativeElement.focus(); });
  }

  save() {
    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new Buyer();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          if (error.error && error.error.length > 0 && error.error[0].ErrorMessage === 'BUYER_LICENCE_TOOMANYUSERS') {
            this.licenseConfirmation.message = this.translate.instant('BUYER.BUYER_LICENCE_TOOMANYUSERS', { userList: (error.error[0].PropertyNames as string[]).join(', ') });
            this.licenseConfirmation.opened = true;
          }
          else {
            this.errorService.show(error);
          }
          this.spinner.hide();
        });
    } else {
      this.spinner.show();
      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new Buyer();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnBuyerSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnBuyerSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnBuyerSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnBuyerSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  returnValues = (e: any) => {
    if (e != null) {
      return e.firstname + ' ' + e.lastname;
    }
  }

}
