import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrTabs } from '@clr/angular';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { Language } from '../../shared/models/language';
import { MasterData } from '../../shared/models/master-data';
import { Product, ProductPropertyType, GridProperty } from '../../shared/models/product';
import { Report } from '../../shared/models/report';
import { SystemProductProperty } from '../../shared/models/system-product-property';

import { PriceUnit } from '../shared/models/price-unit';

// services
import { LanguageService } from '../../shared/services/language.service';

import { MasterDataService } from '../shared/services/master-data.service';
import { ProductService } from '../shared/services/product.service';

export enum CatalogType {
  SUPPLY_CATALOG = 1,
  FORECAST_CATALOG = 2
}

@Component({
  selector: 'product-component',
  templateUrl: 'product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent extends ItemDetailsComponent<Product> implements OnInit {

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('productName') productName;
  @ViewChild('productDescription') productDescription;

  auctionClusterId: number;
  languages: Array<Language> = [];
  priceUnits: Array<PriceUnit> = [];
  priceUnit: PriceUnit;
  productPropertyTypes: Array<ProductPropertyType> = [];
  masterDataLists: Array<MasterData> = [];
  productId: number;
  catalogTypes: any = CatalogType;
  catalogType: number;
  reportTypes: Array<Report> = [];
  filteredReportProperties: Array<GridProperty> = [];
  reportId: number;
  systemProductProperties: Array<SystemProductProperty> = [];

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: ProductService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = new Product();
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.catalogTypes = Object.keys(this.catalogTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.catalogTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
  }

  open(products: Array<Product>, productId: number, languages: Array<Language>,
    priceUnits: Array<PriceUnit>, propertyTypes: Array<ProductPropertyType>, masterDataLists: Array<MasterData>,
    reportTypes: Array<Report>, systemProductProperties: Array<SystemProductProperty>) {

    // Autoselect first tab
    this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    this.productPropertyTypes = propertyTypes;
    this.allItems = products;
    this.languages = languages;
    this.systemProductProperties = systemProductProperties;
    this.priceUnits = priceUnits;

    this.masterDataLists = masterDataLists;
    this.reportTypes = reportTypes;

    if (productId != null) {
      this.productId = productId;
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getProduct(this.auctionClusterId, productId)
        .subscribe((res: Product) => {
          this.model = res;
          this.matchTypeNames();
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new Product();
      this.isEditMode = false;
      this.isOpened = true;

      // Create empty JSON object for translation fields
      const emptyTranslation = {};
      this.languages.forEach(lang => {
        emptyTranslation[lang.code] = '';
      });

      this.model.name = this.model.description = JSON.stringify(emptyTranslation);
    }
  }

  matchTypeNames() {
    this.model.supplyDataEntryGridProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.supplyForecastDataEntryGridProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.supplyForecastPublicGridProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.currentLotAuctioneerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.currentLotBuyerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.transactionInfoAuctioneerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.transactionInfoBuyerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.nextLotAuctioneerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.selectLotAuctioneerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.nextLotBuyerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.reportProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.supplyDataEditorProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
    });
    this.model.supplyForecastDataEditorProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
    });
    this.model.automaticProductPropertyNonGroupingSelections.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.automaticProductPropertyGroupingSelections.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
    });
    this.model.manualProductPropertyNonGroupingSelections.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.manualProductPropertyGroupingSelections.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
    });
    this.model.priceOverviewGridBuyer.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.shoppingListProductDefinitionBuyer.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.prebidOnProductBuyer.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.supplyMonitorProductDefinitionBuyer.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
    this.model.reportProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyTypeName = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId).name;
      }
      if (prop && prop.masterDataListId) {
        this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId, null).subscribe(mdl => {
          const masterDataListFields = mdl.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
          if (masterDataListField) {
            property.masterDataFieldName = masterDataListField.name;
          }
        });
      } else {
        property.masterDataFieldName = ' - ';
      }
    });
  }

  filterReportProperties() {
    const filtered = this.model.reportProperties.filter(rp => rp.displayInfo === this.reportId);
    this.filteredReportProperties = [];
    filtered.forEach(reportProperty => {
      this.filteredReportProperties.push({ ...reportProperty });
    });
  }

  updateReportProperties(reportProperties: Array<GridProperty>) {
    if (this.reportId) {
      this.model.reportProperties = this.model.reportProperties.filter(rp => rp.displayInfo !== this.reportId);
      reportProperties.forEach(property => {
        property.displayInfo = this.reportId;
        this.model.reportProperties.push(property);
      });
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {

    this.model.name = this.productName.data;
    this.model.description = this.productDescription.data;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Product();
          this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();

      this.model.auctionClusterId = this.auctionClusterId;

      this.dataService.save(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Product();
          this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    }
    if (this.errorMessage != null) {
      this.errorService.show(this.errorMessage);
    }
  }

  priceUnitChanged(value: number) {
    this.priceUnit = this.priceUnits.find(f => f.priceUnitId === value);
  }

  public onCancel() {
    this.productName.resetLanguageButtons();
    this.productDescription.resetLanguageButtons();
    super.onCancel();
  }
}
