import { OnInit, Injector, Output, EventEmitter, ViewChild, Directive } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

// interfaces
import { ItemDetails } from '../../interfaces/item-details';

// models
import { DecimalTypeEnum } from '../../enum/decimalTypeEnum';
import { ProductPropertyTypeEnum } from '../../enum/productPropertyTypeEnum';

// services
import { DateTimeService } from '../../services/datetime.service';
import { ErrorService } from '../../services/error.service';
import { LanguageService } from '../../services/language.service';
import { SpinnerService } from '../../services/spinner.service';
import { TitleService } from '../../services/title.service';
import { timer } from 'rxjs';

const ESC_KEYCODE = 27;
const lineBreak = '\\r\\n';
const lineBreakHtml = '<br />';

@Directive()
export abstract class ItemDetailsComponent<T> implements ItemDetails<T>, OnInit {

  model: T; // of type model
  isOpened: boolean;
  modalTitle: string;
  isEditMode: boolean;
  errorMessage: string;
  pageSize: number;
  pageSizeValue = 10;
  translations: any; // make readonly

  @Output() onClosed = new EventEmitter<any>(); // tslint:disable-line:no-output-on-prefix

  allItems: Array<T>;

  @ViewChild(NgForm) detailsForm: NgForm;

  protected errorService: ErrorService;
  protected title: TitleService;
  protected spinner: SpinnerService;
  protected translate: TranslateService;
  protected datetime: DateTimeService;
  protected language: LanguageService;

  constructor(injector: Injector) {
    this.errorService = injector.get(ErrorService);
    this.title = injector.get(TitleService);
    this.spinner = injector.get(SpinnerService);
    this.translate = injector.get(TranslateService);
    this.datetime = injector.get(DateTimeService);
    this.language = injector.get(LanguageService);
    this.pageSize = this.pageSizeValue;
  }

  abstract ngOnInit(): void;

  abstract open(...params: Array<any>): void;

  onSubmit() {
    this.save();
  }

  abstract save(): void;

  public onCancel() {
    this.isOpened = false;
    // this.detailsForm.reset();
    this.errorMessage = null;
  }

  // the generic errorservice will show messages at the lowest level location, which can be troublesome when using large open popups
  // this method allows us to override this in a generic way
  public showError(error: any) {
    this.errorMessage = this.errorService.getErrorMessage(error);;
    timer(5000).subscribe(
      () => this.errorMessage = null
    );
  }

  protected close(isDataChanged: boolean) {
    this.isOpened = false;
    this.onClosed.emit(isDataChanged);
  }

  protected setPageSize(event: any) {
    this.pageSizeValue = event;
    this.pageSize = event;
  }

  // Replace '\r\n' with '<br />'
  protected addLineBreaks(value: string, propertyTypeId: ProductPropertyTypeEnum = null, propertyTypeFormat: number = null): string {
    let values = (value || '').toString().split(lineBreak);

    let valuesParsed = Array<string>();
    values.forEach(v => {
      valuesParsed.push(this.format(v, propertyTypeId, propertyTypeFormat));
    });

    value = valuesParsed.join(lineBreakHtml);
    return value;
  }

  // Replaces HTML line break ('<br />') or new line ('\r\n') with separator (dash by default: '-' )
  protected removeLineBreaks(value: string, separator: string = '-'): string {
    value = this.addLineBreaks((value || '').toString());
    let values = value.split(lineBreakHtml);
    value = values.join(` ${separator} `);
    return value;
  }

  protected format(value: any, propertyTypeId: ProductPropertyTypeEnum = null, propertyTypeFormatId: number = null, decimalPlaces: number = null, useGrouping: boolean = true) {
    if (!(value === undefined || value === null) && propertyTypeId == ProductPropertyTypeEnum.DATE) {
      return this.datetime.getDateStringByFormatAnyUtc(value, propertyTypeFormatId);
    }

    if (value === undefined
      || value === null
      || isNaN(value)
      || isNaN(parseFloat(value))
      || propertyTypeId == null
      || propertyTypeId == ProductPropertyTypeEnum.BOOLEAN
      || propertyTypeId == ProductPropertyTypeEnum.IMAGE
      || propertyTypeId == ProductPropertyTypeEnum.MASTER_DATA
      || propertyTypeId == ProductPropertyTypeEnum.TEXT) {
      return value;
    }

    if (propertyTypeFormatId != null && decimalPlaces == null) {
      switch (propertyTypeFormatId) {
        case DecimalTypeEnum.DECIMAL_0digit:
          decimalPlaces = 0;
          break;
        case DecimalTypeEnum.DECIMAL_1digit:
          decimalPlaces = 1;
          break;
        case DecimalTypeEnum.DECIMAL_2digit:
          decimalPlaces = 2;
          break;
        case DecimalTypeEnum.DECIMAL_3digit:
          decimalPlaces = 3;
          break;
        case DecimalTypeEnum.DECIMAL_4digit:
          decimalPlaces = 4;
          break;
        case DecimalTypeEnum.DECIMAL_5digit:
          decimalPlaces = 5;
          break;
      }
    }

    let number = parseFloat(value);
    let minDecimalPlaces = (decimalPlaces === undefined || decimalPlaces === null) ? 0 : decimalPlaces;
    let maxDecimalPlaces = (decimalPlaces === undefined || decimalPlaces === null) ? 18 : decimalPlaces;

    let culture = this.translate.currentLang;

    var numberFormatter = new Intl.NumberFormat(culture, { useGrouping: useGrouping, minimumFractionDigits: minDecimalPlaces, maximumFractionDigits: maxDecimalPlaces });

    return numberFormatter.format(number);
  }

  setTranslations(key: string) {
    this.translate.get(key).subscribe((res: string) => {
      this.translations = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get(key).subscribe((res: string) => {
        this.translations = res;
      });
    });
  }

  protected getTranslation(value: string) {
    if (!value) {
      return '';
    }
    if (!this.IsJsonString(value)) {
      if (typeof value === 'string') {
        return value.replace(/"/g, "'");
      }
      else {
        return value;
      }
    }
    const translated = this.language.getTranslatableText(value);
    return translated;
  }

  private IsJsonString(str: string) {
    try {
      const o = JSON.parse(str);
      if (o && typeof o === 'object') {
        return true;
      }
    } catch (e) {
      //
    }
    return false;
  }

  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE) {
      event.target.blur();
      this.onCancel();
    }
  }
}
