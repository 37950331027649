import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { BuyerLevelClocks, BuyerLevelCatalog, BuyerLevelBuyer } from '../models/buyer-level-clocks';

// services
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class BuyerSaleService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  getBuyerClocks(): Observable<Array<BuyerLevelClocks>> {
    return this.webApiService.getList(this.apiPath + '/clock');
  }

  getBuyerCatalogs(buyerId: number): Observable<Array<BuyerLevelCatalog>> {
    return this.webApiService.getList(this.apiPath + '/' + buyerId + '/catalog');
  }

  getBuyers(): Observable<Array<BuyerLevelBuyer>> {
    return this.webApiService.getList(this.apiPath + '/buyer');
  }
}
