import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../../shared/components/item-details/item-details.component';

// models
import { GroupingActionOnPropertyEnum } from '../../../shared/enum/groupingActionOnPropertyEnum';
import { NonGroupingSelection, ProductClockFace, ProductClockFaceTypeEnum, ProductClockFaceValueSourceEnum, ProductProperty, ProductSystemClockPropertiesEnum } from '../../../shared/models/product';
import { ProductPropertyStyling } from '../../../shared/models/product-property-styling';
import { MasterDataListField } from '../../../shared/models/master-data';

// services
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../shared/services/product.service';
import { ProductPropertyStylingService } from '../../shared/services/product-property-styling.service';

@Component({
  selector: 'product-clockface-component',
  templateUrl: './product-clockface.component.html',
  styleUrls: ['./product-clockface.component.scss']
})
export class ProductClockFaceComponent extends ItemDetailsComponent<ProductClockFace> implements OnInit, OnDestroy {

  @Output() onDetailsClosed = new EventEmitter<ProductClockFace>();

  auctionClusterId: number;
  productId: number;
  productProperties: Array<ProductProperty> = [];
  productPropertyStylings: Array<ProductPropertyStyling> = [];
  masterDataListFields: Array<MasterDataListField> = [];
  automaticGroupings: Array<NonGroupingSelection> = [];
  manualGroupings: Array<NonGroupingSelection> = [];
  productClockFaceTypeEnum: any = ProductClockFaceTypeEnum;
  productClockFaceValueSourceEnum: any = ProductClockFaceValueSourceEnum;
  productSystemClockPropertiesEnum: any = ProductSystemClockPropertiesEnum;
  groupingActionsEnum: any = GroupingActionOnPropertyEnum;
  groupingActionsTranslations: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private dataService: ProductService,
    private productPropertyStylingService: ProductPropertyStylingService,
  ) {
    super(injector);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });

    this.translate.get('GROUPING_ACTIONS').subscribe((res: string) => {
      this.groupingActionsTranslations = res;
    });
  }

  ngOnInit() {
    this.productId = +this.route.snapshot.params['productId'];
    this.auctionClusterId = +this.route.snapshot.params['id'];

    this.productClockFaceTypeEnum = Object.keys(this.productClockFaceTypeEnum).filter(f => !isNaN(Number(f))).map(key => ({ name: this.productClockFaceTypeEnum[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.productClockFaceValueSourceEnum = Object.keys(this.productClockFaceValueSourceEnum).filter(f => !isNaN(Number(f))).map(key => ({ name: this.productClockFaceValueSourceEnum[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.productSystemClockPropertiesEnum = Object.keys(this.productSystemClockPropertiesEnum).filter(f => !isNaN(Number(f))).map(key => ({ name: this.productSystemClockPropertiesEnum[key], value: Number(key) })).sort((a, b) => a.name.localeCompare(b.name)); // tslint:disable-line:max-line-length
    this.groupingActionsEnum = Object.keys(this.groupingActionsEnum).filter(f => !isNaN(Number(f))).map(key => ({ name: this.groupingActionsEnum[key], value: Number(key) })); // tslint:disable-line:max-line-length
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(items: Array<ProductClockFace>, clockFaceId: number, type: number) {
    const selectedModel = items.find((item) => item.productClockFaceId === clockFaceId) || new ProductClockFace();

    // Clone the selected model to avoid modifying the original items
    this.model = { ...selectedModel, type };
    this.model.type = type;
    if (this.model.systemClockProperty === '0') {
      this.model.systemClockProperty = undefined;
    }
    if (this.model.valueSource === 0) {
      this.model.valueSource = undefined;
    }
    this.isEditMode = clockFaceId != null;

    forkJoin([
      this.dataService.getProductProperties(this.productId, this.translateService.currentLang),
      this.productPropertyStylingService.getProductPropertyStylings(this.auctionClusterId)
    ]).subscribe(([productProperties, productPropertyStylings]) => {
      this.productProperties = productProperties;
      this.productPropertyStylings = productPropertyStylings.sort((a, b) => a.name.localeCompare(b.name));

      this.isOpened = true;
    },
      error => {
        this.errorService.show(error);
      });
  }

  save() {
    this.model.productId = this.productId;

    this.onDetailsClosed.emit(this.model);
    this.close(true);
    this.errorMessage = null;
  }

  onProductPropertyValueChanged = (e: any) => {
    if (e.value) {
      this.model.systemClockProperty = null;
      forkJoin([
        this.dataService.getMasterDataListFields(this.productId, e.value, this.translateService.currentLang),
        this.dataService.getGrouping(this.productId, e.value, this.translateService.currentLang),
      ]).subscribe(([masterDataListFields, groupings]) => {
        this.masterDataListFields = masterDataListFields;

        groupings.forEach(element => {
          const action = this.groupingActionsEnum.find(a => a.value === element.actionOnGrouping);
          if (action) {
            element.actionLabel = this.groupingActionsTranslations[action.name]
          }
        });

        this.automaticGroupings = groupings.filter(_ => _.groupingUse == 1);
        this.manualGroupings = groupings.filter(_ => _.groupingUse == 2);
      },
        error => {
          this.errorService.show(error);
        });
    }
    else{
      this.model.masterDataListFieldId = null;
    }
  }

  onSystemClockPropertyValueChanged = (e: any) => {
    if (e.value) {
      this.automaticGroupings = [];
      this.manualGroupings = [];
      this.masterDataListFields = [];
      this.model.valueSource = null;
      this.model.productPropertyId = null;
      this.model.masterDataListFieldId = null;
      this.model.automaticGroupingProductPropertyId = null;
      this.model.manualGroupingProductPropertyId = null;
    }
  }  

  getGroupingActionTranslated(id: number) {
    const action = this.groupingActionsEnum.find(a => a.value === id);
    if (action) {
      return ` - ${this.groupingActionsTranslations[action.name]}`;
    }
    return '';
  }

  translateSelectBoxes = (item) => {
    if (item) {
      let label = item.name;
      this.translate.get('PRODUCT.CLOCKFACE.' + label.toUpperCase()).subscribe((res: string) => {
        label = res;
      });
      return label;
    }
  }

  onTextUpdated(updatedText: any) {
    this.model.label = updatedText;
  }
}