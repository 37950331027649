export class SupplyForecastPublicUrk {
  speciesAmounts: Array<SpeciesAmounts> = [];
  auctionSupplierAmounts: Array<AuctionSupplierAmounts> = [];
  supplierLandingTimes: Array<SupplierLandingTimes> = [];
}

export class SpeciesAmounts {
  name: string;
  amount: number;
}

export class AuctionSupplierAmounts {
  auctionName: string;
  amount: number;
  numberOfSuppliers: number;
  exitOpenTime: Date;
  sortTime: Date;
  saleTime: Date;
  remark: string;
}

export class SupplierLandingTimes {
  supplier: string;
  landingTime: Date;
}
