import { Component, OnInit, Injector, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrTabs } from '@clr/angular';
import { forkJoin } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { GroupingActionOnPropertyEnum } from '../../shared/enum/groupingActionOnPropertyEnum';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { UrlTypeEnum } from '../../shared/enum/urlTypeEnum';
// tslint:disable-next-line: max-line-length
import { AuctionCluster } from '../../shared/models/auction-cluster';
import { Language } from '../../shared/models/language';
import { MasterData } from '../../shared/models/master-data';
import { Product, ProductPropertyType, GridProperty, StartPriceBehaviourEnum, LotGroupMinimumPriceBehaviourEnum, ProductProperty, ProductBuyButtonTypes, WidgetConfig, MasterDataFieldTypeEnum } from '../../shared/models/product';
import { Report, ReportLevels } from '../../shared/models/report';
import { ProductPropertyStyling } from '../../shared/models/product-property-styling';
import { SystemProductProperty } from '../../shared/models/system-product-property';

import { AuctionUserType } from '../shared/models/auctionusertype';
import { LookupTable } from '../shared/models/lookup-table';
import { PriceUnit } from '../shared/models/price-unit';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterReportService } from '../shared/services/report.service';
import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { LookupTableService } from '../shared/services/lookup-table.service';
import { MasterDataService } from '../shared/services/master-data.service';
import { PriceUnitService } from '../shared/services/price-unit.service';
import { ProductPropertyTypeService } from '../shared/services/product-property-type.service';
import { ProductPropertyStylingService } from '../shared/services/product-property-styling.service';
import { ProductService } from '../shared/services/product.service';

export enum CatalogType {
  SUPPLY_CATALOG = 1,
  FORECAST_CATALOG = 2
}

enum SelectLotSearchBehaviour {
  CONTAINS = 0,
  START_WITH = 1
}

const ESC_KEYCODE = 27;
@Component({
  selector: 'product-editor-component',
  templateUrl: 'product-editor.component.html',
  styleUrls: ['./product-editor.component.scss']
})
export class ProductEditorComponent extends ItemDetailsComponent<Product> implements OnInit {

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('productName') productName;
  @ViewChild('productDescription') productDescription;

  auctionClusterId: number;
  auctionCluster: AuctionCluster;
  languages: Array<Language> = [];
  priceUnits: Array<PriceUnit> = [];
  priceUnit: PriceUnit;
  productPropertyTypes: Array<ProductPropertyType> = [];
  productPropertyStylings: Array<ProductPropertyStyling> = [];
  masterDataLists: Array<MasterData> = [];
  productId: number;
  catalogTypes: any = CatalogType;
  catalogType: number;
  reportTypes: Array<Report> = [];
  filteredReportProperties: Array<GridProperty> = [];
  reportId: number;
  systemProductProperties: Array<SystemProductProperty> = [];
  systemFields: Array<SystemProductProperty> = [];
  firstLoad = true;
  priceLookups: Array<LookupTable> = [];
  colorLookups: Array<LookupTable> = [];
  groupingActions: any = GroupingActionOnPropertyEnum;
  groupingActionsTranslations: any;
  ReportLevels = ReportLevels;
  startPriceBehaviours: Array<any> = [];
  lotGroupMinimumPriceBehaviours: Array<any> = [];
  filteredImageProperties: Array<ProductProperty> = [];
  productPropertyTypeEnum = ProductPropertyTypeEnum;
  productPropertyUrlTypesEnum = UrlTypeEnum;
  selectLotSearchBehaviours: Array<any> = [];
  filteredMasterDataProperties: Array<ProductProperty> = [];
  skipProperties: Array<ProductProperty> = [];

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: ProductService,
    private auctionClusterService: AuctionClusterService,
    private masterDataService: MasterDataService,
    private productPropertyTypeService: ProductPropertyTypeService,
    private productPropertyStylingService: ProductPropertyStylingService,
    private priceUnitService: PriceUnitService,
    private route: ActivatedRoute,
    private router: Router,
    private lookupService: LookupTableService,
    private reportService: AuctionClusterReportService,
    private translateService: TranslateService,
  ) {
    super(injector);
    this.groupingActions = Object.keys(this.groupingActions).filter(f => !isNaN(Number(f))).map(key => ({ name: this.groupingActions[key], value: Number(key) })); // tslint:disable-line:max-line-length

    this.translate.get('GROUPING_ACTIONS').subscribe((res: string) => {
      this.groupingActionsTranslations = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('GROUPING_ACTIONS').subscribe((res: string) => {
        this.groupingActionsTranslations = res;
      });
    });
  }

  ngOnInit() {
    this.model = new Product();
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.productId = +this.route.snapshot.params['productId'];
    this.selectLotSearchBehaviours = (Object.keys(SelectLotSearchBehaviour).filter((el) => { return isNaN(Number(el)) })).map((key) => { return { key: key, value: SelectLotSearchBehaviour[key] } });
    if (this.productId) {
      this.isEditMode = true;
    } else {
      this.dataService.getNewProductModel(this.auctionClusterId).subscribe(newProduct => {
        this.model = newProduct;
        this.model.lotGroupMinimumPriceBehaviour = LotGroupMinimumPriceBehaviourEnum.HIGHEST_MINIMUM_PRICE;
        this.isEditMode = false;
        this.isOpened = true;
        this.createTranslatableFields();
      });
    }

    this.catalogTypes = Object.keys(this.catalogTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.catalogTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.getData();
  }

  open(products: Array<Product>, productId: number, languages: Array<Language>) {

    // Autoselect first tab
    this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    this.allItems = products;
    this.languages = languages;

    if (productId != null) {
      this.productId = productId;
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getProduct(this.auctionClusterId, productId)
        .subscribe((res: Product) => {
          this.model = res;
          this.matchTypeNames();
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new Product();
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  // Create empty JSON object for translation fields
  createTranslatableFields() {
    if (this.model.productId == null || this.model.productId === 0) {
      const emptyTranslation = {};
      this.languages.forEach(lang => {
        emptyTranslation[lang.code] = '';
      });
      this.model.name = this.model.description = JSON.stringify(emptyTranslation);
    }
  }

  getData() {
    // tslint:disable:no-magic-numbers
    this.spinner.show();
    if (this.isEditMode) {
      this.dataService.getProduct(this.auctionClusterId, this.productId).subscribe(product => {
        this.model = product;
        this.reportService.getProductReportsSummary(this.auctionClusterId, this.model.productId).subscribe(data => {
          this.reportTypes = data;
          this.reportTypes.sort((a, b) => {
            if (a.reportLevel + " - " + a.name < b.reportLevel + " - " + b.name) {
              return -1;
            }
            if (a.reportLevel + " - " + a.name > b.reportLevel + " - " + b.name) {
              return 1;
            }
            return 0;
          });

          this.model.productBuyButtons.forEach(_ => {
            _.typeDisplayName = ProductBuyButtonTypes[_.type];
          });
        });
        this.title.set('PRODUCTS.TITLE');
        this.title.add(this.getTranslation(this.model.name));
        this.getImageProperties();
        this.getMasterDataProperties();
        this.matchTypeNames();
        this.getSkipProperties();
      });
    }
    const sources: Array<any> = [
      // tslint:disable-next-line:max-line-length
      // this.lookupService.getLookupTablesForResultProperty(this.auctionClusterId, this.model.productId ? this.model.productId : 0, ProductPropertyTypeEnum.DECIMAL),
      // tslint:disable-next-line:max-line-length
      // this.lookupService.getLookupTablesForResultProperty(this.auctionClusterId, this.model.productId ? this.model.productId : 0, ProductPropertyTypeEnum.NUMBER),
    ];
    if (this.firstLoad) {
      sources.push(this.lookupService.getLookupTables(null, this.auctionClusterId, this.translateService.currentLang)); // !!!! TODO: get only suitable lookups
      sources.push(this.languageService.getAuctionClusterLanguages(this.auctionClusterId));
      sources.push(this.productPropertyTypeService.getProductProperties());
      sources.push(this.masterDataService.getMasterDataListsWithFields(this.auctionClusterId));
      sources.push(this.dataService.getSystemProductProperties());
      sources.push(this.productPropertyStylingService.getProductPropertyStylings(this.auctionClusterId));
      sources.push(this.auctionClusterService.getAuctionCluster(this.auctionClusterId));
      //sources.push(this.priceUnitService.getPriceUnits());
    }
    forkJoin(...sources).subscribe(result => {
      this.priceLookups = <Array<LookupTable>>result[0];
      this.colorLookups = <Array<LookupTable>>result[0];
      if (this.firstLoad) {
        this.languages = <Array<Language>>result[1];
        this.productPropertyTypes = <Array<ProductPropertyType>>result[2];
        this.masterDataLists = <Array<MasterData>>result[3];
        this.systemFields = <Array<SystemProductProperty>>result[4];
        this.productPropertyStylings = <Array<ProductPropertyStyling>>result[5];
        this.auctionCluster = <AuctionCluster>result[6];

        this.startPriceBehaviours = Object.keys(StartPriceBehaviourEnum).filter(f => !isNaN(Number(f)) && (this.auctionCluster.webServiceUrl != null || (this.auctionCluster.webServiceUrl == null && Number(f) != 3))).map(key => ({ name: StartPriceBehaviourEnum[key], value: Number(key) })); // tslint:disable-line:max-line-length
        this.lotGroupMinimumPriceBehaviours = Object.keys(LotGroupMinimumPriceBehaviourEnum).filter(f => !isNaN(Number(f)) && (this.auctionCluster.webServiceUrl != null || (this.auctionCluster.webServiceUrl == null && Number(f) != 3))).map(key => ({ name: LotGroupMinimumPriceBehaviourEnum[key], value: Number(key) })); // tslint:disable-line:max-line-length
        //this.priceUnits = <Array<PriceUnit>>result[5];
        this.firstLoad = false;
      }
      this.matchTypeNames();
      this.createTranslatableFields();
      this.getImageProperties();
      this.getMasterDataProperties();
      this.getSkipProperties();      
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
    // tslint:enable:no-magic-numbers
  }

  getMasterData(id: number) {
    return this.masterDataLists.find(md => md.masterDataListId === id);
  }

  setMasterDataPropName(prop, property) {
    if (prop && prop.masterDataListId) {
      const mdl = this.getMasterData(prop.masterDataListId);
      if (mdl) {
        const masterDataListFields = mdl.fields;
        const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === property.masterDataListFieldId);
        if (masterDataListField) {
          property.masterDataFieldName = masterDataListField.name;
        }
      } else {
        property.masterDataFieldName = ' - ';
      }
    } else {
      property.masterDataFieldName = ' - ';
    }
  }

  get buyerWidgets(): Array<WidgetConfig> {
    return this.model.widgets.filter(w => w.auctionUserTypeId == AuctionUserType.buyer);
  }

  get auctioneerWidgets(): Array<WidgetConfig> {
    return this.model.widgets.filter(w => w.auctionUserTypeId == AuctionUserType.auctioneer);
  }

  matchTypeNames() {
    this.model.supplyDataEntryGridProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
    });
    this.model.supplyForecastDataEntryGridProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType) {
          property.productPropertyTypeName = propertyType.name;
        }
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.supplyForecastPublicGridProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.currentLotAuctioneerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.currentLotBuyerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.transactionInfoAuctioneerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.transactionInfoBuyerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.nextLotAuctioneerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.selectLotAuctioneerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.nextLotBuyerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.reportProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.supplyDataEditorProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
    });
    this.model.supplyForecastDataEditorProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
    });
    this.model.automaticProductPropertyNonGroupingSelections.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;

        property.actionLabel = `${this.getTranslation(prop.name)}${this.getGroupingActionTranslated(property.actionOnGrouping)}`;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.automaticProductPropertyGroupingSelections.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
    });
    this.model.manualProductPropertyNonGroupingSelections.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;

        property.actionLabel = `${this.getTranslation(prop.name)}${this.getGroupingActionTranslated(property.actionOnGrouping)}`;
      }

      this.setMasterDataPropName(prop, property);
    });
    this.model.manualProductPropertyGroupingSelections.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
    });
    this.model.priceOverviewGridBuyer.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.shoppingListProductDefinitionBuyer.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.prebidOnProductBuyer.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.supplyMonitorProductDefinitionBuyer.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.reportProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.packageTypeAuctioneerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.packageTypeBuyerProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.imagesWidgetProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.transactionRegistrationFilterProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
    this.model.transactionRegistrationGridProperties.forEach(property => {
      const prop = this.model.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        const propertyType = this.productPropertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          property.productPropertyTypeName = propertyType.name;
      }
      this.setMasterDataPropName(prop, property);
    });
  }

  getGroupingActionTranslated(id: number) {
    const action = this.groupingActions.find(a => a.value === id);
    if (action) {
      return ` - ${this.groupingActionsTranslations[action.name]}`;
    }

    return '';
  }

  filterReportProperties() {
    const filtered = this.model.reportProperties.filter(rp => rp.reportDesignId === this.reportId);
    this.filteredReportProperties = [];
    filtered.forEach(reportProperty => {
      this.filteredReportProperties.push({ ...reportProperty });
    });
  }

  updateReportProperties() {
    if (this.reportId && this.filteredReportProperties) {
      this.model.reportProperties = this.model.reportProperties.filter(rp => rp.reportDesignId !== this.reportId);
      this.filteredReportProperties.forEach(property => {
        property.reportDesignId = this.reportId;
        this.model.reportProperties.push(property);
      });
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  getNumericProperties() {
    return this.model.productProperties.filter(p => p.propertyTypeId === ProductPropertyTypeEnum.DECIMAL
      || p.propertyTypeId === ProductPropertyTypeEnum.NUMBER);
  }

  getImageProperties() {
    this.model.productProperties.forEach(property => {
      if (property.propertyTypeId && property.propertyTypeId === ProductPropertyTypeEnum.URL &&
        property.propertyTypeFormatId && property.propertyTypeFormatId === UrlTypeEnum.IMAGE_URL) {
        this.filteredImageProperties.push(property);
      }
      if (property.propertyTypeId && property.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        this.masterDataLists.find(md => md.masterDataListId === property.masterDataListId).fields.forEach(field => {
          if (field.propertyTypeId && field.propertyTypeId === ProductPropertyTypeEnum.URL &&
            field.propertyTypeFormatId && field.propertyTypeFormatId === UrlTypeEnum.IMAGE_URL) {
            if (!this.filteredImageProperties.includes(property)) {
              this.filteredImageProperties.push(property);
            }
          }
        });
      }
    });
  }

  getMasterDataProperties() {
    this.filteredMasterDataProperties = this.model.productProperties.filter(property => property.propertyTypeId && property.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA);
  }
  getSkipProperties() {
    this.skipProperties = this.model.productProperties.filter(property => property.propertyTypeId && 
      ((property.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA && 
        this.masterDataLists.find(md => md.masterDataListId === property.masterDataListId).fields.some(mdf => mdf.fieldType === MasterDataFieldTypeEnum.Id))
        || property.propertyTypeId == ProductPropertyTypeEnum.NUMBER
        || property.propertyTypeId == ProductPropertyTypeEnum.TEXT) ) 
  }

  save() {
    this.updateReportProperties();

    this.model.name = this.productName.data;
    this.model.description = this.productDescription.data;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.matchTypeNames();
          this.model.productBuyButtons.forEach(_ => {
            _.typeDisplayName = ProductBuyButtonTypes[_.type];
          });
          this.errorMessage = null;
          this.spinner.hide();
        }, error => {
          this.errorMessage = this.errorService.toString(error);
          this.spinner.hide();
        });
    } else {
      this.model.auctionClusterId = this.auctionClusterId;
      this.dataService.save(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = res;
          // tslint:disable-next-line:max-line-length
          this.router.navigate(['auction/products/' + this.auctionClusterId + '/product/' + res.productId]); // tslint:disable-line:no-floating-promises
          this.matchTypeNames();
          this.reportService.getProductReportsSummary(this.auctionClusterId, this.model.productId).subscribe(data => {
            this.reportTypes = data;
          });

          this.model.productBuyButtons.forEach(_ => {
            _.typeDisplayName = ProductBuyButtonTypes[_.type];
          });

          this.spinner.hide();
        }, error => {
          this.errorMessage = this.errorService.toString(error);
          this.spinner.hide();
        });
    }
    if (this.errorMessage != null) {
      this.errorService.show(this.errorMessage);
    }
  }

  priceUnitChanged(value: number) {
    this.priceUnit = this.priceUnits.find(f => f.priceUnitId === value);
  }

  public onCancel() {
    this.productName.resetLanguageButtons();
    this.productDescription.resetLanguageButtons();
    this.router.navigate(['auction/products/' + this.auctionClusterId]); // tslint:disable-line:no-floating-promises
  }

  @HostListener('window:keydown', ['$event'])
  protected handleWindowKeyDownEvent(event: any) {
    super.handleWindowKeyDownEvent(event);
  }
}
