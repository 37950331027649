export class UserFilter {
  showAuction = false;
  showBuyer = false;
  showSupplier = false;
  showActive = true;
  showInactive = true;
  showBlocked = true;
  showPending = true;
  auctionId: number;
  buyerId: number;
  supplierId: number;
}
