<div class="publish-lot-component" [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
        [width]="600"
        height="450"
        [showTitle]="true"
        title="{{ modalTitle }}"
        [dragEnabled]="false"
        [closeOnOutsideClick]="false"
        [rtlEnabled]="rtlEnabled"
        [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-list [items]="suppliers"
                 [height]="300"
                 [showSelectionControls]="true"
                 [selectAllMode]="true"
                 selectionMode="all"
                 [(selectedItems)]="requiredSuppliersGrid"
                 displayExpr="displayName"
                 keyExpr="supplierId">
        </dx-list>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();">{{ 'PUBLISH' | translate }}</button>
        <!--<button *ngIf="isForecastCatalog" type="submit" class="btn btn-primary" (click)="onSubmit();">{{ 'PUBLISH' | translate }}</button>
      <button *ngIf="!isForecastCatalog" type="button" class="btn btn-primary" (click)="publishAll();">{{ 'LOTS.PUBLISH_ALL' | translate }}</button>
    <button *ngIf="!isForecastCatalog" type="button" class="btn btn-primary" (click)="publishToday();">{{ 'LOTS.PUBLISH_TODAY' | translate }}</button>-->
          <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
    </dx-popup>
</div>
