<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
    <dx-button hint="{{ 'SHARED.PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
  </div>
  <br />
  <dx-data-grid #lookupTablesGrid id="printSection" [rtlEnabled]="rtlEnabled" [dataSource]="items"
                [showBorders]="true"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onCellClick)="openLookupTablesEditorClick($event)"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true" [showInfo]="true" [visible]="true" infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage_lookuptables"></dxo-state-storing>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="lookupTableType" caption="{{ 'SHARED.TYPE' | translate }}" [customizeText]="getlookupTableTypeName">
    </dxi-column>
    <dxi-column type="buttons" [width]="140">
      <dxi-button hint="{{ 'LOOKUP_TABLE.OPEN_LOOKUP_TABLE_LIST_EDITOR' | translate }}" icon="detailslayout" [onClick]="openLookupTableListEditor"></dxi-button>
      <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
      <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
<lookup-table-component #details
                        (onClosed)="onDetailsComponentClosed($event)"></lookup-table-component>
<confirmation-component #confirmation title="{{ 'LOOKUP_TABLE.DELETE_LOOKUP_TABLE' | translate }}"
                        message="{{ 'LOOKUP_TABLE.DELETE_LOOKUP_TABLE_MESSAGE' | translate }}"
                        buttonCloseText="{{ 'SHARED.DELETE' | translate }}" cancellable="true" (close)="deleteSelected();"></confirmation-component>
