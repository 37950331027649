import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// interfaces
import { IAuctionClusterService } from '../../../shared/interfaces/auction-cluster';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { AuctionCluster } from '../../../shared/models/auction-cluster';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class AuctionClusterService implements IAuctionClusterService {

  private apiPath: string;

  private supplierId: number;

  setId(supplierId: number)
  {
    this.supplierId = supplierId;
  }

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierlevel';
  }

  getAuctionClusters(supplierId: number): Observable<Array<AuctionCluster>> {
    return this.webApiService.getList(this.apiPath + '/' + supplierId + '/auctioncluster');
  }

  getAuctionClustersPerLevel(): Observable<Array<AuctionCluster>> {
    return this.webApiService.getList(this.apiPath + '/' + this.supplierId + '/auctioncluster');
  }
}
