import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../models/application-settings';
import { AuctionClusterChannel } from '../models/auction-cluster-channel';
import { AuctionClusterNotification } from '../models/auction-cluster-notification';
import { UserNotification } from '../models/user-notification';
import { UserNotificationChannel } from '../models/user-notification-channel';

// services
import { AuthService } from './auth.service';
import { WebApiService } from './web-api.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationManagerService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private authService: AuthService, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'core/notification';
  }

  getAuctionClusterNotification(auctionClusterNotificationId: number): Observable<AuctionClusterNotification> {
    return this.webApiService.get(`${this.apiPath}/auctioncluster/notification`, auctionClusterNotificationId);
  }

  getAuctionClusterNotifications(auctionClusterId: number): Observable<Array<AuctionClusterNotification>> {
    return this.webApiService.getList(`${this.apiPath}/auctioncluster/${auctionClusterId}/notification`);
  }

  updateAuctionClusterNotification(auctionClusterNotification: AuctionClusterNotification) {
    return this.webApiService.editSingle(`${this.apiPath}/auctioncluster/notification/${auctionClusterNotification.auctionClusterNotificationId}`, auctionClusterNotification);
  }

  enableDisableAuctionClusterNotification(auctionClusterNotification: AuctionClusterNotification, value: boolean) {
    if(value) {
      return this.webApiService.enableDisable(`${this.apiPath}/auctioncluster/notification/enable`, auctionClusterNotification.auctionClusterNotificationId);
    }
    else {
      return this.webApiService.enableDisable(`${this.apiPath}/auctioncluster/notification/disable`, auctionClusterNotification.auctionClusterNotificationId);
    }
  }

  getAuctionClusterChannels(auctionClusterId: number): Observable<Array<AuctionClusterChannel>> {
    return this.webApiService.getList(`${this.apiPath}/auctioncluster/channel/${auctionClusterId}`);
  }

  updateAuctionClusterChannel(auctionClusterChannel: AuctionClusterChannel) {
    return this.webApiService.editSingle(`${this.apiPath}/auctioncluster/channel/${auctionClusterChannel.auctionClusterChannelId}`, auctionClusterChannel);
  }

  enableDisableAuctionClusterChannel(auctionClusterChannel: AuctionClusterChannel, value: boolean) {
    if(value) {
      return this.webApiService.enableDisable(`${this.apiPath}/auctioncluster/channel/enable`, auctionClusterChannel.auctionClusterChannelId);
    }
    else {
      return this.webApiService.enableDisable(`${this.apiPath}/auctioncluster/channel/disable`, auctionClusterChannel.auctionClusterChannelId);
    }
  }

  getUserNotifications(userId: number): Observable<Array<UserNotification>> {
    return this.webApiService.getList(`${this.apiPath}/user/${userId}/notification`);
  }

  enableDisableUserNotification(userNotification: UserNotification, value: boolean) {
    if(value) {
      return this.webApiService.enableDisable(`${this.apiPath}/user/notification/enable`, userNotification.userNotificationId);
    }
    else {
      return this.webApiService.enableDisable(`${this.apiPath}/user/notification/disable`, userNotification.userNotificationId);
    }
  }

  getUserNotificationChannels(userNotificationId: number): Observable<Array<UserNotificationChannel>> {
    return this.webApiService.getList(`${this.apiPath}/user/notification/${userNotificationId}/channel`);
  }

  enableDisableUserNotificationChannel(userNotificationChannel: UserNotificationChannel, value: boolean){
    if(value) {
      return this.webApiService.enableDisable(`${this.apiPath}/user/channel/enable`, userNotificationChannel.userNotificationChannelId);
    }
    else {
      return this.webApiService.enableDisable(`${this.apiPath}/user/channel/disable`, userNotificationChannel.userNotificationChannelId);
    }
  }
}
