<div class="product-clockface-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
    <dx-popup #addProductClockFace [width]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
        [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened">
        <dx-scroll-view width="100%" height="100%">
            <div class="au-dx-popup-padder">
                <dx-form #productClockFaceForm id="productClockFaceForm" validationGroup="productClockFaceData"
                    [showValidationSummary]="false" colCount="auto"
                    [(formData)]="model" [minColWidth]="500">

                    <dxi-item itemType="tabbed">
                        <dxi-tab title="{{ 'PRODUCT.CLOCKFACE.DATA' | translate }}">
                            <dxi-item itemType="group">
                                <dxi-item dataField="name">
                                    <dxo-label [text]="'SHARED.NAME' | translate"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="systemClockProperty" editorType="dxSelectBox" *ngIf="!model?.valueSource && (!model?.productPropertyId || model?.productPropertyId === null)"
                                    [editorOptions]="{ items: productSystemClockPropertiesEnum, valueExpr:'value', displayExpr:translateSelectBoxes, onValueChanged: onSystemClockPropertyValueChanged, showClearButton: true }">
                                    <dxo-label
                                        text="{{ 'PRODUCT.CLOCKFACE.SYSTEM_CLOCK_PROPERTY' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="valueSource" editorType="dxSelectBox" *ngIf="!model?.systemClockProperty"
                                    [editorOptions]="{ items: productClockFaceValueSourceEnum, valueExpr:'value', displayExpr:translateSelectBoxes, showClearButton: true }">
                                    <dxo-label text="{{ 'PRODUCT.CLOCKFACE.VALUE_SOURCE' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="productPropertyId" editorType="dxSelectBox" *ngIf="!model?.systemClockProperty"
                                    [editorOptions]="{ items: productProperties, valueExpr:'productPropertyId', displayExpr:'name', onValueChanged: onProductPropertyValueChanged, showClearButton: true }">
                                    <dxo-label text="{{ 'PRODUCT.PROPERTY_NAME' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="masterDataListFieldId" editorType="dxSelectBox" *ngIf="masterDataListFields !== undefined && masterDataListFields.length > 0"
                                    [editorOptions]="{ items: masterDataListFields, valueExpr:'masterDataListFieldId', displayExpr:'name'}">
                                    <dxo-label text="{{ 'SHARED.MASTER_DATA_FIELD' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="automaticGroupingProductPropertyId" editorType="dxSelectBox"  *ngIf="automaticGroupings !== undefined && automaticGroupings.length > 0"
                                    [editorOptions]="{ items: automaticGroupings, valueExpr:'productPropertyGroupingSelectionId', displayExpr:'actionLabel'}">
                                    <dxo-label
                                        text="{{ 'PRODUCT.PROPERTY.AUTOMATIC_GROUPING_PROPERTY' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="manualGroupingProductPropertyId" editorType="dxSelectBox"  *ngIf="manualGroupings !== undefined && manualGroupings.length > 0"
                                    [editorOptions]="{ items: manualGroupings, valueExpr:'productPropertyGroupingSelectionId', displayExpr:'actionLabel'}">
                                    <dxo-label
                                        text="{{ 'PRODUCT.PROPERTY.MANUAL_GROUPING_PROPERTY' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="productPropertyStylingId" editorType="dxSelectBox"
                                    [editorOptions]="{ items: productPropertyStylings, valueExpr:'productPropertyStylingId', displayExpr:'name' }">
                                    <dxo-label text="{{ 'PRODUCT.CLOCKFACE.STYLING' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="isEditable" editorType="dxCheckBox">
                                    <dxo-label text="{{ 'PRODUCT.CLOCKFACE.IS_EDITABLE' | translate }}"></dxo-label>
                                </dxi-item>

                            </dxi-item>
                        </dxi-tab>
                        <dxi-tab title="{{ 'PRODUCT.CLOCKFACE.POSITION' | translate }}">
                            <dxi-item itemType="group">
                                <dxi-item dataField="rowNumber" editorType="dxNumberBox"
                                    [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                                    <dxo-label text="{{ 'PRODUCT.PROPERTY.ROW_NUMBER' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="columnNumber" editorType="dxNumberBox"
                                    [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                                    <dxo-label text="{{ 'PRODUCT.PROPERTY.COLUMN_NUMBER' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="height" editorType="dxNumberBox"
                                    [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                                    <dxo-label text="{{ 'PRODUCT.PROPERTY.HEIGHT' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="width" editorType="dxNumberBox"
                                    [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                                    <dxo-label text="{{ 'PRODUCT.PROPERTY.WIDTH_PERCENTAGE' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="marginLeft" editorType="dxNumberBox"
                                    [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                                    <dxo-label text="{{ 'PRODUCT.CLOCKFACE.MARGIN_LEFT' | translate }}"></dxo-label>
                                </dxi-item>                                

                                <dxi-item dataField="marginRight" editorType="dxNumberBox"
                                    [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                                    <dxo-label text="{{ 'PRODUCT.CLOCKFACE.MARGIN_RIGHT' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="marginTop" editorType="dxNumberBox"
                                    [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                                    <dxo-label text="{{ 'PRODUCT.CLOCKFACE.MARGIN_TOP' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="marginBottom" editorType="dxNumberBox"
                                    [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                                    <dxo-label text="{{ 'PRODUCT.CLOCKFACE.MARGIN_BOTTOM' | translate }}"></dxo-label>
                                </dxi-item>
                            </dxi-item>
                        </dxi-tab>
                        <dxi-tab title="{{ 'PRODUCT.CLOCKFACE.LABEL' | translate }}">
                            <dxi-item itemType="group">                                
                                <dxi-item dataField="label" [template]="'labelField'">
                                    <dxo-label text="{{ 'PRODUCT.CLOCKFACE.LABEL' | translate }}"></dxo-label>
                                </dxi-item>                            

                                <dxi-item dataField="labelHeight" editorType="dxNumberBox"
                                    [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                                    <dxo-label text="{{ 'PRODUCT.CLOCKFACE.LABEL_HEIGHT' | translate }}"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="labelProductPropertyStylingId" editorType="dxSelectBox"
                                    [editorOptions]="{ items: productPropertyStylings, valueExpr:'productPropertyStylingId', displayExpr:'name' }">
                                    <dxo-label text="{{ 'PRODUCT.CLOCKFACE.LABEL_STYLING' | translate }}"></dxo-label>
                                </dxi-item>
                            </dxi-item>
                        </dxi-tab>
                    </dxi-item>

                    <div *dxTemplate="let data of 'labelField'">      
                        <translatable-field2 [(text)]="data.component.option('formData')[data.dataField]" (textUpdated)="onTextUpdated($event)"></translatable-field2>  
                    </div>
                </dx-form>
                <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnProductClockFaceSubmit">{{ 'SHARED.SAVE' | translate }}</button>
                <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
            </div>
        </dx-scroll-view>
    </dx-popup>
</div>