<div class="item-list-component">
    <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
      <h3 class="modal-title">{{ modalTitle }}</h3>
      <div class="modal-body">
        <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
          <div class="alert-item">
            <span class="alert-text">
              {{errorMessage}}
            </span>
          </div>
        </clr-alert>
  
        <form #detailsForm="ngForm">
          <div *ngFor="let field of model.lotProperties; let i = index">
            <div class="form-group" *ngIf="field.propertyTypeId === 1 && model.lotProperties[i]">
              <label>{{ field.name | translateJson }}</label>
              <input type="number" name="{{'integerValue' + i }}" [(ngModel)]="model.lotProperties[i].intValue" />
            </div>
            <div class="form-group" *ngIf="field.propertyTypeId === 2 && model.lotProperties[i]">
              <label>{{ field.name | translateJson }}</label>
              <input type="number" name="{{'decimalValue' + i}}" [(ngModel)]="model.lotProperties[i].decimalValue" />
            </div>
            <div class="form-group" *ngIf="field.propertyTypeId === 3 && !field.translatable && model.lotProperties[i]">
              <label>{{ field.name | translateJson }}</label>
              <input type="text" name="{{'stringValue' + i}}" [(ngModel)]="model.lotProperties[i].stringValue" />
            </div>
            <div class="form-group" *ngIf="field.propertyTypeId === 3 && field.translatable && model.lotProperties[i]">
              <translatable-field [(data)]="model.lotProperties[i].stringValue" [caption]="getTranslation(field.name)" [componentIndex]="field.productPropertyId" [name]="'translatable' + i"></translatable-field>
            </div>
            <div class="form-group" *ngIf="field.propertyTypeId === 4 && field.propertyTypeFormatId === 11 && model.lotProperties[i]">
              <label>{{ field.name | translateJson }}</label>
              <full-date-time-component [data]="model.lotProperties[i].dateTimeValue" (modelChanged)="dateChanged(field, $event)"></full-date-time-component>
            </div>
            <div class="form-group" *ngIf="field.propertyTypeId === 4 && field.propertyTypeFormatId === 12 && model.lotProperties[i]">
              <label>{{ field.name | translateJson }}</label>
              <long-date-component [data]="model.lotProperties[i].dateTimeValue" (modelChanged)="dateChanged(field, $event)"></long-date-component>
            </div>
            <div class="form-group" *ngIf="field.propertyTypeId === 4 && field.propertyTypeFormatId === 13 && model.lotProperties[i]">
              <label>{{ field.name | translateJson }}</label>
              <long-time-component [data]="model.lotProperties[i].dateTimeValue" (modelChanged)="dateChanged(field, $event)"></long-time-component>
            </div>
            <div class="form-group" *ngIf="field.propertyTypeId === 4 && field.propertyTypeFormatId === 14 && model.lotProperties[i]">
              <label>{{ field.name | translateJson }}</label>
              <short-date-component [data]="model.lotProperties[i].dateTimeValue" (modelChanged)="dateChanged(field, $event)"></short-date-component>
            </div>
            <div class="form-group" *ngIf="field.propertyTypeId === 4 && field.propertyTypeFormatId === 15 && model.lotProperties[i]">
              <label>{{ field.name | translateJson }}</label>
              <short-time-component [data]="model.lotProperties[i].dateTimeValue" (modelChanged)="dateChanged(field, $event)"></short-time-component>
            </div>
            <div class="form-group" *ngIf="field.propertyTypeId === 5 && model.lotProperties[i]">
              <label>{{ field.name | translateJson }}</label>
              <div class="select">
                <select name="{{'product' + i}}" [(ngModel)]="model.lotProperties[i].masterDataListRowId">
                  <option [ngValue]="null"></option>
                  <option *ngFor="let p of getDropdownValues(field.productPropertyId)" [ngValue]="p.value">{{ p.label }}</option>
                </select>
              </div>
            </div>
            <div class="form-group" *ngIf="field.propertyTypeId === 7 && model.lotProperties[i]">
              <label>{{field.name | translateJson }}</label>
              <clr-checkbox
                name="{{'booleanValue' + i}}"
                [(ngModel)]="model.lotProperties[i].booleanValue"
               >
              </clr-checkbox>
            </div>
          </div>
          <div class="form-group">
            <label>{{ 'PREBID_ON_PRODUCT.AMOUNT_MIN_PERCENTAGE' | translate }}</label>
            <input type="number" name="prebidAmountMinPercentage" [(ngModel)]="model.prebidAmountMinPercentage" />%
          </div>
          <div class="form-group">
            <label>{{ 'PREBID_ON_PRODUCT.AMOUNT_MAX_PERCENTAGE' | translate }}</label>
            <input type="number" name="prebidAmountMaPercentage" [(ngModel)]="model.prebidAmountMaxPercentage" />%
          </div>
          <div class="form-group excluded-suppliers-group">
            <label>{{ 'PREBID_ON_PRODUCT.EXCLUDED_SUPPLIERS' | translate }}</label>
            <ng-select
              name="suppliers"
              [items]="suppliers"
              [multiple]="true"
              [closeOnSelect]="false"
              [searchable]="true"
              bindLabel="name"
              bindValue="supplierId"
              [(ngModel)]="model.suppliers">
            </ng-select>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid">{{ 'SHARED.SAVE' | translate }}</button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
      </div>
    </clr-modal>
  </div>
