import { Component } from '@angular/core';

// services
import { TitleService } from '../../shared/services/title.service';

@Component({
  selector: 'privacy-component',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
  constructor(
    private titleService: TitleService
  ) { 
    this.titleService.set('PRIVACY.TITLE');
  }
}
