import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { GenericProductPropertyComponent } from './generic-product-property.component';

// models
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { UrlTypeEnum } from '../../shared/enum/urlTypeEnum';
import { Language } from '../../shared/models/language';
import { MasterData, MasterDataListField } from '../../shared/models/master-data';
import { ProductProperty, ProductPropertyType, GridProperty, NonGroupingSelection } from '../../shared/models/product';

// services
import { LanguageService } from '../../shared/services/language.service';


@Component({
  selector: 'generic-product-properties-component',
  templateUrl: 'generic-product-properties.component.html'
})
export class GenericProductPropertiesComponent extends SimpleListComponent implements OnInit {

  editingIndex: number;
  masterData: Array<MasterData> = [];
  pageSize = 50;
  items: Array<GridProperty> = [];
  productProperties: Array<ProductProperty> = [];
  propertyTypes: Array<ProductPropertyType> = [];
  isSupplyData: boolean;
  isReport: boolean;
  auctionClusterId: number;
  isMasterDataFieldNeeded = true;
  isOrderNeeded = true;
  languages: Array<Language> = [];
  showName = true;
  propertyGroupingShowing = false;
  showOnlyAutomaticGrouping = false;
  showFilterOnCurrentLotProperty = false;
  showLastInFullScreenProperty = false;
  showIncludeInSummaryProperty = false;
  valueToShowInSummary = "";
  automaticProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  manualProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  showWidth = false;
  showColRow = false;
  showComparable = false;
  showRequired = false;
  allowAdd = true;
  propertyTypeFilter: ProductPropertyTypeEnum = null;
  urlFormatFilter: UrlTypeEnum = null;

  @Input('propertyGroupingShowing')
  set propertyGroupingShowingSetter(value: boolean) {
    this.propertyGroupingShowing = value;
  }

  @Input('showFilterOnCurrentLotProperty')
  set showFilterOnCurrentLotPropertySetter(value: boolean) {
    this.showFilterOnCurrentLotProperty = value;
  }

  @Input('showLastInFullScreenProperty')
  set showLastInFullScreenPropertySetter(value: boolean) {
    this.showLastInFullScreenProperty = value;
  }

  @Input('showOnlyAutomaticGrouping')
  set showOnlyAutomaticGroupingSetter(value: boolean) {
    this.showOnlyAutomaticGrouping = value;
  }

  @Input('showComparable')
  set showComparableSetter(value: boolean) {
    this.showComparable = value;
  }

  @Input('showRequired')
  set showRequiredSetter(value: boolean) {
    this.showRequired = value;
  }

  @Input('allowAdd')
  set allowAddSetter(value: boolean) {
    this.allowAdd = value;
  }

  @Input('showIncludeInSummaryProperty')
  set showIncludeInSummaryPropertySetter(value: boolean) {
    this.showIncludeInSummaryProperty = value;
  }

  @Input('showWidth')
  set showWidthSetter(value: boolean) {
    this.showWidth = value;
  }

  @Input('showColRow')
  set showColRowSetter(value: boolean) {
    this.showColRow = value;
  }

  @Input('data')
  set data(value: Array<GridProperty>) {
    this.items = value;
    this.items.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
    this.matchProductPropertiesNames();
    this.matchMasterDataFieldNames();
    this.matchTypeNames();
  }

  @Input('automaticProductPropertyNonGroupingSelections')
  set automaticProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.automaticProductPropertyNonGroupingSelections = value;
  }

  @Input('manualProductPropertyNonGroupingSelections')
  set manualProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.manualProductPropertyNonGroupingSelections = value;
  }

  @Input('productPropertyTypes')
  set propertyType(value: Array<ProductPropertyType>) {
    this.propertyTypes = value;
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('productProperties')
  set productProperty(value: Array<ProductProperty>) {
    this.productProperties = value;
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('masterData')
  set masterDataList(value: Array<MasterData>) {
    this.masterData = value;
    this.matchMasterDataFieldNames();
  }

  @Input('languages')
  set langs(value: Array<Language>) {
    this.languages = value;
  }

  @Input('isSupplyData')
  set supplyData(value: boolean) {
    this.isSupplyData = value;
  }

  @Input('isReport')
  set groupingFlag(value: boolean) {
    this.isReport = value;
  }

  @Input('isMasterDataFieldNeeded')
  set mdFieldNeeded(value: boolean) {
    this.isMasterDataFieldNeeded = value;
  }

  @Input('isOrderNeeded')
  set orderNeeded(value: boolean) {
    this.isOrderNeeded = value;
  }

  @Input('showName')
  set nameShown(value: boolean) {
    this.showName = value;
  }

  @Input('propertyTypeFilter')
  set propertyTypeFiltersValues(value: ProductPropertyTypeEnum) {
    this.propertyTypeFilter = value;
  }

  @Input('urlFormatFilter')
  set urlFormatFiltersValues(value: UrlTypeEnum) {
    this.urlFormatFilter = value;
  }

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onDataChanged = new EventEmitter<Array<GridProperty>>();

  @ViewChild(GenericProductPropertyComponent) detailsComponent: GenericProductPropertyComponent;

  constructor(
    protected injector: Injector,
    protected languageService: LanguageService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
  }

  ngOnInit() { // tslint:disable-line:no-empty
    this.setTranslations('PRODUCT');
  }

  edit(property: GridProperty, event: Event) {
    this.editingIndex = this.items.indexOf(property);
    event.stopPropagation();
    this.detailsComponent.modalTitle = this.translations.PROPERTY.EDIT;
    const clone = { ...property };
    this.detailsComponent.open(this.items, clone, this.productProperties, this.propertyTypes, this.masterData, this.languages);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.PROPERTY.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.productProperties, this.propertyTypes, this.masterData, this.languages);
  }

  onProductPropertyAdded(property: GridProperty) {
    this.items.push(property);
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
    // first sort items and give them numbers from 0
    this.setOrderNumbers();
    this.onDataChanged.emit(this.items);
  }

  onProductPropertyUpdated(property: GridProperty) {
    this.items[this.editingIndex] = property;
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
    // first sort items and give them numbers from 0
    this.setOrderNumbers();
    this.onDataChanged.emit(this.items);
  }

  setOrderNumbers() {
    this.items.sort((a, b) => a.orderNumber - b.orderNumber);
    this.items.forEach((item, i) => {
      item.orderNumber = i;
    });
  }

  moveUp(event: Event) {
    this.moveUpItems(event, this.items);
  }

  moveDown(event: Event) {
    this.moveDownItems(event, this.items);
  }

  delete(property: GridProperty) {
    this.items.splice(this.items.indexOf(property), 1);
    // first sort items and give them numbers from 0
    this.setOrderNumbers();
    this.onDataChanged.emit();
  }

  private matchTypeNames() {
    this.items.forEach(item => {
      const prop = this.productProperties.find(f => f.productPropertyId === item.productPropertyId);
      if (prop) {
        const propertyType = this.propertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (propertyType)
          item.productPropertyTypeName = propertyType.name;
      }
    });
  }

  private matchProductPropertiesNames() {
    this.items.forEach(property => {
      const prop = this.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyName = prop.name;
      }
    });
  }

  private translateProperties() {
    this.items.forEach(property => {
      property.productPropertyName = this.languageService.getTranslatableText(property.productPropertyName);
    });
  }

  matchMasterDataFieldNames() {
    this.items.forEach(item => {
      const prop = this.productProperties.find(pp => pp.productPropertyId === item.productPropertyId);
      if (prop && prop.masterDataListId) {
        let masterDataListFields: Array<MasterDataListField> = [];
        const result = this.masterData.find(md => md.masterDataListId === prop.masterDataListId);
        if (result) {
          masterDataListFields = result.fields;
          const masterDataListField = masterDataListFields.find(mdf => mdf.masterDataListFieldId === item.masterDataListFieldId);
          if (masterDataListField) {
            item.masterDataFieldName = masterDataListField.name;
          }
        } else {
          item.masterDataFieldName = ' - ';
        }
      } else {
        item.masterDataFieldName = ' - ';
      }
    });
  }
}
