import { Component, Injector, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { DxFormComponent } from 'devextreme-angular';

// components
import { EditUserNotificationChannelsComponent } from '../edit-user-notification-channels/edit-user-notification-channels.component';
import { ItemDetailsComponent } from '../item-details/item-details.component';

// models
import { Language } from '../../models/language';
import { User } from '../../models/user';
import { UserNotification } from '../../models/user-notification';

// services
import { LanguageService } from '../../services/language.service';
import { NotificationManagerService } from '../../services/notification-manager.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'edit-user-notifications-component',
  templateUrl: './edit-user-notifications.component.html',
  styleUrls: ['./edit-user-notifications.component.scss']
})
export class EditUserNotificationsComponent extends ItemDetailsComponent<User> implements OnInit, OnDestroy {

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  languages: Array<Language> = [];
  formDirty: boolean = false;
  notifications: Array<UserNotification> = [];
  auctionClusterColumnVisible = false;

  @ViewChild('editUserNotificationsForm', { read: DxFormComponent}) editUserNotificationsForm: DxFormComponent;
  @ViewChild(EditUserNotificationChannelsComponent) notificationChannelsComponent: EditUserNotificationChannelsComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private notificationManagerService: NotificationManagerService,
    private userService: UserService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new User();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public onCancel() {
    this.isOpened = false;
    this.errorMessage = null;
  }
  
  open(userId: number, languages: Array<Language>) {
    this.isOpened = true;
    this.languages = languages;
    this.getData(userId);
    this.spinner.hide();
    this.formDirty = false;
  }
  
  save() {
    this.spinner.show();
    
    this.spinner.hide();
  }

  getData(userId: number) {
    this.spinner.show();
    forkJoin(
      this.userService.getUser(),
      this.notificationManagerService.getUserNotifications(userId)
    ).subscribe(result => {
      this.model = result[0];
      this.notifications = result[1];
      this.auctionClusterColumnVisible = this.calculateAuctionClusterDisplayValue();
      this.notifications.forEach(element => {
          element.auctionClusterNotification.nameText = this.languageService.getTranslatableText(element.auctionClusterNotification.name);
      });
      this.isOpened = true;
      this.spinner.hide();
      }, 
      error => {
        this.errorService.show(error);
        this.spinner.hide();
    });
  }

  editUserNotification = (e: any) => {
    const userNotification: UserNotification = e.row !== undefined ? e.row.data : e.data;

    if(e.column.dataType === "boolean") {
      this.enableDisableNotification(userNotification);
    } else { 
      this.notificationChannelsComponent.open(userNotification, this.languages);
    }
  }
  
  onFieldDataChanged(e) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup'){
      if (!this.formDirty) this.formDirty = true;
    }
  }

  calculateAuctionClusterDisplayValue() {
    let uniqueAuctionClusterId;
    for (var element of this.notifications) {
      if(uniqueAuctionClusterId == null) {
        uniqueAuctionClusterId = element.auctionClusterNotification.auctionClusterId;
      }
      else {
        if(uniqueAuctionClusterId != element.auctionClusterNotification.auctionClusterId) {
          return true;
        }
      }
    }
    return false;
  }

  enableDisableNotification = (userNotification: UserNotification) => {
        this.notificationManagerService.enableDisableUserNotification(userNotification, !userNotification.isActive).subscribe(() => {
      this.getData(this.model.userId);
        }, error => {
          this.errorService.show(error);
    });
  }
}
