import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { PlatformRole } from '../models/platform-role';

// services
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class PlatformRoleService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'platformrole';
  }

  getPlatformRoles(langCode: string): Observable<Array<PlatformRole>> {
    return this.webApiService.getList(this.apiPath + `?langCode=${langCode}`);
  }

  getPlatformRole(platformRoleId: number): Observable<PlatformRole> {
    return this.webApiService.get(this.apiPath, platformRoleId);
  }

  save(platformRole: PlatformRole): Observable<any> {
    return this.webApiService.save<PlatformRole>(this.apiPath, platformRole);
  }

  edit(platformRole: PlatformRole): Observable<any> {
    return this.webApiService.edit<PlatformRole>(this.apiPath, platformRole.platformRoleId, platformRole);
  }

  delete(platformRoleId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, platformRoleId);
  }

  checkIfRoleHasUsers(roleId: number): Observable<Array<number>> {
    return this.webApiService.getList(this.apiPath + '/' + roleId + '/checkusers');
  }
}
