import { AuctionClusterNotification } from "./auction-cluster-notification";
import { UserNotificationChannel } from "./user-notification-channel";

export class UserNotification {
    userNotificationId: number;
    userId: number;
    auctionClusterNotificationId: number;
    isActive: boolean;
    
    auctionClusterNotification: AuctionClusterNotification;
    userNotificationChannels: Array<UserNotificationChannel>;

    channelEmail: UserNotificationChannel;
    channelSMS: UserNotificationChannel;
    channelPush: UserNotificationChannel;
}