import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { Cookies } from '../../shared/constants/cookies';
import { Language } from '../../shared/models/language';

// services
import { AuthService } from '../../shared/services/auth.service';
import { ErrorService } from '../../shared/services/error.service';
import { LanguageService } from '../../shared/services/language.service';
import { TitleService } from '../../shared/services/title.service';

@Component({
  selector: 'login-header-component',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss']
})
export class LoginHeaderComponent implements OnInit {

  changeLanguageDialogOpened: boolean;
  currentLanguage: Language = new Language();
  appModeDialogOpened: boolean;
  languages: Array<Language> = [];
  
  route: ActivatedRoute;

  constructor(
    private cookies: CookieService,
    protected injector: Injector,
    private appSettings: ApplicationSettings,
    private router: Router,
    private authenticationService: AuthService,
    private translate: TranslateService,
    public title: TitleService,
    private languageService: LanguageService,
    private errorService: ErrorService
  ) { 
    this.route = this.injector.get(ActivatedRoute);
  }

  ngOnInit() {
    this.changeLanguageDialogOpened = false;
    this.appModeDialogOpened = false;
    this.title.set('LOG_IN.TITLE');

    this.languageService.getLanguages().subscribe(res => {
      this.languages = res;

      let lastSelectedLanguageCode = localStorage.getItem('last-selected-language-code');
      if ((lastSelectedLanguageCode === undefined || lastSelectedLanguageCode === null))
        lastSelectedLanguageCode = this.cookies.get(Cookies.LANGUAGE_COOKIE);

      if (lastSelectedLanguageCode !== '') {
        // Set current language from cookie
        this.currentLanguage = this.languages.find(lang => lang.code === lastSelectedLanguageCode);
        if (!this.currentLanguage) {
          // Set default language if language isn't found
          this.currentLanguage = this.languages.find(lang => lang.isDefault);
        }
      }
      else {
        // Set default language if cookie isn't set
        this.currentLanguage = this.languages.find(lang => lang.isDefault);
      }

      this.setLanguage(this.currentLanguage.code);
    },
      error => this.errorService.show(error));

    let presetPrinter =  +this.route.snapshot.queryParams['computerId'];
    if(!isNaN(presetPrinter))
     {
      this.setPrinter(presetPrinter);
     }   
  }

  public login() {
    this.authenticationService.goToStartPage('/core/login');
  }

  public register() {
    this.router.navigate(['/core/register'], { queryParamsHandling: 'merge' });
  }

  setLanguage(lang: string) {
    this.currentLanguage = this.languages.find(l => l.code === lang);
    this.translate.use(lang);
    this.cookies.set(Cookies.LANGUAGE_COOKIE, lang);
    localStorage.setItem('last-selected-language-code', lang);
    this.changeLanguageDialogOpened = false;
  }

  setPrinter(printer: any) {
    // Save computerId in cookie for 1y
    this.cookies.set(Cookies.COMPUTER_COOKIE, printer, 365);
  }
}
