<div class="lot-multi-edit-component" [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup width="90%" [showTitle]="true" title="{{ 'SHARED.MULTI_EDIT' | translate }}" [dragEnabled]="false"
            [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <div class="wrapper">
          <fieldset class="fields-wrapper">
            <legend class="fields-wrapper-title">{{ 'LOTS.FILTER_ON' | translate }}</legend>
            <div *ngFor="let property of editableLot.lotProperties; let i = index">
              <div *ngIf="property.propertyTypeId === 1">
                <div class="form-group">
                  <span class="cb-wrapper">
                    <dx-check-box [(value)]="property.selected"
                                  name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                  (onValueChanged)="clearGrid()">
                    </dx-check-box>
                  </span>
                  <label>{{ getTranslation(property.name) }}</label>
                  <span class="original-value">
                    {{ property.displayValue }}
                  </span>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 7">
                <div class="form-group">
                  <span class="cb-wrapper">
                    <dx-check-box [(value)]="property.selected"
                                  name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                  (onValueChanged)="clearGrid()">
                    </dx-check-box>
                  </span>
                  <label>{{ getTranslation(property.name) }}</label>
                  <span class="original-value">
                    {{ property.displayValue }}
                  </span>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 2">
                <div class="form-group">
                  <span class="cb-wrapper">
                    <dx-check-box [(value)]="property.selected"
                                  name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                  (onValueChanged)="clearGrid()">
                    </dx-check-box>
                  </span>
                  <label>{{ getTranslation(property.name) }}</label>
                  <span class="original-value">
                    {{ property.displayValue }}
                  </span>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 3 && !property.translatable">
                <div class="form-group">
                  <span class="cb-wrapper">
                    <dx-check-box [(value)]="property.selected"
                                  name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                  (onValueChanged)="clearGrid()">
                    </dx-check-box>
                  </span>
                  <label>{{ getTranslation(property.name) }}</label>
                  <span class="original-value" [innerHTML]="property.displayValue | safeHtml"></span>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 3 && property.translatable" class="form-group translatable-wrapper">
                <span class="cb-wrapper">
                  <dx-check-box [(value)]="property.selected"
                                name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                (onValueChanged)="clearGrid()">
                  </dx-check-box>
                </span>
              </div>
              <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 11">
                <div class="form-group">
                  <span class="cb-wrapper">
                    <dx-check-box [(value)]="property.selected"
                                  name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                  (onValueChanged)="clearGrid()">
                    </dx-check-box>
                  </span>
                  <label>{{ getTranslation(property.name) }}</label>
                  <span class="original-value">
                    {{ property.displayValue }}
                  </span>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 12">
                <div class="form-group">
                  <span class="cb-wrapper">
                    <dx-check-box [(value)]="property.selected"
                                  name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                  (onValueChanged)="clearGrid()">
                    </dx-check-box>
                  </span>
                  <label>{{ getTranslation(property.name) }}</label>
                  <span class="original-value">
                    {{ property.displayValue }}
                  </span>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 13">
                <div class="form-group">
                  <span class="cb-wrapper">
                    <dx-check-box [(value)]="property.selected"
                                  name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                  (onValueChanged)="clearGrid()">
                    </dx-check-box>
                  </span>
                  <label>{{ getTranslation(property.name) }}</label>
                  <span class="original-value">
                    {{ property.displayValue }}
                  </span>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 14">
                <div class="form-group">
                  <span class="cb-wrapper">
                    <dx-check-box [(value)]="property.selected"
                                  name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                  (onValueChanged)="clearGrid()">
                    </dx-check-box>
                  </span>
                  <label>{{ getTranslation(property.name) }}</label>
                  <span class="original-value">
                    {{ property.displayValue }}
                  </span>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 15">
                <div class="form-group">
                  <span class="cb-wrapper">
                    <dx-check-box [(value)]="property.selected"
                                  name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                  (onValueChanged)="clearGrid()">
                    </dx-check-box>
                  </span>
                  <label>{{ getTranslation(property.name) }}</label>
                  <span class="original-value">
                    {{ property.displayValue }}
                  </span>
                </div>
              </div>
              <div class="form-group master-validation" *ngIf="property.propertyTypeId === 5">
                <span class="cb-wrapper">
                  <dx-check-box [(value)]="property.selected"
                                name="{{'selectedLotProperty' + property.productPropertyId}}" [rtlEnabled]="rtlEnabled"
                                (onValueChanged)="clearGrid()">
                  </dx-check-box>
                </span>
                <label>{{ getTranslation(property.name) }}</label>
                <span class="original-value" [innerHTML]="property.displayValue | safeHtml"></span>
              </div>
            </div>
          </fieldset>
          <fieldset class="fields-wrapper">
            <legend class="fields-wrapper-title">{{ 'LOTS.CHANGE_TO' | translate }}</legend>
            <div *ngFor="let property of editableLot.lotProperties; let i = index">
              <div *ngIf="property.propertyTypeId === 1">
                <div class="form-group">
                  <label for="numberValueProp" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                    <dx-number-box name="{{'numberValueProp' + groupIndex + rowIndex + i}}"
                                   [(value)]="property.intValue"
                                   [showSpinButtons]="true"
                                   format="#0"
                                   [step]="1"
                                   [min]="0"
                                   [rtlEnabled]="rtlEnabled"
                                   (onValueChanged)="checkIfSaveEnabled()"
                                   #numberValueProp>
                    </dx-number-box>
                  </label>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 7">
                <div class="form-group">
                  <dx-check-box [(value)]="property.booleanValue"
                                name="{{'booleanValueProp' + groupIndex + rowIndex + i}}"
                                (onValueChanged)="checkIfSaveEnabled()"
                                [rtlEnabled]="rtlEnabled">
                  </dx-check-box>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 2">
                <div class="form-group">
                  <label for="decimalValueProp" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                    <dx-number-box name="{{'decimalValueProp' + groupIndex + rowIndex + i}}"
                                   [(value)]="property.decimalValue"
                                   [showSpinButtons]="true"
                                   format="#0.##"
                                   [step]="1"
                                   [min]="0"
                                   [rtlEnabled]="rtlEnabled"
                                   (onValueChanged)="checkIfSaveEnabled()"
                                   #numberValueProp>
                    </dx-number-box>
                  </label>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 3 && !property.translatable">
                <div class="form-group">
                  <label for="stringValueProp" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                    <dx-text-box [(value)]="property.stringValue"
                                 name="{{'stringValueProp' + groupIndex + rowIndex + i}}"
                                 (onValueChanged)="checkIfSaveEnabled()">
                    </dx-text-box>
                  </label>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 3 && property.translatable" class="form-group translatable-wrapper">
                <translatable-field [(data)]="property.stringValue"
                                    [caption]="getTranslation(property.name)"
                                    [componentIndex]="property.productPropertyId"
                                    [name]="'translatable' + groupIndex + rowIndex + i"
                                    [isFocusable]="true"
                                    [insideLotEditor]="true"
                                    [clusterLanguages]="languages">
                </translatable-field>
              </div>
              <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 11">
                <div class="form-group">
                  <label for="dateValueProp" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                    <date-time-component ngDefaultControl class="dt-input"
                                         [name]="'dateValueProp' + groupIndex + rowIndex + i"
                                         [(data)]="property.dateTimeValue"
                                         (modelChanged)="dateChanged(property, $event);checkIfSaveEnabled();"
                                         #dateValueProp>
                    </date-time-component>
                  </label>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 12">
                <div class="form-group">
                  <label for="dateValueProp" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                    <date-component ngDefaultControl
                                    class="dt-input"
                                    [name]="'dateValueProp' + groupIndex + rowIndex + i"
                                    [(data)]="property.dateTimeValue"
                                    (modelChanged)="dateChanged(property, $event);checkIfSaveEnabled();"
                                    #dateValueProp
                                    [min]="property.systemProductPropertyType === 12 ? currentDate: null">
                    </date-component>
                  </label>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 13">
                <div class="form-group">
                  <label for="dateValueProp" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                    <time-component ngDefaultControl class="dt-input"
                                    [name]="'dateValueProp' + groupIndex + rowIndex + i"
                                    [(data)]="property.dateTimeValue"
                                    (modelChanged)="dateChanged(property, $event);checkIfSaveEnabled();"
                                    #dateValueProp>
                    </time-component>
                  </label>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 14">
                <div class="form-group">
                  <label for="dateValueProp" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                    <date-component ngDefaultControl
                                    class="dt-input"
                                    [name]="'dateValueProp' + groupIndex + rowIndex + i"
                                    [(data)]="property.dateTimeValue"
                                    (modelChanged)="dateChanged(property, $event);checkIfSaveEnabled();"
                                    #dateValueProp
                                    [min]="property.systemProductPropertyType === 12 ? currentDate: null">
                    </date-component>
                  </label>
                </div>
              </div>
              <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 15">
                <div class="form-group">
                  <label for="dateValueProp" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                    <time-component ngDefaultControl class="dt-input"
                                    [name]="'dateValueProp' + groupIndex + rowIndex + i"
                                    [(data)]="property.dateTimeValue"
                                    (modelChanged)="dateChanged(property, $event);checkIfSaveEnabled();"
                                    #dateValueProp>
                    </time-component>
                  </label>
                </div>
              </div>
              <div class="form-group master-validation" *ngIf="property.propertyTypeId === 5">
                <label for="masterDataProp" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm dd-label">
                    <masterdata-dropdown name="{{ 'masterDataProp' + groupIndex + rowIndex + i }}"
                                         #masterDataProp
                                         [auctionClusterId]="product.auctionClusterId"
                                         [listId]="property.masterDataListId"
                                         [(selectedRowId)]="property.masterDataListRowId"
                                         (onValueChanged)="checkIfSaveEnabled()">
                      </masterdata-dropdown>
                </label>
              </div>
            </div>
          </fieldset>
          <dx-data-grid #lotMultiEditGrid id="printSection" class="data-grid-wrapper"
                        [rtlEnabled]="rtlEnabled"
                        [showBorders]="true"
                        (onInitialized)="initColumn($event)"
                        [allowColumnReordering]="true"
                        [hoverStateEnabled]="true"
                        [columnAutoWidth]="true"
                        [rowAlternationEnabled]="true"
                        [(selectedRowKeys)]="selectedRows"
                        (onSelectionChanged)="selectionChangedHandler($event)">
            <dxo-selection selectAllMode="page" showCheckBoxesMode="always" mode="multiple"></dxo-selection>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                       [showNavigationButtons]="true" [showInfo]="true"
                       infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
            <dxi-column [width]="0"></dxi-column>
          </dx-data-grid>
          <br />
          <div class="footer-buttons">
          <button type="button" class="btn btn-outline align-left" [disabled]="!runEnabled" (click)="selectedPropertiesChanged();">{{ 'SHARED.RUN' | translate }}</button>
          <button type="button" class="btn btn-outline align-right" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
            <button type="submit" class="btn btn-primary align-right" [disabled]="!saveEnabled" (click)="onMultiEdit();">{{ 'LOTS.EDIT_SELECTED_LOTS' | translate: {numberOfLots: itemsToChange} }}</button>
          </div>
        </div>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
