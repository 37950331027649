import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Catalog } from '../../../shared/models/catalog';
import { Clock } from '../../../shared/models/clock';
import { TransactionMonitorLot } from '../../../shared/models/transaction-monitor-lot';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class AuctionTransactionMonitorService {
  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getLots( auctionClusterId: number, catalogId: number, date: string, clockId?: number): Observable<TransactionMonitorLot> {
    let url = `${this.apiPath}/${auctionClusterId}/catalog/${catalogId}/date/${date}/transactionmonitor`;
    if (clockId && clockId !== 0) {
      url += `?clockId=${clockId}`;
    }
    return this.webApiService.getList(url);
  }

  getLotsForTransactionId(auctionClusterId: number, transactionId: number): Observable<any> {
    return this.webApiService.getList(`${this.apiPath}/${auctionClusterId}/transaction/${transactionId}/lots`);
  }

  getCatalogsForPermissions(auctionClusterId: number, permissionIds: Array<number>): Observable<Array<Catalog>> {
    return this.webApiService.save(this.apiPath + '/' + auctionClusterId + '/auction/catalog/permission', { 'ids': permissionIds });
  }

  getClocksByCatalog(auctionClusterId: number, catalogId: number, permissionIds: Array<number>): Observable<Array<Clock>> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/auction/catalog/${catalogId}/clock`, { 'ids': permissionIds });
  }

  printTransactions(auctionClusterId: number, catalogId: number, transactionIds: Array<number>): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/catalog/${catalogId}/printtransactiontickets`, { 'ids': transactionIds });
  }

  askForConfirmation(auctionClusterId: number, transactionId: number): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/transaction/${transactionId}/askforconfirmation`, {});
  }

  rejectTransaction(auctionClusterId: number, transactionId: number): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/transaction/${transactionId}/reject`, {});
  }

  cancelConfirmation(auctionClusterId: number, transactionId: number): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/transaction/${transactionId}/askforconfirmation/cancel`, {});
  }

  updateTransactionPrice(auctionClusterId: number, transactionId: number, price: number, askForConfirmation: boolean): Observable<any> {
    var url = `${this.apiPath}/${auctionClusterId}/transaction/${transactionId}/updateprice`;
    return this.webApiService.save(url, { 'Price': price, 'AskForConfirmation': askForConfirmation });
  }

  resendTransactions(auctionClusterId: number, transactionIds: Array<number>): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/transaction/resend`, transactionIds);
  }
}
