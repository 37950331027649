import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { ProductBuyButtonComponent } from './product-buy-button.component';

// models
import { ProductBuyButton } from '../../shared/models/product';

@Component({
  selector: 'product-buy-buttons-component',
  templateUrl: 'product-buy-buttons.component.html'
})

export class ProductBuyButtonsComponent extends SimpleListComponent implements OnInit {

  items: Array<ProductBuyButton> = [];
  auctionClusterId: number;
  editingIndex: number;
  productId: number;
  
  @Input('productBuyButtons') 
  set data(value: Array<ProductBuyButton>) {
    this.items = value;
    this.items.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
  }

  @Output() dataChanged = new EventEmitter<boolean>();

  @ViewChild(ProductBuyButtonComponent) detailsComponent: ProductBuyButtonComponent;

  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.setTranslations('PRODUCT.BUY_BUTTON');
    this.productId = +this.route.snapshot.params['productId'];    
  }

  edit(buyButton: ProductBuyButton, event: Event) {
    this.editingIndex = this.items.indexOf(buyButton);
    event.stopPropagation();
    this.detailsComponent.modalTitle = this.translations.EDIT;
    const clone = { ...buyButton };
    this.detailsComponent.open(this.items.filter(item => item !== buyButton), clone);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, new ProductBuyButton());
  }

  delete(buyButton: ProductBuyButton, event: Event) {
    this.items.splice(this.items.indexOf(buyButton), 1);
    this.setOrderNumbers();
    this.dataChanged.emit();
  }

  onBuyButtonAdded(buyButton: ProductBuyButton) {
    this.items.push(buyButton);
    this.setOrderNumbers();
    this.dataChanged.emit();
  }

  onBuyButtonUpdated(buyButton: ProductBuyButton) {
    this.items[this.editingIndex] = buyButton;
    this.setOrderNumbers();
    this.dataChanged.emit();
  }

  moveUp(event: Event) {
    this.moveUpItems(event, this.items);
  }

  moveDown(event: Event) {
    this.moveDownItems(event, this.items);
  }

  setOrderNumbers() {
    this.items.sort((a, b) => a.orderNumber - b.orderNumber);
    this.items.forEach((item, i) => {
      item.orderNumber = i;
    });
  }
}