import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

// services
import { LanguageService } from '../../shared/services/language.service';


@Component({
  selector: 'name-confirmation-component',
  templateUrl: 'name-confirmation.component.html',
  providers: []
})
export class NameConfirmationComponent {

  @Input('title') title: string;
  @Input('infoText') infoText: string;
  @Input('message') message: string;
  @Input('buttonCloseText') buttonCloseText = 'SHARED.CLOSE';
  @Input('cancellable') cancellable: boolean;

  @Output() close = new EventEmitter<string>();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor (
    private languageService: LanguageService
  ) {
      this._subscription = this.languageService.direction.subscribe(dir => {
        this.rtlEnabled = dir;
      });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  opened: boolean;
  name: string = '';
}
