export class ExportConstants {

    // Unicode character "Zero width no break space" for header in report exporting
    readonly BLOB_HEADER = '\uFEFF';

    // Type of encoding for exported reports
    readonly BLOB_TYPE = 'text/csv;charset=utf-8';
    readonly FILE_EXTENSION_CSV = 'csv';

    // format for exporting data
    readonly CSV_DATE_FORMAT = 'DD.MM.YYYY';
    readonly CSV_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';
}

export const CSV_DATE_FORMAT = 'DD.MM.YYYY';
