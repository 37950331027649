<dx-date-box
  type="date"
  displayFormat="shortdate"
  #dateInput
  [attr.name]="nameString"
  [attr.tabindex]="tabIndexValue"
  placeholder="Short Date"
  class="focusable"
  [(value)]="time"
  (ngModelChange)="changeModelState()"
  (blur)="onblurEvent()"
  [required]="requiredValue"
  [readonly]="disabledValue"
  [min]="minValue"
  openOnFieldClick="true"
  (onFocusOut)="onblurEvent()"
  (onFocusIn)="onFocusIn()"
  (onClosed)="pickerClosed()"
  (onInitialized)="onInitialized.emit($event)"
  [disabled]="disabledValue">
</dx-date-box>

