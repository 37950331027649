import { PresalesModeEnum } from "./catalog";

export class GenericLot {
  columnTitles: Array<GenericLotColumn>;
  rows: Array<GenericLotRow>;
}

export class GenericLots {
  lots: GenericLot;
  prebid: BuyerActionOnLotGroup;
}

export class GenericBuybackLot {
  lots: GenericLot;
  buybacks: Array<BuybackOnLot>;
}

export class GenericLotColumn {
  name: string;
  propertyTypeId: number;
  propertyTypeFormatId: number;
}

export class GenericLotRow {
  id: number;
  groupedIds: Array<number>;
  values: Array<string>;
  hasMark: boolean;
  prebid: BuyerActionOnLotGroup;
  isInManualGroup: boolean;
  selected = false;
  isImported: boolean;
  amount: number;
  amountInBox: number;
  originalAmount: number;
  maxAmount: number;

  // Display helper props
  price: number;
  subNumber: number;
  canRemove: boolean;
  allOrNothing: boolean;
  showPrice: boolean;
}

export class BuybackOnLot {
  buybackOnLotId: number;
  lotId: number
  buyerId: number;
  subbuyerId: number;
  subNumber: number;
  price: number;
  presalesMode: PresalesModeEnum;
  amountWanted: number;
  catalogId: number;
  creditLimitExceeded: boolean;
  index: number;
}

export class BuybackOnLotGroup {
  lotId: number;
  buybacks: Array<BuybackOnLot> = [];
}

export class BuyerActionOnLotGroup {
  buyerId: number;
  subbuyerId: number;
  subNumber: number;
  price: number;
  amountWanted: number;
  catalogId: number;
  groupedIds: Array<number>;
  allOrNothing: boolean;
  maxAmount: number;
  buyerActionOnLot: Array<BuyerActionOnLot> = [];

  allowPartialLotPrebid: boolean;
}

export class BuyerActionOnLot {
  buyerActionOnLotId: number;
  lotId: number;
  subbuyerId: number;
  subNumber: number;
  price: number;
  amountWanted: number;
  allOrNothing: boolean;
  maxAmount: number;
}
