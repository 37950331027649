import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// components
import { ItemListComponent } from '../../shared/components/item-list/item-list.component';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { UserType } from '../../shared/models/clock';
import { UserPermissions, BuyerPermissionEnum } from '../../shared/models/user-permissions';

import { BuyerLevelClocks, ClockOpenType } from '../shared/models/buyer-level-clocks';
import { BuyerProduct } from '../shared/models/buyer-product';

// services
import { TranslateService } from '@ngx-translate/core';
import { ServiceFactory } from '../../shared/factory/service-factory';
import { LanguageService } from '../../shared/services/language.service';
import { ProductService } from '../../buyer/shared/services/product.service';
import { TitleService } from '../../shared/services/title.service';
import { TokenService } from '../../shared/services/token.service';
import { WebApiService } from '../../shared/services/web-api.service';

@Component({
  selector: 'buyer-clocks.component',
  templateUrl: 'buyer-clocks.component.html',
  styleUrls: ['./buyer-clocks.component.scss']
})

export class BuyerClocksComponent extends ItemListComponent<BuyerLevelClocks> implements OnInit, OnDestroy {

  productService: ProductService;

  products: Array<BuyerProduct> = [];
  permissions: UserPermissions;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private titleService: TitleService,
    private languageService: LanguageService,
    private tokenService: TokenService,
    private translateService: TranslateService,
  ) {
    super(injector, BuyerLevelClocks);
    this.getServices(route, appSettings, webApiService);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.titleService.set('BUYER.BUYER_CLOCK_OVERVIEW');
    this.setTranslations('BUYER');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.productService = ServiceFactory.getProductService(document.location.hash, route, appSettings, webApiService) as ProductService;
  }

  isBuyOnClock() {
    return this.getPermission(BuyerPermissionEnum.BuyOnClock);
  }

  isViewer() {
    return this.getPermission(BuyerPermissionEnum.ViewSales);
  }

  isProjection() {
    return this.getPermission(BuyerPermissionEnum.Projection);
  }

  getPermission(permission: BuyerPermissionEnum) {
    if (!this.permissions) {
      this.permissions = this.tokenService.getCurrentUserPermissions();
    }
    return this.permissions.BuyerPermissions.find(p => p.Key === this.id && p.Value.some(value => typeof (value) === 'number' && value === permission));
  }

  getData() {
    this.spinner.show();

    this.productService.getProductsClocks(this.translateService.currentLang).subscribe(products => {
      this.products = products;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.errorService.show(error);
    });
  }

  startClock(buyerId: number, productId: number, event: any, target: ClockOpenType) {
    // event.stopPropagation();

    const product = this.products.find(p => p.productId === productId);

    let readOnlyFlag = false;
    let projectionFlag = false;
    let priceOverviewFlag = false;

    if (target === ClockOpenType.Any) {

      if (this.isBuyOnClock())
      {
        //buyer view is default
      }
      else if (this.isViewer()) {
        readOnlyFlag = true;
      }
      else if (this.isProjection()) {
        projectionFlag = true;
      }
    }
    else if (target === ClockOpenType.Buy) {
      if (!this.isBuyOnClock()) {
        return;
      }
    }
    else if (target === ClockOpenType.Projection) {
      if (this.isProjection()) {
        projectionFlag = true;
      }
      else {
        return;
      }
    }
    else if (target === ClockOpenType.View) {
      readOnlyFlag = true;
    }
    else if (target === ClockOpenType.PriceOverview) {

      priceOverviewFlag = true;
    }

    if (product) {
      let clocksParams = '';
      product.clocks.forEach(c => {
        clocksParams += `clockId=${c.clockId}&`;
      });
      const lang = this.translate.currentLang;
      const audioParameters = this.getAudioParameters(product.clocks[0]);
      let url = `${this.appSettings.clockURL}?buyerId=${buyerId}&${clocksParams}lang=${lang}&viewType=${UserType.BUYER}&useWebSocketsOnly=${product.clocks[0].useWebSocketOnly}${audioParameters}`; // tslint:disable-line:max-line-length
      if (readOnlyFlag) {
        url += '&readOnly=1';
      }
      else if (projectionFlag) {
        url += '&isAuctionRoom=1';
      }
      else if (priceOverviewFlag) {
        url += '&showingWidgets=256';
      }

      window.open(url, '_blank');
    }
  }

  getAudioParameters = (data: any) => {
    return `&playerBufferSize=${data.playerBufferSize}&recorderBufferSize=${data.recorderBufferSize}&sampleRate=${data.sampleRate}&opusSamplingRate=${data.opusSamplingRate}&frameDuration=${data.frameDuration}`;
  }

  translateField = (item: any) => {
    if (item && this.isJson(item.value)) {
      const label = this.getTranslation(item.value);
      return label;
    } else {
      return item.value;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  isJson(value: any) {
    value = typeof value !== 'string' ? JSON.stringify(value) : value;

    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }
    if (typeof value === 'object' && value !== null) {
      return true;
    }
    return false;
  }

  createDataSchema(): void { } // tslint:disable-line:no-empty

  onRowClick = (e: any) => {
    if (this.isProjection()) {
      this.startClock(this.id, e.data.productId, e.data, 3);
    }
    else if (this.isBuyOnClock()) {
      this.startClock(this.id, e.data.productId, e.data, 1);
    }
  }
}
