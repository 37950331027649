<div class="lot-multi-edit-component" [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-tag-box name="{{ name }}"
                 [dataSource]="dataSource"
                 acceptCustomValue="false"
                 valueExpr="value"
                 searchTimeout="200"
                 #masterDataTagbox
                 [(value)]="selectedRowIds"
                 displayExpr="label"
                 searchMode="Contains"
                 [searchEnabled]="true"
                 [showClearButton]="true"
                 (onFocusOut)="focusOut($event);"
                 (onFocusIn)="focusIn($event)"
                 [tabIndex]="tabIndex"
                 (onValueChanged)="valueChanged($event)"
                 (onClear)="selectedRowIds = []"
                 placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                 class="filter-lot-select focusable">
    <dx-validator *ngIf="required">
      <dxi-validation-rule type="required" message=""></dxi-validation-rule>
    </dx-validator>
  </dx-tag-box>
  <span class="tooltip master-data-tooltip" *ngIf="(masterDataTagbox.touched || masterDataTagbox.dirty) && masterDataTagbox.invalid">
    <clr-tooltip>
      <clr-icon shape="exclamation-circle" class="is-error" size="22"></clr-icon>
    </clr-tooltip>
  </span>
 </div>
