import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import DataGrid from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import * as _moment from 'moment';

// components
import { DxFormComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { DateTimeTypeEnum } from '../../shared/enum/dateTimeTypeEnum';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { UrlTypeEnum } from '../../shared/enum/urlTypeEnum';
import { TransactionMonitorLotColumn, TransactionMonitorLotRow } from '../../shared/models/transaction-monitor-lot';

// services
import { TranslateService } from '@ngx-translate/core';
import { AuctionTransactionMonitorService } from '../shared/services/auction-transaction-monitor.service';


@Component({
  selector: 'auction-transaction-detail-component',
  templateUrl: 'auction-transaction-detail.component.html',
  styleUrls: ['./auction-transaction-detail.component.scss']
})
export class AuctionTransactionDetailComponent extends ItemDetailsComponent<TransactionMonitorLotRow> implements OnInit, OnDestroy {
  auctionClusterId: number;
  originalTransactionRow: any;
  lotData: any;
  transactionId: number;
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  gridInstance: DataGrid;
  originalTransactionPrice: number;
  formdata: any = {
    transactionPrice: 0
  };
  @ViewChild('editForm') form: DxFormComponent;

  isOpened: boolean = false;
  popUpShowed: boolean = false;
  popUpOpened: boolean = false;
  imageLoaded: boolean = false;
  largeImageSrc: string = '';
  imageCaption: string = '';
  imagePopUpShown: boolean = false;

  constructor(
    protected injector: Injector,
    private transactionMonitorService: AuctionTransactionMonitorService,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    super(injector);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('TRANSACTION_MONITOR');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  save = async () => {
    try {
      this.spinner.show();
      console.log(this.form);
      let validationResult = this.form.instance.validate();
      if (!validationResult.isValid) {
        return;
      }
      if (this.formdata.transactionPrice != this.originalTransactionPrice) {
        await this.transactionMonitorService.updateTransactionPrice(this.auctionClusterId, this.transactionId, this.formdata.transactionPrice, false).toPromise();
      }
      this.close(true);
    }
    catch (error) {
      console.log(error);
      this.errorService.show(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  async open(auctionClusterId: number, transactionId: number) {
    this.spinner.show();
    this.auctionClusterId = auctionClusterId;
    this.transactionId = transactionId;
    try {
      // retrieve and show transactionlots
      this.lotData = await this.transactionMonitorService.getLotsForTransactionId(this.auctionClusterId, transactionId).toPromise();
      this.generateTable(this.lotData, this.gridInstance, this.parseObject(this.lotData.rows, this.parseColumns(this.lotData.columnTitles)));
      if (this.lotData.rows.length > 0) {
        this.originalTransactionPrice = this.lotData.rows[0].price;
        this.formdata.transactionPrice = this.originalTransactionPrice;
      }
      this.isOpened = true;
    }
    catch (error) {
      console.log(error);
      this.errorService.show(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  initLotColumn = (e: any) => {
    this.gridInstance = e.component;
  }

  private parseColumns(columns: Array<TransactionMonitorLotColumn>): any[] {
    var _columns = [];

    // Additional columns for displaying transaction lot ids
    _columns.push({
      name: this.getTranslation(this.translations.IDS),
      propertyTypeId: ProductPropertyTypeEnum.TEXT,
      propertyTypeFormatId: null
    });

    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });

    return _columns;
  }

  generateTable = (rows: any, dataGridInstance: DataGrid, gridData: any[]) => {
    var gridColumns = [];
    const gridCurrentColums = dataGridInstance.getVisibleColumns();

    if (!gridCurrentColums.some(col => col.dataField === this.translations.IDS)) {
      dataGridInstance.addColumn({
        dataField: this.getTranslation(this.translations.IDS),
        caption: this.translations.IDS,
        visibleIndex: 0,
        allowEditing: false,
        encodeHtml: false,
        formItem: { visible: false },
      });
    }
    gridColumns.push(this.translations.IDS);


    rows.columnTitles.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (gridColumns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (gridColumns.includes(columnName));
      }
      gridColumns.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      if (!isColumn) {
        dataGridInstance.addColumn({
          dataField: columnName,
          name: row.uniqueName,
          caption: this.getTranslation(row.name),
          visibleIndex: i + 1,
          allowEditing: false,
          dataType: this.getDataType(row.propertyTypeId, row.propertyTypeFormatId),
          encodeHtml: false,
          cellTemplate: this.assignDefaultCellTemplate(row.propertyTypeId, row.propertyTypeFormatId)
        });
      }
    });

    gridColumns.push('id');
    if (!gridCurrentColums.some(col => col.dataField === 'id')) {
      dataGridInstance.addColumn({
        dataField: 'id',
        caption: 'id',
        visible: false,
        formItem: { visible: false },
        allowEditing: false
      });
    }

    this.generateTableData(dataGridInstance, gridColumns, gridData);
  }

  generateTableData(dataGridInstance: DataGrid, gridColumns: any[], gridData: any[]) {
    var gridItems = [];
    gridData.forEach((row, i) => {
      let gridItem = '';
      gridColumns.forEach((column, j) => {
        
          gridItem += '"' + column + '" : "' + this.getTranslation(row['key' + j]) + '",';
      });
      gridItems.push(JSON.parse('{ ' + gridItem.slice(0, -1) + '}'));
    });

    var dataSource = {
      store: {
        type: 'array',
        key: 'id',
        data: gridItems
      }
    };
    dataGridInstance.option('dataSource', dataSource as any);
  }

  getDataType(fieldType: number, format: number): 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' {
    switch (fieldType) {
      case ProductPropertyTypeEnum.NUMBER: {
        return 'number';
      }
      case ProductPropertyTypeEnum.DECIMAL: {
        return 'number';
      }
      case ProductPropertyTypeEnum.TEXT: {
        return 'string';
      }
      case ProductPropertyTypeEnum.DATE: {
        if (format == DateTimeTypeEnum.FULL_DATE_TIME) return 'datetime';
        return 'date';
      }
      case ProductPropertyTypeEnum.BOOLEAN: {
        return 'boolean';
      }
      case ProductPropertyTypeEnum.COLOR: {
        return 'string';
      }
      case ProductPropertyTypeEnum.URL: {
        return 'string';
      }
      default: break;
    }
    return
  }

  private parseObject(rows: Array<TransactionMonitorLotRow>, columns: Array<TransactionMonitorLotColumn>): any[] {
    var data = [];

    // Ignore parsing if there are no columns defined
    if (columns.length === 0) {
      return data;
    }

    rows.forEach(row => {
      if (row.groupedIds) {
        if (row.groupedIds.length == 0) {
          row.values.unshift("");
        }
        else {
          let ids = "";
          row.groupedIds.forEach((id, i) => {
            ids += id;
            if (i != (row.groupedIds.length - 1)) {
              ids += ", ";
            }
          });
          row.values.unshift(ids);
        }

      }
      const obj = new Object();
      columns.forEach((column, i) => {
        if (column.propertyTypeId === ProductPropertyTypeEnum.DATE) {
          if (column.propertyTypeFormatId == DateTimeTypeEnum.SHORT_DATE) {
            let date = new Date(row.values[i]);
            date.setHours(12);
            date.setMinutes(0);
            obj['key' + i] = (date).toISOString();
          }
          else {
            obj['key' + i] = this.addLineBreaks(row.values[i], column.propertyTypeId, column.propertyTypeFormatId);
          }
        }
        else if (column.propertyTypeId === ProductPropertyTypeEnum.TEXT
          || column.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN
          || column.propertyTypeId === ProductPropertyTypeEnum.IMAGE
          || column.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA
          || isNaN(Number(row.values[i]))) {
          // Add line breaks for string value
          let objValue = this.getTranslation(row.values[i]);
          objValue = this.addLineBreaks(objValue, column.propertyTypeId);
          obj['key' + i] = objValue;
        } else {
          obj['key' + i] = row.values[i]; //this.format(Number(row.values[i]), column.propertyTypeId);
        }
      });
      obj['__item__'] = row;

      data.push(obj);
    });

    return data;
  }

  assignDefaultCellTemplate(propertyTypeId: ProductPropertyTypeEnum, propertyTypeFormatId?: UrlTypeEnum) {
    /*if (propertyTypeId === ProductPropertyTypeEnum.URL && propertyTypeFormatId === UrlTypeEnum.IMAGE_URL) {
      return 'imageCellTemplate';
    } else */if (propertyTypeId === ProductPropertyTypeEnum.TEXT) {
      return 'textCellTemplate';
    } else {
      return 'cellTemplate';
    }
  }
}
