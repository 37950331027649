export class AdditionalMenuItem {
  externalAppId: number;
  name: string;
  applicationUrl: string;
  openInNewTab: boolean;
  menuType: number;
  menuItemName: string;
  setAsStartPage: boolean = false;
  submenuId: number;
  sysName: string;
  content: string;
  orderNumber: number;
  menuOption: number;
  accessLevels: Array<number> = [];
  accessLevelsString: Array<string> = [];
}

export enum MenuTypes {
  URL = 1,
  CONTENT = 2,
  MENU = 3
}

export enum AccessLevel {
  PUBLIC = 0,
  PLATFORM = 1,
  AUCTION_CLUSTER = 2,
  BUYER = 3,
  SUPPLIER = 4
}
