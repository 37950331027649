import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// services
import { AdditionalMenuItemsService } from '../../services/additional-menu-items.service';
import { LanguageService } from '../../services/language.service';
import { TitleService } from '../../services/title.service';

// models
import { AdditionalMenuItem, MenuTypes } from '../../models/additional-menu-item';

export class MasterDataDropdown {
  rowId: number;
  value: string;
}

@Component({
  selector: 'additional-menu-items-container-component',
  templateUrl: 'additional-menu-items-container.component.html',
  styleUrls: ['./additional-menu-items-container.component.scss']
})
export class AdditionalMenuItemsContainerComponent implements OnInit {

  menuItem: AdditionalMenuItem;
  externalAppId: number;
  externalApplicationUrl: SafeResourceUrl;
  htmlContent: SafeHtml;
  //menuTypeChangeSubscription: Subscription;

  constructor(
    protected title: TitleService,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    //private router: Router,
    private sanitizationService: DomSanitizer,
    private dataService: AdditionalMenuItemsService,
  ) {
    //this.menuTypeChangeSubscription = this.router.events.subscribe((e: any) => {
    //  if (e instanceof NavigationEnd) {
    //    this.initializeValues();
    //  }
    //})
  }

  ngOnInit() {
    this.title.set('ADDITIONAL_MENU_ITEMS.TITLE');
    this.initializeValues();
  }

  initializeValues() {
    this.externalAppId = +this.route.snapshot.paramMap.get('menuId');

    this.dataService.getPublicMenuItem(this.externalAppId).subscribe(result => {
      this.menuItem = result;

      if (this.menuItem) {
        if (this.menuItem.menuType === MenuTypes.URL) {
          this.htmlContent = undefined;
          this.getData();

        } else if (this.menuItem.menuType === MenuTypes.CONTENT) {
          this.externalApplicationUrl = undefined;
          this.htmlContent = this.getTranslation(this.menuItem.content)
        }
      }
    });
  }

  ngOnDestroy() {
    //if (this.menuTypeChangeSubscription) {
    //  this.menuTypeChangeSubscription.unsubscribe();
    //}
  }

  getData() {
    this.title.set(this.getTranslation(this.menuItem.menuItemName));

    let url = this.menuItem.applicationUrl;
    this.externalApplicationUrl = this.sanitizationService.bypassSecurityTrustResourceUrl(url);
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }
}
